import { INTERACTION } from 'src/constants/interactions';
import StringField from 'src/models/fields/stringField';
import { hmsmsToSeconds } from 'src/utils/timelineFormat';
import EnumeratorField from './fields/enumeratorField';
import IdField from './fields/idField';

const Label = (t, field) => t(`interaction.menu.fields.menuItem.${field}`);

const actions = (t) =>
  Object.keys(INTERACTION.MENU.MENU_ITEM.ACTION).map((key) => ({
    value: INTERACTION.MENU.MENU_ITEM.ACTION[key],
    label: Label(t, `action.${key.toLowerCase()}`)
  }));
export const fields = (t) => ({
  id: new IdField('id', Label(t, 'id')),
  title: new StringField('title', Label(t, 'title'), {
    required: true,
    max: 100
  }),
  action: new EnumeratorField('action', Label(t, 'action.title'), actions(t)),
  jumpTo: new StringField('jump_to', Label(t, 'jumpTo'), {
    testFunc: (val) => !Number.isNaN(hmsmsToSeconds(val))
  }),
  jumpTime: new StringField('jump_time', Label(t, 'jumpTime')),
  link: new StringField('link', Label(t, 'link'), {
    max: 100
  }),
  timeJumpToNode: new StringField('time_jump_to_node', Label(t, 'timeJumpToNode'), {
    testFunc: (val) => !Number.isNaN(hmsmsToSeconds(val))
  })
});

export default {
  fields
};
