/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PublishVideoDisplay from 'src/containers/videoDisplay/PublishVideoDisplay';
import { sortListNumber } from 'src/utils/objectCompare';
import { useDispatch } from 'react-redux';
import * as VideoActions from 'src/actions/videoPlayer';
import ReactGA from 'react-ga';
import { isMobile } from 'react-device-detect';

import 'video-react/dist/video-react.css';

const useStyles = makeStyles((theme) => ({
  wrap: {
    // [theme.breakpoints.down('md')]: {
    //   paddingTop: theme.spacing(24)
    // },
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    backgroundColor: 'black'
  },
  top: {
    width: '100%',
    height: '100%'
  },
  topItem: {
    width: '98%'
  }
}));

const Video = ({ data, submitPass, isSubmited }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [pwd, setPwd] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = () => {
    submitPass(pwd);
  };
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(ref.current.getBoundingClientRect().width);
    dispatch(VideoActions.setVideoData(data));
    ReactGA.pageview(`${process.env.REACT_APP_PUBLIC_URL}/v/${data?.id_public}`);
  }, []);

  return (
    <>
      <Helmet>
        <title>{data?.publish?.title || 'Waocon'}</title>
        <meta name="description" content={data?.publish?.description} />
        <meta name="author" content={data?.publish?.author} />
        <meta property="og:title" content={data?.publish?.title || 'Waocon'} />
        <meta property="og:description" content={data?.publish?.description || 'Require authentication'} />
        <meta property="og:url" content={`${process.env.REACT_APP_PUBLIC_URL}/v/${data?.id_public}`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@waoconvideo" />
        <meta name="twitter:title" content={data?.publish?.title || 'Waocon'} />
        <meta name="twitter:description" content={data?.publish?.description || 'Require authentication'} />
        <meta name="twitter:card" content="player" />
        <meta name="twitter:player" content={`${process.env.REACT_APP_PUBLIC_URL}/v/${data?.id_public}`} />
      </Helmet>
      <Box className={classes.wrap}>
        <Box
          ref={ref}
          sx={{
            width: isMobile
              ? `calc(${(100 / 9) * 16}vh - ${(80 / 9) * 16}px)`
              : `calc(${(100 / 9) * 16}vh - ${(30 / 9) * 16}px)`,
            height: '100vh'
          }}
        >
          {/* VideoDisplay part */}
          {data.validate ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography color="textPrimary" variant="h6">
                {`${data.validate}. Enter passs...here!`}
              </Typography>
              <TextField
                size="small"
                onChange={(v) => setPwd(v.target.value)}
                value={pwd}
                type="password"
                placeholder="Password"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
                autoFocus
              />
              <Button color="secondary" disabled={isSubmited} type="submit" onClick={handleSubmit} variant="contained">
                {t('common.actions.submit')}
              </Button>
            </Box>
          ) : (
            <>
              <PublishVideoDisplay
                videoMedia={{
                  src: `${process.env.REACT_APP_PUBLIC_URL}${data.publish?.video}`,
                  name: data?.name,
                  loadingScreen: data?.thumbnail ? `${process.env.REACT_APP_PUBLIC_URL}${data?.thumbnail}` : null,
                  endScreen: data?.end_screen ? `${process.env.REACT_APP_PUBLIC_URL}${data?.end_screen}` : null
                }}
                stopTime={sortListNumber(data?.stop)}
                fluid={false}
                width={width}
                autoPlay={!!data?.publish.autoplay}
                defaultVolume={(data?.publish?.default_volume || 100) / 100}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

Video.propTypes = {
  data: PropTypes.object.isRequired,
  isSubmited: PropTypes.bool.isRequired,
  submitPass: PropTypes.func.isRequired
};

export default Video;
