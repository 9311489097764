import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';

export const PrejectPreviewTypes = createTypes(`
  PROJECT_PREVIEW_RESET

  PROJECT_PREVIEW_GET_PREVIEW_REQUESTING
  PROJECT_PREVIEW_GET_PREVIEW_SUCCESS
  PROJECT_PREVIEW_GET_PREVIEW_FAILURE
`);

export const reset = () => ({
  type: PrejectPreviewTypes.PROJECT_PREVIEW_RESET
});

// Get preview action
export const getPreviewSuccess = (project) => ({
  type: PrejectPreviewTypes.PROJECT_PREVIEW_GET_PREVIEW_SUCCESS,
  preview: project
});

export const getPreviewFailure = () => ({
  type: PrejectPreviewTypes.PROJECT_PREVIEW_GET_PREVIEW_FAILURE
});

export const getPreviewRequest = (id) => async (dispatch) => {
  dispatch({ type: PrejectPreviewTypes.PROJECT_PREVIEW_GET_PREVIEW_REQUESTING });
  const temp = await axiosClient.get(`/admin/project/preview/${id}`);
  return temp;
};
