/* eslint-disable */
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  IconButton,
  ListItemText,
  makeStyles,
  MenuItem,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import UserRoles from 'src/constants/UserRoles';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { routes } from 'src/configs';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`
    }
  }
}));

const AccountPopover = (props) => {
  const { text } = props;
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const classes = useStyles();
  const { userState, onLogout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await onLogout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };
  return (
    <>
      <Tooltip title={t('profileMenu.profileMenu')}>
        <Box
          component={ButtonBase}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Avatar
            src={`${userState?.avatar}`}
            sx={{
              height: 32,
              width: 32
            }}
          />
          {text && (
            <Typography
              sx={{
                marginLeft: 1
              }}
              variant="p"
            >
              {text}
            </Typography>
          )}
        </Box>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          position: 'relative',
          sx: { width: 240 }
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: '4px'
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Avatar sx={{ alignSelf: 'center', width: 64, height: 64 }} src={AccountCircleIcon} />
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2" textAlign="center">
            {`${t('profileMenu.hi')}${userState.name}`}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem className={classes.menuItem} sx={{ width: '100%' }} component={RouterLink} to="/app/projects">
            <ListItemText
              sx={{ textAlign: 'center' }}
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {t('profileMenu.myProjects')}
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            sx={{ width: '100%' }}
            onClick={() => navigate(routes.accountUpdateUser(userState.id))}
          >
            <ListItemText
              sx={{ textAlign: 'center' }}
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {t('profileMenu.myAccount')}
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button color="secondary" fullWidth onClick={handleLogout} variant="outlined">
            {t('profileMenu.logout')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

AccountPopover.propTypes = {
  text: PropTypes.string
};

export default AccountPopover;
