/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Box, Checkbox, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import * as NodeInteractionActions from 'src/actions/nodeInteraction';
import AlertModal from 'src/components/modal/AlertModal';
import { INTERACTION } from 'src/constants/interactions';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import { useModal } from 'src/hooks/useModal';
import videoController from 'src/utils/videoController';
import { getCurrentTimeVideo } from '../FlexibleTimeInteractionTimelineTracker/FlexibleTimeInteractionTimelineTracker';
import FixedTimeInteractionTimelineSlider from './FixedTimeInteractionTimelineSlider';
import { InteractionSingleTimeInput } from './InteractionTimelineSingleInput';

const useStyles = makeStyles((theme) => ({
  left: {
    paddingTop: '4px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  hide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export const FixedTimeInteractionTimelineTracker = ({
  videoDuration,
  handleTimeChange,
  config,
  canDelete,
  mode,
  videoHasStarted
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sliderValue, setSliderValue] = useState(parseFloat(config.start_time));
  const handleValueChange = useCallback((number) => {
    setSliderValue(number);
  }, []);
  const handleValueChangeSubmit = useCallback(
    (startTime, endTime) => {
      handleTimeChange({ type: config.type, start_time: startTime, end_time: endTime });
    },
    [videoDuration]
  );
  useEffect(() => {
    setSliderValue(parseFloat(config.start_time));
  }, [config.start_time]);
  const { openModal } = useModal();
  return (
    <Box sx={{ position: 'relative', marginBottom: '12px', pr: '32px' }}>
      <Grid container>
        <Grid item xs={12} sm={2} sx={{ display: 'flex', maxWidth: '100%', overflow: 'hidden', alignItems: 'center' }}>
          {canDelete && (
            <IconButton sx={{ p: 0, mr: '14px' }} onClick={() => openModal(`deleteInteraction${config.id}`)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <Box
            style={{ maxWidth: '80%' }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            <Typography
              onClick={() => {
                if (!videoHasStarted) {
                  videoController.getInstance().play();
                  videoController.getInstance().pause();
                }
                dispatch(EdittingInteractionActions.setConfig(config));
                dispatch(NodeInteractionActions.setMode(NODE_EDIT.MODE.EDIT));
                videoController.getInstance().seek(getCurrentTimeVideo(config));
              }}
              textAlign="center"
              variant="subtitle2"
              color={!config.is_active && mode === NODE_EDIT.MODE.LIST ? 'GrayText' : 'black'}
              sx={{
                cursor: 'pointer',
                textDecoration: !config.is_active && mode === NODE_EDIT.MODE.LIST ? 'line-through' : 'none',
                whiteSpace: 'nowrap',
                textAlign: 'left'
              }}
              noWrap
            >
              {config.title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7}>
          <FixedTimeInteractionTimelineSlider
            interactionType={config.type}
            sliderValue={sliderValue}
            videoDuration={videoDuration}
            handleValueChange={handleValueChange}
            handleValueChangeSubmit={handleValueChangeSubmit}
          />
        </Grid>
        <Grid item xs={3} className={classes.hide}>
          <InteractionSingleTimeInput
            sliderValue={sliderValue}
            handleValueChangeSubmit={handleValueChangeSubmit}
            videoDuration={videoDuration}
          />
        </Grid>
        <AlertModal
          modalName={`deleteInteraction${config.id}`}
          message={t('interaction.modal.confirmDelete', { interactionName: config.title })}
          confirmAction={() => dispatch(NodeInteractionActions.deleteRefetchRequest(config.id_interaction))}
        />
      </Grid>
      {mode === NODE_EDIT.MODE.LIST && (
        <Box sx={{ position: 'absolute', right: '-8px', top: '50%', transform: 'translateY(-60%)' }}>
          <Checkbox
            checked={config.is_active === 1}
            onChange={() =>
              dispatch(
                NodeInteractionActions.updateActiveRequest({
                  interactionID: config.id_interaction,
                  is_active: !config.is_active
                })
              )
            }
          />
        </Box>
      )}
    </Box>
  );
};

FixedTimeInteractionTimelineTracker.propTypes = {
  videoDuration: PropTypes.number.isRequired,
  handleTimeChange: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  canDelete: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  videoHasStarted: PropTypes.bool.isRequired
};
