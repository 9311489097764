/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import { Box, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import timelineFormat, { hmsmsToSeconds, hmsToSeconds } from 'src/utils/timelineFormat';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import videoController from 'src/utils/videoController';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { INTERACTION } from 'src/constants/interactions';

const useStyles = makeStyles((theme) => ({
  center: {
    paddingLeft: '12px',
    paddingTop: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px'
    }
  }
}));

const getDuration = (type) => {
  switch (type) {
    case INTERACTION.TYPE.MENU:
      return INTERACTION.MENU.DURATION;
    default:
  }
  return 0;
};

export const InteractionTimelineDoubleInput = ({ sliderValues, handleValueChange, handleValueChangeSubmit }) => {
  const classes = useStyles();
  const [edittingStartTimeStr, setEdittingStartTimeStr] = useState(sliderValues[0]);
  const [edittingEndTimeStr, setEdittingEndTimeStr] = useState(sliderValues[1]);
  const [editableStartTime, setEditableStartTime] = useState(false);
  const [editableEndTime, setEditableEndTime] = useState(false);

  const handleCurrentTimeLblChange = useCallback(
    (evt) => {
      if (editableStartTime) {
        setEdittingStartTimeStr(evt.target.value);
      } else if (editableEndTime) {
        setEdittingEndTimeStr(evt.target.value);
      }
    },
    [editableStartTime, editableEndTime]
  );

  const handleCurrentTimeLblFocus = useCallback(
    (field) => {
      if (field === 'start') {
        setEditableStartTime(true);
        setEdittingStartTimeStr(timelineFormat(sliderValues[0], 'hh:mm:ss.ms'));
      } else if (field === 'end') {
        setEditableEndTime(true);
        setEdittingEndTimeStr(timelineFormat(sliderValues[1], 'hh:mm:ss.ms'));
      }
    },
    [sliderValues]
  );

  const handleSubmitValue = useCallback(() => {
    if (editableStartTime) {
      let newStartTime = hmsmsToSeconds(edittingStartTimeStr);
      if (Number.isNaN(newStartTime) || newStartTime > sliderValues[1]) {
        newStartTime = sliderValues[0];
      }
      handleValueChange([newStartTime, sliderValues[1]]);
      handleValueChangeSubmit([newStartTime, sliderValues[1]]);
      setEditableStartTime(false);
    }
    if (editableEndTime) {
      let newEndTime = hmsmsToSeconds(edittingEndTimeStr);
      if (Number.isNaN(newEndTime) || newEndTime < sliderValues[0]) {
        newEndTime = sliderValues[1];
      }
      handleValueChange([sliderValues[0], newEndTime]);
      handleValueChangeSubmit([sliderValues[0], newEndTime]);
      setEditableEndTime(false);
    }
  }, [edittingStartTimeStr, edittingEndTimeStr, editableStartTime, editableEndTime, sliderValues]);
  return (
    <Box className={classes.center}>
      <TextField
        value={editableStartTime ? edittingStartTimeStr : timelineFormat(sliderValues[0], 'hh:mm:ss.ms')}
        onChange={(evt) => handleCurrentTimeLblChange(evt)}
        onFocus={() => handleCurrentTimeLblFocus('start')}
        onBlur={() => handleSubmitValue('start')}
        size="small"
        onKeyDown={(evt) => {
          if (evt.key === 'Enter') {
            evt.target.blur();
          }
        }}
        inputProps={{ style: { textAlign: 'center', width: 100 } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{ sx: { height: 30 } }}
      />

      <TextField
        value={editableEndTime ? edittingEndTimeStr : timelineFormat(sliderValues[1], 'hh:mm:ss.ms')}
        onChange={(evt) => handleCurrentTimeLblChange(evt)}
        onFocus={() => handleCurrentTimeLblFocus('end')}
        onBlur={() => handleSubmitValue('end')}
        size="small"
        onKeyDown={(evt) => {
          if (evt.key === 'Enter') {
            evt.target.blur();
          }
        }}
        inputProps={{ style: { textAlign: 'center', width: 100 } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{ sx: { height: 30 } }}
      />
    </Box>
  );
};

InteractionTimelineDoubleInput.propTypes = {
  sliderValues: PropTypes.array.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  handleValueChangeSubmit: PropTypes.func.isRequired
};
