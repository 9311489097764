import { Box, IconButton, LinearProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import InputField from 'src/components/FormControls/InputField';
import { useProjectEditTitle } from 'src/hooks/projectList/editTitle';

export const ProjectEditTitleModal = ({ modalName, project }) => {
  const { t, isShowing, isSubmitting, handleSubmit, onSubmit, fields, formItems, handleClose } = useProjectEditTitle({
    modalName,
    project
  });

  return (
    <>
      <Dialog onClose={handleClose} fullWidth open={isShowing} aria-labelledby="form-dialog-title">
        <DialogTitle>
          <Typography mt={2} variant="h4" align="center" fontWeight="bold">
            {t('projects.editTitle.title')}
          </Typography>
          <IconButton
            style={{
              top: 5,
              right: 5,
              position: 'absolute'
            }}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography component="h1" variant="h5">
            {isSubmitting && <LinearProgress />}
          </Typography>
          <InputField
            {...formItems}
            name={fields.name.name}
            label={fields.name.label}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(onSubmit)();
              }
            }}
            autoFocus
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2
            }}
          >
            <Button
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              {t('common.actions.save')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

ProjectEditTitleModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired
};
