import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CodeOffIcon from '@material-ui/icons/CodeOff';
import CodeOnIcon from '@material-ui/icons/Code';
import { useSelector } from 'react-redux';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import { useEffect } from 'react';
import videoController from 'src/utils/videoController';

export const ToggleNextPrevButton = (props) => {
  const mode = useSelector(NodeInteractionSelectors.getMode);
  const { className, setMode, on } = props;
  useEffect(() => {
    if (mode !== NODE_EDIT.MODE.LIST) {
      setMode(false);
    }
  }, [mode]);
  return (
    <Box
      className={`video-react-control video-react-button ${className}`}
      tabIndex="0"
      onClick={() => {
        if (on) {
          videoController.getInstance().play();
        } else {
          videoController.getInstance().pause();
        }
        setMode(!on);
      }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        visibility: mode === NODE_EDIT.MODE.LIST ? 'visible' : 'hidden'
      }}
    >
      {!on ? (
        <CodeOnIcon
          sx={{
            fontSize: 16
          }}
        />
      ) : (
        <CodeOffIcon sx={{ fontSize: 16 }} />
      )}
    </Box>
  );
};

ToggleNextPrevButton.propTypes = {
  className: PropTypes.string,
  setMode: PropTypes.func.isRequired,
  on: PropTypes.bool.isRequired
};
