/* eslint-disable no-unused-vars */
import { createTypes } from 'reduxsauce';
import _get from 'lodash/get';
import * as ProjectNodeActions from 'src/actions/projectNode';
import * as ModalActions from 'src/actions/modal';

export const DraggingTypes = createTypes(`
  DRAGGING_SET_DRAG_ITEM
  DRAGGING_SET_DROP_ITEM
  DRAGGING_SET_FREEZE
  DRAGGING_RESET_REQUEST
`);

export const setDragItem = ({ item, dragType }) => (dispatch, getState) => {
  const freeze = _get(getState(), 'dragging.freeze', false);
  if (!freeze) dispatch({ type: DraggingTypes.DRAGGING_SET_DRAG_ITEM, item, dragType });
};

export const setDropItem = ({ item, dropType }) => (dispatch, getState) => {
  const freeze = _get(getState(), 'dragging.freeze', false);
  if (!freeze) dispatch({ type: DraggingTypes.DRAGGING_SET_DROP_ITEM, item, dropType });
};

export const stopDrag = () => (dispatch, getState) => {
  const dragType = _get(getState(), 'dragging.dragType', null);
  const dropItem = _get(getState(), 'dragging.dropItem', null);
  const dropType = _get(getState(), 'dragging.dropType', null);
  if (dropItem && dropType === dragType) {
    if (dropItem[`${dropType}_id`]) {
      dispatch(ModalActions.modalShow('confirmReplaceAsset'));
      dispatch({ type: DraggingTypes.DRAGGING_SET_FREEZE, freeze: true });
    } else {
      dispatch(updateNode());
    }
  } else {
    dispatch(resetRequest());
  }
};
export const updateNode = () => (dispatch, getState) => {
  const dragItem = _get(getState(), 'dragging.dragItem', null);
  const dropItem = _get(getState(), 'dragging.dropItem', null);
  const dropType = _get(getState(), 'dragging.dropType', null);
  dispatch(ProjectNodeActions.updateRequest({ ...dropItem, [`${dropType}_id`]: dragItem.id }));
  dispatch(resetRequest());
};

export const resetRequest = () => ({ type: DraggingTypes.DRAGGING_RESET_REQUEST });
