/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import { Box, Button, makeStyles, Typography, Grid } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as NodeInteractionActions from 'src/actions/nodeInteraction';
import * as ProjectNodeActions from 'src/actions/projectNode';
import { Loading } from 'src/components/LoadingScreen';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import 'video-react/dist/video-react.css';
import InteractionsForm from '../../containers/interactionsForm/InteractionsForm';
import TimelineList from '../../containers/timeline/TimelineList';
import VideoDisplay from '../../containers/videoDisplay/VideoDisplay';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(2),
    minHeight: '100%',
    position: 'relative'
  },

  top: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4)
  },

  topItem: {
    width: '48%'
  }
}));

const NodeEdit = ({ setSettingsHeight, nodeID }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(NodeInteractionActions.fetchRequest(nodeID));
  }, [nodeID]);

  const node = useSelector(NodeInteractionSelectors.getNode);
  const loading = useSelector(NodeInteractionSelectors.getLoading);
  useEffect(() => {
    setSettingsHeight(window.innerHeight);
  }, []);

  const onClose = useCallback(() => {
    dispatch(ProjectNodeActions.setScreen('videoNodeSettings'));
  }, []);
  useEffect(() => {
    dispatch(NodeInteractionActions.setMode(NODE_EDIT.MODE.LIST));
    return () => dispatch(NodeInteractionActions.resetRequest());
  }, []);
  return (
    <Box className={classes.wrap}>
      <Loading loading={loading} />
      <Box sx={{ position: 'absolute', right: '4px', top: '-2px' }}>
        <Button onClick={onClose}>
          <BackIcon />
          {t('projectNode.edit.backToListNode')}
        </Button>
      </Box>
      <Grid container spacing={4}>
        {/* VideoDisplay part */}
        <Grid item sx={12} md={6} style={{ width: '100%' }}>
          <VideoDisplay
            videoMedia={{
              src: node?.video ? `${process.env.REACT_APP_PUBLIC_URL}${node?.video}` : '',
              nodeName: node?.name,
              nodeVideoName: node?.video_name
            }}
          />
        </Grid>
        {/* InteractionForm part */}
        <Grid item sx={12} md={6} style={{ width: '100%' }}>
          {node?.video ? (
            <InteractionsForm />
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <Typography textAlign="center" variant="h5">
                {t('projectNode.edit.noVideoWarning')}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      {/* TimelineList part */}
      {node?.video && <TimelineList />}
    </Box>
  );
};

NodeEdit.propTypes = {
  setSettingsHeight: PropTypes.func.isRequired,
  nodeID: PropTypes.string.isRequired
};

export default NodeEdit;
