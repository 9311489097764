/* eslint-disable no-unused-vars */
import { Box, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { isNode, useStoreState } from 'react-flow-renderer';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as DraggingActions from 'src/actions/dragging';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import * as NodeInteractionActions from 'src/actions/nodeInteraction';
import * as ProjectNodeActions from 'src/actions/projectNode';
import AlertModal from 'src/components/modal/AlertModal';
import { NODE_TYPES } from 'src/constants/node';
import * as ProjectNodeSelectors from 'src/selectors/projectNode';
import ProjectListNode from './ListNode';
import NodeEdit from './NodeEdit';
import { ProjectSettings } from './ProjectSettings';
import { ScreenNodeSettings } from './ScreenNodeSettings';
import { VideoNodeSettings } from './VideoNodeSettings';

const Project = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [settingsHeight, setSettingsHeight] = useState(0);
  const selectedArr = useStoreState((store) => store.selectedElements);
  const currentScreen = useSelector(ProjectNodeSelectors.getScreen);
  useEffect(() => {
    let screen = 'projectSettings';
    if (selectedArr) {
      if (isNode(selectedArr[0])) {
        if (selectedArr[0].type === NODE_TYPES.VIDEO_NODE) {
          screen = 'videoNodeSettings';
        } else {
          screen = 'screenNodeSettings';
        }
      }
    }
    dispatch(ProjectNodeActions.setScreen(screen));
  }, [selectedArr]);
  useEffect(() => dispatch(EdittingInteractionActions.resetRequest()), []);
  useEffect(() => dispatch(NodeInteractionActions.resetRequest()), []);
  return (
    <>
      <Helmet>
        <title>{t('breadcrumbs.project')}</title>
      </Helmet>
      <Box
        sx={{
          minHeight: '100%'
        }}
      >
        <Box sx={{ display: ['videoNodeEdit'].includes(currentScreen) ? 'hidden' : 'visible' }}>
          <Grid container>
            <Grid item xs={12}>
              {currentScreen === 'projectSettings' && <ProjectSettings setSettingsHeight={setSettingsHeight} />}
              {selectedArr && currentScreen === 'videoNodeSettings' && (
                <VideoNodeSettings setSettingsHeight={setSettingsHeight} nodeID={selectedArr[0].id} />
              )}
              {selectedArr && currentScreen === 'screenNodeSettings' && (
                <ScreenNodeSettings setSettingsHeight={setSettingsHeight} nodeID={selectedArr[0].id} />
              )}
            </Grid>
            <Grid item xs={12}>
              <ProjectListNode settingsHeight={settingsHeight} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: currentScreen === 'videoNodeEdit' ? 'visible' : 'hidden' }}>
          {selectedArr && currentScreen === 'videoNodeEdit' && (
            <NodeEdit setSettingsHeight={setSettingsHeight} nodeID={selectedArr[0].id} />
          )}
        </Box>
      </Box>
      <AlertModal
        modalName="confirmReplaceAsset"
        title={t('project.modal.confirmReplaceAssetTitle')}
        message={t('project.modal.confirmReplaceAssetMsg')}
        confirmAction={() => dispatch(DraggingActions.updateNode())}
        onClose={() => dispatch(DraggingActions.resetRequest())}
      />
    </>
  );
};

export default Project;
