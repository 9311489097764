import axios from 'axios';
import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';
import { v4 as uuid } from 'uuid';

export const ProjectDetailTypes = createTypes(`
  SET_SEARCH_TERMS
  SET_PREVIEW_MEDIA
  SET_SELECTED_FOLDER
  SET_UPLOAD_MEDIA_PROGRESS
  SET_UPLOAD_MEDIA_CANCEL_TOKEN_SOURCE
  SET_UPLOAD_MEDIA_QUEUE
  UPDATE_UPLOAD_MEDIA_QUEUE
  REMOVE_UPLOAD_MEDIA_QUEUE

  FETCH_REQUEST
  FETCH_SUCCESS
  FETCH_FAILURE

  UPLOAD_MEDIA_REQUEST
  UPLOAD_MEDIA_SUCCESS
  UPLOAD_MEDIA_FAILURE

  CANCEL_UPLOAD_MEDIA

  RESET
  PROJECT_UPDATE_SUCCESS
  PROJECT_UPDATE_FAILURE
  PROJECT_UPDATE_REQUEST

  PROJECT_CREATE_FOLDER_SUCCESS
  PROJECT_CREATE_FOLDER_FAILURE
  PROJECT_CREATE_FOLDER_REQUEST

  PROJECT_DELETE_MEDIA_SUCCESS
  PROJECT_DELETE_MEDIA_FAILURE
  PROJECT_DELETE_MEDIA_REQUEST

  PROJECT_DELETE_FOLDER_SUCCESS
  PROJECT_DELETE_FOLDER_FAILURE
  PROJECT_DELETE_FOLDER_REQUEST
`);

export const setSearchTerms = (searchTerms) => ({ type: ProjectDetailTypes.SET_SEARCH_TERMS, searchTerms });

export const setPreviewMedia = (previewMedia) => ({ type: ProjectDetailTypes.SET_PREVIEW_MEDIA, previewMedia });

export const setSelectedFolder = (selectedFolder) => ({
  type: ProjectDetailTypes.SET_SELECTED_FOLDER,
  selectedFolder
});

export const setUploadMediaProgress = (uploadMediaProgress) => ({
  type: ProjectDetailTypes.SET_UPLOAD_MEDIA_PROGRESS,
  uploadMediaProgress
});

export const setUploadMediaCancelTokenSource = (uploadMediaCancelTokenSource) => ({
  type: ProjectDetailTypes.SET_UPLOAD_MEDIA_CANCEL_TOKEN_SOURCE,
  uploadMediaCancelTokenSource
});

export const setUploadMediaQueue = (uploadMedia) => ({
  type: ProjectDetailTypes.SET_UPLOAD_MEDIA_QUEUE,
  uploadMedia
});

export const updateUploadMediaQueue = (index, progress) => ({
  type: ProjectDetailTypes.UPDATE_UPLOAD_MEDIA_QUEUE,
  index,
  progress
});

export const removeUploadMediaQueue = (id) => ({
  type: ProjectDetailTypes.REMOVE_UPLOAD_MEDIA_QUEUE,
  id
});

// Fetch ProjectDetail action
export const fetchProjectSuccess = ({ project }) => ({ type: ProjectDetailTypes.FETCH_SUCCESS, project });

export const fetchProjectFailure = () => ({
  type: ProjectDetailTypes.FETCH_FAILURE
});

export const fetchProjectRequest = (id) => async (dispatch) => {
  dispatch({ type: ProjectDetailTypes.FETCH_REQUEST });
  return axiosClient.get(`/admin/project/${id}`);
};

// Upload Media ProjectDetail action
export const uploadMediaSuccess = () => ({
  type: ProjectDetailTypes.UPLOAD_MEDIA_SUCCESS
});

export const uploadMediaFailure = () => ({
  type: ProjectDetailTypes.UPLOAD_MEDIA_FAILURE
});

export const uploadMediaRequest = ({ files, folderID, projectID, replace }) => async (dispatch) => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const formData = new FormData();
  const id = uuid();

  const onUploadProgress = (evt) => {
    dispatch({
      type: ProjectDetailTypes.UPDATE_UPLOAD_MEDIA_QUEUE,
      id,
      progress: Math.round((100 * evt.loaded) / evt.total)
    });
  };

  files.forEach((file) => {
    formData.append('files[]', file);
  });

  if (folderID) {
    formData.append('folder_id', folderID);
  }

  if (replace) {
    formData.append('replace', replace);
  }

  formData.append('project_id', projectID);

  dispatch({ type: ProjectDetailTypes.UPLOAD_MEDIA_REQUEST });

  dispatch({
    type: ProjectDetailTypes.SET_UPLOAD_MEDIA_QUEUE,
    uploadMedia: {
      id,
      source,
      progress: 0
    }
  });

  try {
    await axiosClient.post('/admin/media', formData, {
      // signal: controller.signal,
      cancelToken: source.token,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });

    return id;
  } catch (err) {
    throw new Error(id);
  }
};

export const cancelUploadMedia = () => ({
  type: ProjectDetailTypes.CANCEL_UPLOAD_MEDIA
});

export const reset = () => ({
  type: ProjectDetailTypes.RESET
});
export const updateSuccess = (updatedProject) => ({
  type: ProjectDetailTypes.PROJECT_UPDATE_SUCCESS,
  updatedProject
});

export const updateFailure = (error) => ({
  type: ProjectDetailTypes.PROJECT_UPDATE_FAILURE,
  error
});

export const updateRequest = (formData, id) => async (dispatch) => {
  dispatch({ type: ProjectDetailTypes.PROJECT_UPDATE_REQUEST });
  return axiosClient.put(`/admin/project/${id}`, formData);
};

// Create folder
export const createFolderSuccess = () => ({
  type: ProjectDetailTypes.PROJECT_CREATE_FOLDER_SUCCESS
});

export const createFolderFailure = () => ({
  type: ProjectDetailTypes.PROJECT_CREATE_FOLDER_FAILURE
});

// eslint-disable-next-line camelcase
export const createFolderRequest = ({ project_id, folder_id, name }) => async (dispatch) => {
  dispatch({ type: ProjectDetailTypes.PROJECT_CREATE_FOLDER_REQUEST });
  return axiosClient.post('/admin/folder', { project_id, folder_id, name });
};

// Delete media
export const deleteMediaSuccess = () => ({
  type: ProjectDetailTypes.PROJECT_DELETE_MEDIA_SUCCESS
});

export const deleteMediaFailure = () => ({
  type: ProjectDetailTypes.PROJECT_DELETE_MEDIA_FAILURE
});

export const deleteMediaRequest = ({ images, videos, sounds }) => async (dispatch) => {
  dispatch({ type: ProjectDetailTypes.PROJECT_DELETE_MEDIA_REQUEST });
  return axiosClient.post('/admin/media/delete', { images, videos, sounds });
};

// Delete folder
export const deleteFolderSuccess = () => ({
  type: ProjectDetailTypes.PROJECT_DELETE_FOLDER_SUCCESS
});

export const deleteFolderFailure = () => ({
  type: ProjectDetailTypes.PROJECT_DELETE_FOLDER_FAILURE
});

export const deleteFolderRequest = (folderID) => async (dispatch) => {
  dispatch({ type: ProjectDetailTypes.PROJECT_DELETE_FOLDER_REQUEST });
  return axiosClient.delete(`/admin/folder/${folderID}`);
};

export const autoFetchRequest = (id) => async (dispatch) => {
  try {
    dispatch({ type: ProjectDetailTypes.FETCH_REQUEST });
    const rs = await axiosClient.get(`/admin/project/${id}`);
    dispatch(fetchProjectSuccess(rs));
  } catch (e) {
    dispatch(fetchProjectFailure(e));
  }
};
