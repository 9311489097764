import { createSelector } from 'reselect';

const nodeInteractionState = (state) => state.nodeInteraction;

export const getInteractionList = createSelector(
  nodeInteractionState,
  (nodeInteraction) => nodeInteraction.node?.interactions || nodeInteraction?.interactions || []
);

export const getInteraction = createSelector(
  (state) => state.nodeInteraction.node?.interactions || state.nodeInteraction?.interactions || [],
  (_, id) => id,
  (list, id) => list.find((interaction) => interaction.id === id)
);

export const getIsEditting = createSelector(
  (state) => state.nodeInteraction.edittingInteraction,
  (edittingInteraction) => !!edittingInteraction.type
);
export const getEdittingInteractionConfig = createSelector(
  nodeInteractionState,
  (nodeInteraction) => nodeInteraction.edittingInteraction
);

export const getMode = createSelector(nodeInteractionState, (nodeInteraction) => nodeInteraction.mode);

export const getNode = createSelector(nodeInteractionState, (nodeInteraction) => nodeInteraction.node);

export const getNumOfInteraction = createSelector(
  nodeInteractionState,
  (nodeInteraction) => nodeInteraction.node.interactions.length
);

export const getLoading = createSelector(nodeInteractionState, (nodeInteraction) => nodeInteraction.loading);

export const getMenuButtonEnable = createSelector(
  nodeInteractionState,
  (nodeInteraction) => nodeInteraction.node.menuButtonEnable
);
