/* eslint-disable no-unused-vars */
import { makeStyles, Slider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import React, { useMemo } from 'react';
import 'rc-slider/assets/index.css';
import './FlexibleTimeInteractionTimelineSlider.css';

const FlexibleTimeInteractionTimelineSlider = ({
  handleValueChange,
  videoDuration,
  sliderValues,
  handleValueChangeSubmit
}) => {
  const value = sliderValues.map((val) => val * 100);
  const max = useMemo(() => Number.parseFloat(videoDuration).toFixed(2) * 100, [videoDuration]);
  return (
    <Range
      // allowCross={false}
      // pushable
      min={0}
      tabIndex={[0, 1]}
      max={max}
      value={value}
      draggableTrack
      step={1}
      onAfterChange={(newVals) => handleValueChangeSubmit(newVals.map((val) => val / 100))}
      onChange={(_newVals, p1, p2) => {
        // eslint-disable-next-line prefer-const
        let newVals = _newVals;
        // if (_newVals[0] !== sliderValues[0] && _newVals[1] === sliderValues[1]) {
        //   newVals = [Math.min(_newVals[0], sliderValues[1] - minDistance), sliderValues[1]];
        // } else if (_newVals[0] === sliderValues[0] && _newVals[1] !== sliderValues[1]) {
        //   newVals = [sliderValues[0], Math.max(_newVals[1], sliderValues[0] + minDistance)];
        // }
        handleValueChange(newVals.map((val) => val / 100));
      }}
    />
  );
};

FlexibleTimeInteractionTimelineSlider.propTypes = {
  videoDuration: PropTypes.number.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  sliderValues: PropTypes.array.isRequired,
  handleValueChangeSubmit: PropTypes.func.isRequired
};

export default FlexibleTimeInteractionTimelineSlider;
