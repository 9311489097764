import { Box, experimentalStyled, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import PublishVideoDisplay from 'src/containers/videoDisplay/PublishVideoDisplay';
import * as ViewVideoActions from 'src/actions/viewVideo';
import { sortListNumber } from 'src/utils/objectCompare';

const VideoLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const useStyles = makeStyles((theme) => ({
  wrap: {
    // paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black'
  },
  top: {
    width: '100%',
    height: '100%'
  },
  topItem: {
    width: '98%'
  }
}));

const PreviewVideoLayout = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
  }, [data]);
  useEffect(() => {
    const fetchData = async () => {
      const d = await dispatch(ViewVideoActions.fetchPreviewRequest({ id }));
      if (d) setData(d);
    };
    return fetchData();
  }, []);
  return (
    <>
      <VideoLayoutRoot>
        {data && (
          <Box className={classes.wrap}>
            <Box ref={ref} sx={{ width: `calc(${(100 / 9) * 16}vh - ${(30 / 9) * 16}px)`, height: '100vh' }}>
              <PublishVideoDisplay
                videoMedia={{
                  src: `${process.env.REACT_APP_PUBLIC_URL}${data.publish?.video}`,
                  name: data?.name
                }}
                stopTime={sortListNumber(data?.stop)}
                fluid={false}
                width={width}
                autoPlay={!!data?.publish.autoplay}
                defaultVolume={(data?.publish?.default_volume || 100) / 100}
              />
            </Box>
          </Box>
        )}
      </VideoLayoutRoot>
    </>
  );
};

export default PreviewVideoLayout;
