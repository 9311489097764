import * as yup from 'yup';
import { i18n } from 'src/i18n';
import GenericField from 'src/models/fields/genericField';

export default class BooleanField extends GenericField {
  constructor(name, label, { yesLabel = undefined, noLabel = undefined, yesValue = true } = {}) {
    super(name, label);
    this.yesValue = yesValue;
    this.yesLabel = yesLabel || i18n.t('common.yes');
    this.noLabel = noLabel || i18n.t('common.no');
  }

  forTable = (overrides) => {
    const defaultRender = (value) => (value === this.yesValue ? this.yesLabel : this.noLabel);

    const { title = this.label, sorter = true, dataIndex = this.name, render = defaultRender, ...others } =
      overrides || {};

    return {
      ...others,
      title,
      sorter,
      dataIndex,
      render
    };
  };

  forView = (value) => (value ? this.yesLabel : this.noLabel);

  forFormInitialValue = (value) => value;

  forForm = () => yup.bool().default(false).label(this.label);

  forFilter = () => yup.bool().label(this.label);

  forExport = () => yup.bool().nullable(true).default(false).label(this.label);

  forImport = () => yup.bool().default(false).label(this.label);
}
