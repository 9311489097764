import { createSelector } from 'reselect';

const draggingState = (state) => state.dragging;

export const getDroptable = createSelector(
  draggingState,
  (dragging) => !!dragging.dropItem && !!dragging.dragItem && dragging.dragType === dragging.dropType
);

export const getDnDMode = createSelector(draggingState, (dragging) => !!dragging.dragItem);
