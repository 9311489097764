/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { Box, Checkbox, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import * as NodeInteractionActions from 'src/actions/nodeInteraction';
import AlertModal from 'src/components/modal/AlertModal';
import { INTERACTION } from 'src/constants/interactions';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import { useModal } from 'src/hooks/useModal';
import videoController from 'src/utils/videoController';
import FixedTimeInteractionTimelineSlider from './FlexibleTimeInteractionTimelineSlider';
import { InteractionTimelineDoubleInput } from './InteractionTimelineDoubleInput';

const getMinDuration = (type) => {
  switch (type) {
    case INTERACTION.TYPE.IMAGE:
      return INTERACTION.IMAGE.MIN_DURATION;
    default:
  }
  return 0;
};

export const getCurrentTimeVideo = (config) => {
  if (config.type === INTERACTION.TYPE.JUMP_TO) {
    return parseInt(config.jump.jump_time, 10);
  }
  return config.start_time;
};

const useStyles = makeStyles((theme) => ({
  hide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

export const FlexibleTimeInteractionTimelineTracker = ({
  videoDuration,
  handleTimeChange,
  config,
  canDelete,
  mode,
  videoHasStarted
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const interactionMinDuration = useMemo(() => getMinDuration(config.type), [config.type]);
  const [sliderValues, setSliderValues] = useState([parseFloat(config.start_time), parseFloat(config.end_time)]);
  const handleValueChange = useCallback((valuesArr) => {
    setSliderValues(valuesArr);
  }, []);
  const handleValueChangeSubmit = useCallback((values) => {
    const startTime = values[0];
    const endTime = values[1];
    handleTimeChange({ type: config.type, start_time: startTime, end_time: endTime });
  }, []);

  const { openModal } = useModal();

  useEffect(() => {
    setSliderValues([config.start_time, config.end_time]);
  }, [config.start_time, config.end_time]);
  return (
    <Box sx={{ position: 'relative', marginBottom: '12px', pr: '32px' }}>
      <Grid container>
        <Grid item xs={12} sm={2} sx={{ display: 'flex', maxWidth: '100%', overflow: 'hidden', alignItems: 'center' }}>
          {canDelete && (
            <IconButton sx={{ p: 0, mr: '14px' }} onClick={() => openModal(`deleteInteraction${config.id}`)}>
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <Box style={{ maxWidth: '80%' }} sx={{ display: 'flex', justifyContent: 'center', paddingRight: '20px' }}>
            <Typography
              onClick={() => {
                if (!videoHasStarted) {
                  videoController.getInstance().play();
                  videoController.getInstance().pause();
                }
                dispatch(EdittingInteractionActions.setConfig(config));
                dispatch(NodeInteractionActions.setMode(NODE_EDIT.MODE.EDIT));
                videoController.getInstance().seek(getCurrentTimeVideo(config));
              }}
              textAlign="center"
              variant="subtitle2"
              color={!config.is_active && mode === NODE_EDIT.MODE.LIST ? 'GrayText' : 'black'}
              sx={{
                cursor: 'pointer',
                textDecoration: !config.is_active && mode === NODE_EDIT.MODE.LIST ? 'line-through' : 'none',
                whiteSpace: 'nowrap',
                textAlign: 'left'
              }}
              noWrap
            >
              {config.title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} style={{ display: 'flex', alignItems: 'center' }}>
          <FixedTimeInteractionTimelineSlider
            sliderValues={sliderValues}
            videoDuration={videoDuration}
            minDistance={interactionMinDuration}
            handleValueChange={handleValueChange}
            handleValueChangeSubmit={handleValueChangeSubmit}
          />
        </Grid>
        <Grid item xs={3} className={classes.hide}>
          <InteractionTimelineDoubleInput
            sliderValues={sliderValues}
            handleValueChange={handleValueChange}
            handleValueChangeSubmit={handleValueChangeSubmit}
          />
        </Grid>
        <AlertModal
          modalName={`deleteInteraction${config.id}`}
          message={t('interaction.modal.confirmDelete', { interactionName: config.title })}
          confirmAction={() => dispatch(NodeInteractionActions.deleteRefetchRequest(config.id_interaction))}
        />
      </Grid>
      {mode === NODE_EDIT.MODE.LIST && (
        <Box sx={{ position: 'absolute', right: '-8px', top: '50%', transform: 'translateY(-50%)' }}>
          <Checkbox
            checked={config.is_active === 1}
            onChange={() =>
              dispatch(
                NodeInteractionActions.updateActiveRequest({
                  interactionID: config.id_interaction,
                  is_active: !config.is_active
                })
              )
            }
          />
        </Box>
      )}
    </Box>
  );
};

FlexibleTimeInteractionTimelineTracker.propTypes = {
  videoDuration: PropTypes.number.isRequired,
  handleTimeChange: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  canDelete: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  videoHasStarted: PropTypes.bool.isRequired
};
