import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core';
import { experimentalStyled, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '../../icons/Menu';
import AccountPopover from '../AccountPopover';
import LanguagePopover from '../LanguagePopover';
import Logo from '../Logo';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      objectFit: 'contain'
    }
  }
}));

const DashboardNavbar = (props) => {
  const classes = useStyles();
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
          <RouterLink to="/" style={{ display: 'flex' }}>
            <Logo className={classes.logo} />
          </RouterLink>
        </Box>
        <Box sx={{ mr: 2 }}>
          <LanguagePopover />
        </Box>
        <Box sx={{ mr: 1 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
