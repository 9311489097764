import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { ReactFlowProvider } from 'react-flow-renderer';
import 'src/mixins/chartjs';
import routes from 'src/routes';
import { createCustomTheme } from './theme';
import useSettings from './hooks/useSettings';

const App = () => {
  const routing = useRoutes(routes);
  const { settings } = useSettings();
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });
  return (
    <ThemeProvider theme={theme}>
      <ReactFlowProvider>
        <CssBaseline />
        {routing}
      </ReactFlowProvider>
    </ThemeProvider>
  );
};

export default App;
