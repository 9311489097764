import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import { getInteractionDisplay } from './InteractionsDisplay';

export const EdittingInteractionsDisplay = ({ videoBounding }) => {
  const edittingInteraction = useSelector(EdittingInteractionSelectors.getConfig);
  return <>{getInteractionDisplay({ interaction: edittingInteraction, videoBounding, isEditting: true })}</>;
};

EdittingInteractionsDisplay.propTypes = {
  videoBounding: PropTypes.object.isRequired
};
