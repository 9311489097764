/* eslint-disable no-unused-vars */
import { Box, Grid } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import * as NodeInteractionActions from 'src/actions/nodeInteraction';
import { INTERACTION } from 'src/constants/interactions';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import { FixedTimeInteractionTimelineTracker } from 'src/containers/timeline/FixedTimeInteractionTimelineTracker/FixedTimeInteractionTimelineTracker';
import { FixedTimeInteractionTimelineTrackerJump } from 'src/containers/timeline/FixedTimeInteractionTimelineTrackerJump/FixedTimeInteractionTimelineTrackerJump';
import { FlexibleTimeInteractionTimelineTracker } from 'src/containers/timeline/FlexibleTimeInteractionTimelineTracker/FlexibleTimeInteractionTimelineTracker';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';
import VideoTimelineTracker from './VideoTimelineTracker/VideoTimelineTracker';

const getListStyle = (enableReorder) => ({
  background: enableReorder ? 'lightblue' : 'transparent',
  padding: '8px',
  borderRadius: '8px'
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 1px 0`,
  padding: '2px',
  paddingTop: '4px',
  borderRadius: '8px',

  // change background colour if dragging
  background: isDragging ? '#fa8ebb' : 'transparent',

  // styles we need to apply on draggables
  ...draggableStyle
});
const TimelineList = () => {
  const dispatch = useDispatch();
  const mode = useSelector(NodeInteractionSelectors.getMode);
  const nodeInteractions = useSelector(NodeInteractionSelectors.getInteractionList);
  const videoDuration = useSelector(VideoPlayerSelectors.getDuration);
  const videoHasStarted = useSelector(VideoPlayerSelectors.getHasStarted);
  const edittingInteraction = useSelector(EdittingInteractionSelectors.getConfig);
  const handleTimeChangeListMode = useCallback(({ timeConfig, interactionID }) => {
    dispatch(NodeInteractionActions.hotUpdateRequest({ config: timeConfig, interactionID }));
  }, []);
  const handleTimeChangeCreateAndEditMode = useCallback(
    (timeConfig) => {
      if (edittingInteraction.type === INTERACTION.TYPE.JUMP_TO) {
        dispatch(EdittingInteractionActions.setJumpConfig(timeConfig));
      } else {
        dispatch(EdittingInteractionActions.setConfig(timeConfig));
      }
    },
    [edittingInteraction]
  );
  const getTimeline = useCallback(
    (config, handleTimeChange, canDelete) => {
      switch (config.type) {
        case INTERACTION.TYPE.JUMP_TO:
          return (
            <FixedTimeInteractionTimelineTrackerJump
              videoDuration={videoDuration}
              handleTimeChange={handleTimeChange}
              config={config}
              canDelete={canDelete}
              mode={mode}
              videoHasStarted={videoHasStarted}
            />
          );

        case INTERACTION.TYPE.PAUSE:
        case INTERACTION.TYPE.FORM:
          return (
            <FixedTimeInteractionTimelineTracker
              videoDuration={videoDuration}
              handleTimeChange={handleTimeChange}
              config={config}
              canDelete={canDelete}
              mode={mode}
              videoHasStarted={videoHasStarted}
            />
          );
        case INTERACTION.TYPE.MENU:
        case INTERACTION.TYPE.TEXT:
        case INTERACTION.TYPE.IMAGE:
        case INTERACTION.TYPE.HIGHLIGHT:
        case INTERACTION.TYPE.TRANSPARENT_BUTTON:
        case INTERACTION.TYPE.ZOOM:
        case INTERACTION.TYPE.LINK:
          return (
            <FlexibleTimeInteractionTimelineTracker
              videoDuration={videoDuration}
              handleTimeChange={handleTimeChange}
              config={config}
              canDelete={canDelete}
              mode={mode}
              videoHasStarted={videoHasStarted}
            />
          );

        default:
          return null;
      }
    },
    [videoDuration, mode, videoHasStarted]
  );
  const [enableReorder, setEnableReorder] = useState(false);

  return (
    <Grid container>
      <Grid item xs={12}>
        <VideoTimelineTracker
          enableReorder={enableReorder}
          onToggleReorder={() => setEnableReorder(!enableReorder)}
          mode={mode}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: '4px' }}>
        {(mode === NODE_EDIT.MODE.CREATE || mode === NODE_EDIT.MODE.EDIT) && (
          <Box sx={{ p: '8px' }}>{getTimeline(edittingInteraction, handleTimeChangeCreateAndEditMode, false)}</Box>
        )}
        {mode === NODE_EDIT.MODE.LIST && (
          <>
            <DragDropContext
              onDragEnd={(rs) => {
                if (!rs.destination) return;
                dispatch(
                  NodeInteractionActions.updateOrderRequest({ startInd: rs.source.index, endInd: rs.destination.index })
                );
              }}
            >
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(enableReorder)}>
                    {nodeInteractions.map((interaction, index) => (
                      <Draggable
                        key={`${interaction.id + interaction.type}`}
                        draggableId={`${interaction.id_interaction}`}
                        index={index}
                        isDragDisabled={!enableReorder}
                      >
                        {(draggableprovided, draggableSnapshot) => (
                          <div
                            ref={draggableprovided.innerRef}
                            {...draggableprovided.draggableProps}
                            {...draggableprovided.dragHandleProps}
                            {...(enableReorder
                              ? {
                                  style: getItemStyle(
                                    draggableSnapshot.isDragging,
                                    draggableprovided.draggableProps.style
                                  )
                                }
                              : {})}
                          >
                            {getTimeline(
                              interaction,
                              (timeConfig) => {
                                handleTimeChangeListMode({ timeConfig, interactionID: interaction.id_interaction });
                              },
                              true
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default TimelineList;
