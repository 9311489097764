import { Autocomplete, Box, Button, FormLabel, Grid, TextField, Typography } from '@material-ui/core';
import { BorderColor as BorderColorIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import InputField from 'src/components/FormControls/InputField';
import { useVideoNodeSettings } from 'src/hooks/useVideoNodeSettings';
import { fields as _nodeFields } from 'src/models/node';
import timelineFormat from 'src/utils/timelineFormat';

const InputFieldProps = {
  InputProps: {
    sx: { height: '30px' }
  }
};

export const VideoNodeSettings = ({ setSettingsHeight, nodeID }) => {
  const {
    t,
    control,
    formItems,
    onUpdate,
    handleSubmit,
    videoOptions,
    DnDModeProps,
    node,
    onClickEditNode
  } = useVideoNodeSettings(nodeID);
  const nodeFields = _nodeFields(t);
  const ref = useRef(null);
  useEffect(() => {
    setSettingsHeight(ref.current.clientHeight);
  }, []);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onUpdate)();
      }}
    >
      <Box
        ref={ref}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor: '#F1F4F7',
          p: 2,
          pb: 1
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <div
              {...DnDModeProps}
              style={{
                // 16:9
                maxWidth: '275px',
                height: '155px',
                border: '1px solid #BEC0C3',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {node.video ? (
                <video style={{ maxWidth: '100%', maxHeight: '100%' }} controls key={`${node.video}`}>
                  <source src={`${process.env.REACT_APP_PUBLIC_URL}${node.video}`} type="video/mp4" />
                  <track kind="captions" />
                </video>
              ) : (
                <img
                  width="100%"
                  height="100%"
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                  alt="video"
                  src="/static/empty_video.png"
                />
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Grid item>
              <Typography variant="h5" sx={{ textTransform: 'uppercase' }} display="inline">
                {t('projectNode.settings.title')}
              </Typography>
              <Button onClick={onClickEditNode} sx={{ pt: '0px' }}>
                <BorderColorIcon fontSize="small" />
                {t('projectNode.settings.editNode')}
              </Button>
            </Grid>
            <Grid item>
              <Grid container spacing={5}>
                <Grid item xs={6} md={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputField
                        label={nodeFields.name.label}
                        name={nodeFields.name.name}
                        {...formItems}
                        BlurOnEnter
                        OnBlur={handleSubmit(onUpdate)}
                        {...InputFieldProps}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mt: 0 }}>
                      <Controller
                        name={nodeFields.video.name}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            autoSelect
                            blurOnSelect
                            isOptionEqualToValue={(opt, value) => {
                              if (!value) return false;
                              return `${opt.id}` === `${value.id}`;
                            }}
                            {...field}
                            options={videoOptions}
                            autoHighlight
                            onChange={(_, val) => {
                              field.onChange(val);
                            }}
                            getOptionLabel={(opt) => `${opt.name}` || ''}
                            renderInput={(params) => (
                              <>
                                <FormLabel>{nodeFields.video.label}</FormLabel>
                                <TextField
                                  {...params}
                                  onBlur={handleSubmit(onUpdate)}
                                  size="small"
                                  InputProps={{
                                    ...params.InputProps,
                                    sx: { height: 30, pt: '1px !important' }
                                  }}
                                />
                              </>
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="subtitle2" display="inline" sx={{ mt: 3, ml: 1 }}>
                        {`${t('projectNode.fields.videoDuration')}: ${timelineFormat(
                          node.duration / 1000,
                          'hh:mm:ss'
                        )}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputField
                        label={nodeFields.title.label}
                        name={nodeFields.title.name}
                        {...formItems}
                        OnBlur={handleSubmit(onUpdate)}
                        {...InputFieldProps}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <FormLabel>{nodeFields.description.label}</FormLabel>
                      </Grid>
                      <Controller
                        name={nodeFields.description.name}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            onBlur={handleSubmit(onUpdate)}
                            multiline
                            rows="2"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Them button submit nay de form lang nghe enter */}
      <Button type="submit" sx={{ display: 'none' }} />
    </form>
  );
};

VideoNodeSettings.propTypes = {
  setSettingsHeight: PropTypes.func.isRequired,
  nodeID: PropTypes.string.isRequired
};
