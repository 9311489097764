import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';
import { Box, Button, Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const ColorPicker = ({ color, onColorChange, mode }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (_color) => {
    const colorValue = mode === 'hex' ? _color.hex : _color.rgb;
    onColorChange(colorValue);
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClick} sx={{ pl: '8px', pr: '8px', height: '31px', py: '4px' }}>
        <Box
          sx={{
            width: '24px',
            height: '24px',
            borderRadius: '6px',
            backgroundColor: mode === 'hex' ? color : `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${color?.a})`
          }}
        />
        <ArrowDropDownIcon />
      </Button>
      <Popover
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SketchPicker color={color} onChange={handleColorChange} />
      </Popover>
    </>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.object.isRequired,
  onColorChange: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['rgba', 'hex'])
};

ColorPicker.defaultProps = {
  mode: 'rgba'
};

export default ColorPicker;
