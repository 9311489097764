/* eslint-disable no-unused-vars */
import { Box, Card, Grid, IconButton, TextField } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputField from 'src/components/FormControls/InputField';
import SelectField from 'src/components/FormControls/SelectField';
import { INTERACTION } from 'src/constants/interactions';
import { fields as _menuItemFields } from 'src/models/menuItem';
import { useMenuItemForm } from 'src/hooks/useMenuItemForm';

const InputFieldProps = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      xs: 2,
      style: {
        marginTop: '4px',
        display: 'flex',
        alignItems: 'center'
      }
    },
    input: {
      xs: 10,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const InputFieldxs6Props = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      xs: 3,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px'
      }
    },
    input: {
      xs: 9,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const SelectFieldProps = {
  layout: {
    label: {
      xs: 3,
      style: {
        marginTop: '4px',
        display: 'flex',
        alignItems: 'center'
      }
    },
    input: {
      xs: 9,
      style: {
        marginTop: '8px'
      }
    }
  }
};
export const MenuItemForm = ({
  item,
  index,
  formItems,
  handleSubmit,
  watch,
  handleRemove,
  isLastItem,
  handleSwap,
  jumpToInteractionOptions,
  jumpToNodeOptions
}) => {
  const { t } = useTranslation();
  const menuItemFields = _menuItemFields(t);
  const watchAction = watch(`items.${index}.${menuItemFields.action.name}`);

  const { onFieldsSubmit, control } = useMenuItemForm(item);

  return (
    <>
      <Card sx={{ px: 2, py: 1, pb: 0, mb: 1 }}>
        <Grid container>
          <Grid item xs={11}>
            <InputField
              label={menuItemFields.title.label}
              name={`items.${index}.${menuItemFields.title.name}`}
              {...formItems}
              {...InputFieldProps}
              BlurOnEnter
              OnBlur={handleSubmit}
            />
          </Grid>
          <Grid item xs={1}>
            <Box sx={{ dipslay: 'flex', justifyContent: 'center' }}>
              <IconButton onClick={() => handleRemove(index)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid container spacing={0} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item xs={8}>
              <SelectField
                label={menuItemFields.action.label}
                name={`items.${index}.${menuItemFields.action.name}`}
                options={menuItemFields.action.options}
                {...formItems}
                {...SelectFieldProps}
                sx={{ height: '30px' }}
                handleChange={handleSubmit}
              />
            </Grid>
            {[INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_SPECIFIC].includes(watchAction) && (
              <Grid item xs={4}>
                <Controller
                  name="jump.jump_to"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <TextField
                      sx={{ width: '100%', maxWidth: '200px', margin: '0 10px', marginBottom: '4px' }}
                      size="small"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.target.blur();
                        }
                      }}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        onFieldsSubmit({ 'jump.jump_to': value });
                      }}
                      value={value}
                      inputProps={{
                        style: { padding: '4px', textAlign: 'center' }
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {[INTERACTION.MENU.MENU_ITEM.ACTION.LINK].includes(watchAction) && (
              <Grid item xs={4}>
                <InputField
                  label=""
                  name={`items.${index}.${menuItemFields.link.name}`}
                  {...formItems}
                  {...InputFieldProps}
                  BlurOnEnter
                  OnBlur={handleSubmit}
                />
              </Grid>
            )}
            {watchAction === INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_INTERACTION && (
              <Grid item xs={8}>
                <SelectField
                  label={t('interaction.actions.jump_to_interaction')}
                  name={`items.${index}.interaction_id`}
                  options={jumpToInteractionOptions}
                  {...formItems}
                  {...SelectFieldProps}
                  sx={{ height: '30px' }}
                  handleChange={handleSubmit}
                />
              </Grid>
            )}
            {watchAction === INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_NODE && (
              <Grid item xs={8}>
                <SelectField
                  label={t('interaction.actions.jump_to_node')}
                  name={`items.${index}.node_id`}
                  options={jumpToNodeOptions}
                  {...formItems}
                  {...SelectFieldProps}
                  sx={{ height: '30px' }}
                  handleChange={handleSubmit}
                />
              </Grid>
            )}
            {[INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_NODE].includes(watchAction) && (
              <Grid item xs={8}>
                <InputField
                  label={t('interaction.label.jumpTo')}
                  name={`items.${index}.${menuItemFields.timeJumpToNode.name}`}
                  {...formItems}
                  {...InputFieldxs6Props}
                  BlurOnEnter
                  OnBlur={handleSubmit}
                />
              </Grid>
            )}
          </Grid>

          <IconButton
            sx={{ p: '0 !important', ml: 1 }}
            disabled={index === 0}
            onClick={() => handleSwap(index, index - 1)}
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton sx={{ p: '0 !important' }} disabled={isLastItem} onClick={() => handleSwap(index, index + 1)}>
            <ArrowDownwardIcon />
          </IconButton>
        </Box>
      </Card>
    </>
  );
};

MenuItemForm.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  formItems: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleSwap: PropTypes.func.isRequired,
  isLastItem: PropTypes.bool.isRequired,
  jumpToInteractionOptions: PropTypes.array.isRequired,
  jumpToNodeOptions: PropTypes.array.isRequired
};
