/* eslint-disable no-unused-vars */
import { Box, Grid, TextField, Typography, makeStyles, Button, Divider, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InputField from 'src/components/FormControls/InputField';
import { VIDEO_CONTROLBAR_HEIGHT } from 'src/configs/layout';
import * as videoPlayerSelectors from 'src/selectors/videoPlayer';
import videoController from 'src/utils/videoController';
import UploadIcon from '@material-ui/icons/Upload';
import SendIcon from '@material-ui/icons/Send';
import ArrowRight from '@material-ui/icons/ArrowRight';
import authAxios from 'src/utils/axiosClient';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReactGA from 'react-ga';

import './FormInteractionDisplay.css';

const fixed = (x) => Number.parseFloat(x).toFixed(1);
const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white' // - Set the Input border
      },
      '&:hover fieldset': {
        borderColor: 'white'
      },

      '&.Mui-focused fieldset': {
        borderColor: '#3490dc'
      }
    }
  }
});

const schema = yup
  .object()
  .shape({
    email_field: yup.string().nullable(true).trim().label('Email field').required().email()
  })
  .required();

export const FormInteractionDisplay = ({ config, videoBounding, isEditting, isPublishMode = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const videoControl = videoController.getInstance();

  const [displayStatus, setDisplayStatus] = useState('hidden');
  const [classNameFade, setClassNameFade] = useState('');
  const currentTime = useSelector(videoPlayerSelectors.getCurrentTime);
  const videoDuration = useSelector(videoPlayerSelectors.getDuration);
  const videoData = useSelector(videoPlayerSelectors.getVideoData);
  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(
      yup
        .object()
        .shape({
          email_field: yup.string().nullable(true).trim().label(config.email_field).required().email()
        })
        .required()
    )
  });

  const formItems = useMemo(() => ({ control, formState, fullWidth: true }), [formState]);
  const fontSize = useMemo(() => config.style.fontSize * videoBounding.height, [config.style.fontSize, videoBounding]);
  const InputFieldProps = useMemo(
    () => ({
      InputProps: {
        sx: {
          height: fontSize * 2,
          color: 'white',
          fontSize
        }
      },
      autoComplete: 'off',
      labelProps: {
        sx: {
          color: 'white',
          fontSize,
          fontStyle: 'italic'
        }
      },
      layout: {
        label: {
          xs: 12,
          style: {
            display: 'flex',
            alignItems: 'center',
            marginTop: fontSize / 3
          }
        },
        input: {
          xs: 12,
          style: {
            marginTop: (fontSize * 1) / 4,
            color: 'white'
          }
        }
      }
    }),
    [fontSize]
  );
  const inputFileRef = useRef(null);
  const [inputFile, setInputFile] = useState('');
  const handleUploadMedia = useCallback((evt) => {
    const { files } = evt.target;
    if (files.length) {
      setInputFile(files[0]);
    }
  }, []);
  const onClickSubmit = useCallback(
    async (form) => {
      if (isPublishMode) {
        ReactGA.event({
          category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
          action: `${t('googleAnalyticsActions.actions.form')}`,
          label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.title} - ${t(
            'common.actions.submit'
          )}`,
          value: videoData.id
        });
      }

      if (!isEditting) {
        const formData = new FormData();
        formData.append('form_id', config.id);
        formData.append('first_field', form.first_field);
        formData.append('second_field', form.second_field);
        formData.append('third_field', form.third_field);
        if (inputFile) formData.append('file', inputFile);
        await authAxios({
          method: 'post',
          url: '/admin/form-answers',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }

      if (config.feedback_screen) {
        setDisplayStatus('submitted');
      } else {
        setDisplayStatus('closed');
        setClassNameFade('form-interaction-fadeOut');
        videoControl.play();
      }
    },
    [config.feedback_screen, inputFile]
  );
  const onClickContinue = useCallback((e) => {
    const buttonTitle = e.target.innerHTML.replace(/<[^>]+>/g, '');
    if (isPublishMode) {
      ReactGA.event({
        category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
        action: `${t('googleAnalyticsActions.actions.form')}`,
        label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.title} - ${buttonTitle}`,
        value: videoData.id
      });
    }

    setDisplayStatus('closed');
    setClassNameFade('form-interaction-fadeOut');
    videoControl.play();
  }, []);

  const onClickBackToQuestion = useCallback(() => {
    setDisplayStatus('form');
  }, []);

  // because video.pause not execute immediately

  const hasPaused = useSelector(videoPlayerSelectors.getIsPaused);
  useEffect(() => {
    // trigger
    if (
      (currentTime === videoDuration && fixed(config.end_time) === fixed(videoDuration)) ||
      (currentTime <= config.start_time &&
        config.start_time - currentTime <= 0.6 &&
        (displayStatus === 'hidden' || (config.start_time === 0 && currentTime === config.start_time)))
    ) {
      if (config.style.fadeIn) {
        setClassNameFade('form-interaction-fadeIn');
      } else {
        setClassNameFade('');
      }
      setDisplayStatus('form');
      if (isPublishMode) {
        ReactGA.event({
          category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
          action: `${t('googleAnalyticsActions.actions.form')}`,
          label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.title}`,
          value: videoData.id
        });
      }
      videoControl.pause();
    } else if (currentTime < config.start_time && config.start_time - currentTime > 0.6) {
      setDisplayStatus('hidden');
    } else if (currentTime > config.start_time && !hasPaused) {
      setDisplayStatus('closed');
    } else if (displayStatus !== 'closed') {
      videoControl.pause();
    }
  }, [currentTime, isEditting, isPublishMode]);
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        visibility: displayStatus !== 'hidden' && displayStatus !== 'closed' ? 'visible' : 'hidden',
        opacity: displayStatus !== 'hidden' && displayStatus !== 'closed' ? 1 : 0,
        background: 'rgb(2,0,36)',
        // eslint-disable-next-line no-dupe-keys
        background: 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(39,43,77,0.5) 100%)',
        color: 'white'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          p: 2,
          visibility: displayStatus === 'form' ? 'visible' : 'hidden'
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: fontSize * 1.5, fontWeight: 800 }} gutterBottom>
              {config.title}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Grid container>
              <Grid item xs={12}>
                <InputField
                  label={config.first_field}
                  name="first_field"
                  {...formItems}
                  {...InputFieldProps}
                  classes={classes}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label={config.second_field}
                  name="second_field"
                  {...formItems}
                  {...InputFieldProps}
                  classes={classes}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label={config.third_field}
                  name="third_field"
                  {...formItems}
                  {...InputFieldProps}
                  classes={classes}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  label={config.email_field}
                  name="email_field"
                  {...formItems}
                  {...InputFieldProps}
                  classes={classes}
                  helperTextProps={{
                    sx: {
                      fontSize,
                      fontStyle: 'italic',
                      ml: 0
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                p: `${fontSize * 0.5}px`,
                m: `${fontSize * 0.7}px`,
                width: '100%',
                height: fontSize * 10,
                backgroundColor: `rgba(70, 81, 92, 0.5)`,
                borderRadius: `${fontSize * 1.1}px`,
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
              onClick={() => inputFileRef.current.click()}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px dashed white',
                  borderRadius: `${fontSize * 1.1}px`
                }}
              >
                <UploadIcon sx={{ fontSize: fontSize * 1.5 }} />
                <Typography sx={{ fontSize, fontWeight: 700, pointerEvents: 'none' }}>{config.file_upload}</Typography>
                <input
                  type="file"
                  accept=".pdf, .docx, .doc"
                  ref={inputFileRef}
                  name="media"
                  style={{ display: 'none' }}
                  onChange={handleUploadMedia}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mt: -1.5 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, pr: 0 }}>
              <Button
                variant="contained"
                sx={{
                  px: '6px',
                  py: '0px',
                  height: fontSize * 2.5,
                  mr: 1,
                  fontWeight: 700,
                  fontSize: fontSize * 1,
                  fontStyle: 'Roboto',
                  bgcolor: 'white',
                  color: '#1D475A',
                  '&:hover': {
                    color: '#1D475A',
                    bgcolor: '#53C4F6'
                  }
                }}
                onClick={onClickContinue}
              >
                <Typography sx={{ fontSize, fontWeight: 600 }}>{t('common.actions.skipQuestion')}</Typography>
              </Button>
              <Button
                variant="contained"
                sx={{
                  px: '12px',
                  py: '0px',
                  height: fontSize * 2.5,
                  fontWeight: 700,
                  fontSize: fontSize * 1,
                  fontStyle: 'Roboto',
                  bgcolor: '#1D475A',
                  '&:hover': {
                    color: '#1D475A',
                    bgcolor: 'white'
                  }
                }}
                onClick={handleSubmit(onClickSubmit)}
              >
                <SendIcon sx={{ mr: 0.5, fontSize: fontSize * 1.5 }} />
                <Typography sx={{ fontSize, fontWeight: 600 }}>{t('common.actions.submit')}</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          p: 2,
          width: '100%',
          height: '100%',
          visibility: displayStatus === 'submitted' ? 'visible' : 'hidden',
          background: config.enable_background
            ? 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(77,39,77,0.5) 100%)'
            : 'transparent'
        }}
      >
        {config.enable_feedback && (
          <>
            <Typography gutterBottom sx={{ fontSize: fontSize * 1.5, fontWeight: 600 }}>
              {t('common.messages.thankyou')}
            </Typography>
            <Divider sx={{ borderColor: 'white', width: '100%', mb: 2 }} />
          </>
        )}
        <Typography sx={{ fontSize }}>{config.generic_feedback}</Typography>
        <Box sx={{ position: 'absolute', bottom: 0, right: 0, p: 2 }}>
          <Button
            variant="contained"
            sx={{
              px: '12px',
              py: '0px',
              height: fontSize * 2.5,
              fontWeight: 700,
              fontSize: fontSize * 1,
              fontStyle: 'Roboto',
              bgcolor: '#1D475A',
              '&:hover': {
                color: '#1D475A',
                bgcolor: 'white'
              }
            }}
            onClick={onClickContinue}
          >
            <ArrowRight sx={{ fontSize: fontSize * 1.5 }} />
            <Typography sx={{ fontSize, fontWeight: 600 }}>{t('common.actions.continueToVideo')}</Typography>
          </Button>
        </Box>
        {isEditting && (
          <Box sx={{ position: 'absolute', bottom: 0, left: 0, p: 2 }}>
            <Tooltip title={t('common.messages.noteBackToQuestionOnlyShowInEditMode')}>
              <Button
                variant="contained"
                sx={{
                  px: '12px',
                  py: '0px',
                  height: fontSize * 2.5,
                  fontWeight: 700,
                  fontSize: fontSize * 1,
                  fontStyle: 'Roboto',
                  bgcolor: '#1D475A',
                  '&:hover': {
                    color: '#1D475A',
                    bgcolor: 'white'
                  }
                }}
                onClick={onClickBackToQuestion}
              >
                <Typography sx={{ fontSize, fontWeight: 600 }}>{t('common.actions.backToQuestion')}</Typography>
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};

FormInteractionDisplay.propTypes = {
  videoBounding: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isPublishMode: PropTypes.bool.isRequired
};
