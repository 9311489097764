import { createSelector } from 'reselect';

const edittingInteractionState = (state) => state.edittingInteraction;

export const getType = createSelector(
  edittingInteractionState,
  (edittingInteraction) => edittingInteraction.config?.type
);

export const getConfig = createSelector(edittingInteractionState, (edittingInteraction) => edittingInteraction.config);

export const getStartTime = createSelector(
  edittingInteractionState,
  (edittingInteraction) => edittingInteraction.config?.start_time
);

export const textInteractionGetTextConfig = createSelector(edittingInteractionState, (edittingInteraction) => ({
  ...edittingInteraction.config,
  style: undefined
}));

export const textInteractionGetTextStyleConfig = createSelector(
  edittingInteractionState,
  (edittingInteraction) => edittingInteraction.config?.style
);

export const getValidateError = createSelector(
  edittingInteractionState,
  (edittingInteraction) => edittingInteraction.validateError
);
