import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LayoutContainer, LayoutHeader } from 'src/components/layout';
import { routes } from 'src/configs';
import CreateForm from './components/CreateForm';

const AccountCreate = () => {
  const { t } = useTranslation();

  return (
    <LayoutContainer
      title={t('breadcrumbs.accounts.create.title')}
      header={
        <LayoutHeader
          title={t('breadcrumbs.accounts.create.title')}
          breadcrumbs={[
            {
              path: routes.dashboard,
              name: t('breadcrumbs.dashboard')
            },
            {
              path: routes.accounts,
              name: t('breadcrumbs.accounts.title')
            }
          ]}
        />
      }
    >
      <Box>
        <CreateForm />
      </Box>
    </LayoutContainer>
  );
};

export default AccountCreate;
