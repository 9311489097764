import { INTERACTION } from 'src/constants/interactions';

export const objectSpecialEqual = (objTarget, objSrc) =>
  Object.keys(objTarget).every((key) => {
    if (!objTarget[key] && !objSrc[key]) return true;
    if (objTarget[key] && objSrc[key]) return objTarget[key].toString() === objSrc[key].toString();
    return false;
  });

export const parseF = (str, fix = 2) => parseFloat(str).toFixed(fix) || 0.0;

export const sortListInteraction = (arr, orderBy = 'asc') => {
  if (!Array.isArray(arr)) return [];
  const newArr = arr.map((e) => {
    if (e.type === INTERACTION.TYPE.JUMP_TO) {
      e.action.product_start_time = e.action.jump.product_jump_time;
      e.action.product_end_time = e.action.jump.product_jump_to;
    }
    return e;
  });
  const clause = (a, b) =>
    orderBy === 'asc'
      ? a.action.product_start_time < b.action.product_start_time
      : a.action.product_start_time > b.action.product_start_time;
  newArr.sort((a, b) => (clause(a, b) ? -1 : 1));
  return newArr;
};

export const getListStartTime = (arr) => {
  const filter = arr.map((inter) => inter.action.product_start_time).filter((e) => e || e === 0);
  return filter;
};

export const getListInterByStartTime = (arr, videoCurrentTime = 0) => {
  const arrElement = arr.filter((e) => e.action.product_start_time <= videoCurrentTime);
  const lastElement = arrElement[arrElement.length - 1];
  const filter = arr.filter((e) => e.action.product_start_time === lastElement?.action.product_start_time) || [];
  return filter;
};

export const getIndexNextTime = (listStartTime, videoCurrentTime) => {
  const nextTime = listStartTime.filter((e) => e >= videoCurrentTime)[0];
  return listStartTime.indexOf(nextTime);
};

export const sortListNumber = (listNumber, orderBy = 'asc') => {
  if (!Array.isArray(listNumber)) return [];
  const clause = (a, b) => (orderBy === 'asc' ? a < b : a > b);
  listNumber.sort((a, b) => (clause(a, b) ? -1 : 1));
  return listNumber;
};
