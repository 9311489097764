import { Box, Checkbox, FormControl, FormControlLabel, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

const SingleCheckboxField = ({ name, label, control, checkboxProps, checkboxLabelProps, handleChange, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <FormControl {...props}>
        <FormControlLabel
          {...field}
          control={<Checkbox {...checkboxProps} />}
          checked={field.value}
          onChange={(e) => {
            field.onChange(e.target.checked);
            handleChange({ [name]: e.target.checked });
          }}
          label={
            <Box sx={{ ml: 0, display: 'flex', alignItems: 'center' }}>
              <Typography color="textPrimary" variant="subtitle2" {...checkboxLabelProps}>
                {label}
              </Typography>
            </Box>
          }
          value={field.value}
        />
      </FormControl>
    )}
  />
);

SingleCheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  checkboxProps: PropTypes.object,
  checkboxLabelProps: PropTypes.object,
  handleChange: PropTypes.func
};

SingleCheckboxField.defaultProps = {
  handleChange: () => {},
  checkboxProps: {
    size: 'small'
  }
};

export default SingleCheckboxField;
