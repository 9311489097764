const RegexPattern = {
  OTP: /^([0-9]{6})$/,
  Email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  Password: /^[\w!@#$%^&*()_+\-={};':"|,.<>?]{6,32}$/,
  PasswordKeyPress: /^[!@#$%^&*()_+\-={};':"|,.<>?]$/,

  NumberChar: /\d/,

  // eslint-disable-next-line
  URL: /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
};

export default RegexPattern;
