import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import ColorField from 'src/components/FormControls/ColorField';
import InputField from 'src/components/FormControls/InputField';
import RoundedSliderField from 'src/components/FormControls/RoundedSliderField';
import SelectField from 'src/components/FormControls/SelectField';
import SingleCheckboxField from 'src/components/FormControls/SingleCheckboxField';
import { INTERACTION } from 'src/constants/interactions';
import { fields as _highlightFields } from 'src/models/highlight';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';
import * as ProjectNodeSelectors from 'src/selectors/projectNode';
import timelineFormat, { hmsmsToSeconds } from 'src/utils/timelineFormat';
import { getInteractionLabel } from 'src/utils/getInteractionStartTime';

const tabsStyle = {
  '& .MuiTabs-indicator': {
    backgroundColor: '#ED257A'
  }
};

const tabStyle = {
  maxWidth: '50%',
  width: '50%',
  '&.Mui-selected': {
    color: '#ED257A'
  }
};

const InputFieldxs12Props = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      style: {
        width: 'max-content',
        minWidth: '140px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px'
      }
    },
    input: {
      style: {
        flex: '1',
        marginTop: '8px'
      }
    }
  }
};

const InputFieldxs6Props = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      xs: 4,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px'
      }
    },
    input: {
      xs: 8,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const borderWidthProps = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      xs: 7,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px'
      }
    },
    input: {
      xs: 5,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const convertRedux2Form = (redux) => {
  const converted = {};
  converted.title = redux.title;
  converted.style = redux.style;
  if (redux.jump === null && redux.link === null) {
    converted.jump = {
      type: '0'
    };
  } else if (redux.jump === null && redux.link !== null) {
    converted.jump = {
      type: INTERACTION.ACTION.LINK
    };
    converted.link = redux.link;
  } else if (redux.jump) {
    if (redux.jump.type === INTERACTION.ACTION.JUMP_TO_INTERACTION) {
      converted.jump = {
        type: redux.jump.type,
        interaction_id: redux.jump.interaction_id
      };
    } else if (redux.jump.type === INTERACTION.ACTION.JUMP_TO_NODE) {
      converted.jump = {
        type: redux.jump.type,
        node_id: redux.jump.node_id,
        title: 'Jump',
        time_jump_to_node: redux.jump?.time_jump_to_node
          ? timelineFormat(redux.jump?.time_jump_to_node, 'hh:mm:ss.ms')
          : '00:00:00.00'
      };
    } else {
      converted.jump = {
        type: redux.jump.type,
        jump_time: timelineFormat(redux.jump.jump_time, 'hh:mm:ss.ms'),
        jump_to: timelineFormat(redux.jump.jump_to, 'hh:mm:ss.ms')
      };
    }
  }
  return converted;
};

const HighlightInteractionForm = () => {
  const { t } = useTranslation();
  const highlightFields = _highlightFields(t);
  const [formIndex, setFormIndex] = useState(0);
  const handleChange = (event, newValue) => {
    setFormIndex(newValue);
  };
  const [formHeight, setFormHeight] = useState(0);
  const form0Ref = useRef();
  const form1Ref = useRef();
  useEffect(() => {
    if (formIndex === 0) {
      setFormHeight(form0Ref.current.getBoundingClientRect().height);
    } else if (formIndex === 1) {
      setFormHeight(form1Ref.current.getBoundingClientRect().height);
    }
  }, [formIndex]);

  const edittingInteraction = useSelector(EdittingInteractionSelectors.getConfig);
  const { control, formState, watch, setValue, getValues } = useForm({
    defaultValues: convertRedux2Form(edittingInteraction),
    reValidateMode: 'onBlur'
  });
  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  const interactionList = useSelector(NodeInteractionSelectors.getInteractionList);
  const jumpToInteractionOptions = useMemo(
    () =>
      interactionList.map((inter) => ({
        label: getInteractionLabel(inter),
        value: inter.id_interaction
      })),
    [interactionList]
  );

  // Handle jump to node
  const nodeList = useSelector(ProjectNodeSelectors.getListJumpNode);
  const jumpToNodeOptions = useMemo(() =>
    nodeList.map((node) => ({
      label: node.name,
      value: node.id
    }))
  );

  const watchActionType = watch('jump.type');
  const firstRenderRef = useRef(true);
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else if (
      [
        INTERACTION.ACTION.JUMP_TO_NODE,
        INTERACTION.ACTION.JUMP_TO_END,
        INTERACTION.ACTION.JUMP_TO_START,
        INTERACTION.ACTION.JUMP_TO_SPECIFIC,
        INTERACTION.ACTION.JUMP_TO_SPECIFIC_SCHEDULER
      ].includes(watchActionType)
    ) {
      setValue('jump.jump_time', '00:00:00.00');
      setValue('jump.jump_to', '00:00:00.00');
      setValue('jump.time_jump_to_node', '00:00:00.00');
    }
    setFormHeight(form0Ref.current.getBoundingClientRect().height);
  }, [watchActionType]);
  const dispatch = useDispatch();

  const videoDuration = useSelector(VideoPlayerSelectors.getDuration);
  const convertJumpFields2Redux = useCallback(
    (values) => {
      const converted = {};
      if (values.type === '0') {
        converted.jump = null;
        converted.link = null;
      } else if (values.type === INTERACTION.ACTION.LINK) {
        converted.jump = null;
        converted.link = '';
      } else if (values.type === INTERACTION.ACTION.JUMP_TO_INTERACTION) {
        converted.jump = {
          type: values.type,
          interaction_id: values.interaction_id
        };
      } else if (values.type === INTERACTION.ACTION.JUMP_TO_NODE) {
        converted.jump = {
          type: values.type,
          node_id: values.node_id,
          time_jump_to_node: hmsmsToSeconds(values.time_jump_to_node) || 0
        };
      } else {
        let jumpToSecs;
        if (values.type === INTERACTION.ACTION.JUMP_TO_START) {
          jumpToSecs = 0;
        } else if (values.type === INTERACTION.ACTION.JUMP_TO_END) {
          jumpToSecs = videoDuration;
        } else {
          jumpToSecs = hmsmsToSeconds(values.jump_to) || 0;
        }
        const jumpTimeSecs = hmsmsToSeconds(values.jump_time) || 0;
        converted.jump = {
          type: values.type,
          jump_to: jumpToSecs,
          jump_time: jumpTimeSecs
        };
        converted.link = null;

        setValue('jump.jump_to', timelineFormat(jumpToSecs, 'hh:mm:ss.ms'));
        setValue('jump.jump_time', timelineFormat(jumpTimeSecs, 'hh:mm:ss.ms'));
      }
      return converted;
    },
    [videoDuration]
  );

  const onFieldsSubmit = useCallback((field) => {
    let submitField = field;
    const key = Object.keys(field)[0];
    if (
      [
        'jump.type',
        'jump.jump_to',
        'jump.jump_time',
        'jump.time_jump_to_node',
        'jump.interaction_id',
        'jump.node_id'
      ].includes(key)
    ) {
      const jumpValues = getValues('jump');
      submitField = convertJumpFields2Redux(jumpValues);
    }
    dispatch(EdittingInteractionActions.setConfig(submitField));
  });
  const onStyleFieldsSubmit = useCallback((field) => {
    const key = Object.keys(field)[0];
    // remove prefix 'style.'
    dispatch(EdittingInteractionActions.setStyleConfig({ [key.slice(6)]: field[key] }));
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs sx={tabsStyle} value={formIndex} onChange={handleChange}>
          <Tab sx={tabStyle} label={t('interaction.interactionTitle')} id="highlight-interaction" />
          <Tab sx={tabStyle} label={t('interaction.styleTitle')} id="highlight-style" />
        </Tabs>
      </Box>
      <Box sx={{ position: 'relative', p: 1, height: formHeight }}>
        <Box
          sx={{ position: 'absolute', visibility: formIndex === 0 ? 'visible' : 'hidden', width: '100%' }}
          ref={form0Ref}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputField
                label={highlightFields.title.label}
                name="title"
                {...formItems}
                {...InputFieldxs12Props}
                BlurOnEnter
                OnBlur={onFieldsSubmit}
              />
            </Grid>
            <Grid item xs={12}>
              <ColorField
                name="style.backgroundColor"
                label={highlightFields.backgroundColor.label}
                {...formItems}
                {...InputFieldxs12Props}
                mode="rgba"
                onChange={onStyleFieldsSubmit}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectField
                label={highlightFields.action.label}
                name="jump.type"
                options={highlightFields.action.options}
                {...formItems}
                {...InputFieldxs12Props}
                sx={{ height: '30px' }}
                handleChange={onFieldsSubmit}
              />
            </Grid>
            {watchActionType === INTERACTION.ACTION.JUMP_TO_NODE && (
              <>
                <Grid item xs={12}>
                  <SelectField
                    label={t('interaction.actions.jump_to_node')}
                    name="jump.node_id"
                    options={jumpToNodeOptions}
                    {...formItems}
                    {...InputFieldxs12Props}
                    sx={{ height: '30px' }}
                    handleChange={onFieldsSubmit}
                  />
                </Grid>
                {getValues('jump.node_id') !== undefined ? (
                  <Grid item xs={6}>
                    <InputField
                      label={highlightFields.jumpTo.label}
                      name="jump.time_jump_to_node"
                      {...formItems}
                      {...InputFieldxs6Props}
                      BlurOnEnter
                      OnBlur={onFieldsSubmit}
                    />
                  </Grid>
                ) : (
                  <div />
                )}
              </>
            )}
            {watchActionType === INTERACTION.ACTION.JUMP_TO_INTERACTION && (
              <Grid item xs={12}>
                <SelectField
                  label={highlightFields.jumpToInteraction.label}
                  name="jump.interaction_id"
                  options={jumpToInteractionOptions}
                  {...formItems}
                  {...InputFieldxs12Props}
                  sx={{ height: '30px' }}
                  handleChange={onFieldsSubmit}
                />
              </Grid>
            )}
            {watchActionType === INTERACTION.ACTION.JUMP_TO_SPECIFIC && (
              <Grid item xs={6}>
                <InputField
                  label={highlightFields.jumpTo.label}
                  name="jump.jump_to"
                  {...formItems}
                  {...InputFieldxs6Props}
                  BlurOnEnter
                  OnBlur={onFieldsSubmit}
                />
              </Grid>
            )}
            {watchActionType === INTERACTION.ACTION.JUMP_TO_SPECIFIC_SCHEDULER && (
              <>
                <Grid item xs={6}>
                  <InputField
                    label={highlightFields.jumpTime.label}
                    name="jump.jump_time"
                    {...formItems}
                    {...InputFieldxs6Props}
                    BlurOnEnter
                    OnBlur={onFieldsSubmit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    label={highlightFields.jumpTo.label}
                    name="jump.jump_to"
                    {...formItems}
                    {...InputFieldxs6Props}
                    BlurOnEnter
                    OnBlur={onFieldsSubmit}
                  />
                </Grid>
              </>
            )}
            {watchActionType === INTERACTION.ACTION.LINK && (
              <Grid item xs={12}>
                <InputField
                  label={highlightFields.link.label}
                  name="link"
                  {...formItems}
                  {...InputFieldxs12Props}
                  placeholder="Example: https://www.google.com/"
                  BlurOnEnter
                  OnBlur={onFieldsSubmit}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        {/* style form */}
        <Box
          sx={{ position: 'absolute', visibility: formIndex === 1 ? 'visible' : 'hidden', width: '100%' }}
          ref={form1Ref}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <RoundedSliderField
                name="style.borderRadius"
                label={t('interaction.style.borderRadius')}
                {...formItems}
                onChange={onStyleFieldsSubmit}
                valueLabelDisplay="on"
              />
            </Grid>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={11}>
                  <InputField
                    label={t('interaction.style.border')}
                    name="style.borderWidth"
                    {...formItems}
                    {...borderWidthProps}
                    BlurOnEnter
                    OnBlur={onStyleFieldsSubmit}
                    OnMouseUp={onStyleFieldsSubmit}
                    type="number"
                    inputProps={{ min: 0, max: 10 }}
                  />
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                  px
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sx={{ ml: -3 }}>
              <SelectField
                name="style.borderStyle"
                options={highlightFields.borderRadius.options}
                {...formItems}
                {...InputFieldxs6Props}
                handleChange={onStyleFieldsSubmit}
                sx={{ height: '30px' }}
              />
            </Grid>
            <Grid item xs={2} sx={{ ml: 2 }}>
              <ColorField name="style.borderColor" {...formItems} onChange={onStyleFieldsSubmit} />
            </Grid>
            <Grid item xs={6}>
              <SingleCheckboxField
                label={highlightFields.fadeIn.label}
                name="style.fadeIn"
                {...formItems}
                handleChange={onStyleFieldsSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <SingleCheckboxField
                label={highlightFields.fadeOut.label}
                name="style.fadeOut"
                {...formItems}
                handleChange={onStyleFieldsSubmit}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default HighlightInteractionForm;
