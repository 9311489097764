import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@material-ui/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import EmailField from 'src/components/FormControls/EmailField';
import PasswordField from 'src/components/FormControls/PasswordField';
import RegexPattern from 'src/constants/RegexPattern';
import { useAuth } from 'src/hooks';
import * as Yup from 'yup';

const LoginForm = () => {
  const theme = useTheme();

  const { t } = useTranslation();

  const { onLogin } = useAuth();

  const [isFPdHover, setIsFPdHover] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.mixed()
      .test('required', t('auth.validate.email.required'), (value) => value.trim())
      .test('format', t('auth.validate.email.format'), (value) => RegexPattern.Email.test(value)),

    password: Yup.string().required(t('auth.validate.password.required'))
  });

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(schema)
  });

  const { isSubmitting } = formState;

  return (
    <form noValidate onSubmit={handleSubmit(onLogin)}>
      <EmailField
        required
        autoFocus
        name="email"
        label={t('auth.label.email')}
        control={control}
        formState={formState}
        disabled={isSubmitting}
      />
      <PasswordField
        required
        fullWidth
        name="password"
        label={t('auth.label.password')}
        control={control}
        formState={formState}
        disabled={isSubmitting}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          textDecoration: isFPdHover ? 'underline' : 'none',
          color: isFPdHover ? theme.palette.primary.main : theme.palette.text.primary
        }}
        to="/forgot-password"
        component={RouterLink}
        onMouseEnter={() => setIsFPdHover(true)}
        onMouseLeave={() => setIsFPdHover(false)}
      >
        {t('auth.label.forgotPasswordQs')}
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button fullWidth color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
          {t('auth.label.login')}
        </Button>
      </Box>
    </form>
  );
};

export default LoginForm;
