/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Box, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import GridOffIcon from '@material-ui/icons/GridOff';
import GridOnIcon from '@material-ui/icons/GridOn';
import PanToolIcon from '@material-ui/icons/LowPriorityTwoTone';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as VideoPlayerActions from 'src/actions/videoPlayer';
import { INTERACTION } from 'src/constants/interactions';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import { VideoTimelineInput } from './VideoTimelineInput';
import VideoTimelineSlider from './VideoTimelineSlider';

const useStyles = makeStyles((theme) => ({
  wrap: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  left: {
    display: 'flex',
    marginTop: '12px',
    alignItems: 'flex-end',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  right: {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  actionButton: {
    [theme.breakpoints.up('sx')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      pr: 4
    }
  }
}));

const VideoTimelineTracker = ({ enableReorder, onToggleReorder, mode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showVideoGridState } = useVideoPlayer();
  const handleToggleVideoGridClick = useCallback(() => {
    dispatch(VideoPlayerActions.setShowVideoGrid(!showVideoGridState));
  }, [showVideoGridState]);
  return (
    <Box sx={{ pr: '40px', pl: '8px' }}>
      <Grid container>
        <Grid item xs={12} sm={2} className={classes.actionButton}>
          <IconButton onClick={handleToggleVideoGridClick}>
            {showVideoGridState ? <GridOffIcon /> : <GridOnIcon />}
          </IconButton>
          {mode === NODE_EDIT.MODE.LIST && (
            <Tooltip title="On/Off reorder">
              <IconButton onClick={onToggleReorder}>
                <PanToolIcon sx={{ color: enableReorder ? 'green' : 'red' }} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12} sm={7}>
          <VideoTimelineSlider />
        </Grid>
        <Grid item xs={3} className={classes.hide}>
          <VideoTimelineInput />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VideoTimelineTracker;
