/*eslint-disable*/
import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';

export const BackgroundSoundTrackTypes = createTypes(`
  BG_SOUND_TRACK_SET_SOUND
  BG_SOUND_TRACK_SET_PROGRESS
  BG_SOUND_TRACK_SET_CURRENT
  BG_SOUND_TRACK_SET_DURATION
  BG_SOUND_TRACK_SET_PAUSED_ICON
  BG_SOUND_TRACK_RESET

  BG_SOUND_TRACK_FETCH_REQUEST
  BG_SOUND_TRACK_FETCH_SUCCESS
  BG_SOUND_TRACK_FETCH_FAILURE

  BG_SOUND_TRACK_UPDATE_REQUEST
  BG_SOUND_TRACK_UPDATE_SUCCESS
  BG_SOUND_TRACK_UPDATE_FAILURE
  
  BG_SOUND_TRACK_FETCH_SOUNDS_SUCCESS
  BG_SOUND_TRACK_FETCH_SOUNDS_FAILURE
  BG_SOUND_TRACK_FETCH_SOUNDS_REQUEST
`);

export const setSound = (sound) => ({ type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_SET_SOUND, sound });

export const setProgress = (progress) => ({ type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_SET_PROGRESS, progress });

export const setCurrent = (current) => ({ type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_SET_CURRENT, current });

export const setDuration = (duration) => ({ type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_SET_DURATION, duration });

export const setPausedIcon = (pausedIcon) => ({
  type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_SET_PAUSED_ICON,
  pausedIcon
});

export const reset = () => ({
  type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_RESET
});

// Fetch
export const fetchSuccess = (sound) => ({
  type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_SUCCESS,
  sound
});

export const fetchFailure = () => ({
  type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_FAILURE
});

export const fetchRequest = (id) => async (dispatch) => {
  dispatch({ type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_REQUEST });
  return axiosClient.get(`/admin/sound/${id}`);
};

// Update
export const updateSuccess = (sound) => ({
  type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_UPDATE_SUCCESS,
  sound
});

export const updateFailure = () => ({
  type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_UPDATE_FAILURE
});

export const updateRequest = ({ id, is_active, loop, volume, mute }) => async (dispatch) => {
  dispatch({ type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_UPDATE_REQUEST });
  return axiosClient.put(`/admin/sound/${id}`, {
    is_active,
    loop,
    volume,
    mute
  });
};

// Fetch Sounds
export const fetchSoundsSuccess = (sounds) => {
  return {
    type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_SOUNDS_SUCCESS,
    sounds
  };
};

export const fetchSoundsFailure = () => ({
  type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_SOUNDS_FAILURE
});

export const fetchSoundsRequest = (id) => async (dispatch) => {
  dispatch({ type: BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_SOUNDS_REQUEST });
  return axiosClient.get(`/admin/project/${id}`);
};
