import { Box, TextField, Typography } from '@material-ui/core';
import BrokenImageOutlinedIcon from '@material-ui/icons/BrokenImageOutlined';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import { useScreenNode } from 'src/hooks/useScreenNode';

const cssProps = {
  nodeRoot: (selected, highlight) => {
    let borderCss = '1px solid #777';
    if (highlight) {
      borderCss = '1px solid #FFAB1A';
    } else if (selected) {
      borderCss = '1px solid #87CDCF';
    }
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column',
      border: borderCss,
      borderRadius: '50%',
      height: '149px',
      width: '155px',
      backgroundColor: 'background.paper',
      cursor: 'pointer !important'
    };
  },
  nodeIcon: (selected, searched) => {
    let bgColor = '#F7F7F7';
    if (searched) {
      bgColor = '#fad087';
    } else if (selected) {
      bgColor = 'primary.light';
    }
    return {
      position: 'absolute',
      top: 13,
      height: '56%',
      width: '82%',
      borderRadius: '50% 50% 50% 50%/60% 60% 31% 31%',
      backgroundColor: bgColor,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer !important',
        backgroundColor: 'primary.main'
      }
    };
  },
  nodeButton: () => ({
    position: 'absolute',
    p: 0,
    verticalAlign: 'middle',
    right: -24,
    backgroundColor: 'background.paper',
    '&:hover': {
      backgroundColor: 'primary.main',
      '.MuiSvgIcon-root': {
        color: 'white'
      }
    },
    zIndex: -1
  }),
  disabledButton: {
    color: '#777 !important'
  }
};

const EndNode = (props) => {
  const { data, selected, isDragging } = props;
  const { onSubmit, toggleEdit, control, editable, DnDModeProps } = useScreenNode({
    data,
    selected,
    isDragging
  });

  return (
    <Box sx={cssProps.nodeRoot(selected, data.highlight)} {...DnDModeProps}>
      <Box sx={cssProps.nodeIcon(selected, data.highlight)}>
        <BrokenImageOutlinedIcon sx={{ fontSize: 50 }} />
      </Box>
      <Box sx={{ mt: 8, width: '110px' }}>
        {editable ? (
          <Box className="nodrag">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus
                  onFocus={(evt) => evt.target.select()}
                  size="small"
                  onBlur={onSubmit}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                  variant="standard"
                  InputProps={{
                    disableUnderline: true
                  }}
                  sx={{ backgroundColor: 'white' }}
                />
              )}
            />
          </Box>
        ) : (
          <>
            <Typography align="center" noWrap onClick={toggleEdit}>
              {data.name}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

EndNode.propTypes = {
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired
};

export default memo(EndNode);
