import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as ProjectNodeActions from 'src/actions/projectNode';
import { useNotify } from 'src/hooks/useNotify';
import { fields as _fields } from 'src/models/node';
import { FormSchema } from 'src/models/schema';
import * as DraggingSelectors from 'src/selectors/dragging';
import * as DraggingActions from 'src/actions/dragging';

export const useScreenNode = ({ data, selected, isDragging }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fields = _fields(t);
  const { warningNotify } = useNotify();

  // Create child
  const handleCreateChild = useCallback(async () => {
    try {
      await dispatch(ProjectNodeActions.createChildRequest(data));
    } catch (e) {
      warningNotify(e.message === 'maxNode' ? t('projectNode.create.maxNode') : t('projectNode.create.failure'));
      dispatch(ProjectNodeActions.createChildFailure(e.message));
    }
  }, [data]);

  // Rename
  const [editable, setEditable] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const toggleEdit = useCallback(() => {
    if (clickCount < 1) {
      setClickCount(clickCount + 1);
    }
    if (clickCount === 1 && !editable) {
      setEditable(true);
    }
  }, [editable, clickCount, selected]);

  const { schema } = useMemo(() => new FormSchema(fields.id, [fields.name]), []);

  const { formState, control, reset, handleSubmit } = useForm({
    defaultValues: {
      [fields.name.name]: data.name
    },
    resolver: schema,
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback(() => {
    handleSubmit((form) => {
      dispatch(ProjectNodeActions.updateRequest({ id: data.id, name: form[fields.name.name] }));
    })();
    setEditable(false);
    setClickCount(0);
  }, [data]);
  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      reset({ [fields.name.name]: data.name });
    }
  }, [formState, data]);
  useEffect(() => {
    if (!selected) {
      if (editable) setEditable(false);
      if (clickCount !== 0) setClickCount(0);
    }
  }, [selected]);
  useEffect(() => {
    if (isDragging) {
      setClickCount(clickCount - 1);
    }
  }, [isDragging]);

  useEffect(() => {
    reset({ [fields.name.name]: data.name });
  }, [data]);

  // Drag and Drop
  const DnDMode = useSelector(DraggingSelectors.getDnDMode);
  const DnDModeProps = useMemo(
    () =>
      DnDMode
        ? {
            onMouseEnter: () => dispatch(DraggingActions.setDropItem({ item: data, dropType: 'image' })),
            onMouseLeave: () => dispatch(DraggingActions.setDropItem({ item: null, dropType: null }))
          }
        : {},
    [DnDMode]
  );
  return {
    handleCreateChild,
    onSubmit,
    toggleEdit,
    control,
    editable,
    DnDModeProps
  };
};
