import { Box, Button, Grid, Pagination, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Loading } from 'src/components/LoadingScreen';
import { ProjectCard } from 'src/components/projectNode';
import { useProjectList } from 'src/hooks/projectList/list';
import { useModal } from 'src/hooks/useModal';
import PlusIcon from 'src/icons/Plus';
import { ProjectCreateModal } from './createModal';
import { ProjectFilter } from './filter';

const ProjectListContainer = () => {
  const { t } = useTranslation();
  const { list, pagination, onSearch, filter, loading } = useProjectList();
  const { openModal } = useModal();
  return (
    <>
      <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid item md={9} xs={7}>
          <ProjectFilter filter={filter} onSearch={onSearch} />
        </Grid>
        <Grid item md={3} xs={5}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color="secondary"
              sx={{ m: 0 }}
              variant="contained"
              key="create"
              startIcon={<PlusIcon fontSize="small" />}
              onClick={() => openModal('projectCreate')}
            >
              {t('common.actions.create')}
            </Button>
          </Box>
          <ProjectCreateModal modalName="projectCreate" />
        </Grid>
      </Grid>

      <Box sx={{ position: 'relative', mt: 4 }}>
        <Loading loading={loading} />

        {list.length > 0 ? (
          <Box>
            <Grid container spacing={3}>
              {list.map((project) => (
                <Grid item md={4} xs={6} key={project.id}>
                  <ProjectCard project={project} />
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                paddingTop: '24px',
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Pagination count={pagination.totalPage} page={pagination.page} onChange={pagination.onPageChange} />
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              padding: '8px'
            }}
          >
            <Typography variant="h5" textAlign="left">
              {t('common.messages.notFound')}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProjectListContainer;
