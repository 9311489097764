import { makeStyles, Slider } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import 'src/containers/timeline/css/InteractionTimelineSlider.css';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import timelineFormat from 'src/utils/timelineFormat';
import videoController from 'src/utils/videoController';
import { isMobile } from 'react-device-detect';

const useStyles = (num) =>
  makeStyles((theme) => ({
    root: {
      height: 8
    },

    markLabel: {
      top: 24,
      color: '#fff',
      fontWeight: 'bold'
    },

    valueLabel: {
      transform: 'scale(1) translateY(-13px) !important'
    },

    mark: {
      width: 2,
      height: 10,
      marginTop: -2,
      backgroundColor: '#fff',
      '&:first-child': {
        color: 'blue !important!'
      }
    },

    rail: {
      borderRadius: 10,
      height: theme.spacing(5),
      color: '#272B4D'
    },

    track: {
      width: '100% !important',
      borderRadius: 10,
      height: theme.spacing(5),
      color: '#272B4D'
    },

    thumb: {
      color: '#ED257A',
      marginLeft: -0.5,
      marginTop: -4,
      borderRadius: 0,
      width: 2,
      height: `${50 + num * 49}px`,
      zIndex: 20,
      boxShadow: 'none !important',
      [theme.breakpoints.down('md')]: {
        height: `${60 + num * 70}px`
      }
    }
  }));

const VideoTimelineSlider = () => {
  const numOfInteraction = useSelector(NodeInteractionSelectors.getNumOfInteraction);
  const mode = useSelector(NodeInteractionSelectors.getMode);
  const classes = useStyles(mode === NODE_EDIT.MODE.LIST ? numOfInteraction : 1)();
  const { videoDuration, videoCurrentTime } = useVideoPlayer();
  const marks = useMemo(() => {
    if (videoDuration === 0) return [];
    const quantity = isMobile ? 6 : 10;
    const delta = parseFloat(videoDuration / quantity, 10);

    const temp = [...new Array(quantity - 1)].map((_, index) => ({
      label: timelineFormat((index + 1) * delta, 'hh:mm:ss'),
      value: (index + 1) * delta
    }));
    return [{ label: '', value: 0 }, ...temp, { label: '', value: videoDuration }];
  }, [videoDuration]);
  const valueLabelFormat = useCallback((x) => timelineFormat(x), []);

  const handleTimeChange = useCallback((_, number) => {
    videoController.getInstance()?.seek?.(number);
  }, []);
  return (
    <Slider
      classes={classes}
      className="only-css-to-timeline-slider"
      min={0}
      max={videoDuration}
      value={videoCurrentTime}
      valueLabelDisplay="on"
      marks={marks}
      step={0.01}
      valueLabelFormat={valueLabelFormat}
      onChange={handleTimeChange}
    />
  );
};

VideoTimelineSlider.propTypes = {
  // numOfInteractions: PropTypes.number,
  // setCurrentTime: PropTypes.func.isRequired
};

export default VideoTimelineSlider;
