/* eslint-disable no-unused-vars */
import { useTheme } from '@emotion/react';
import { Box, Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import EditorField from 'src/components/FormControls/EditorField';
import { INTERACTION } from 'src/constants/interactions';
import 'src/containers/interactionsForm/text/TextInteraction.css';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import * as ProjectNodeSelectors from 'src/selectors/projectNode';
import { getDuration } from 'src/selectors/videoPlayer';
import { getInteractionLabel } from 'src/utils/getInteractionStartTime';
import timelineFormat, { hmsmsToSeconds } from 'src/utils/timelineFormat';
import ColorField from './ColorField';

export const convertFormToRedux = (formValues, videoDuration) => {
  const converted = { ...formValues };
  switch (formValues.jump.type) {
    case INTERACTION.ACTION.LINK: {
      converted.jump = null;
      converted.link = formValues.link || '';
      break;
    }
    case '0': {
      converted.jump = null;
      converted.link = null;
      break;
    }
    case INTERACTION.ACTION.JUMP_TO_START: {
      converted.link = null;
      converted.jump = {
        ...formValues.jump,
        jump_to: 0,
        jump_time: hmsmsToSeconds(formValues.jump.jump_time)
      };
      break;
    }
    case INTERACTION.ACTION.JUMP_TO_END: {
      converted.link = null;
      converted.jump = {
        ...formValues.jump,
        jump_to: videoDuration,
        jump_time: hmsmsToSeconds(formValues.jump.jump_time)
      };
      break;
    }
    case INTERACTION.ACTION.JUMP_TO_NODE: {
      converted.link = null;
      converted.jump = {
        ...formValues.jump,
        jump_to: hmsmsToSeconds(formValues.jump.jump_to) || 0,
        jump_time: hmsmsToSeconds(formValues.jump.jump_time) || 0,
        time_jump_to_node: hmsmsToSeconds(formValues.jump.time_jump_to_node) || 0
      };
      break;
    }
    default: {
      converted.link = null;
      converted.jump = {
        ...formValues.jump,
        jump_to: hmsmsToSeconds(formValues.jump.jump_to),
        jump_time: hmsmsToSeconds(formValues.jump.jump_time)
      };
    }
  }
  return converted;
};

export const convertReduxToForm = (redux) => {
  const converted = { ...redux };
  if (redux.jump === null && redux.link !== null) {
    converted.jump = { type: INTERACTION.ACTION.LINK };
  } else if (redux.jump === null && redux.link === null) {
    converted.jump = { type: '0' };
  } else if (redux.jump.type === INTERACTION.ACTION.JUMP_TO_INTERACTION) {
    converted.jump = {
      type: redux.jump.type,
      interaction_id: redux.jump.interaction_id
    };
  } else if (redux.jump.type === INTERACTION.ACTION.JUMP_TO_NODE) {
    converted.jump = {
      type: redux.jump.type,
      node_id: redux.jump.node_id,
      time_jump_to_node: redux.jump?.time_jump_to_node
        ? timelineFormat(redux.jump?.time_jump_to_node, 'hh:mm:ss.ms')
        : '00:00:00.00'
    };
  } else if (redux.jump) {
    converted.jump = {
      ...redux.jump,
      jump_to: redux.jump?.jump_to ? timelineFormat(redux.jump.jump_to, 'hh:mm:ss.ms') : '00:00:00.00',
      jump_time: redux.jump?.jump_time ? timelineFormat(redux.jump.jump_time, 'hh:mm:ss.ms') : '00:00:00.00'
    };
  }

  return converted;
};

export const actionOptions_ = (t) =>
  ['', ...Object.keys(INTERACTION.ACTION)]
    .filter((key) => key !== 'CONTINUE')
    .map((key) =>
      key
        ? {
            value: INTERACTION.ACTION[key],
            label: t(`interaction.actions.${key.toLowerCase()}`)
          }
        : {
            value: '0',
            label: t('interaction.actions.noAction')
          }
    );

const TextInteractionInteractionForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const actionOptions = actionOptions_(t);
  const textConfig = useSelector(EdittingInteractionSelectors.textInteractionGetTextConfig);
  const dispatch = useDispatch();
  const videoDuration = useSelector(getDuration);
  const [titleError, setTitleError] = useState('');
  const [contentError, setContentError] = useState('');

  const interactionList = useSelector(NodeInteractionSelectors.getInteractionList);
  const jumpToInteractionOptions = useMemo(
    () =>
      interactionList.map((inter) => ({
        label: getInteractionLabel(inter),
        value: inter.id_interaction
      })),
    [interactionList]
  );

  // Handle jump to node
  const nodeList = useSelector(ProjectNodeSelectors.getListJumpNode);
  const jumpToNodeOptions = useMemo(() =>
    nodeList.map((node) => ({
      label: node.name,
      value: node.id
    }))
  );
  const { control, handleSubmit, watch, reset, setValue, getValues, formState } = useForm({
    mode: 'onChange',
    defaultValues: convertReduxToForm(textConfig, nodeList || [])
  });

  const watchJumpTypeField = watch('jump.type');

  const onSubmit = handleSubmit((formValues) => {
    dispatch(EdittingInteractionActions.textInteractionSetTextConfig(convertFormToRedux(formValues, videoDuration)));
  });

  useEffect(() => {
    reset(convertReduxToForm(textConfig));
  }, [textConfig]);

  useEffect(() => {
    if (watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_START) {
      dispatch(EdittingInteractionActions.setConfig({ jump: { ...textConfig.jump, jump_to: 0 } }));
    } else if (watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_END) {
      dispatch(EdittingInteractionActions.setConfig({ jump: { ...textConfig.jump, jump_to: videoDuration } }));
    }
  }, [watchJumpTypeField]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="title"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.text.label.title')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    onChange(event.target.value);
                    dispatch(EdittingInteractionActions.setConfig({ title: event.target.value }));

                    if (!event.target.value.length || event.target.value.length > 100) {
                      // if (!event.target.value.length) {
                      if (!event.target.value.length) {
                        setTitleError(t('interaction.text.validate.title.required'));
                      } else {
                        setTitleError(t('interaction.text.validate.title.max', { max: 100 }));
                      }
                      dispatch(EdittingInteractionActions.setValidateError(true));
                    } else {
                      setTitleError('');
                      if (contentError) {
                        dispatch(EdittingInteractionActions.setValidateError(true));
                      } else {
                        dispatch(EdittingInteractionActions.setValidateError(false));
                      }
                    }
                  }}
                  value={value}
                />
              </Box>
              {!!titleError && (
                <Box
                  sx={{
                    display: 'flex',
                    height: 40,
                    maxHeight: 40,
                    alignItems: 'center',
                    color: theme.palette.error.main
                  }}
                >
                  <Typography width={160} />
                  <Typography sx={{ width: 'calc(100% - 160px)' }}>{titleError}</Typography>
                </Box>
              )}
            </Box>
          )}
        />

        <Controller
          name="jump.type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 600 }} width={160}>
                {t('interaction.text.label.action')}
              </Typography>
              <Select
                sx={{ width: 'calc(100% - 160px)' }}
                size="small"
                value={value}
                fullWidth
                onChange={(e) => {
                  onChange(e.target.value);
                  onSubmit();
                }}
              >
                {actionOptions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        />

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_SPECIFIC && (
          <Controller
            name="jump.jump_to"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.text.label.jumpTo')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                    onSubmit();
                  }}
                  value={value}
                />
              </Box>
            )}
          />
        )}

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_SPECIFIC_SCHEDULER && (
          <Grid container spacing={5}>
            <Grid item md={6} xs={12}>
              <Controller
                name="jump.jump_time"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 600 }} width={160}>
                      {t('interaction.text.label.jumpAt')}
                    </Typography>
                    <TextField
                      sx={{ width: 'calc(100% - 160px)' }}
                      size="small"
                      fullWidth
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        onSubmit();
                      }}
                      value={value}
                    />
                  </Box>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Controller
                name="jump.jump_to"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 600 }} width={160}>
                      {t('interaction.text.label.jumpTo')}
                    </Typography>
                    <TextField
                      sx={{ width: 'calc(100% - 160px)' }}
                      size="small"
                      fullWidth
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        onSubmit();
                      }}
                      value={value}
                    />
                  </Box>
                )}
              />
            </Grid>
          </Grid>
        )}

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_NODE && (
          <>
            <Controller
              name="jump.node_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: 600 }} width={160}>
                    {t('interaction.actions.jump_to_node')}
                  </Typography>
                  <Select
                    sx={{ width: 'calc(100% - 160px)' }}
                    size="small"
                    value={value}
                    fullWidth
                    onChange={(e) => {
                      onChange(e.target.value);
                      onSubmit();
                    }}
                  >
                    {jumpToNodeOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            />

            {getValues('jump.node_id') && (
              <Controller
                name="jump.time_jump_to_node"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 600 }} width={160}>
                      {t('interaction.jump.label.jumpTo')}
                    </Typography>
                    <TextField
                      sx={{ width: 'calc(100% - 160px)' }}
                      size="small"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.target.blur();
                        }
                      }}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        onSubmit();
                      }}
                      value={value}
                    />
                  </Box>
                )}
              />
            )}
          </>
        )}

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_INTERACTION && (
          <Controller
            name="jump.interaction_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.text.label.jumpToInteraction')}
                </Typography>
                <Select
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  value={value}
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value);
                    onSubmit();
                  }}
                >
                  {jumpToInteractionOptions.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
          />
        )}

        {watchJumpTypeField === INTERACTION.ACTION.LINK && (
          <Controller
            name="link"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.text.label.hyperlink')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={(_value) => {
                    onChange(_value);
                    onSubmit();
                  }}
                  value={value}
                  placeholder="Example: https://google.com.vn"
                />
              </Box>
            )}
          />
        )}
        <EditorField
          disabled={!!titleError}
          name="content"
          control={control}
          defaultTextAlign="Center"
          formState={{ errors: contentError ? { content: { message: contentError } } : {} }}
          onChange={(obj) => {
            const newValue = Object.values(obj)[0];
            if (!titleError) {
              dispatch(EdittingInteractionActions.setConfig({ content: newValue }));

              if (!newValue.length || titleError || newValue.length > 1000) {
                dispatch(EdittingInteractionActions.setValidateError(true));
                if (!newValue.length) {
                  setContentError(t('interaction.text.validate.content.required'));
                } else if (newValue.length > 1000) {
                  setContentError(t('interaction.text.validate.content.max', { max: 600 }));
                }
              } else {
                dispatch(EdittingInteractionActions.setValidateError(false));
                setContentError('');
              }
            } else {
              dispatch(EdittingInteractionActions.setValidateError(false));
            }
          }}
        />

        <Controller
          name="background_color"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 600 }} width={160}>
                {t('interaction.text.label.backgroundColor')}
              </Typography>
              <Box sx={{ width: 120 }}>
                <ColorField
                  id="text-react-color-custom"
                  color={value}
                  onColorChange={(Color) => {
                    onChange(Color);
                    onSubmit();
                  }}
                />
              </Box>
            </Box>
          )}
        />
      </form>
    </>
  );
};

export default TextInteractionInteractionForm;
