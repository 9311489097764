/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as ProjectNodeActions from 'src/actions/projectNode';
import { useNotify } from 'src/hooks/useNotify';
import { useStoreActions } from 'react-flow-renderer';
import { fields as _fields } from 'src/models/node';
import { FormSchema } from 'src/models/schema';
import * as DraggingSelectors from 'src/selectors/dragging';
import * as DraggingActions from 'src/actions/dragging';

const _nodeContextItems = (t) => ({
  edit: {
    label: t('common.actions.edit')
  },
  rename: {
    label: t('common.actions.rename')
  },
  copy: {
    label: t('common.actions.copy'),
    shortcut: 'Ctrl/Cmd+C'
  },
  delete: {
    label: t('common.actions.delete'),
    shortcut: 'Backspace'
  }
});

export const useVideoNode = ({ data, selected, isDragging }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fields = _fields(t);
  const { warningNotify } = useNotify();
  const [blurByContextClick, setBlurByContextClick] = useState(false);

  // Create child
  const handleCreateChild = useCallback(async () => {
    try {
      await dispatch(ProjectNodeActions.createChildRequest(data));
    } catch (e) {
      warningNotify(e.message === 'maxNode' ? t('projectNode.create.maxNode') : t('projectNode.create.failure'));
      dispatch(ProjectNodeActions.createChildFailure(e.message));
    }
  }, [data]);

  // Rename
  const [editable, setEditable] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const toggleEdit = useCallback(() => {
    if (clickCount < 1) {
      setClickCount(clickCount + 1);
    }
    if (clickCount === 1 && !editable) {
      setEditable(true);
    }
  }, [editable, clickCount, selected]);

  const { schema } = useMemo(() => new FormSchema(fields.id, [fields.name]), []);

  const { formState, control, reset, handleSubmit, setFocus } = useForm({
    defaultValues: {
      [fields.name.name]: data.name
    },
    resolver: schema,
    reValidateMode: 'onBlur'
  });

  const onSubmit = useCallback(() => {
    handleSubmit((form) => {
      dispatch(ProjectNodeActions.updateRequest({ id: data.id, name: form[fields.name.name] }));
    })();
    setEditable(false);
    setClickCount(0);
  }, [data]);

  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      reset({ [fields.name.name]: data.name });
    }
  }, [formState, data]);
  useEffect(() => {
    if (!selected) {
      if (editable) setEditable(false);
      if (clickCount !== 0) setClickCount(0);
      setBlurByContextClick(false);
    }
  }, [selected]);
  useEffect(() => {
    if (isDragging) {
      setClickCount(clickCount - 1);
    }
  }, [isDragging]);

  useEffect(() => {
    reset({ [fields.name.name]: data.name });
  }, [data]);

  // Context Menu
  const handleCloseContextMenu = useCallback(() => {
    dispatch(ProjectNodeActions.closeNodeContextMenuRequest(data.id));
  }, [data]);

  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    setAnchorEl(anchorRef.current);
    return () => setAnchorEl(null);
  }, []);

  const anchorRef = useRef(null);
  const nodeContextItems = _nodeContextItems(t);
  const resetSelected = useStoreActions((actions) => actions.resetSelectedElements);

  const handleMenuItemClick = useCallback((item) => {
    switch (item) {
      case 'view': {
        dispatch(ProjectNodeActions.setScreen('videoNodeView'));
        break;
      }
      case 'edit': {
        dispatch(ProjectNodeActions.setScreen('videoNodeEdit'));
        break;
      }
      case 'rename': {
        setEditable(true);
        setBlurByContextClick(true);
        break;
      }
      case 'copy': {
        dispatch(ProjectNodeActions.setSelectedRequest(data.id));
        dispatch(ProjectNodeActions.setCopyItemRequest());
        break;
      }
      case 'delete': {
        resetSelected();
        dispatch(ProjectNodeActions.deleteNodeRequest([data]));
        break;
      }
      default:
    }
  }, []);

  // Drag and Drop
  const DnDMode = useSelector(DraggingSelectors.getDnDMode);
  const DnDModeProps = useMemo(
    () =>
      DnDMode
        ? {
            onMouseEnter: () => dispatch(DraggingActions.setDropItem({ item: data, dropType: 'video' })),
            onMouseLeave: () => dispatch(DraggingActions.setDropItem({ item: null, dropType: null }))
          }
        : {},
    [DnDMode]
  );
  return {
    control,
    editable,

    handleCreateChild,
    onSubmit,
    toggleEdit,
    handleCloseContextMenu,

    anchorRef,
    anchorEl,
    nodeContextItems,
    handleMenuItemClick,
    blurByContextClick,
    setBlurByContextClick,
    DnDModeProps
  };
};
