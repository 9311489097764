import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import * as AuthActions from 'src/actions';
import * as ProjectDetailActions from 'src/actions/projectDetail';
import StorageKeys from 'src/constants/StorageKeys';
import * as AccountActions from 'src/actions/account';
import * as UserSelectors from 'src/selectors/auth';
import * as AccountSelectors from 'src/selectors/account';
import { useNotify } from './useNotify';

export const useAuth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorNotify, successNotify, closeNotify } = useNotify();

  const userState = useSelector(UserSelectors.getUserState);
  const loginState = useSelector(UserSelectors.getLoginState);
  const forgotPasswordState = useSelector(UserSelectors.getForgotPasswordState);
  const accountDetailState = useSelector(AccountSelectors.getAccountDetail);
  const resetPasswordState = useSelector(UserSelectors.getResetPasswordState);

  const onLogin = useCallback(
    async (formData) => {
      try {
        const data = await dispatch(AuthActions.loginRequest(formData));
        const { access_token: accessToken, user } = data;

        localStorage.setItem(StorageKeys.AccessToken, accessToken);
        dispatch(AuthActions.loginSuccess(user));
        navigate('/app/projects', { replace: true });
      } catch (err) {
        errorNotify(t('auth.request.login.failure'));
        dispatch(AuthActions.loginFailure());
      }
    },
    [dispatch, navigate]
  );

  const onForgotPassword = useCallback(
    async (formData) => {
      try {
        await dispatch(AuthActions.forgotPasswordRequest(formData));
        dispatch(AuthActions.forgotPasswordSuccess());
        navigate('/reset-password', { replace: true, state: { email: formData.email } });
      } catch (err) {
        errorNotify(t('auth.request.forgotPassword.failure'));
        dispatch(AuthActions.forgotPasswordFailure());
      }
    },
    [dispatch, navigate]
  );

  const onResetPassword = useCallback(
    async (formData) => {
      try {
        await dispatch(AuthActions.resetPasswordRequest(formData));
        dispatch(AuthActions.forgotPasswordSuccess());
        successNotify(t('auth.request.resetPassword.success'));
        navigate('/login');
      } catch (err) {
        errorNotify(t('auth.request.resetPassword.failure'));
        dispatch(AuthActions.forgotPasswordFailure());
      }
    },
    [dispatch, navigate]
  );

  const onUpdate = useCallback(
    async (formData, id) => {
      try {
        await dispatch(AccountActions.editAccountRequest(formData, id));

        dispatch(AccountActions.editSuccess());
        navigate('/app/projects', { replace: true });
        successNotify(t('account.request.update.success'));
      } catch (err) {
        errorNotify(t('account.request.update.failure'));
        dispatch(AccountActions.editFailure());
      }
    },
    [dispatch, navigate]
  );

  const onUserDetail = useCallback(
    async (id) => {
      try {
        const { user } = await dispatch(AccountActions.detailAccoutRequest(id));

        dispatch(AccountActions.detailSuccess(user));
      } catch (err) {
        errorNotify(t('account.request.detail.failure'));
        dispatch(AccountActions.detailFailure());
      }
    },
    [dispatch, navigate]
  );

  const onLogout = useCallback(() => {
    dispatch(AuthActions.logoutRequest());
    dispatch(ProjectDetailActions.reset());
  }, [dispatch]);

  useEffect(() => () => closeNotify(), []);

  return {
    userState,
    loginState,
    accountDetailState,
    forgotPasswordState,
    resetPasswordState,

    onUpdate,
    onUserDetail,
    onLogin,
    onForgotPassword,
    onResetPassword,
    onLogout
  };
};

export const requireAuth = (NewComponent) => (props) => {
  const navigate = useNavigate();
  const userState = useSelector(UserSelectors.getUserState);

  useEffect(() => {
    if (!userState) {
      navigate('/login');
    }
  }, [navigate, userState]);

  return userState && <NewComponent {...props} />;
};

export const requireRole = (NewComponent, role) => (props) => {
  const navigate = useNavigate();
  const userState = useSelector(UserSelectors.getUserState);

  useEffect(() => {
    if (!userState) {
      navigate('/login');
    }
  }, [navigate, userState]);

  return userState?.type === role ? <NewComponent {...props} /> : <Navigate to="/404" replace />;
};
