import { Box } from '@material-ui/core';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import * as videoPlayerSelectors from 'src/selectors/videoPlayer';
import videoController from 'src/utils/videoController';
import './PauseInteractionDisplay.css';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
// import { useVideoPlayer } from 'src/hooks/useVideoPlayer';

const ResizeHandleBottomRight = () => (
  <Box
    sx={{
      zIndex: 1,
      position: 'absolute',
      top: '2px',
      left: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'se-resize !important'
    }}
  />
);

const ResizeHandleBottomLeft = () => (
  <Box
    sx={{
      zIndex: 1,
      position: 'absolute',
      top: '2px',
      right: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'sw-resize !important'
    }}
  />
);

const ResizeHandleBottom = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '-2px',
      left: '48%',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 's-resize !important'
    }}
  />
);

const ResizeHandleTop = () => (
  <Box
    sx={{
      position: 'absolute',
      bottom: '-2px',
      left: '48%',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'n-resize !important'
    }}
  />
);
const ResizeHandleLeft = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '48%',
      right: '-2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'w-resize !important'
    }}
  />
);

const ResizeHandleRight = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '48%',
      right: '4px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'e-resize !important'
    }}
  />
);

const ResizeHandleTopLeft = () => (
  <Box
    className="resize-box"
    sx={{
      position: 'absolute',
      bottom: '2px',
      right: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'nw-resize !important'
    }}
  />
);

const ResizeHandleTopRight = () => (
  <Box
    sx={{
      position: 'absolute',
      bottom: '2px',
      left: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'ne-resize !important'
    }}
  />
);

// const fixed = (x) => Number.parseFloat(x).toFixed(1);

export const PauseInteractionDisplay = ({ config, videoBounding, isEditting, isPublishMode = false }) => {
  const dispatch = useDispatch();

  const [dragging, setDragging] = useState(false);

  const { t } = useTranslation();

  const handleChangeConfig = useCallback((newConfig) => {
    dispatch(EdittingInteractionActions.setConfig(newConfig));
  }, []);

  const videoControl = videoController.getInstance();
  const videoIsPaused = useSelector(videoPlayerSelectors.getIsPaused);
  const videoDuration = useSelector(videoPlayerSelectors.getDuration);
  const showGrid = useSelector(videoPlayerSelectors.getShowVideoGrid);
  const gridCellWidth = useSelector(videoPlayerSelectors.getWidthCellGrid);
  const videoData = useSelector(videoPlayerSelectors.getVideoData);
  useEffect(() => {
    if (showGrid) {
      const newX = (Math.trunc(config.x / gridCellWidth) * gridCellWidth) / videoBounding.width;
      const newY = (Math.trunc(config.y / gridCellWidth) * gridCellWidth) / videoBounding.height;
      const newWidth = Math.trunc(config.width / gridCellWidth) * gridCellWidth;
      const newHeight = Math.trunc(config.height / gridCellWidth) * gridCellWidth;
      dispatch(EdittingInteractionActions.setConfig({ x: newX, y: newY, width: newWidth, height: newHeight }));
    }
  }, [showGrid]);
  const [triggerDisplay, setTriggerDisplay] = useState(false);
  const [classNameFade, setClassNameFade] = useState('');
  const currentTime = useSelector(videoPlayerSelectors.getCurrentTime);
  const [hasClick, setHasClick] = useState(false);
  const audioRef = useRef(new Audio(config.sound ? `${process.env.REACT_APP_PUBLIC_URL}${config.sound?.url}` : ''));
  useEffect(() => {
    audioRef.current.src = config.sound ? `${process.env.REACT_APP_PUBLIC_URL}${config.sound?.url}` : '';
    audioRef.current.load();
  }, [config.sound]);
  const handleDismissDiplay = useCallback(
    (dismissByClick = false) => {
      if (config.fade_sound && dismissByClick) {
        fadeOutAudio();
      } else {
        audioRef.current.pause();
      }
      if (config.style.fadeOut && dismissByClick) {
        setClassNameFade('pause-interaction-fadeOut');
      } else {
        setClassNameFade('');
      }
      setTriggerDisplay(false);
    },
    [audioRef, config.fade_sound, config.style.fadeOut]
  );
  const fadeInAudio = () => {
    // first render audioRef.current.duration is NaN, still wonder why
    const fadePoint = Math.min(2, 0.5 * audioRef.current.duration) || 2;
    audioRef.current.volume = 0;
    const fadeAudio = setInterval(() => {
      if (audioRef.current.currentTime <= fadePoint && audioRef.current.volume <= 0.9) {
        audioRef.current.volume += 0.1;
      }
      if (audioRef.current.volume > 0.9 || audioRef.current.currentTime > fadePoint) {
        audioRef.current.volume = 1;
        clearInterval(fadeAudio);
      }
    }, 200);
  };

  const fadeOutAudio = () => {
    let count = 4;
    audioRef.current.volume = 1;
    const fadeAudio = setInterval(() => {
      if (audioRef.current.volume >= 0.2 && count > 0) {
        audioRef.current.volume -= 0.2;
        count -= 1;
      }
      if (count === 0) {
        audioRef.current.pause();
        clearInterval(fadeAudio);
      }
    }, 100);
  };

  useEffect(() => {
    // 2 border
    if (config.start_time > currentTime && config.start_time - currentTime > 0.6) {
      setHasClick(false);
      handleDismissDiplay();
    } else if (currentTime - config.start_time > 0 && hasClick) {
      handleDismissDiplay();
    }
    // handle dismiss
    else if (triggerDisplay && !videoIsPaused) {
      setHasClick(true);
      handleDismissDiplay(true);
    }
    // handle trigger
    else if (
      (config.start_time >= currentTime &&
        config.start_time - currentTime <= 0.6 &&
        (!videoIsPaused || currentTime === videoDuration) &&
        !hasClick) ||
      (currentTime === 0 && config.start_time === 0)
    ) {
      if (config.style.fadeIn) {
        setClassNameFade('pause-interaction-fadeIn');
      } else {
        setClassNameFade('');
      }
      setTriggerDisplay(true);
      if (config.play_sound) {
        audioRef.current.loop = true;
        audioRef.current.currentTime = 0;
        audioRef.current.volume = 1;
        audioRef.current.play();
        if (config.fade_sound) {
          fadeInAudio();
        }
      }
      if (isPublishMode) {
        ReactGA.event({
          category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
          action: t(`googleAnalyticsActions.actions.pauseScreen`) || '',
          label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.title}`,
          value: videoData.id
        });
      }
      videoControl.pause();
    }
  }, [currentTime, videoIsPaused, isPublishMode, config.sound, config.play_sound]);

  useEffect(() => () => audioRef.current?.pause(), []);
  useEffect(() => {
    if (isEditting) {
      // handle case video has not started and user create new
      setTimeout(() => {
        if (config.style.fadeIn) {
          setClassNameFade('pause-interaction-fadeIn');
        } else {
          setClassNameFade('');
        }
        setTriggerDisplay(true);
        if (config.play_sound) {
          audioRef.current.loop = true;
          audioRef.current.currentTime = 0;
          audioRef.current.volume = 1;
          audioRef.current.play();
          if (config.fade_sound) {
            fadeInAudio();
          }
        }
        videoControl.pause();
      }, 0.1);
    }
  }, []);
  useEffect(() => {
    if (isEditting) {
      dispatch(EdittingInteractionActions.setInitialVideoWidth(videoBounding.width));
    }
  }, []);
  return (
    <Rnd
      className={`rnd ${classNameFade}`}
      enableResizing={isEditting}
      disableDragging={!isEditting}
      {...(showGrid ? { dragGrid: [gridCellWidth, gridCellWidth], resizeGrid: [gridCellWidth, gridCellWidth] } : {})}
      style={{
        zIndex: 1,
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        border: isEditting ? '2px dashed white' : 'none',
        cursor: 'auto !important',

        visibility: triggerDisplay ? 'visible' : 'hidden',
        opacity: triggerDisplay ? 1 : 0
      }}
      bounds="parent"
      size={{
        width: config.width,
        height: config.height
      }}
      position={{
        x: config.x * videoBounding.width,
        y: config.y * videoBounding.height
      }}
      minWidth="10%"
      minHeight="20%"
      onDragStart={() => setDragging(true)}
      onDragStop={(e, d) => {
        setDragging(false);
        handleChangeConfig({ x: d.lastX / videoBounding.width, y: d.lastY / videoBounding.height });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        handleChangeConfig({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          x: position.x / videoBounding.width,
          y: position.y / videoBounding.height
        });
      }}
      resizeHandleComponent={{
        bottomRight: <ResizeHandleBottomRight />,
        bottomLeft: <ResizeHandleBottomLeft />,
        bottom: <ResizeHandleBottom />,
        top: <ResizeHandleTop />,
        left: <ResizeHandleLeft />,
        right: <ResizeHandleRight />,
        topLeft: <ResizeHandleTopLeft />,
        topRight: <ResizeHandleTopRight />
      }}
    >
      <Box
        sx={{
          width: isEditting ? '100%' : config.width,
          height: isEditting ? '100%' : config.height,
          position: 'absolute',
          top: 0,
          left: 0,
          cursor: dragging ? 'move !important' : 'auto !important',
          borderRadius: `${config.style.borderRadius}%`,
          backgroundColor: `rgba(${config.style.backgroundColor.r}, ${config.style.backgroundColor.g}, ${config.style.backgroundColor.b}, ${config.style.backgroundColor.a})`,
          border: `${config.style.borderWidth}px ${config.style.borderStyle} rgba(${config.style.borderColor.r}, ${config.style.borderColor.g}, ${config.style.borderColor.b}, ${config.style.borderColor.a})`,
          '-webkit-text-stroke': config.style.textStroke,
          paddingTop: `${parseFloat(config.style.topPadding)}px`,
          transform: `scale(${videoBounding.width / config.style.initialVideoWidth})`,
          transformOrigin: 'top left',
          userSelect: 'none',
          '& p': {
            margin: 0,
            padding: 0,
            boxSizing: 'border-box'
          },
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center'
        }}
      >
        {config.content && parse(config.content)}
      </Box>
    </Rnd>
  );
};

PauseInteractionDisplay.propTypes = {
  videoBounding: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isPublishMode: PropTypes.bool.isRequired
};
