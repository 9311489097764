/* eslint-disable no-unused-vars */
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { INTERACTION } from 'src/constants/interactions';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';
import { EdittingInteractionsDisplay } from './EdittingInteractionsDisplay';
import { FormInteractionDisplay } from './formInteractionDisplay/FormInteractionDisplay';
import { HighlightInteractionDisplay } from './highlightInteractionDisplay/HighlightInteractionDisplay';
import { ImageInteractionDisplay } from './imageInteractionDisplay/ImageInteractionDisplay';
import JumpToInteractionDisplay from './jumpToInteractionDisplay/JumpToInteractionDisplay';
import LinkVideoInteractionDisplay from './linkVideoInteractionDisplay/LinkVideoInteractionDisplay';
import { MenuInteractionDisplay } from './MenuInteractionDisplay';
import { NodeInteractionsDisplay } from './NodeInteractionsDisplay';
import { PauseInteractionDisplay } from './pauseInteractionDisplay/PauseInteractionDisplay';
import TextInteractionDisplay from './textInteractionDisplay/TextInteractionDisplay';
import TransparentButtonInteractionDisplay from './transparentButtonInteractionDisplay/TransparentButtonInteractionDisplay';
import ZoomInteractionDisplay from './zoomInteractionDisplay/ZoomInteractionDisplay';

export const getInteractionDisplay = ({ interaction, videoBounding, isEditting = false, multiInter = false }) => {
  if (multiInter) {
    interaction.action.start_time = interaction.action.product_start_time;
    interaction.action.end_time = interaction.action.product_end_time;
    if (interaction.action.jump) {
      if (INTERACTION.ACTION.JUMP_TO_NODE !== interaction.action.jump.type) {
        interaction.action.jump.jump_to = interaction.action.jump.product_jump_to;
      }
      interaction.action.jump.jump_time = interaction.action.jump.product_jump_time;
    }
  }
  switch (interaction.type) {
    case INTERACTION.TYPE.MENU:
      return (
        <MenuInteractionDisplay
          config={multiInter ? interaction.action : interaction}
          videoBounding={videoBounding}
          isEditting={isEditting}
          isPublishMode={multiInter}
        />
      );
    case INTERACTION.TYPE.TEXT:
      return (
        <TextInteractionDisplay
          config={multiInter ? interaction.action : interaction}
          videoBounding={videoBounding}
          isEditting={isEditting}
          isPublishMode={multiInter}
        />
      );
    case INTERACTION.TYPE.IMAGE:
      return (
        <ImageInteractionDisplay
          config={multiInter ? interaction.action : interaction}
          videoBounding={videoBounding}
          isEditting={isEditting}
          isPublishMode={multiInter}
        />
      );
    case INTERACTION.TYPE.HIGHLIGHT:
      return (
        <HighlightInteractionDisplay
          config={multiInter ? interaction.action : interaction}
          videoBounding={videoBounding}
          isEditting={isEditting}
          isPublishMode={multiInter}
        />
      );
    case INTERACTION.TYPE.PAUSE:
      return (
        <PauseInteractionDisplay
          config={multiInter ? interaction.action : interaction}
          videoBounding={videoBounding}
          isEditting={isEditting}
          isPublishMode={multiInter}
        />
      );
    case INTERACTION.TYPE.TRANSPARENT_BUTTON:
      return (
        <TransparentButtonInteractionDisplay
          config={multiInter ? interaction.action : interaction}
          videoBounding={videoBounding}
          isPublishMode={multiInter}
          isEditting={isEditting}
        />
      );
    case INTERACTION.TYPE.ZOOM:
      return (
        <ZoomInteractionDisplay
          config={multiInter ? interaction.action : interaction}
          videoBounding={videoBounding}
          isPublishMode={multiInter}
          isEditting={isEditting}
        />
      );
    case INTERACTION.TYPE.LINK:
      return (
        <LinkVideoInteractionDisplay
          config={multiInter ? interaction.action : interaction}
          videoBounding={videoBounding}
          isPublishMode={multiInter}
          isEditting={isEditting}
        />
      );
    case INTERACTION.TYPE.FORM:
      return (
        <FormInteractionDisplay
          config={multiInter ? interaction.action : interaction}
          videoBounding={videoBounding}
          isPublishMode={multiInter}
          isEditting={isEditting}
        />
      );
    // return <LinkVideoInteractionDisplay config={interaction} videoBounding={videoBounding} isEditting={isEditting} />;
    case INTERACTION.TYPE.JUMP_TO:
      return (
        <JumpToInteractionDisplay
          config={multiInter ? interaction.action : interaction}
          isPublishMode={multiInter}
          isEditting={isEditting}
        />
      );

    default:
      return null;
  }
};

export const InteractionsDisplay = () => {
  const mode = useSelector(NodeInteractionSelectors.getMode);
  const videoBounding = useSelector(VideoPlayerSelectors.getVideoBounding);
  return (
    <Box id="interaction-bounding-box" className="waocon-interaction-bounding-box">
      {(mode === NODE_EDIT.MODE.CREATE || mode === NODE_EDIT.MODE.EDIT) && (
        <EdittingInteractionsDisplay videoBounding={videoBounding} />
      )}
      {!!(mode === NODE_EDIT.MODE.LIST) && <NodeInteractionsDisplay videoBounding={videoBounding} />}
    </Box>
  );
};

InteractionsDisplay.propTypes = {
  // videoBounding: PropTypes.object.isRequired
};
