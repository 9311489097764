/* eslint-disable no-unused-vars */
import { Box, IconButton } from '@material-ui/core';
import CodeOffIcon from '@material-ui/icons/CodeOff';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { INTERACTION } from 'src/constants/interactions';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';
import videoController from 'src/utils/videoController';

const getStartTime = (interaction) => {
  if (interaction.type === INTERACTION.TYPE.JUMP_TO) {
    return parseFloat(interaction.jump.jump_time);
  }
  return parseFloat(interaction.start_time);
};

export const NextPrevInteraction = ({ progressControlBounding, visible, onClickOff }) => {
  const interactionList = useSelector(NodeInteractionSelectors.getInteractionList);
  const videoBounding = useSelector(VideoPlayerSelectors.getVideoBounding);
  const videoDuration = useSelector(VideoPlayerSelectors.getDuration);
  const currentTime = useSelector(VideoPlayerSelectors.getCurrentTime);
  const isPaused = useSelector(VideoPlayerSelectors.getIsPaused);
  const [curIdx, setCurIdx] = useState(-1);
  const videoControl = videoController.getInstance();
  const listStartTime = useMemo(() => interactionList.map((inter) => getStartTime(inter)).sort((a, b) => a - b), [
    interactionList
  ]);
  useEffect(() => {
    if (videoControl) {
      if (visible) {
        videoControl.pause();
      }
    }
  }, [isPaused, visible, videoControl]);

  const onPrevClick = useCallback(() => {
    if (currentTime <= listStartTime[0] || (curIdx === 0 && currentTime === listStartTime[curIdx])) return;
    let newIdx;
    if (curIdx === -1 || currentTime !== listStartTime[curIdx]) {
      listStartTime.every((time, index, array) => {
        if (index === listStartTime.length - 1) {
          newIdx = index;
          return false;
        }
        if (currentTime === array[index + 1]) {
          newIdx = index;
          return false;
        }
        if (currentTime > time && currentTime < array[index + 1]) {
          newIdx = index;
          return false;
        }
        return true;
      });
    } else {
      newIdx = curIdx - 1;
    }
    videoControl.seek(listStartTime[newIdx]);
    setCurIdx(newIdx);
  }, [listStartTime, currentTime, videoControl, curIdx]);

  const onNextClick = useCallback(() => {
    if (
      currentTime >= listStartTime.at(-1) ||
      (curIdx === listStartTime.length - 1 && currentTime === listStartTime.at(-1))
    )
      return;
    let newIdx;
    if (currentTime < listStartTime[0]) {
      newIdx = 0;
    } else if (curIdx === -1 || currentTime !== listStartTime[curIdx]) {
      listStartTime.every((time, index, array) => {
        if (currentTime === time) {
          newIdx = index + 1;
          return false;
        }
        if (currentTime > time && currentTime < array[index + 1]) {
          newIdx = index + 1;
          return false;
        }
        return true;
      });
    } else {
      newIdx = curIdx + 1;
    }
    videoControl.seek(listStartTime[newIdx]);
    setCurIdx(newIdx);
  }, [listStartTime, currentTime, videoControl, curIdx]);

  useEffect(() => {
    if (!visible) {
      setCurIdx(-1);
    }
  }, [visible]);

  return videoBounding && progressControlBounding ? (
    <Box
      id="next-prev-bounding-box"
      className="waocon-interaction-bounding-box"
      sx={{
        overflow: 'visible',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `rgba(0, 0, 0, 0.5)`,
        visibility: visible ? 'visible' : 'hidden'
      }}
    >
      <IconButton onClick={onPrevClick}>
        <SkipPreviousIcon sx={{ color: 'white' }} />
      </IconButton>

      <IconButton
        onClick={() => {
          onClickOff();
          videoControl.play();
        }}
      >
        <CodeOffIcon sx={{ color: 'white' }} />
      </IconButton>

      <IconButton onClick={onNextClick}>
        <SkipNextIcon sx={{ color: 'white' }} />
      </IconButton>
      <Box
        sx={{
          position: 'absolute',
          top: progressControlBounding.top - videoBounding.top,
          left: progressControlBounding.left - videoBounding.left,
          width: progressControlBounding.width,
          height: progressControlBounding.height,
          pointerEvents: 'none'
        }}
      >
        {listStartTime.map((time) => (
          <div key={`${time}`}>
            <Box
              sx={{
                width: '5px',
                height: '5px',
                bgcolor: '#e3a807',
                borderRadius: '50%',
                position: 'absolute',
                top: '-1px',
                left: (time / videoDuration) * progressControlBounding.width
              }}
            />
          </div>
        ))}
      </Box>
    </Box>
  ) : null;
};

NextPrevInteraction.propTypes = {
  // videoBounding: PropTypes.object.isRequired,
  progressControlBounding: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onClickOff: PropTypes.func.isRequired
};
