import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router';
import InputField from 'src/components/FormControls/InputField';
import PasswordField from 'src/components/FormControls/PasswordField';
import RegexPattern from 'src/constants/RegexPattern';
import { useAuth } from 'src/hooks';
import { useNotify } from 'src/hooks/useNotify';
import * as Yup from 'yup';

const ResetPasswordForm = () => {
  const theme = useTheme();

  const location = useLocation();

  const email = location.state?.email;

  const { successNotify } = useNotify();

  const { t } = useTranslation();

  const { onResetPassword, onForgotPassword, forgotPasswordState } = useAuth();

  const [isRPdHover, setIsRPdHover] = useState(false);

  const schema = Yup.object().shape({
    otp: Yup.mixed()
      .test('required', t('auth.validate.otp.required'), (value) => value.trim())
      .test('format', t('auth.validate.otp.format'), (value) => RegexPattern.OTP.test(value)),

    password: Yup.string()
      .required(t('auth.validate.password.required'))
      .min(6, t('auth.validate.password.min', { min: 6 }))
      .max(32, t('auth.validate.password.max', { max: 32 }))
      .test('format', t('auth.validate.password.format'), (value) => RegexPattern.Password.test(value)),

    passwordConfirm: Yup.mixed().oneOf([Yup.ref('password'), null], t('auth.validate.password.confirm'))
  });

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      otp: '',
      password: '',
      passwordConfirm: ''
    },
    resolver: yupResolver(schema)
  });

  const { isSubmitting } = formState;

  const handleOTPKeyPress = (evt) => {
    if (!RegexPattern.NumberChar.test(evt.key) || evt.target.value.length >= 6) {
      evt.preventDefault();
    }
  };

  const handleResendOTP = async () => {
    await onForgotPassword({ email });
    successNotify(t('auth.label.resendOTPMsg'));
  };

  return email ? (
    <form noValidate onSubmit={handleSubmit(onResetPassword)}>
      <Box mt={2}>
        <Typography variant="caption" display="block" color="textSecondary">
          {t('auth.label.resetPasswordCaption', { email })}
        </Typography>
      </Box>
      <InputField
        required
        fullWidth
        autoFocus
        name="otp"
        label={t('auth.label.otp')}
        onKeyPress={handleOTPKeyPress}
        control={control}
        formState={formState}
        disabled={isSubmitting}
      />
      <PasswordField
        required
        fullWidth
        name="password"
        label={t('auth.label.password')}
        control={control}
        formState={formState}
        disabled={isSubmitting}
      />
      <PasswordField
        required
        fullWidth
        name="passwordConfirm"
        label={t('auth.label.passwordConfirm')}
        control={control}
        formState={formState}
        disabled={isSubmitting}
      />
      {forgotPasswordState.requesting ? (
        <Box
          style={{
            cursor: 'not-allowed',
            display: 'flex',
            justifyContent: 'flex-end',
            textDecoration: 'none',
            color: '#ccc'
          }}
          to="/forgot-password"
        >
          {t('auth.label.resendOTP')}
        </Box>
      ) : (
        <Box
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'flex-end',
            textDecoration: isRPdHover ? 'underline' : 'none',
            color: isRPdHover ? theme.palette.primary.main : theme.palette.text.primary
          }}
          to="/forgot-password"
          onClick={handleResendOTP}
          onMouseEnter={() => setIsRPdHover(true)}
          onMouseLeave={() => setIsRPdHover(false)}
        >
          {t('auth.label.resendOTP')}
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <Button fullWidth color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
          {t('auth.label.resetPassword')}
        </Button>
      </Box>
    </form>
  ) : (
    <Navigate to="/404" replace />
  );
};

export default ResetPasswordForm;
