/* eslint-disable */
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  IconButton,
  ListItemText,
  makeStyles,
  MenuItem,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import UserRoles from 'src/constants/UserRoles';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { routes } from 'src/configs';
import { useNavigate } from 'react-router';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LanguagePopover from './LanguagePopover';
import ReviewProject from './PreviewProject';
import PublicSettings from './PublicSettings';
import PublishManager from './PublishManager';
import AccountPopover from './AccountPopover';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`
    }
  },
  box: {
    padding: '44px 20px',
    paddingBottom: '0'
  },
  boxItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
    marginBottom: '14px'
  },
  language: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const ActionListPopover = () => {
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const classes = useStyles();
  const { userState, onLogout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await onLogout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };
  return (
    <>
      <Tooltip title={t('profileMenu.profileMenu')}>
        <Box
          component={ButtonBase}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <MoreHorizIcon fontSize="large" />
        </Box>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          position: 'relative',
          sx: { width: 240 }
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: '4px'
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Box className={classes.box}>
          <Box className={classes.boxItem}>
            <ReviewProject text={t('projectPreview.label.projectPreview')} />
          </Box>
          <Box className={classes.boxItem}>
            <PublishManager text={t('publishManager.label.publishManager')} />
          </Box>
          <Box className={classes.boxItem}>
            <PublicSettings text={t('publicSettings.title')} />
          </Box>
          <Box className={classes.boxItem}>
            <AccountPopover text={t('profileMenu.profileMenu')} />
          </Box>

          <Divider />

          <Box className={`${classes.boxItem} ${classes.language}`}>
            <LanguagePopover />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ActionListPopover;
