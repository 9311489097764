import { Box, CircularProgress, experimentalStyled } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';
import { NAVBAR_HEIGHT, SIDEBAR_WIDTH } from 'src/configs/layout';
import { requireAuth } from 'src/hooks';
import { reset } from 'src/actions/projectDetail';
import { useProjectDetail } from 'src/hooks/useProjectDetail';
import Project from 'src/pages/Project';
import * as ProjectSelectors from 'src/selectors/projectDetail';
import ProjectNavbar from './ProjectNavbar';
import ProjectSidebar from './ProjectSidebar';

const ProjectLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const ProjectLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: NAVBAR_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDEBAR_WIDTH
  }
}));

const ProjectLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const ProjectLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const ProjectLayout = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { onFetch } = useProjectDetail();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const fetchState = useSelector(ProjectSelectors.getFetchState);

  useEffect(() => {
    onFetch(id);
    return () => {
      dispatch(reset());
    };
  }, [id]);

  return (
    <>
      {fetchState.failure && <Navigate to="/404" />}
      {fetchState.requesting ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10
          }}
        >
          <CircularProgress size={80} thickness={2.0} />
        </Box>
      ) : (
        <ProjectLayoutRoot>
          <ProjectNavbar onSidebarMobileOpen={() => setMobileNavOpen(true)} />
          <ProjectSidebar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
          <ProjectLayoutWrapper>
            <ProjectLayoutContainer>
              <ProjectLayoutContent>
                <Project />
              </ProjectLayoutContent>
            </ProjectLayoutContainer>
          </ProjectLayoutWrapper>
        </ProjectLayoutRoot>
      )}
    </>
  );
};

export default requireAuth(ProjectLayout);
