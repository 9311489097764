import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PrejectPreviewTypes } from 'src/actions/projectPreview';

const requestState = {
  requesting: false,
  success: false,
  failure: false
};

const INITIAL_STATE = {
  preview: null,

  getPreview: requestState
};

const projectPreviewReset = () => INITIAL_STATE;

const getPreviewRequest = (state = INITIAL_STATE) => ({
  ...state,
  getPreview: {
    ...requestState,
    requesting: true
  }
});

const getPreviewSuccess = (state = INITIAL_STATE, { preview }) => ({
  ...state,
  getPreview: {
    ...requestState,
    success: true
  },
  preview
});

const getPreviewFailure = (state = INITIAL_STATE) => ({
  ...state,
  getPreview: {
    ...requestState,
    failure: true
  }
});

export const HANDLERS = {
  [PrejectPreviewTypes.PROJECT_PREVIEW_RESET]: projectPreviewReset,

  [PrejectPreviewTypes.PROJECT_PREVIEW_GET_PREVIEW_REQUESTING]: getPreviewRequest,
  [PrejectPreviewTypes.PROJECT_PREVIEW_GET_PREVIEW_SUCCESS]: getPreviewSuccess,
  [PrejectPreviewTypes.PROJECT_PREVIEW_GET_PREVIEW_FAILURE]: getPreviewFailure
};

const projectPreviewReducer = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'projectPreview',
  storage
};

export default persistReducer(persistConfig, projectPreviewReducer);
