import { Box, Button, TextField, Typography } from '@material-ui/core';
import { useTheme } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import 'src/containers/interactionsForm/text/TextInteraction.css';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';

const ZoomInteractionInteractionForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const textConfig = useSelector(EdittingInteractionSelectors.textInteractionGetTextConfig);
  const dispatch = useDispatch();

  const [titleError, setTitleError] = useState('');

  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: textConfig
  });

  const handleZomInPrewview = useCallback(() => {
    dispatch(
      EdittingInteractionActions.setConfig({
        is_preview: true
      })
    );
  });

  const handleZomOutPrewview = useCallback(() => {
    dispatch(
      EdittingInteractionActions.setConfig({
        is_preview: false
      })
    );
  });

  const onSubmit = handleSubmit((formValues) => {
    dispatch(EdittingInteractionActions.textInteractionSetTextConfig(formValues));
  });

  useEffect(() => {
    reset(textConfig);
  }, [textConfig]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="title"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.zoom.label.title')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    onChange(event.target.value);
                    dispatch(EdittingInteractionActions.setConfig({ title: event.target.value }));

                    if (!event.target.value.length || event.target.value.length > 100) {
                      if (!event.target.value.length) {
                        setTitleError(t('interaction.zoom.validate.title.required'));
                      } else {
                        setTitleError(t('interaction.zoom.validate.title.max', { max: 100 }));
                      }
                      dispatch(EdittingInteractionActions.setValidateError(true));
                    } else {
                      setTitleError('');
                      dispatch(EdittingInteractionActions.setValidateError(false));
                    }
                  }}
                  value={value}
                />
              </Box>
              {!!titleError && (
                <Box
                  sx={{
                    display: 'flex',
                    height: 40,
                    maxHeight: 40,
                    alignItems: 'center',
                    color: theme.palette.error.main
                  }}
                >
                  <Typography width={160} />
                  <Typography sx={{ width: 'calc(100% - 160px)' }}>{titleError}</Typography>
                </Box>
              )}
            </Box>
          )}
        />

        <Box sx={{ display: 'flex', mb: 2 }}>
          <Typography sx={{ fontWeight: 600 }} width={160}>
            {t('interaction.zoom.label.zoomPreview')}
          </Typography>
          <Box sx={{ width: 'calc(100% - 160px)', display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="outlined"
              sx={{ width: '48%' }}
              disabled={textConfig.is_preview}
              onClick={handleZomInPrewview}
            >
              {t('interaction.zoom.label.zoomIn')}
            </Button>
            <Button
              variant="outlined"
              sx={{ width: '48%' }}
              disabled={!textConfig.is_preview}
              onClick={handleZomOutPrewview}
            >
              {t('interaction.zoom.label.zoomOut')}
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ZoomInteractionInteractionForm;
