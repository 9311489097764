import { createTypes } from 'reduxsauce';

export const VideoPlayerTypes = createTypes(`
  RESET_REQUEST
  SET_VIDEO_PLAYER
  SET_SHOW_VIDEO_GRID
  SET_WIDTH_CELL_GRID
  SET_VIDEO_BOUNDING
  SET_VIDEO_ZOOM
  SET_VIDEO_DÁTA
`);

export const setVideoPlayer = (videoPlayer) => ({ type: VideoPlayerTypes.SET_VIDEO_PLAYER, videoPlayer });
export const setShowVideoGrid = (showVideoGrid) => ({ type: VideoPlayerTypes.SET_SHOW_VIDEO_GRID, showVideoGrid });
export const setWidthCellGrid = (widthCellGrid) => ({ type: VideoPlayerTypes.SET_WIDTH_CELL_GRID, widthCellGrid });
export const setVideoBounding = (videoBounding) => ({ type: VideoPlayerTypes.SET_VIDEO_BOUNDING, videoBounding });
export const setTriggerDisplay = (triggerDisplay) => ({
  type: VideoPlayerTypes.SET_VIDEO_TRIGGER_DISPLAY,
  triggerDisplay
});
export const resetRequest = () => ({ type: VideoPlayerTypes.RESET_REQUEST });
export const setZoom = (zoom) => ({ type: VideoPlayerTypes.SET_VIDEO_ZOOM, zoom });
export const setVideoData = (videoData) => ({ type: VideoPlayerTypes.SET_VIDEO_DÁTA, videoData });
