import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as ProjectActions from 'src/actions/projectList';
import { useNotify } from 'src/hooks/useNotify';
import { fields as _fields } from 'src/models/project';
import { FormSchema } from 'src/models/schema';
import { useModal } from '../useModal';

export const useProjectCreate = ({ modalName }) => {
  const { successNotify, errorNotify } = useNotify();
  const { t } = useTranslation();
  const fields = _fields(t);
  const dispatch = useDispatch();
  const { closeModal, isShowing } = useModal(modalName);
  const handleClose = useCallback(() => closeModal(modalName), [modalName]);

  const { schema } = useMemo(() => new FormSchema(fields.id, [fields.name]), []);
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      [fields.name.name]: ''
    },
    resolver: schema
  });
  const isSubmitting = useMemo(() => formState.isSubmitting, [formState]);
  const formItems = useMemo(
    () => ({
      control,
      formState
    }),
    [control, formState]
  );
  const onCreate = useCallback(async (formData) => {
    try {
      await dispatch(ProjectActions.createRequest(formData));
      successNotify(t('projects.create.success'));
      handleClose();
      dispatch(ProjectActions.listRefetchRequest());
    } catch (e) {
      dispatch(ProjectActions.createFailure(e));
      errorNotify(t('projects.create.failed'));
    }
  }, []);

  useEffect(() => {
    reset({ [fields.name.name]: '' });
  }, [isShowing]);
  return {
    t,
    isShowing,
    isSubmitting,
    formItems,
    handleClose,
    control,
    handleSubmit,
    fields,
    onCreate
  };
};
