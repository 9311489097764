import { FormHelperText, Grid } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import 'src/containers/interactionsForm/text/TextInteraction.css';

const EditorField = ({ name, control, formState, onChange, onInit, defaultTextAlign, ...others }) => {
  const { errors = {} } = formState;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Grid container>
          <Grid item xs={12}>
            <Editor
              tinymceScriptSrc="/js/tinymce/tinymce.min.js"
              {...others}
              onEditorChange={(content) => {
                field.onChange(content);
                onChange({ [name]: content });
              }}
              value={field.value}
              onInit={() => {
                if (onInit) onInit();
              }}
              init={{
                width: '100%',
                height: 200,
                menubar: false,
                plugins: ['lists', 'paste'],
                toolbar:
                  'fontselect fontsizeselect forecolor | undo redo | bold italic underline | alignleft aligncenter alignright | indent outdent removeformat strikethrough subscript superscript | numlist bullist',
                toolbar_mode: 'sliding',
                setup: (editor) => {
                  editor.on('init', () => {
                    if (defaultTextAlign) {
                      editor.execCommand(`Justify${defaultTextAlign}`, false);
                    }
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormHelperText error>{_get(errors, `${name}.message`)}</FormHelperText>
          </Grid>
        </Grid>
      )}
    />
  );
};

EditorField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired,
  defaultTextAlign: PropTypes.oneOf(['Center', 'Left', 'Right'])
};

export default EditorField;
