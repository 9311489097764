import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';
import qs from 'qs';

export const ProjectProcessManagementTypes = createTypes(`
  
  PROJECT_PROCESS_MANAGEMENT_SET_PAGE
  PROJECT_PROCESS_MANAGEMENT_SET_FILTER
  
  PROJECT_PROCESS_MANAGEMENT_LIST_SUCCESS
  PROJECT_PROCESS_MANAGEMENT_LIST_FAILURE
  PROJECT_PROCESS_MANAGEMENT_LIST_REQUEST

  PROJECT_PROCESS_MANAGEMENT_REST_REQUEST

  
  
`);

export const resetRequest = () => ({ type: ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_REST_REQUEST });

export const listSuccess = (rs) => ({
  type: ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_LIST_SUCCESS,
  list: rs.data,
  totalPage: rs.last_page
});

export const listFailure = (error) => ({
  type: ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_LIST_FAILURE,
  error
});

export const listRequest = (_filter, page) => async (dispatch) => {
  const filter = { ..._filter, page };
  try {
    dispatch({ type: ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_LIST_REQUEST });
    const rs = await axiosClient.get(`/admin/project/searchProject?${qs.stringify(filter)}`);

    dispatch(listSuccess(rs.data));
  } catch (e) {
    dispatch(listFailure(e));
  }
};

export const setPage = (page) => ({ type: ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_SET_PAGE, page });

export const setFilter = (filter) => ({
  type: ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_SET_FILTER,
  filter
});
