import { INTERACTION } from 'src/constants/interactions';
import timelineFormat from './timelineFormat';

export const getInteractionStartTime = (interaction) => {
  if (!interaction) return undefined;
  if (interaction.type === INTERACTION.TYPE.JUMP_TO) {
    return interaction.jump.jump_time;
  }
  return interaction.start_time;
};

export const getInteractionLabel = (interaction) => {
  if (interaction.type === INTERACTION.TYPE.JUMP_TO) {
    return `${timelineFormat(interaction.jump.jump_time, 'hh:mm:ss.ms')}  ${interaction.jump.title}`;
  }
  return `${timelineFormat(interaction.start_time, 'hh:mm:ss.ms')}  ${interaction.title}`;
};
