import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';
import qs from 'qs';

export const AccountTypes = createTypes(`
  ACCOUNT_LIST_REQUEST
  ACCOUNT_LIST_SUCCESS
  ACCOUNT_LIST_FAILURE
  ACCOUNT_SET_PAGE
  ACCOUNT_SET_FILTER
  ACCOUNT_LIST_RESET_REQUEST
  
  ACCOUNT_CREATE_REQUEST
  ACCOUNT_CREATE_SUCCESS
  ACCOUNT_CREATE_FAILURE

  ACCOUNT_EDIT_REQUEST
  ACCOUNT_EDITUSER_REQUEST
  ACCOUNT_EDIT_SUCCESS
  ACCOUNT_EDIT_FAILURE

  ACCOUNT_DELETE_REQUEST
  ACCOUNT_DELETE_SUCCESS
  ACCOUNT_DELETE_FAILURE

  ACCOUNT_DETAIL_REQUEST
  ACCOUNT_DETAILUSER_REQUEST
  ACCOUNT_DETAIL_SUCCESS
  ACCOUNT_DETAIL_FAILURE
`);

export const resetRequest = () => ({ type: AccountTypes.ACCOUNT_LIST_RESET_REQUEST });

export const listSuccess = (rs) => ({
  type: AccountTypes.ACCOUNT_LIST_SUCCESS,
  list: rs.data,
  totalPage: rs.last_page
});

export const listFailure = (error) => ({
  type: AccountTypes.ACCOUNT_LIST_FAILURE,
  error
});

export const listRequest = (_filter, page) => async (dispatch) => {
  const filter = { ..._filter, page };
  try {
    dispatch({ type: AccountTypes.ACCOUNT_LIST_REQUEST });
    const rs = await axiosClient.get(`/admin/user?${qs.stringify(filter)}`);

    dispatch(listSuccess(rs.users));
  } catch (e) {
    dispatch(listFailure(e));
  }
};

export const setPage = (page) => ({ type: AccountTypes.ACCOUNT_SET_PAGE, page });

export const setFilter = (filter) => ({ type: AccountTypes.ACCOUNT_SET_FILTER, filter });

export const createSuccess = () => ({
  type: AccountTypes.ACCOUNT_CREATE_SUCCESS
});

export const createFailure = (error) => ({
  type: AccountTypes.ACCOUNT_CREATE_FAILURE,
  error
});

export const createRequest = (formData) => async (dispatch) => {
  dispatch({ type: AccountTypes.ACCOUNT_CREATE_REQUEST });
  return axiosClient.post('/admin/register', {
    name: formData.name,
    email: formData.email,
    password: formData.password,
    password_confirmation: formData.passwordConfirm,
    type: formData.type
  });
};

export const listRefetchRequest = () => async (dispatch) => {
  dispatch({ type: AccountTypes.ACCOUNT_LIST_RESET_REQUEST });
  try {
    dispatch({ type: AccountTypes.ACCOUNT_LIST_REQUEST });
    const rs = await axiosClient.get(`/admin/user`);
    dispatch(listSuccess(rs.data));
  } catch (e) {
    dispatch(listFailure(e));
  }
};

export const editSuccess = () => ({
  type: AccountTypes.ACCOUNT_EDIT_SUCCESS
});

export const editFailure = (error) => ({
  type: AccountTypes.ACCOUNT_EDIT_FAILURE,
  error
});

export const editRequest = (formData, id) => async (dispatch) => {
  dispatch({ type: AccountTypes.ACCOUNT_EDIT_REQUEST });
  return axiosClient.put(`/admin/user/${id}?_method=PUT`, {
    name: formData.name,
    type: formData.type,
    password: formData.password,
    password_confirmation: formData.passwordConfirm
  });
};

export const editAccountRequest = (formData, id) => async (dispatch) => {
  dispatch({ type: AccountTypes.ACCOUNT_EDITUSER_REQUEST });
  return axiosClient.put(`/admin/updateAccount/${id}?_method=PUT`, {
    name: formData.name,
    type: formData.type,
    password: formData.password,
    password_confirmation: formData.passwordConfirm
  });
};

export const deleteSuccess = () => ({
  type: AccountTypes.ACCOUNT_DELETE_SUCCESS
});

export const deleteFailure = (error) => ({
  type: AccountTypes.ACCOUNT_DELETE_FAILURE,
  error
});

export const deleteRequest = (id) => async (dispatch) => {
  dispatch({ type: AccountTypes.ACCOUNT_DELETE_REQUEST });
  return axiosClient.delete(`/admin/user/${id}`);
};

export const detailSuccess = (user) => ({
  type: AccountTypes.ACCOUNT_DETAIL_SUCCESS,
  accountDetail: user
});

export const detailFailure = (error) => ({
  type: AccountTypes.ACCOUNT_DETAIL_FAILURE,
  error
});

export const detailRequest = (id) => async (dispatch) => {
  dispatch({ type: AccountTypes.ACCOUNT_DETAIL_REQUEST });
  return axiosClient.get(`/admin/user/${id}`);
};
export const detailAccoutRequest = (id) => async (dispatch) => {
  dispatch({ type: AccountTypes.ACCOUNT_DETAILUSER_REQUEST });
  return axiosClient.get(`/admin/edit/${id}`);
};
