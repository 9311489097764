/* eslint-disable */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import AreaField from 'src/components/FormControls/AreaField';
import InputField from 'src/components/FormControls/InputField';
import { usePublishSettings } from 'src/hooks/publishSettings';
import * as yup from 'yup';

const Sharing = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const { publishState, onUpdate } = usePublishSettings();

  const initialForm = useMemo(() => {
    const { sharing_title, sharing_description } = publishState || {};

    return {
      sharing_title: sharing_title || '',
      sharing_description: sharing_description || ''
    };
  }, [publishState]);

  const schema = yup.object().shape({
    sharing_title: yup
      .string()
      .nullable()
      .trim()
      .max(100, t('publicSettings.basicInfo.validate.title.max', { max: 100 })),
    sharing_description: yup
      .string()
      .nullable()
      .trim()
      .max(200, t('publicSettings.basicInfo.validate.description.max', { max: 200 }))
  });

  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: initialForm,
    resolver: yupResolver(schema)
  });

  const { isDirty } = formState;

  const controlFields = useMemo(
    () => ({
      control,
      formState
    }),
    [control, formState]
  );

  const onSubmit = useCallback(async (formValues) => {
    const { sharing_title, sharing_description } = formValues;
    const publish = await onUpdate({ id, sharing_title, sharing_description });
    reset({ sharing_title: publish.sharing_title, sharing_description: publish.sharing_description });
  });

  const handleCancelClick = useCallback(() => {
    reset(initialForm);
  }, [initialForm]);

  useEffect(() => {
    return () => {
      reset(initialForm);
    };
  }, []);

  return (
    <form noValidate>
      <InputField name="sharing_title" label={t('publicSettings.basicInfo.title')} {...controlFields} />
      <AreaField
        style={{ resize: 'vertical', width: 350 }}
        minRows={5}
        name="sharing_description"
        label={t('publicSettings.basicInfo.description')}
        {...controlFields}
      />

      <Box sx={{ display: isDirty ? '' : 'none' }}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)} sx={{ mr: 1 }}>
          {t('publicSettings.basicInfo.saveAndApply')}
        </Button>
        <Button variant="contained" onClick={handleCancelClick} sx={{ ml: 1 }}>
          {t('publicSettings.basicInfo.cancel')}
        </Button>
      </Box>
    </form>
  );
};

export default Sharing;
