import { createReducer } from 'reduxsauce';
import { ProjectDetailTypes } from 'src/actions';

const requestState = {
  requesting: false,
  success: false,
  failure: false
};

const INITIAL_STATE = {
  project: null,
  searchTerms: '',
  previewMedia: null,
  selectedFolder: null,
  uploadMediaProgress: 100,
  uploadMediaCancelTokenSource: null,

  uploadMediaQueue: [],

  fetch: requestState,
  uploadMedia: requestState,
  createFolder: requestState,
  deleteMedia: requestState,
  deleteFolder: requestState
};

const setSearchTerms = (state = INITIAL_STATE, { searchTerms }) => ({
  ...state,
  searchTerms
});

const setPreviewMedia = (state = INITIAL_STATE, { previewMedia }) => ({
  ...state,
  previewMedia
});

const setSelectedFolder = (state = INITIAL_STATE, { selectedFolder }) => ({
  ...state,
  selectedFolder
});

const setUploadMediaProgress = (state = INITIAL_STATE, { uploadMediaProgress }) => ({
  ...state,
  uploadMediaProgress
});

const setUploadMediaCancelTokenSource = (state = INITIAL_STATE, { uploadMediaCancelTokenSource }) => ({
  ...state,
  uploadMediaCancelTokenSource
});

const setUploadMediaQueue = (state = INITIAL_STATE, { uploadMedia }) => ({
  ...state,
  uploadMediaQueue: [...state.uploadMediaQueue, uploadMedia]
});

const updateUploadMediaQueue = (state = INITIAL_STATE, { id, progress }) => {
  const newUploadMediaQueue = [...state.uploadMediaQueue];
  const index = newUploadMediaQueue.findIndex((elemt) => elemt.id === id);

  if (newUploadMediaQueue[index]) {
    newUploadMediaQueue[index].progress = progress;
  }

  return {
    ...state,
    uploadMediaQueue: newUploadMediaQueue
  };
};

const removeUploadMediaQueue = (state = INITIAL_STATE, { id }) => {
  const newUploadMediaQueue = state.uploadMediaQueue.filter((uploadMedia) => uploadMedia.id !== id);

  return {
    ...state,
    uploadMediaQueue: newUploadMediaQueue
  };
};

const fetchSuccess = (state = INITIAL_STATE, { project }) => ({
  ...state,
  fetch: {
    ...requestState,
    success: true
  },
  project
});

const fetchFailure = (state = INITIAL_STATE) => ({
  ...state,
  fetch: {
    ...requestState,
    failure: true
  }
});

const fetchRequest = (state = INITIAL_STATE) => ({
  ...state,
  fetch: {
    ...requestState,
    requesting: true
  }
});

const uploadMediaSuccess = (state = INITIAL_STATE) => ({
  ...state,
  uploadMedia: {
    ...requestState,
    success: true
  }
});

const uploadMediaFailure = (state = INITIAL_STATE) => ({
  ...state,
  uploadMedia: {
    ...requestState,
    failure: true
  }
});

const uploadMediaRequest = (state = INITIAL_STATE) => ({
  ...state,
  uploadMedia: {
    ...requestState,
    requesting: true
  }
});

const cancelUploadMedia = (state = INITIAL_STATE) => {
  state.uploadMediaQueue.forEach((media) => {
    media.source.cancel();
  });

  return {
    ...state,
    uploadMediaQueue: []
  };
};

const reset = () => INITIAL_STATE;

const updateRequest = (state = INITIAL_STATE) => ({
  ...state,
  fetch: {
    failed: false,
    requesting: true
  }
});

const updateSuccess = (state = INITIAL_STATE, { updatedProject }) => ({
  ...state,
  project: {
    ...state.project,
    ...updatedProject
  },
  fetch: {
    requesting: false,
    failed: false
  }
});

const updateFailure = (state = INITIAL_STATE) => ({
  ...state,
  fetch: {
    requesting: false,
    failed: true
  }
});

// Create folder
const createFolderSuccess = (state = INITIAL_STATE) => ({
  ...state,
  createFolder: {
    ...requestState,
    success: true
  }
});

const createFolderFailure = (state = INITIAL_STATE) => ({
  ...state,
  createFolder: {
    ...requestState,
    failure: true
  }
});

const createFolderRequest = (state = INITIAL_STATE) => ({
  ...state,
  createFolder: {
    ...requestState,
    requesting: true
  }
});

// Delete media
const deleteMediaSuccess = (state = INITIAL_STATE) => ({
  ...state,
  deleteMedia: {
    ...requestState,
    success: true
  }
});

const deleteMediaFailure = (state = INITIAL_STATE) => ({
  ...state,
  deleteMedia: {
    ...requestState,
    failure: true
  }
});

const deleteMediaRequest = (state = INITIAL_STATE) => ({
  ...state,
  deleteMedia: {
    ...requestState,
    requesting: true
  }
});

// Delete folder
const deleteFolderSuccess = (state = INITIAL_STATE) => ({
  ...state,
  deleteFolder: {
    ...requestState,
    success: true
  }
});

const deleteFolderFailure = (state = INITIAL_STATE) => ({
  ...state,
  deleteFolder: {
    ...requestState,
    failure: true
  }
});

const deleteFolderRequest = (state = INITIAL_STATE) => ({
  ...state,
  deleteFolder: {
    ...requestState,
    requesting: true
  }
});

export const HANDLERS = {
  [ProjectDetailTypes.SET_SEARCH_TERMS]: setSearchTerms,
  [ProjectDetailTypes.SET_PREVIEW_MEDIA]: setPreviewMedia,
  [ProjectDetailTypes.SET_SELECTED_FOLDER]: setSelectedFolder,
  [ProjectDetailTypes.SET_UPLOAD_MEDIA_PROGRESS]: setUploadMediaProgress,
  [ProjectDetailTypes.SET_UPLOAD_MEDIA_CANCEL_TOKEN_SOURCE]: setUploadMediaCancelTokenSource,
  [ProjectDetailTypes.SET_UPLOAD_MEDIA_QUEUE]: setUploadMediaQueue,
  [ProjectDetailTypes.UPDATE_UPLOAD_MEDIA_QUEUE]: updateUploadMediaQueue,
  [ProjectDetailTypes.REMOVE_UPLOAD_MEDIA_QUEUE]: removeUploadMediaQueue,

  [ProjectDetailTypes.FETCH_SUCCESS]: fetchSuccess,
  [ProjectDetailTypes.FETCH_FAILURE]: fetchFailure,
  [ProjectDetailTypes.FETCH_REQUEST]: fetchRequest,

  [ProjectDetailTypes.UPLOAD_MEDIA_SUCCESS]: uploadMediaSuccess,
  [ProjectDetailTypes.UPLOAD_MEDIA_FAILURE]: uploadMediaFailure,
  [ProjectDetailTypes.UPLOAD_MEDIA_REQUEST]: uploadMediaRequest,

  [ProjectDetailTypes.CANCEL_UPLOAD_MEDIA]: cancelUploadMedia,

  [ProjectDetailTypes.RESET]: reset,

  [ProjectDetailTypes.PROJECT_UPDATE_SUCCESS]: updateSuccess,
  [ProjectDetailTypes.PROJECT_UPDATE_FAILURE]: updateFailure,
  [ProjectDetailTypes.PROJECT_UPDATE_REQUEST]: updateRequest,

  [ProjectDetailTypes.PROJECT_CREATE_FOLDER_SUCCESS]: createFolderSuccess,
  [ProjectDetailTypes.PROJECT_CREATE_FOLDER_FAILURE]: createFolderFailure,
  [ProjectDetailTypes.PROJECT_CREATE_FOLDER_REQUEST]: createFolderRequest,

  [ProjectDetailTypes.PROJECT_DELETE_MEDIA_SUCCESS]: deleteMediaSuccess,
  [ProjectDetailTypes.PROJECT_DELETE_MEDIA_FAILURE]: deleteMediaFailure,
  [ProjectDetailTypes.PROJECT_DELETE_MEDIA_REQUEST]: deleteMediaRequest,

  [ProjectDetailTypes.PROJECT_DELETE_FOLDER_SUCCESS]: deleteFolderSuccess,
  [ProjectDetailTypes.PROJECT_DELETE_FOLDER_FAILURE]: deleteFolderFailure,
  [ProjectDetailTypes.PROJECT_DELETE_FOLDER_REQUEST]: deleteFolderRequest
};

export const projectDetail = createReducer(INITIAL_STATE, HANDLERS);

// const persistConfig = {
//   keyPrefix: 'hls-',
//   key: 'projectDetail',
//   storage
// };

// export default persistReducer(persistConfig, projectDetail);
export default projectDetail;
