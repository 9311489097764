import { createSelector } from 'reselect';

const videoPlayerState = (state) => state.videoPlayer;

export const getVideoPlayerState = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.videoPlayer?.getState);

export const getCurrentTime = createSelector(
  videoPlayerState,
  (videoPlayer) => videoPlayer.videoPlayer?.currentTime || 0
);

export const getShowVideoGrid = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.showVideoGrid);
export const getDuration = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.videoPlayer?.duration || 0);
export const getIsPaused = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.videoPlayer?.paused);

export const getWidthCellGrid = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.widthCellGrid);

export const getVideoBounding = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.videoBounding);

export const getReload = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.videoPlayer?.load);

export const getMuted = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.videoPlayer?.muted);

export const getHasStarted = createSelector(
  videoPlayerState,
  (videoPlayer) => videoPlayer.videoPlayer?.hasStarted || false
);

export const getHasEnded = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.videoPlayer?.ended || false);

export const getZoom = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.zoom);
export const getVideoData = createSelector(videoPlayerState, (videoPlayer) => videoPlayer.videoData);
