import { Box } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { LayoutContainer, LayoutHeader } from 'src/components/layout';
import { useAuth } from 'src/hooks';
import { routes } from 'src/configs';
import UpdateUserForm from './components/UpdateUserForm';

const AcccountUpdateUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { onUserDetail } = useAuth();

  useEffect(
    useCallback(async () => {
      await onUserDetail(id);
    }),
    [id]
  );

  return (
    <LayoutContainer
      title={t('breadcrumbs.accounts.update.title')}
      header={
        <LayoutHeader
          title={t('breadcrumbs.accounts.update.title')}
          breadcrumbs={[
            {
              path: routes.dashboard,
              name: t('breadcrumbs.dashboard')
            },
            {
              path: routes.accountUpdateUser,
              name: t('breadcrumbs.accounts.update.title')
            }
          ]}
        />
      }
    >
      <Box px={3}>
        <UpdateUserForm id={id} />
      </Box>
    </LayoutContainer>
  );
};

export default AcccountUpdateUser;
