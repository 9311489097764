import * as yup from 'yup';
import GenericField from 'src/models/fields/genericField';

export default class StringField extends GenericField {
  constructor(
    name,
    label,
    {
      required = false,
      min = undefined,
      max = undefined,
      matches = undefined,
      email = false,
      transformUpperCase = false,
      testFunc = null
    } = {}
  ) {
    super(name, label);

    this.required = required;
    this.min = min;
    this.max = max;
    this.email = email;
    this.transformUpperCase = transformUpperCase;
    this.testFunc = testFunc;

    if (matches) {
      this.matches = Array.isArray(matches) ? matches : [matches];
    }
  }

  forTable(overrides) {
    const defaultRender = undefined;

    const { title = this.label, sorter = true, dataIndex = this.name, render = defaultRender, ...others } =
      overrides || {};

    return {
      ...others,
      title,
      sorter,
      dataIndex,
      render
    };
  }

  update = ({
    required = false,
    min = undefined,
    max = undefined,
    matches = undefined,
    email = false,
    transformUpperCase = false
  }) => {
    this.required = required;
    this.min = min;
    this.max = max;
    this.email = email;
    this.transformUpperCase = transformUpperCase;

    if (matches) {
      this.matches = Array.isArray(matches) ? matches : [matches];
    }

    return this;
  };

  forForm = () => {
    let yupChain = yup.string().nullable(true).trim().label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    if (this.email) {
      yupChain = yupChain.email();
    }

    if (this.matches) {
      yupChain = yupChain.matches(...this.matches);
    }

    if (this.transformUpperCase) {
      yupChain = yupChain.transform((value, originalValue) => {
        if (!originalValue) {
          return undefined;
        }

        return originalValue.toUpperCase();
      });
    }

    if (this.testFunc) {
      yupChain = yupChain.test('test', (val) => this.testFunc(val));
    }
    return yupChain;
  };

  forFilter = () => yup.string().nullable(true).trim().label(this.label);

  forExport() {
    return yup.mixed().label(this.label);
  }

  forImport() {
    let yupChain = yup.string().nullable(true).trim().label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    if (this.matches) {
      yupChain = yupChain.matches(...this.matches);
    }

    return yupChain;
  }
}
