/* eslint-disable no-unused-vars */
import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import InputField from 'src/components/FormControls/InputField';
import SingleCheckboxField from 'src/components/FormControls/SingleCheckboxField';
import { fields as _formFields } from 'src/models/form';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const tabsStyle = {
  '& .MuiTabs-indicator': {
    backgroundColor: '#ED257A'
  }
};

const tabStyle = {
  maxWidth: '50%',
  width: '50%',
  '&.Mui-selected': {
    color: '#ED257A'
  }
};

const InputFieldxs12Props = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      xs: 12,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px'
      }
    },
    input: {
      xs: 12,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const InputFieldMultilineProps = {
  layout: {
    label: {
      xs: 3,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px'
      }
    },
    input: {
      xs: 9,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const borderWidthProps = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      xs: 7,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px'
      }
    },
    input: {
      xs: 5,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const FormInteractionForm = () => {
  const { t } = useTranslation();
  const formFields = _formFields(t);
  const schema = yup
    .object()
    .shape({
      [formFields.receiveEmail.name]: yup
        .string()
        .nullable(true)
        .trim()
        .label(formFields.receiveEmail.label)
        .required()
        .email()
    })
    .required();
  const videoBounding = useSelector(VideoPlayerSelectors.getVideoBounding);
  const [formIndex, setFormIndex] = useState(0);
  const handleChange = (event, newValue) => {
    setFormIndex(newValue);
  };
  const [formHeight, setFormHeight] = useState(0);
  const form0Ref = useRef();
  const form1Ref = useRef();
  useEffect(() => {
    if (formIndex === 0) {
      setFormHeight(form0Ref.current.getBoundingClientRect().height);
    } else if (formIndex === 1) {
      setFormHeight(form1Ref.current.getBoundingClientRect().height);
    }
  }, [formIndex]);
  const edittingInteraction = useSelector(EdittingInteractionSelectors.getConfig);

  const { control, formState, watch, handleSubmit, setValue, getValues, trigger } = useForm({
    defaultValues: {
      ...edittingInteraction,
      style: {
        ...edittingInteraction.style,
        fontSize: Math.round(edittingInteraction.style.fontSize * videoBounding.height)
      }
    },
    resolver: yupResolver(schema),
    reValidateMode: 'onChange'
  });
  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  const dispatch = useDispatch();

  const onFieldsSubmit = useCallback(async (field) => {
    const key = Object.keys(field)[0];
    if (key === formFields.receiveEmail.name) {
      const rs = await trigger(key);
      if (!rs) {
        dispatch(EdittingInteractionActions.setValidateError('key'));
        return;
      }
      dispatch(EdittingInteractionActions.setValidateError(false));
    }
    dispatch(EdittingInteractionActions.setConfig(field));
  });
  const onStyleFieldsSubmit = useCallback((field) => {
    const key = Object.keys(field)[0];
    dispatch(
      EdittingInteractionActions.setStyleConfig({
        [key.slice(6)]: key === 'style.fontSize' ? field[key] / videoBounding.height : field[key]
      })
    );
  }, []);

  const watchFeedbackScreen = watch(formFields.feedbackScreen.name);
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs sx={tabsStyle} value={formIndex} onChange={handleChange}>
          <Tab sx={tabStyle} label={t('interaction.image.interaction.title')} id="image-interaction" />
          <Tab sx={tabStyle} label={t('interaction.image.style.title')} id="image-style" />
        </Tabs>
      </Box>
      <Box sx={{ position: 'relative', p: 1, height: formHeight }}>
        <Box
          sx={{ position: 'absolute', visibility: formIndex === 0 ? 'visible' : 'hidden', width: '100%' }}
          ref={form0Ref}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputField
                label={formFields.title.label}
                name={formFields.title.name}
                {...formItems}
                {...InputFieldxs12Props}
                BlurOnEnter
                OnBlur={onFieldsSubmit}
              />
            </Grid>
            <Grid xs={12} sx={{ height: '12px' }} />
            <Grid item xs={6}>
              <InputField
                label={formFields.firstField.label}
                name={formFields.firstField.name}
                {...formItems}
                {...InputFieldxs12Props}
                BlurOnEnter
                OnBlur={onFieldsSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label={formFields.secondField.label}
                name={formFields.secondField.name}
                {...formItems}
                {...InputFieldxs12Props}
                BlurOnEnter
                OnBlur={onFieldsSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label={formFields.thirdField.label}
                name={formFields.thirdField.name}
                {...formItems}
                {...InputFieldxs12Props}
                BlurOnEnter
                OnBlur={onFieldsSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label={formFields.emailField.label}
                name={formFields.emailField.name}
                {...formItems}
                {...InputFieldxs12Props}
                BlurOnEnter
                OnBlur={onFieldsSubmit}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={formFields.fileUpload.label}
                name={formFields.fileUpload.name}
                {...formItems}
                {...InputFieldxs12Props}
                BlurOnEnter
                OnBlur={onFieldsSubmit}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={formFields.receiveEmail.label}
                name={formFields.receiveEmail.name}
                {...formItems}
                {...InputFieldxs12Props}
                BlurOnEnter
                OnBlur={onFieldsSubmit}
              />
            </Grid>
            <Grid item xs={12}>
              <SingleCheckboxField
                label={formFields.feedbackScreen.label}
                name={formFields.feedbackScreen.name}
                {...formItems}
                handleChange={onFieldsSubmit}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: -2 }}>
              <SingleCheckboxField
                label={formFields.enableBackground.label}
                name={formFields.enableBackground.name}
                {...formItems}
                handleChange={onFieldsSubmit}
                disabled={!watchFeedbackScreen}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: -2 }}>
              <SingleCheckboxField
                label={formFields.enableFeedback.label}
                name={formFields.enableFeedback.name}
                {...formItems}
                handleChange={onFieldsSubmit}
                disabled={!watchFeedbackScreen}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={formFields.genericFeedback.label}
                name={formFields.genericFeedback.name}
                {...formItems}
                {...InputFieldMultilineProps}
                OnBlur={onFieldsSubmit}
                disabled={!watchFeedbackScreen}
                BlurOnEnter
                multiline
                rows="2"
              />
            </Grid>
          </Grid>
        </Box>
        {/* style form */}
        <Box
          sx={{ position: 'absolute', visibility: formIndex === 1 ? 'visible' : 'hidden', width: '100%' }}
          ref={form1Ref}
        >
          <Grid container>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={11}>
                  <InputField
                    label={formFields.fontSize.label}
                    name={formFields.fontSize.name}
                    {...formItems}
                    {...borderWidthProps}
                    BlurOnEnter
                    OnBlur={onStyleFieldsSubmit}
                    OnMouseUp={onStyleFieldsSubmit}
                    type="number"
                    inputProps={{ min: 6, max: 16 }}
                  />
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                  px
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3}>
              <SingleCheckboxField
                label={formFields.fadeIn.label}
                name={formFields.fadeIn.name}
                {...formItems}
                handleChange={onStyleFieldsSubmit}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default FormInteractionForm;
