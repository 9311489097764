import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { VideoPlayerTypes } from 'src/actions';

const INITIAL_STATE = {
  videoPlayer: null,
  showVideoGrid: false,
  widthCellGrid: 0,
  zoom: false,
  videoData: undefined
};

const setVideoPlayer = (state = INITIAL_STATE, { videoPlayer }) => ({
  ...state,
  videoPlayer
});

const setShowVideoGrid = (state = INITIAL_STATE, { showVideoGrid }) => ({
  ...state,
  showVideoGrid
});

const setWidthCellGrid = (state = INITIAL_STATE, { widthCellGrid }) => ({
  ...state,
  widthCellGrid
});

const setVideoBounding = (state = INITIAL_STATE, { videoBounding }) => ({
  ...state,
  videoBounding
});

const setZoom = (state = INITIAL_STATE, { zoom }) => ({
  ...state,
  zoom: {
    ...state.zoom,
    ...zoom
  }
});

const setVideoData = (state = INITIAL_STATE, { videoData }) => ({
  ...state,
  videoData: {
    ...state.videoData,
    ...videoData
  }
});

const resetRequest = () => INITIAL_STATE;

export const HANDLERS = {
  [VideoPlayerTypes.SET_VIDEO_PLAYER]: setVideoPlayer,
  [VideoPlayerTypes.SET_SHOW_VIDEO_GRID]: setShowVideoGrid,
  [VideoPlayerTypes.SET_WIDTH_CELL_GRID]: setWidthCellGrid,
  [VideoPlayerTypes.SET_VIDEO_BOUNDING]: setVideoBounding,
  [VideoPlayerTypes.SET_VIDEO_ZOOM]: setZoom,
  [VideoPlayerTypes.RESET_REQUEST]: resetRequest,
  [VideoPlayerTypes.SET_VIDEO_DÁTA]: setVideoData
};

const videoPlayerReducer = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'videoPlayer',
  storage
};

export default persistReducer(persistConfig, videoPlayerReducer);
