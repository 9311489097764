/* eslint-disable no-unused-vars */
import { Box, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import timelineFormat, { hmsmsToSeconds, hmsToSeconds } from 'src/utils/timelineFormat';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import videoController from 'src/utils/videoController';
import { useTranslation } from 'react-i18next';
import 'src/containers/timeline/css/InteractionTimelineSlider.css';

const useStyles = makeStyles(() => ({
  center: {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export const VideoTimelineInput = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { videoDuration, videoCurrentTime } = useVideoPlayer();
  const [editingTimeStr, setEditingTimeStr] = useState(0);
  const [editable, setEditable] = useState(false);

  const onEditingTimeChange = useCallback(
    (evt) => {
      if (editable) {
        setEditingTimeStr(evt.target.value);
      }
    },
    [editable]
  );

  const onInputFocus = useCallback(() => {
    videoController.getInstance()?.pause?.();
    setEditable(true);
    setEditingTimeStr(timelineFormat(videoCurrentTime, 'hh:mm:ss.ms'));
  }, [videoController, videoCurrentTime]);

  const handleCurrentTimeLblBlur = useCallback(() => {
    let newVal = hmsmsToSeconds(editingTimeStr);
    if (Number.isNaN(newVal)) {
      newVal = videoCurrentTime;
    } else if (newVal > videoDuration) {
      newVal = videoDuration;
    }
    videoController.getInstance()?.seek?.(newVal);
    setEditable(false);
  }, [editingTimeStr, videoController, videoDuration, videoCurrentTime]);
  return (
    <Box className={classes.center}>
      <Typography component="span" alignContent="flex-end" sx={{ mr: 1 }}>
        {t('interaction.label.currentTime')}
      </Typography>

      <TextField
        value={editable ? editingTimeStr : timelineFormat(videoCurrentTime, 'hh:mm:ss.ms')}
        onChange={onEditingTimeChange}
        onFocus={onInputFocus}
        onBlur={handleCurrentTimeLblBlur}
        onKeyDown={(evt) => {
          if (evt.key === 'Enter') {
            evt.target.blur();
          }
        }}
        size="small"
        inputProps={{ style: { textAlign: 'center', width: 100 } }}
      />
    </Box>
  );
};
