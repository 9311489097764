/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Rnd } from 'react-rnd';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { Box, Typography } from '@material-ui/core';
import { VIDEO_CONTROLBAR_HEIGHT } from 'src/configs/layout';
import { useTranslation } from 'react-i18next';
import * as videoPlayerSelectors from 'src/selectors/videoPlayer';
import { GA_CATEGORIES, GA_ACTION } from 'src/utils/getInitials';
import ReactGA from 'react-ga';
import './ImageInteractionDisplay.css';
import videoController from 'src/utils/videoController';
import { INTERACTION } from 'src/constants/interactions';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';

const ResizeHandleBottomRight = () => (
  <Box
    sx={{
      zIndex: 1,
      position: 'absolute',
      top: '2px',
      left: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'se-resize !important'
    }}
  />
);

const ResizeHandleBottomLeft = () => (
  <Box
    sx={{
      zIndex: 1,
      position: 'absolute',
      top: '2px',
      right: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'sw-resize !important'
    }}
  />
);

const ResizeHandleBottom = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '-2px',
      left: '48%',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 's-resize !important'
    }}
  />
);

const ResizeHandleTop = () => (
  <Box
    sx={{
      position: 'absolute',
      bottom: '-2px',
      left: '48%',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'n-resize !important'
    }}
  />
);
const ResizeHandleLeft = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '48%',
      right: '-2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'w-resize !important'
    }}
  />
);

const ResizeHandleRight = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '48%',
      right: '4px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'e-resize !important'
    }}
  />
);

const ResizeHandleTopLeft = () => (
  <Box
    className="resize-box"
    sx={{
      position: 'absolute',
      bottom: '2px',
      right: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'nw-resize !important'
    }}
  />
);

const ResizeHandleTopRight = () => (
  <Box
    sx={{
      position: 'absolute',
      bottom: '2px',
      left: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'ne-resize !important'
    }}
  />
);

export const ImageInteractionDisplay = ({ config, videoBounding, isEditting, isPublishMode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [dragging, setDragging] = useState(false);

  const props = useMemo(
    () => ({
      borderRadius: `${config.style.borderRadius}%`,
      opacity: config.style.opacity / 100,
      border: `${config.style.borderWidth}px ${config.style.borderStyle} rgba(${config.style.borderColor.r}, ${config.style.borderColor.g}, ${config.style.borderColor.b}, ${config.style.borderColor.a})`
    }),
    [config.style]
  );

  const handleChangeConfig = useCallback((newConfig) => {
    dispatch(EdittingInteractionActions.setConfig(newConfig));
  }, []);

  useEffect(() => {
    if (config.style.fullScreen) {
      handleChangeConfig({
        x: 0,
        y: 0,
        width: 1,
        height: 1
      });
    }
  }, [config.style.fullScreen, videoBounding]);

  const videoControl = videoController.getInstance();
  const { videoData } = useVideoPlayer(videoPlayerSelectors.getVideoData);
  const videoDuration = useSelector(videoPlayerSelectors.getDuration);
  const [jumpToSpecificScheduler, setJumpToSpecificScheduler] = useState(false);
  const onClick = useCallback(() => {
    if (isPublishMode) {
      ReactGA.event({
        category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
        action:
          (config.link && t(`googleAnalyticsActions.actions.link`)) ||
          (config.jump.type && t(`interaction.mapKeyAction.${config.jump.type}`)) ||
          t(`googleAnalyticsActions.actions.noAction`),
        label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.title}`,
        value: videoData.id
      });
    }
    if (config.jump !== null) {
      switch (config.jump.type) {
        case INTERACTION.ACTION.JUMP_TO_START:
        case INTERACTION.ACTION.JUMP_TO_END:
        case INTERACTION.ACTION.JUMP_TO_SPECIFIC: {
          videoControl.seek(config.jump.jump_to);
          break;
        }
        case INTERACTION.ACTION.JUMP_TO_NODE: {
          if (isPublishMode) {
            videoControl.seek(parseInt(config.jump.time_jump_to_node || 0, 10) + (config.jump.product_jump_to || 0));
          }
          break;
        }
        case INTERACTION.ACTION.JUMP_TO_SPECIFIC_SCHEDULER: {
          setJumpToSpecificScheduler(true);
          break;
        }
        case INTERACTION.ACTION.JUMP_TO_INTERACTION: {
          if (!isEditting) {
            videoControl.seek(config.jump.jump_to);
          }
          break;
        }
        default:
      }
    } else if (config.link !== null) {
      window.open(config.link, '_blank');
      videoControl.pause();
    }
  }, [config.jump, config.link, isPublishMode, isEditting]);

  const showGrid = useSelector(videoPlayerSelectors.getShowVideoGrid);
  const gridCellWidth = useSelector(videoPlayerSelectors.getWidthCellGrid);
  useEffect(() => {
    if (showGrid) {
      const newX = (Math.trunc((config.x * videoBounding.width) / gridCellWidth) * gridCellWidth) / videoBounding.width;
      const newY =
        (Math.trunc((config.y * videoBounding.height) / gridCellWidth) * gridCellWidth) / videoBounding.height;
      const newWidth =
        (Math.trunc((config.width * videoBounding.width) / gridCellWidth) * gridCellWidth) / videoBounding.width;
      const newHeight =
        (Math.trunc((config.height * videoBounding.height) / gridCellWidth) * gridCellWidth) / videoBounding.height;
      dispatch(EdittingInteractionActions.setConfig({ x: newX, y: newY, width: newWidth, height: newHeight }));
    }
  }, [showGrid]);
  const [triggerDisplay, setTriggerDisplay] = useState(false);
  const [classNameFade, setClassNameFade] = useState('');
  const currentTime = useSelector(videoPlayerSelectors.getCurrentTime);
  useEffect(() => {
    if (jumpToSpecificScheduler) {
      if (Math.abs(currentTime - config.jump.jump_time) < 1) {
        videoControl.seek(config.jump.jump_to);
        setJumpToSpecificScheduler(false);
      }
    }
    if (currentTime >= config.start_time && currentTime < config.end_time) {
      if (!triggerDisplay) {
        if (config.style.fadeIn) {
          setClassNameFade('image-interaction-fadeIn');
        } else {
          setClassNameFade('');
        }
      }
      setTriggerDisplay(true);
    } else {
      if (triggerDisplay) {
        if (config.style.fadeOut) {
          setClassNameFade('image-interaction-fadeOut');
        } else {
          setClassNameFade('');
        }
      }
      setTriggerDisplay(false);
    }
  }, [currentTime]);

  useEffect(() => {
    if (config.image && !config.ratio) {
      handleChangeConfig({ ratio: (config.width * videoBounding.width) / (config.height * videoBounding.height) });
    }
  }, []);
  return (
    // <Box
    //   // className={`${classNameFade} waocon-interaction-bounding-box`}
    //   className={clsx({
    //     [classNameFade]: true,
    //     'waocon-interaction-bounding-box': isEditting,
    //     'waocon-interaction-display-box': !isEditting
    //   })}
    //   sx={{
    //     visibility: triggerDisplay ? 'visible' : 'hidden',
    //     opacity: triggerDisplay ? 1 : 0
    //   }}
    // >
    <Rnd
      className={`rnd ${classNameFade}`}
      enableResizing={!config.style.fullScreen && isEditting}
      disableDragging={!isEditting}
      {...(showGrid ? { dragGrid: [gridCellWidth, gridCellWidth] } : {})}
      {...(config.image ? { lockAspectRatio: config.ratio } : {})}
      style={{
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        border: isEditting ? '2px dashed white' : 'none',
        cursor: 'auto !important',

        // test
        visibility: triggerDisplay ? 'visible' : 'hidden',
        opacity: triggerDisplay ? 1 : 0
      }}
      bounds="parent"
      size={{
        width: config.width * videoBounding.width,
        height: config.height * videoBounding.height
      }}
      position={{
        x: config.x * videoBounding.width,
        y: config.y * videoBounding.height
      }}
      minWidth="10%"
      minHeight="10%"
      onDragStart={() => setDragging(true)}
      onDragStop={(e, d) => {
        setDragging(false);
        handleChangeConfig({ x: d.lastX / videoBounding.width, y: d.lastY / videoBounding.height });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        handleChangeConfig({
          width: ref.offsetWidth / videoBounding.width,
          height: ref.offsetHeight / videoBounding.height,
          x: position.x / videoBounding.width,
          y: position.y / videoBounding.height
        });
      }}
      resizeHandleComponent={{
        bottomRight: <ResizeHandleBottomRight />,
        bottomLeft: <ResizeHandleBottomLeft />,
        bottom: <ResizeHandleBottom />,
        top: <ResizeHandleTop />,
        left: <ResizeHandleLeft />,
        right: <ResizeHandleRight />,
        topLeft: <ResizeHandleTopLeft />,
        topRight: <ResizeHandleTopRight />
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          cursor: dragging ? 'move !important' : 'auto !important',
          ...(!isEditting ? { cursor: 'pointer !important' } : {})
        }}
        {...(!isEditting ? { onClick } : {})}
      >
        {config.image ? (
          <img
            style={{
              height: '100%',
              width: '100%',
              ...props
            }}
            draggable={false}
            src={config.image ? `${process.env.REACT_APP_PUBLIC_URL}${config.image.url}` : ''}
            alt=""
          />
        ) : (
          <Box
            sx={{
              bgcolor: 'white',
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...props
            }}
          >
            <Typography
              sx={{ cursor: dragging ? 'move' : 'default', ...(!isEditting ? { cursor: 'pointer !important' } : {}) }}
            >
              {t('interaction.image.yourImageHere')}
            </Typography>
          </Box>
        )}
        <Box
          className="image-interaction-jump-to-schedule-checked"
          sx={{ visibility: !isEditting && jumpToSpecificScheduler ? 'visible' : 'hidden' }}
        >
          <CheckIcon className="image-interaction-jump-to-schedule-checked-icon" />
        </Box>
      </Box>
    </Rnd>
    // </Box>
  );
};

ImageInteractionDisplay.propTypes = {
  videoBounding: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isPublishMode: PropTypes.bool
};

ImageInteractionDisplay.defaultProps = {
  isPublishMode: false
};
