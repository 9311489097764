import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';

export const ViewVideoTypes = createTypes(`
  VIEW_VIDEO_FETCH_REQUEST
  VIEW_VIDEO_FETCH_SUCCESS
  VIEW_VIDEO_FETCH_FAILURE
  VIEW_VIDEO_FETCH_PREVIEW_REQUEST
  VIEW_VIDEO_FETCH_PREVIEW_SUCCESS
  VIEW_VIDEO_FETCH_PREVIEW_FAILURE
`);

export const fetchViewSuccess = (node) => ({
  type: ViewVideoTypes.VIEW_VIDEO_FETCH_SUCCESS,
  node
});

export const fetchViewFailure = (error) => ({
  type: ViewVideoTypes.VIEW_VIDEO_FETCH_FAILURE,
  error
});

export const fetchViewRequest = ({ id, password = null }) => async (dispatch) => {
  try {
    dispatch({ type: ViewVideoTypes.VIEW_VIDEO_FETCH_REQUEST });
    const rs = await axiosClient.post(`/v/${id}`, { password });
    dispatch(fetchViewSuccess(rs));
    return rs;
  } catch (e) {
    if (e.validate) {
      return e;
    }
    dispatch(fetchViewFailure(e));
    return null;
  }
};

export const fetchPreviewSuccess = (node) => ({
  type: ViewVideoTypes.VIEW_VIDEO_FETCH_PREVIEW_SUCCESS,
  node
});

export const fetchPreviewFailure = (error) => ({
  type: ViewVideoTypes.VIEW_VIDEO_FETCH_PREVIEW_FAILURE,
  error
});

export const fetchPreviewRequest = ({ id }) => async (dispatch) => {
  try {
    dispatch({ type: ViewVideoTypes.VIEW_VIDEO_FETCH_PREVIEW_REQUEST });
    const rs = await axiosClient.get(`/admin/project/preview/${id}`);
    dispatch(fetchPreviewSuccess(rs));
    return rs;
  } catch (e) {
    if (e.validate) {
      return e;
    }
    dispatch(fetchPreviewFailure(e));
    return null;
  }
};
