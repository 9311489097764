/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
// /* eslint-disable */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Checkbox, FormControlLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import InputField from 'src/components/FormControls/InputField';
import RoundedSlider from 'src/components/interaction/RoundedSlider';
import 'src/containers/interactionsForm/text/TextInteraction.css';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as VideoPlayerSelector from 'src/selectors/videoPlayer';
import * as yup from 'yup';
import ColorField from 'src/components/FormControls/ColorField';

const props = {
  // InputProps: {
  //   sx: { height: '30px' }
  // },
  layout: {
    grid: {
      my: 0
    },
    label: {
      xs: 0
    },
    input: {
      xs: 12
    }
  }
};

export const textStrokes = [
  { label: 'No text stroke', value: 0, cssValue: 'none' },
  { label: 'Black text stroke', value: 1, cssValue: '1px black' },
  { label: 'White text stroke', value: 2, cssValue: '1px white' }
];

export const borderStyles = [
  { label: 'Solid', value: 0, cssValue: 'solid' },
  { label: 'Dotted', value: 1, cssValue: 'dotted' },
  { label: 'Dashed', value: 2, cssValue: 'dashed' }
];

const TextInteractionStyleForm = () => {
  const { t } = useTranslation();

  const borderRadiusTimechangeRef = useRef(null);

  const dispatch = useDispatch();
  const videoBounding = useSelector(VideoPlayerSelector.getVideoBounding);

  const schema = yup.object().shape({});
  const textStyleConfig = useSelector(EdittingInteractionSelectors.textInteractionGetTextStyleConfig);
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      ...textStyleConfig,
      // paddingTop: Math.ceil(textStyleConfig.paddingTop * videoBounding.height)
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = handleSubmit((formValues) => {
    dispatch(
      EdittingInteractionActions.textInteractionSetTextStyleConfig({
        ...formValues,
        // paddingTop: formValues.paddingTop / videoBounding.height
      })
    );
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="textStroke"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 600 }} width={160}>
                {t('interaction.text.label.textStroke')}
              </Typography>
              <Select
                sx={{ width: 'calc(100% - 160px)' }}
                size="small"
                value={value}
                fullWidth
                onChange={(evt) => {
                  onChange(evt.target.value);
                  onSubmit();
                }}
              >
                {textStrokes.map((textStroke) => (
                  <MenuItem key={textStroke.value} value={textStroke.value}>
                    {textStroke.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        />

        <Controller
          name="borderRadius"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 600 }} width={160}>
                {t('interaction.text.label.roundedCorners')}
              </Typography>
              <RoundedSlider
                sx={{ width: 'calc(100% - 160px)' }}
                valueLabelDisplay="on"
                max={360}
                onChange={(_, newValue) => {
                  onChange(newValue);

                  if (borderRadiusTimechangeRef.current) {
                    clearTimeout(borderRadiusTimechangeRef.current);
                  }
                  borderRadiusTimechangeRef.current = setTimeout(() => {
                    onSubmit();
                  }, 150);
                }}
                value={value}
              />
            </Box>
          )}
        />

        <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600 }} width={160}>
            {t('interaction.text.label.border')}
          </Typography>
          <Box sx={{ display: 'flex', width: 'calc(100% - 160px)' }}>
            <Box sx={{ width: '200px' }}>
              <InputField
                name="borderWidth"
                control={control}
                formState={formState}
                BlurOnEnter
                OnBlur={onSubmit}
                OnMouseUp={onSubmit}
                type="number"
                inputProps={{ min: 0, max: 50 }}
                {...props}
              />
            </Box>

            <Controller
              name="borderStyle"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ width: 200 }} mx={1}>
                  <Select
                    size="small"
                    value={value}
                    fullWidth
                    onChange={(evt) => {
                      onChange(evt.target.value);
                      onSubmit();
                    }}
                  >
                    {borderStyles.map((borderStyle) => (
                      <MenuItem key={borderStyle.value} value={borderStyle.value}>
                        {borderStyle.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            />
            <ColorField
              name="borderColor"
              control={control}
              formState={formState}
              onChange={onSubmit}
              layout={{ grid: { my: 0, mt: 0.5 } }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: 'calc(100% - 160px)',
            mb: 2,
            height: 40,
            maxHeight: 40,
            alignItems: 'center'
          }}
        >
          <Typography sx={{ fontWeight: 600 }} width={160}>
            {t('interaction.text.label.topPadding')}
          </Typography>
          <Box sx={{ width: 120 }}>
            <InputField
              name="paddingTop"
              control={control}
              formState={formState}
              BlurOnEnter
              OnBlur={onSubmit}
              OnMouseUp={onSubmit}
              type="number"
              inputProps={{ min: 0, max: 50 }}
              {...props}
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
          <Controller
            name="fadeIn"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box sx={{ width: 160 }}>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      onChange={(e) => {
                        onChange(e.target.checked);
                        onSubmit();
                      }}
                      checked={value}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: 600 }} width={160}>
                      {t('interaction.text.label.fadeIn')}
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Box>
            )}
          />

          <Controller
            name="fadeOut"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box sx={{ width: 160 }}>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      onChange={(e) => {
                        onChange(e.target.checked);
                        onSubmit();
                      }}
                      checked={value}
                    />
                  }
                  label={
                    <Typography sx={{ fontWeight: 600 }} width={160}>
                      {t('interaction.text.label.fadeOut')}
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Box>
            )}
          />
        </Box>
      </form>
    </>
  );
};

export default TextInteractionStyleForm;
