import timelineFormat, { hmsmsToSeconds } from 'src/utils/timelineFormat';
import { INTERACTION } from 'src/constants/interactions';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { getDuration } from 'src/selectors/videoPlayer';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as ProjectNodeSelectors from 'src/selectors/projectNode';
import { useForm } from 'react-hook-form';

export const useMenuItemForm = (item) => {
  const dispatch = useDispatch();
  const videoDuration = useSelector(getDuration);

  const textConfig = useSelector(EdittingInteractionSelectors.textInteractionGetTextConfig);
  const nodeList = useSelector(ProjectNodeSelectors.getListJumpNode);

  const convertReduxToForm = useCallback((redux) => {
    const converted = { ...redux };
    if (redux.jump) {
      converted.jump = {
        ...redux.jump,
        jump_to: redux.jump?.jump_to ? timelineFormat(redux.jump.jump_to, 'hh:mm:ss.ms') : '00:00:00.00',
        jump_time: redux.jump?.jump_time ? timelineFormat(redux.jump.jump_time, 'hh:mm:ss.ms') : '00:00:00.00'
      };
      if (redux.type === INTERACTION.TYPE.JUMP_TO) {
        converted.jump.time_jump_to_node = redux.jump?.time_jump_to_node
          ? timelineFormat(redux.jump?.time_jump_to_node, 'hh:mm:ss.ms')
          : '00:00:00.00';
      }
    }

    return converted;
  }, []);

  const { control, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: convertReduxToForm(textConfig, nodeList || [])
  });

  const convertFormToRedux = useCallback((formValues, duration) => {
    const converted = { ...formValues };

    const second = hmsmsToSeconds(converted.jump.jump_to);
    if (second > duration) {
      converted.link = null;
      converted.jump = {
        ...formValues.jump,
        jump_to: duration,
        jump_time: duration
      };
      setValue('jump.jump_to', timelineFormat(duration, 'hh:mm:ss.ms'));
    } else if (second >= 0) {
      switch (formValues.jump.type) {
        case INTERACTION.ACTION.LINK: {
          converted.jump = null;
          converted.link = formValues.link || '';
          break;
        }
        case '0': {
          converted.jump = null;
          converted.link = null;
          break;
        }
        case INTERACTION.ACTION.JUMP_TO_START: {
          converted.link = null;
          converted.jump = {
            ...formValues.jump,
            jump_to: 0,
            jump_time: hmsmsToSeconds(formValues.jump.jump_time)
          };
          break;
        }
        case INTERACTION.ACTION.JUMP_TO_END: {
          converted.link = null;
          converted.jump = {
            ...formValues.jump,
            jump_to: duration,
            jump_time: hmsmsToSeconds(formValues.jump.jump_time)
          };
          break;
        }
        case INTERACTION.ACTION.JUMP_TO_NODE: {
          converted.link = null;
          converted.jump = {
            ...formValues.jump,
            title: 'Jump',
            jump_to: hmsmsToSeconds(formValues.jump.jump_to) || 0,
            jump_time: hmsmsToSeconds(formValues.jump.jump_time) || 0,
            time_jump_to_node: hmsmsToSeconds(formValues.jump.time_jump_to_node) || 0
          };
          break;
        }
        default: {
          converted.link = null;
          converted.jump = {
            ...formValues.jump,
            jump_to: hmsmsToSeconds(formValues.jump.jump_to),
            jump_time: hmsmsToSeconds(formValues.jump.jump_time)
          };
        }
      }
    } else {
      setValue('jump.jump_to', timelineFormat(0, 'hh:mm:ss.ms'));
      converted.link = null;
      converted.jump = {
        ...formValues.jump,
        jump_to: 0,
        jump_time: 0
      };
    }

    return converted;
  });

  useEffect(() => {
    setValue('jump.jump_to', item.jump_to || '00:00:00.00');
  }, []);

  const onFieldsSubmit = useCallback(() => {
    const jumpValues = getValues('jump');
    const newJumpValues = { jump: { ...jumpValues, jumpId: item.itemID } };
    dispatch(EdittingInteractionActions.setSingleMenuItemJumpConfig(convertFormToRedux(newJumpValues, videoDuration)));
  });

  return {
    convertFormToRedux,
    convertReduxToForm,
    onFieldsSubmit,
    control
  };
};
