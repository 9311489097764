import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'src/actions/projectNode';
import { fields as _fields } from 'src/models/node';
import { FormSchema } from 'src/models/schema';
import * as NodeSelectors from 'src/selectors/projectNode';
import * as ProjectSelectors from 'src/selectors/projectDetail';
import { objectSpecialEqual } from 'src/utils/objectCompare';
import * as DraggingSelectors from 'src/selectors/dragging';
import * as DraggingActions from 'src/actions/dragging';

export const useVideoNodeSettings = (nodeID) => {
  const { t } = useTranslation();
  const fields = _fields(t);
  const dispatch = useDispatch();

  const node = useSelector((state) => NodeSelectors.getSelectedNode(state, nodeID));
  const listVideo = useSelector(ProjectSelectors.getListVideo);
  const videoRef = useRef(null);
  // fetch
  useEffect(() => {
    dispatch(Actions.fetchNodeRequest(nodeID));
  }, [nodeID]);

  // update
  const getAutoCompleteVideoValue = useCallback((videoID, _listVideo) => {
    const video = _listVideo.find((vid) => `${vid.id}` === `${videoID}`);
    return video
      ? {
          id: video.id,
          name: video.name
        }
      : { id: null, name: 'None' };
  }, []);
  const videoOptions = useMemo(() => [{ id: null, name: 'None' }, ...listVideo], [listVideo]);
  const { schema } = useMemo(
    () =>
      new FormSchema(fields.id, [
        fields.name,
        fields.durationIn,
        fields.durationOut,
        fields.description,
        fields.title,
        fields.video
      ]),
    []
  );
  const getDefaultValues = useCallback(
    (_node, _listVideo) => ({
      [fields.name.name]: _node?.name || '',
      [fields.title.name]: _node?.title || '',
      [fields.video.name]: getAutoCompleteVideoValue(_node.video_id, _listVideo),
      [fields.description.name]: _node?.description || '',
      [fields.durationIn.name]: _node?.duration_in ?? 0,
      [fields.durationOut.name]: _node?.duration_out ?? 0
    }),
    []
  );
  const { formState, control, reset, handleSubmit, getValues } = useForm({
    defaultValues: getDefaultValues(node, listVideo),
    resolver: schema,
    reValidateMode: 'onBlur'
  });

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );
  const resetDefault = useCallback((_node, _listVideo) => {
    reset(getDefaultValues(_node, _listVideo), {
      keepErrors: false,
      keepDirty: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false
    });
  }, []);

  useEffect(() => {
    resetDefault(node, listVideo);
  }, [node, listVideo]);

  const onUpdate = useCallback(
    async (formData) => {
      // Them dong nay vi yup validate number bi sida, van chap nhan vd nhu 0.124a
      // under the hood la do no parse 0.124a thanh 0.124, vi vay can reset lai de hien thi dung gia tri
      resetDefault(formData, listVideo);
      if (!objectSpecialEqual(formData, node)) {
        await dispatch(Actions.updateRequest({ id: node.id, ...formData }));
      }
    },
    [node, formState, listVideo]
  );
  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      resetDefault(node, listVideo);
    }
  }, [formState, node, listVideo]);

  // Drag and drop
  const DnDMode = useSelector(DraggingSelectors.getDnDMode);
  const DnDModeProps = useMemo(
    () =>
      DnDMode
        ? {
            onMouseEnter: () => dispatch(DraggingActions.setDropItem({ item: node, dropType: 'video' })),
            onMouseLeave: () => dispatch(DraggingActions.setDropItem({ item: null, dropType: null }))
          }
        : {},
    [DnDMode]
  );

  // video
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [node]);

  // go to edit
  const onClickEditNode = useCallback(() => {
    dispatch(Actions.setScreen('videoNodeEdit'));
  }, []);

  return {
    t,
    node,
    control,
    formState,
    formItems,
    onUpdate,
    handleSubmit,
    resetDefault,
    videoOptions,
    getValues,
    DnDModeProps,
    videoRef,
    onClickEditNode
  };
};
