import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Login from 'src/pages/auth/Login';
import NotFound from 'src/pages/NotFound';
import ProjectLayout from './components/ProjectLayout';
import VideoLayout from './components/VideoLayout';
import PreviewVideoLayout from './components/PreviewVideoLayout';
import Accounts from './pages/accounts';
import AccountCreate from './pages/accounts/Create';
import AccountList from './pages/accounts/List';
import AcccountUpdate from './pages/accounts/Update';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Projects from './pages/Projects';
import ProjectProcessManagement from './pages/ProjectProcessManagement';
import AcccountUpdateUser from './pages/auth/AcccountUpdateUser';

const routes = [
  {
    path: '/app',
    element: <DashboardLayout />,
    children: [
      { path: 'projects', element: <Projects /> },
      { path: 'project-process-management', element: <ProjectProcessManagement /> },
      { path: ':id/updateAccount', element: <AcccountUpdateUser /> },
      {
        path: 'accounts',
        element: <Accounts />,
        children: [
          { path: '', element: <AccountList /> },
          { path: 'list', element: <AccountList /> },
          { path: 'create', element: <AccountCreate /> },
          { path: ':id/update', element: <AcccountUpdate /> },
          { path: '*', element: <Navigate to="/404" replace /> }
        ]
      },
      { path: 'settings', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" replace /> }
    ]
  },
  {
    path: 'projects/:id',
    element: <ProjectLayout />
  },
  {
    path: '',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '404', element: <NotFound /> },
      { path: '', element: <Navigate to="/app/projects" /> },
      { path: '*', element: <Navigate to="/404" replace /> }
    ]
  },
  {
    path: 'v/:id',
    element: <VideoLayout />
  },
  {
    path: 'preview/:id',
    element: <PreviewVideoLayout />
  }
];

export default routes;
