import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';
import qs from 'qs';

export const ProjectListTypes = createTypes(`
  PROJECT_LIST_REQUEST
  PROJECT_LIST_SUCCESS
  PROJECT_LIST_FAILURE
  PROJECT_SET_PAGE
  PROJECT_SET_FILTER
  PROJECT_LIST_RESET_REQUEST
  
  PROJECT_CREATE_REQUEST
  PROJECT_CREATE_SUCCESS
  PROJECT_CREATE_FAILURE

  PROJECT_EDIT_TITLE_REQUEST
  PROJECT_EDIT_TITLE_SUCCESS
  PROJECT_EDIT_TITLE_FAILURE

  PROJECT_DELETE_REQUEST
  PROJECT_DELETE_SUCCESS
  PROJECT_DELETE_FAILURE

  PROJECT_DUPLICATE_REQUEST
  PROJECT_DUPLICATE_SUCCESS
  PROJECT_DUPLICATE_FAILURE
`);

export const resetRequest = () => ({ type: ProjectListTypes.PROJECT_LIST_RESET_REQUEST });

export const listSuccess = (rs) => ({
  type: ProjectListTypes.PROJECT_LIST_SUCCESS,
  list: rs.data,
  totalPage: rs.last_page
});

export const listFailure = (error) => ({
  type: ProjectListTypes.PROJECT_LIST_FAILURE,
  error
});

export const listRequest = (_filter, page) => async (dispatch) => {
  const filter = { ..._filter, page };
  try {
    dispatch({ type: ProjectListTypes.PROJECT_LIST_REQUEST });
    const rs = await axiosClient.get(`/admin/project/search?${qs.stringify(filter)}`);
    dispatch(listSuccess(rs.data));
  } catch (e) {
    dispatch(listFailure(e));
  }
};

export const setPage = (page) => ({ type: ProjectListTypes.PROJECT_SET_PAGE, page });

export const setFilter = (filter) => ({ type: ProjectListTypes.PROJECT_SET_FILTER, filter });

export const createSuccess = () => ({
  type: ProjectListTypes.PROJECT_CREATE_SUCCESS
});

export const createFailure = (error) => ({
  type: ProjectListTypes.PROJECT_CREATE_FAILURE,
  error
});

export const createRequest = (formData) => async (dispatch) => {
  dispatch({ type: ProjectListTypes.PROJECT_CREATE_REQUEST });
  return axiosClient.post('/admin/project', formData);
};

export const listRefetchRequest = () => async (dispatch) => {
  dispatch({ type: ProjectListTypes.PROJECT_LIST_RESET_REQUEST });
  try {
    dispatch({ type: ProjectListTypes.PROJECT_LIST_REQUEST });
    const rs = await axiosClient.get(`/admin/project`);
    dispatch(listSuccess(rs.data));
  } catch (e) {
    dispatch(listFailure(e));
  }
};

export const editTitleSuccess = () => ({
  type: ProjectListTypes.PROJECT_EDIT_TITLE_SUCCESS
});

export const editTitleFailure = (error) => ({
  type: ProjectListTypes.PROJECT_EDIT_TITLE_FAILURE,
  error
});

export const editTitleRequest = (formData, id) => async (dispatch) => {
  dispatch({ type: ProjectListTypes.PROJECT_EDIT_TITLE_REQUEST });
  return axiosClient.put(`/admin/project/${id}`, formData);
};

export const deleteSuccess = () => ({
  type: ProjectListTypes.PROJECT_DELETE_SUCCESS
});

export const deleteFailure = (error) => ({
  type: ProjectListTypes.PROJECT_DELETE_FAILURE,
  error
});

export const deleteRequest = (id) => async (dispatch) => {
  dispatch({ type: ProjectListTypes.PROJECT_DELETE_REQUEST });
  return axiosClient.delete(`/admin/project/${id}`);
};

export const duplicateSuccess = () => ({
  type: ProjectListTypes.PROJECT_DUPLICATE_SUCCESS
});

export const duplicateFailure = (error) => ({
  type: ProjectListTypes.PROJECT_DUPLICATE_FAILURE,
  error
});

export const duplicateRequest = (id) => async (dispatch) => {
  dispatch({ type: ProjectListTypes.PROJECT_DUPLICATE_REQUEST });
  return axiosClient.post(`/admin/project/copy`, { id });
};
