import { Avatar, Box, Divider, Drawer, List, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PersonIcon from '@material-ui/icons/Person';
import VideoCameraBackIcon from '@material-ui/icons/VideoCameraBack';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import UserRoles from 'src/constants/UserRoles';
import getInitials from 'src/utils/getInitials';
import { useAuth } from '../../hooks/useAuth';
import Logo from '../Logo';
import NavSection, { reduceChildRoutes } from '../NavSection';
import Scrollbar from '../Scrollbar';

const adminSections = (t) => [
  {
    title: t('sidebar.management'),
    items: [
      {
        title: t('sidebar.account.title'),
        path: '/app/accounts',
        icon: <PersonIcon fontSize="small" />,
        children: [
          {
            title: t('sidebar.account.list'),
            path: '/app/accounts'
          },
          {
            title: t('sidebar.account.create'),
            path: '/app/accounts/create'
          }
        ]
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { t } = useTranslation();
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { userState } = useAuth();
  const userSections = () => [
    {
      items: [
        {
          title: t('sidebar.account.edit'),
          path: `/app/${userState.id}/updateAccount`
        }
      ]
    }
  ];
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const sections = useMemo(() => (userState?.type === UserRoles.Admin ? adminSections(t) : userSections()), [
    userState,
    t
  ]);

  const items = useMemo(
    () => [
      {
        title: <Typography sx={{ fontSize: '13px', fontWeight: 700 }}>{t('sidebar.allYourProject')}</Typography>,
        path: '/app/projects',
        icon: <VideoCameraBackIcon fontSize="small" />
      },
      {
        title: (
          <Typography sx={{ fontSize: '13px', fontWeight: 700 }}>{t('sidebar.projectProcessManagement')}</Typography>
        ),
        path: '/app/project-process-management',
        icon: <VideoCameraBackIcon fontSize="small" />
      }
    ],
    [t]
  );

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2, pt: 3 }}>
          <Box
            sx={{
              alignItems: 'center',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              pb: 2
            }}
          >
            <RouterLink to="/dashboard/account" style={{ textDecoration: 'none' }}>
              <Avatar sx={{ bgcolor: '#5D5FEF', height: 30, width: 30 }}>{getInitials(userState?.name)}</Avatar>
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="h6">
                {userState?.name}
              </Typography>
            </Box>
          </Box>
          <Divider />
        </Box>
        {sections.map((section) => (
          <Box key={section.title} sx={{ p: 2 }}>
            <NavSection
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          </Box>
        ))}
        <Box sx={{ p: 2 }}>
          <List sx={{ fontSize: '12px' }} disablePadding>
            {[...items].map((item) => (
              <div key={item.path}>
                {reduceChildRoutes({
                  item,
                  pathname: location.pathname,
                  depth: 0
                })}
              </div>
            ))}
          </List>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            // backgroundImage: 'url(/static/images/bg.jpeg)',
            backgroundAttachment: 'local',
            backgroundRepeat: 'round',
            // boxShadow:
            //   '0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
            borderRight: 'none'
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
