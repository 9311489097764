import { Box, Container } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const LayoutContainer = ({ children, maxWidth, title, header }) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Box
      sx={{
        minHeight: '100%',
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: 'background.paper'
      }}
    >
      <Container maxWidth={maxWidth}>
        {header}
        <Box
          sx={{
            mt: 2
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  </>
);

LayoutContainer.propTypes = {
  title: PropTypes.string,
  header: PropTypes.any,
  children: PropTypes.any,
  maxWidth: PropTypes.string
};

LayoutContainer.defaultProps = {
  maxWidth: 'xl'
};

export default LayoutContainer;
