/* eslint-disable */
import { FormControlLabel, RadioGroup, Typography, Radio, Box, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import _get from 'lodash/get';

const RadioFieldHelperText = ({
  name,
  control,
  formState,
  error,
  label,
  inputProps,
  helperText,
  options,
  labelStyle,
  ...props
}) => {
  const { errors = {} } = formState;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          {!!label && (
            <Typography
              style={{
                marginTop: 16,
                ...labelStyle
              }}
              color="textPrimary"
              variant="subtitle2"
            >
              {label}
            </Typography>
          )}
          <RadioGroup {...props} {...field}>
            {options &&
              options.map((item) => (
                <Box sx={{ mb: 3 }}>
                  <FormControlLabel
                    control={<Radio color="primary" onChange={props.onChange} />}
                    key={item.value}
                    label={
                      <Box sx={{ ml: 2 }}>
                        <Typography color="textPrimary" variant="subtitle2">
                          {item.label}
                        </Typography>
                      </Box>
                    }
                    value={item.value}
                  />
                  <Typography sx={{ ml: 6 }} color="textPrimary" variant="subtitle1">
                    {item.helperText}
                  </Typography>
                </Box>
              ))}
          </RadioGroup>
          <FormHelperText>{helperText || _get(errors, `${name}.message`)}</FormHelperText>
        </>
      )}
    />
  );
};

RadioFieldHelperText.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,

  error: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,

  inputProps: PropTypes.object,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string,
  labelStyle: PropTypes.object
};

RadioFieldHelperText.defaultProps = {
  error: false,
  required: false,
  autoFocus: false,
  disabled: false,

  inputProps: null,
  defaultValue: '',
  type: '',
  label: '',
  margin: 'normal',
  variant: 'outlined',
  helperText: '',
  autoComplete: ''
};

export default RadioFieldHelperText;
