/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextInteractionInteractionForm from './TextInteractionInteractionForm';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import TextInteractionStyleForm from './TextInteractionStyleForm';
import { useDispatch } from 'react-redux';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2, pr: 4 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const FullWidthTabs = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const a11yProps = (index) => ({
    id: `tab-${index}`
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  useEffect(() => () => dispatch(EdittingInteractionActions.resetRequest()), []);
  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <AppBar
        className="menu-text-interaction"
        sx={{ backgroundColor: '#f4f5f7', boxShadow: 'none' }}
        position="static"
      >
        <Tabs value={value} onChange={handleChange} indicatorColor="secondary" textColor="inherit" variant="fullWidth">
          <Tab sx={{ color: '#ED257A' }} label="Interaction" {...a11yProps(0)} />
          <Tab sx={{ color: '#ED257A' }} label="Style" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ overflow: 'unset', position: 'relative', zIndex: 1 }}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <TextInteractionInteractionForm />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TextInteractionStyleForm />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};

const TextInteractionForm = () => {
  return <FullWidthTabs />;
};

export default TextInteractionForm;
