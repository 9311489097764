/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import { AXIS_MOVEMENT } from 'src/configs/node';
import { NODE_TYPES } from 'src/constants/node';

export const flattenData = (nodes) => {
  const info = {
    elements: [],
    numNodes: 0,
    numPaths: nodes[0]?.node_child[0]?.node_child?.length || 0
  };
  if (nodes.length > 0) {
    nodes.forEach((node) => {
      info.numNodes += 1;
      info.elements.push(createNode(null, node));
      nodeTravel(node, info);
    });
  }
  // minus 2 screens
  return { ...info, numNodes: info.numNodes - 2 };
};

export const createNode = (parent, { node_child, ...rest }) => ({
  id: `${rest.id}`,
  type: rest.type || NODE_TYPES.VIDEO_NODE,
  data: {
    ...rest,
    id: `${rest.id}`,
    parent: parent ? parent.id : null,
    isBeginning: parent?.type === NODE_TYPES.START_NODE || false
  },
  position: { x: rest.x ?? rest.id * AXIS_MOVEMENT.X, y: rest.y ?? 0 },
  connectable: !(rest.type === NODE_TYPES.START_NODE && node_child.length > 0)
});

export const createEdge = (sNode, tNode) => ({
  id: `${sNode.id}-${tNode.id}`,
  source: `${sNode.id}`,
  target: `${tNode.id}`,
  type: 'custom'
});

const nodeTravel = (node, info) => {
  if (!node.node_child || !node.node_child.length) return;
  node.node_child.forEach((child) => {
    if (!info.elements.find((e) => parseInt(e.id, 10) === parseInt(child.id, 10))) {
      info.numNodes += 1;
      info.elements.push(createNode(node, child));
    }
    if (!info.elements.find((el) => el.id === `${node.id}-${child.id}`)) {
      info.elements.push(createEdge(node, child));
    }
    nodeTravel(child, info);
  });
};
