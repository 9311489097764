import { useSelector } from 'react-redux';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';

export const useVideoPlayer = () => {
  const videoPlayerState = useSelector(VideoPlayerSelectors.getVideoPlayerState);
  const showVideoGridState = useSelector(VideoPlayerSelectors.getShowVideoGrid);
  const widthCellGridState = useSelector(VideoPlayerSelectors.getWidthCellGrid);
  const videoCurrentTime = useSelector(VideoPlayerSelectors.getCurrentTime);
  const videoDuration = useSelector(VideoPlayerSelectors.getDuration);
  const videoHasStarted = useSelector(VideoPlayerSelectors.getHasStarted);
  const videoReload = useSelector(VideoPlayerSelectors.getReload);
  const zoom = useSelector(VideoPlayerSelectors.getZoom);
  const videoData = useSelector(VideoPlayerSelectors.getVideoData);
  return {
    videoPlayerState,
    videoCurrentTime,
    videoDuration,
    widthCellGridState,
    showVideoGridState,
    videoHasStarted,
    videoReload,
    zoom,
    videoData
  };
};
