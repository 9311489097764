export const AXIS_MOVEMENT = {
  X: 250,
  Y: 80
};

export const MAX_NODES = 30;

export const ENDNODE_MAXSIZE = {
  HEIGHT: 150,
  WIDTH: 150
};
