/* eslint-disable no-unused-vars */
import { Box, makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { INTERACTION } from 'src/constants/interactions';
import timelineFormat, { hmsToSeconds } from 'src/utils/timelineFormat';
import videoController from 'src/utils/videoController';

const useStyles = makeStyles((theme) => ({
  center: {
    paddingLeft: '12px',
    paddingTop: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}));

export const InteractionTimelineSingleInputJump = ({ sliderValue, handleValueChangeSubmit }) => {
  const classes = useStyles();
  const [edittingTimeStr, setEdittingTimeStr] = useState(0);
  const [editable, setEditable] = useState(false);

  const handleCurrentTimeLblChange = useCallback(
    (evt) => {
      if (editable) {
        setEdittingTimeStr(evt.target.value);
      }
    },
    [editable]
  );

  const handleCurrentTimeLblFocus = useCallback(() => {
    setEditable(true);
    setEdittingTimeStr(timelineFormat(sliderValue));
  }, [videoController, sliderValue]);

  const handleSubmitValue = useCallback(() => {
    setEditable(false);
    handleValueChangeSubmit(hmsToSeconds(edittingTimeStr));
  }, [edittingTimeStr, videoController]);
  return (
    <Box className={classes.center}>
      <TextField
        value={editable ? edittingTimeStr : timelineFormat(sliderValue)}
        onChange={handleCurrentTimeLblChange}
        onFocus={handleCurrentTimeLblFocus}
        onBlur={handleSubmitValue}
        size="small"
        onKeyDown={(evt) => {
          if (evt.key === 'Enter') {
            evt.target.blur();
          }
        }}
        inputProps={{ style: { textAlign: 'center', width: 100 } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{ sx: { height: 30 } }}
      />
    </Box>
  );
};

InteractionTimelineSingleInputJump.propTypes = {
  sliderValue: PropTypes.number.isRequired,
  handleValueChangeSubmit: PropTypes.func.isRequired
};
