/* eslint-disable no-unused-vars */
import { Avatar, Box, Button, FormLabel, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import ContentCopyIcon from '@material-ui/icons/ContentCopy';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import InputField from 'src/components/FormControls/InputField';
import SelectField from 'src/components/FormControls/SelectField';
import { useProjectSettings } from 'src/hooks/useProjectSettings';
import { fields as _fields } from 'src/models/project';
import HelpIcon from '@material-ui/icons/HelpOutline';

const InputFieldProps = {
  InputProps: {
    sx: { height: '30px' }
  }
};

export const ProjectSettings = ({ setSettingsHeight }) => {
  const {
    t,
    project,
    numNodes,
    numPaths,
    formState,
    formItems,
    onUpdate,
    handleSubmit,
    copyToClipboard
  } = useProjectSettings();
  const fields = _fields(t);
  const ref = useRef(null);
  useEffect(() => {
    setSettingsHeight(ref.current.clientHeight);
  }, []);

  return (
    <Box
      ref={ref}
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: '#F1F4F7',
        p: 2,
        pb: 1
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2}>
          <img height="135px" style={{ maxWidth: '100%' }} alt="video" src="/static/empty_video.png" />
        </Grid>
        <Grid item xs={12} sm={9} md={9}>
          <Typography variant="h5" sx={{ textTransform: 'uppercase', mb: 1 }}>
            {t('project.settings.title')}
          </Typography>
          <Grid container>
            <Grid item xs={6} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ height: '24px', width: '24px', fontSize: '14px' }}>{numNodes}</Avatar>
                  <Typography sx={{ ml: 2 }}>{t('project.settings.videoNodes')}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ height: '24px', width: '24px', fontSize: '14px' }}>2</Avatar>
                  <Typography sx={{ ml: 2 }}>{t('project.settings.screens')}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ height: '24px', width: '24px', fontSize: '14px' }}>{numPaths}</Avatar>
                  <Typography sx={{ ml: 2 }}>{t('project.settings.paths')}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ maxWidth: formState.errors[fields.name.name] ? '100%' : '100%', display: 'inline-block' }}>
                  <InputField
                    label={fields.name.label}
                    name={fields.name.name}
                    {...formItems}
                    OnBlur={handleSubmit(onUpdate)}
                    layout={{
                      label: {
                        xs: 12
                      },
                      input: {
                        xs: 12,
                        style: {
                          marginTop: '8px'
                        }
                      }
                    }}
                    {...InputFieldProps}
                  />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ maxWidth: '100%', display: 'inline-block' }}>
                  <SelectField
                    name={fields.aspect_ratio.name}
                    label={fields.aspect_ratio.label}
                    options={fields.aspect_ratio.options}
                    tooltipMsg={t('project.settings.aspect_ratio')}
                    {...formItems}
                    handleChange={handleSubmit(onUpdate)}
                    sx={{ height: '30px' }}
                    layout={{
                      label: {
                        xs: 12,
                        style: {
                          marginBottom: '8px',
                          marginTop: '8px'
                        }
                      },
                      input: {
                        xs: 12
                      }
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ maxWidth: '90%', display: 'inline-block' }}>
                  <InputField
                    label={fields.id.label}
                    name={fields.id.name}
                    disabled
                    {...formItems}
                    layout={{
                      label: {
                        xs: 12
                      },
                      input: {
                        xs: 12,
                        style: {
                          marginTop: '8px'
                        }
                      }
                    }}
                    {...InputFieldProps}
                  />
                </Box>
                <IconButton sx={{ mt: 2 }} onClick={() => copyToClipboard(project?.id_public)}>
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

ProjectSettings.propTypes = {
  setSettingsHeight: PropTypes.func.isRequired
};
