/* eslint-disable no-unused-vars */
// /* eslint-disable no-unused-vars */
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import GridLines from 'react-gridlines';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as VideoActions from 'src/actions/videoPlayer';
import { VIDEO_CONTROLBAR_HEIGHT } from 'src/configs/layout';
import { InteractionsDisplay } from 'src/containers/videoDisplay/InteractionsDisplay';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';
import videoController from 'src/utils/videoController';
import { ControlBar, Player } from 'video-react';
import 'video-react/dist/video-react.css';
import PlayToggle from 'video-react/lib/components/control-bar/PlayToggle';
import ProgressControl from 'video-react/lib/components/control-bar/ProgressControl';
import VolumeMenuButton from 'video-react/lib/components/control-bar/VolumeMenuButton';
import Shortcut from 'video-react/lib/components/Shortcut';
import CurrentTimeDisplay from 'video-react/lib/components/time-controls/CurrentTimeDisplay';
import DurationDisplay from 'video-react/lib/components/time-controls/DurationDisplay';
import TimeDivider from 'video-react/lib/components/time-controls/TimeDivider';
import './css/VideoDisplay.css';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { EditorVideoBigPlayButton } from './EditorVideoBigPlayButton';
import { NextPrevInteraction } from './NextPrevInteraction';
import { ToggleNextPrevButton } from './ToggleNextPrevButton';

const useStyles = makeStyles(() => ({
  controllBar: {
    bottom: `-${VIDEO_CONTROLBAR_HEIGHT}px !important`
  }
}));

const DOMRectToObj = (domRect, isVideoBounding = false) => ({
  x: domRect.x,
  y: domRect.y,
  width: domRect.width,
  height: domRect.height - (isVideoBounding ? VIDEO_CONTROLBAR_HEIGHT : 0),
  top: domRect.top,
  left: domRect.left
});

const getProgressControlBounding = () =>
  DOMRectToObj(
    document
      .getElementsByClassName('video-react-progress-holder video-react-slider-horizontal video-react-slider')[0]
      .getBoundingClientRect()
  );
const VideoDisplay = (props) => {
  const { videoMedia } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const videoRef = useRef(null);

  const { widthCellGridState, showVideoGridState } = useVideoPlayer();

  const handleStateChange = useCallback((state) => {
    dispatch(VideoActions.setVideoPlayer(state));
  }, []);

  useEffect(() => {
    videoController.getInstance(videoRef?.current);
  }, [videoMedia]);

  useEffect(() => {
    videoRef.current?.subscribeToStateChange?.(handleStateChange);
  }, [videoMedia]);

  const videoBoundingRef = useRef(null);

  const videoBounding = useSelector(VideoPlayerSelectors.getVideoBounding);
  const videoHasStarted = useSelector(VideoPlayerSelectors.getHasStarted);
  const [progressControlBounding, setProgressControlBounding] = useState();

  const zoom = useSelector(VideoPlayerSelectors.getZoom);

  const [nextPrevInteractionMode, setNextPrevInteractionMode] = useState(false);

  useEffect(() => () => dispatch(VideoActions.resetRequest()), []);

  useEffect(() => {
    dispatch(VideoActions.setVideoBounding(DOMRectToObj(videoBoundingRef.current.getBoundingClientRect(), true)));

    if (videoHasStarted) {
      setProgressControlBounding(getProgressControlBounding());
    }
  }, [videoBoundingRef, videoHasStarted]);

  useEffect(() => {
    dispatch(VideoActions.setWidthCellGrid(videoRef?.current.video.video.clientWidth / 32));

    const handleWindowResize = () => {
      const videoBounding_ = DOMRectToObj(videoBoundingRef.current.getBoundingClientRect(), true);
      dispatch(VideoActions.setWidthCellGrid(videoRef?.current.video.video.clientWidth / 32));
      dispatch(VideoActions.setVideoBounding(videoBounding_));
      dispatch(EdittingInteractionActions.setInitialVideoWidth(videoBounding_.width));
      setProgressControlBounding(getProgressControlBounding());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [videoRef]);

  return (
    <>
      <Typography variant="h6" display="inline">
        {t('projectNode.edit.nodeName')}
      </Typography>
      <Typography display="inline">{`: ${videoMedia.nodeName}`}</Typography>
      <br />
      <Typography variant="h6" display="inline">
        {t('projectNode.edit.videoName')}
      </Typography>
      <Typography display="inline">{`: ${videoMedia.nodeVideoName}`}</Typography>
      <Box
        sx={{ position: 'relative', overflow: 'hidden', paddingBottom: `${VIDEO_CONTROLBAR_HEIGHT}px` }}
        ref={videoBoundingRef}
      >
        <Box
          id="video-bounding-box"
          sx={{
            position: 'relative',
            width: '100%',
            transform: zoom?.zoom ? `scale(${1 / zoom?.width})` : 'scale(1)',
            transformOrigin: zoom?.zoom
              ? `${(zoom?.x + zoom?.width / 2) * 100}% ${(zoom?.y + zoom?.height / 2) * 100}%`
              : 'unset',
            transition: 'transform 600ms, width 600ms, height 600ms'
          }}
        >
          <Player
            className="video-player"
            playsInline
            // eslint-disable-next-line react/prop-types
            fluid={false}
            width={videoBounding?.width}
            height={(videoBounding?.width / 16) * 9}
            ref={videoRef}
            src={videoMedia.src}
            aspectRatio="16:9"
          >
            <GridLines
              className={showVideoGridState ? 'video-player-grid' : 'video-player-grid video-player-grid--off'}
              lineColor="#fff"
              lineColor2="#bbb"
              cellWidth={widthCellGridState * 4}
              strokeWidth={0.3}
              cellWidth2={widthCellGridState}
              strokeWidth2={0.3}
            />

            <Shortcut disabled />
            <ControlBar disableDefaultControls autoHide={false} className={classes.controllBar}>
              <PlayToggle />
              <VolumeMenuButton />
              <CurrentTimeDisplay />
              <TimeDivider />
              <DurationDisplay />
              <ProgressControl />
              <ToggleNextPrevButton
                order={7}
                on={nextPrevInteractionMode}
                setMode={(on) => setNextPrevInteractionMode(on)}
              />
            </ControlBar>
          </Player>
        </Box>
        <InteractionsDisplay />
        <NextPrevInteraction
          progressControlBounding={progressControlBounding}
          visible={nextPrevInteractionMode}
          onClickOff={() => setNextPrevInteractionMode(false)}
        />
        <EditorVideoBigPlayButton />
      </Box>
    </>
  );
};

VideoDisplay.propTypes = {
  videoMedia: PropTypes.object.isRequired // {src, name}
};

export default VideoDisplay;
