import { Box, makeStyles } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import VideocamIcon from '@material-ui/icons/Videocam';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import TreeItem from '@material-ui/lab/TreeItem';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Draggable from 'react-draggable';
import { useDraggableTreeItem } from 'src/hooks/useDraggableTreeItem';

const useStyles = makeStyles({
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

const getItemIcon = (type, color) => {
  const props = color ? { sx: { color }, fontSize: 'small' } : {};
  switch (type) {
    case 'video':
      return <VideocamIcon {...props} />;
    case 'image':
      return <ImageIcon {...props} />;
    case 'sound':
      return <VolumeUpIcon {...props} />;
    default:
      return <VideocamIcon {...props} />;
  }
};
const DraggableTreeItem = ({ item, type }) => {
  const {
    dragging,
    position,
    droptable,
    rootRef,

    onStart,
    onDrag,
    onStop,
    onMouseDown
  } = useDraggableTreeItem({ item, type });

  const ItemIcon = useCallback((color) => getItemIcon(type, color), [type]);
  const classes = useStyles();

  return (
    <div style={{ position: 'relative' }} ref={rootRef}>
      <TreeItem
        classes={{ label: classes.label }}
        key={item.id}
        nodeId={`${item.uuid}`}
        label={item.name}
        icon={<ItemIcon />}
        sx={{
          position: dragging ? 'static' : 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          visibility: dragging ? 'visible' : 'hidden',
          '& .Mui-selected, & .Mui-selected: hover': {
            backgroundColor: '#87CDCF'
          }
        }}
      />
      <Draggable position={position} onStart={onStart} onDrag={onDrag} onStop={onStop} onMouseDown={onMouseDown}>
        {dragging ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              border: '1px solid white',
              backgroundColor: droptable ? '#24c6c9' : '#556576',
              padding: '6px',
              position: 'fixed',
              pointerEvents: 'none'
            }}
          >
            <ItemIcon color="white" />
          </Box>
        ) : (
          <TreeItem
            classes={{ label: classes.label }}
            key={item.id}
            nodeId={item.uuid}
            label={item.name}
            icon={<ItemIcon />}
            sx={{
              '& .Mui-selected, & .Mui-selected: hover': {
                backgroundColor: '#87CDCF'
              }
            }}
          />
        )}
      </Draggable>
    </div>
  );
};

DraggableTreeItem.propTypes = {
  item: PropTypes.object,
  type: PropTypes.string
};

export default DraggableTreeItem;
