import { INTERACTION } from 'src/constants/interactions';

export const getDataSubmit = (nodeID, interaction) => {
  switch (interaction.type) {
    case INTERACTION.TYPE.IMAGE:
      return {
        type: interaction.type,
        node_id: nodeID,
        image: {
          ...interaction,
          image_id: interaction.image?.id,
          image: undefined,
          jump: undefined
        },
        jump: interaction.jump ? { ...interaction.jump } : null
      };
    case INTERACTION.TYPE.HIGHLIGHT:
      return {
        type: interaction.type,
        node_id: nodeID,
        highlight: {
          ...interaction,
          jump: undefined
        },
        jump: interaction.jump ? { ...interaction.jump } : null
      };

    case INTERACTION.TYPE.TEXT:
      return {
        type: interaction.type,
        node_id: nodeID,
        text: {
          ...interaction,
          jump: undefined
        },
        jump: interaction.jump ? { ...interaction.jump } : null
      };
    case INTERACTION.TYPE.PAUSE:
      return {
        type: interaction.type,
        node_id: nodeID,
        pause: {
          ...interaction,
          sound_id: interaction.sound?.id,
          sound: undefined
        }
      };

    case INTERACTION.TYPE.TRANSPARENT_BUTTON:
      return {
        type: interaction.type,
        node_id: nodeID,
        transparent_button: {
          ...interaction,
          jump: undefined
        },
        jump: interaction.jump ? { ...interaction.jump } : null
      };

    case INTERACTION.TYPE.LINK:
      return {
        type: interaction.type,
        node_id: nodeID,
        link: {
          ...interaction
        }
      };

    case INTERACTION.TYPE.JUMP_TO:
      return {
        type: interaction.type,
        node_id: nodeID,
        jump: {
          ...interaction.jump
        }
      };

    default:
      return {
        type: interaction.type,
        node_id: nodeID,
        [INTERACTION.KEY[interaction.type]]: {
          ...interaction,
          type: undefined
        }
      };
  }
};
