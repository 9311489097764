// /* eslint-disable */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import 'src/containers/interactionsForm/transparent-button/TransparentButtonInteraction.css';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as yup from 'yup';
import RoundedSlider from 'src/components/interaction/RoundedSlider';
import ColorField from '../text/ColorField';
import { borderStyles } from '../text/TextInteractionStyleForm';

const TextInteractionStyleForm = () => {
  const { t } = useTranslation();

  const borderRadiusTimechangeRef = useRef(null);

  const dispatch = useDispatch();

  const schema = yup.object().shape({});
  const textStyleConfig = useSelector(EdittingInteractionSelectors.textInteractionGetTextStyleConfig);
  const { control, handleSubmit } = useForm({
    defaultValues: textStyleConfig,
    resolver: yupResolver(schema)
  });

  const onSubmit = handleSubmit((formValues) => {
    dispatch(EdittingInteractionActions.textInteractionSetTextStyleConfig(formValues));
  });

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="borderRadius"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 600 }} width={160}>
                {t('interaction.transparentButton.label.roundedCorners')}
              </Typography>
              <RoundedSlider
                sx={{ width: 'calc(100% - 160px)' }}
                valueLabelDisplay="on"
                max={360}
                onChange={(_, newValue) => {
                  onChange(newValue);

                  if (borderRadiusTimechangeRef.current) {
                    clearTimeout(borderRadiusTimechangeRef.current);
                  }
                  borderRadiusTimechangeRef.current = setTimeout(() => {
                    onSubmit();
                  }, 150);
                }}
                value={value}
              />
            </Box>
          )}
        />
        <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600 }} width={160}>
            {t('interaction.transparentButton.label.border')}
          </Typography>
          <Box sx={{ display: 'flex', width: 'calc(100% - 160px)' }}>
            <Controller
              name="borderWidth"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ width: 120 }}>
                  <TextField
                    size="small"
                    onChange={(newValue) => {
                      onChange(newValue);
                      onSubmit();
                    }}
                    value={value}
                  />
                </Box>
              )}
            />

            <Controller
              name="borderStyle"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ width: 120 }} mx={1}>
                  <Select
                    size="small"
                    value={value}
                    fullWidth
                    onChange={(evt) => {
                      onChange(evt.target.value);
                      onSubmit();
                    }}
                  >
                    {borderStyles.map((borderStyle) => (
                      <MenuItem key={borderStyle.value} value={borderStyle.value}>
                        {borderStyle.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            />

            <Controller
              name="borderColor"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ width: 120 }}>
                  <ColorField
                    id="transparent-button-react-color-custom"
                    color={value}
                    onColorChange={(color) => {
                      onChange(color);
                      onSubmit();
                    }}
                  />
                </Box>
              )}
            />
          </Box>
        </Box>
      </form>
    </>
  );
};

export default TextInteractionStyleForm;
