export default {
  locale: 'en-US',

  label: {
    email: 'Email',
    password: 'Password',
    passwordConfirm: 'Confirm password',
    otp: 'OTP'
  },
  validate: {
    email: {
      required: 'Email cannot be blank',
      format: 'Please enter a valid email'
    },
    password: {
      required: 'Password cannot be blank',
      min: 'Password must contain at least {{number}} characters',
      max: 'Password too long',
      format: 'Password contains invalid character',
      confirm: 'Confirm not match'
    },
    otp: {
      required: 'Please enter your OTP',
      format: 'OTP invalid character'
    }
  },

  common: {
    messages: {
      error: 'Error',
      notFound: 'No result found.',
      copiedToClipboard: 'Copied to clipboard!',
      thankyou: 'Thank you',
      noteBackToQuestionOnlyShowInEditMode: 'Back to question only visible in edit mode'
    },
    actions: {
      no: 'No',
      yes: 'Yes',
      save: 'Save',
      next: 'Next',
      back: 'Back',
      delete: 'Delete',
      submit: 'Submit',
      ingore: 'Ingore',
      create: 'Create',
      import: 'Import',
      export: 'Export',
      confirm: 'Confirm',
      search: 'Search',
      cancel: 'Cancel',
      reset: 'Reset',
      edit: 'Edit',
      update: 'Update',
      dismiss: 'Dismiss',
      restore: 'Restore',
      template: 'Download Template',
      restoreSelected: 'Restore All',
      deleteSelected: 'Delete All',
      duplicate: 'Duplicate',
      rename: 'Rename',
      startProject: 'Start Project',
      replaceCopy: 'Replace Copy',
      addCopy: 'Add Copy',
      copy: 'Copy',
      paste: 'Paste',
      new: 'New',
      selectImage: 'Select Image',
      selectAnImage: 'Select an image',
      uploadAnImage: 'Upload an image',
      browse: 'Browse...',
      uploadSound: 'Upload Sound',
      selectSound: 'Select sound',
      removeSound: 'Remove sound',
      skipQuestion: 'Skip question',
      tryQuestion: 'Try question',
      continueToVideo: 'Continue to video',
      backToQuestion: 'Back to question'
    },
    state: {
      on: 'On',
      off: 'Off',
      none: 'None',
      undefined: ''
    },
    modal: {
      preview: 'Preview',
      confirmation: 'Confirmation'
    },
    days: {
      morning: 'morning',
      evening: 'evening',
      afternoon: 'afternoon'
    },
    labels: {
      actions: 'Actions'
    },
    status: {
      active: 'Active',
      deactive: 'Deactive',
      activated: 'Activated',
      deactivated: 'Deactivated'
    },
    list: {
      deactivate: {
        success: 'Delete {{item}} successfully!',
        failed: 'Delete {{item}} failed!',
        failure: 'Delete {{item}} failed!',
        confirm: 'Are you sure you want to delete {{item}} list is selected?'
      },
      result: 'Result {{total}} of {{records}} records'
    }
  },
  projects: {
    title: 'All Your Projects',
    projectProcessManagement: 'Project Process Management',
    search: 'Search project name',
    create: {
      title: 'Create new project',
      success: 'Create project success!',
      failed: 'Create project failed! Please try again!'
    },
    editTitle: {
      title: 'Edit project title',
      success: 'Change saved!',
      failed: 'Change failed! Please try again'
    },
    delete: {
      confirmMessage: 'Do you want to delete project: {{projectName}}?',
      success: 'Delete success!',
      failed: 'Delete failed! Please try again.'
    },
    duplicate: {
      success: 'Duplicate project success!',
      failed: 'Duplicate failed! Please try again!'
    },
    fields: {
      id: 'ID',
      name: 'Project Name',
      buttonSound: 'Default button sound',
      durationIn: 'In',
      durationOut: 'Out',
      aspect_ratio: 'Aspect ratio'
    },
    defaultButtonSound: {
      none: 'None',
      default: 'Default'
    },
    update: {
      success: 'Project updated!',
      error: 'Something wrong happened. Try again!'
    }
  },
  projectProcessManagement: {
    table: {
      name: 'Name',
      status: 'Status',
      createAt: 'Created At',
      action: 'Action'
    },
    viewStatus: {
      published: 'Published',
      processing: 'Processing...',
      publishedFailed: 'Publish Failed',
      draft: 'Draft'
    },
    action: {
      viewLog: 'View Log',
      viewURL: ' View URL',
      rePublish: 'Re-Publish',
      viewProject: 'View Project'
    }
  },
  header: {
    user: {
      logout: 'Logout',
      profile: 'Profile',
      hello: 'Hi,'
    }
  },
  project: {
    modal: {
      confirmReplaceAssetTitle: 'Replace Asset',
      confirmReplaceAssetMsg: 'This node already contains asset.\n Are you sure you want to replace it?'
    },
    settings: {
      title: 'Project Settings',
      videoNodes: 'Video Nodes',
      screens: 'Screens',
      paths: 'Paths',
      applyAll: 'Apply to all existing nodes',
      defaultUITransitionDuration: 'Default UI Transition Duration',
      defaultButtonSoundTooltip:
        'Button sounds must be less than 3 seconds long. Affects only on newly created buttons.',
      defaultUITransitionDurationTooltip:
        'Set the default duration (between 0 to 2 seconds) of the appear/disappear animation for Ul elements. Affects only newly created nodes',
      aspect_ratio: 'Aspect ratio'
    }
  },
  projectNode: {
    create: {
      failure: 'Something wrong happened. Please try again!',
      maxNode: 'Numbers of video nodes has reach limit!'
    },
    fields: {
      name: 'Node name',
      video: 'Node video',
      title: 'Node title',
      description: 'Node description',
      durationIn: 'In',
      durationOut: 'Out',
      videoDuration: 'Duration',
      screenName: 'Screen Name',
      screenAsset: 'Screen Asset'
    },
    settings: {
      title: 'Node settings',
      editNode: 'Edit node',
      UITransitionDuration: 'UI Transition Duration',
      UITransitionDurationTooltip:
        'Set the default duration (between 0 to 2 seconds) of the appear/disappear animation for Ul elements. Affects only newly created nodes',
      screenTitle: 'Screen settings'
    },
    edit: {
      noVideoWarning: 'Please add video to edit this node',
      nodeName: 'Node',
      videoName: 'Video',
      backToListNode: 'Back to list node'
    }
  },

  interaction: {
    interactionTitle: 'Interaction',
    styleTitle: 'Style',
    alertSoundFormat: 'You can only upload audio files in MP3 format.',
    style: {
      borderStyle: {
        solid: 'Solid',
        dotted: 'Dotted',
        dashed: 'Dashed'
      },
      textStroke: {
        title: 'Text stroke',
        white: 'White text stroke',
        black: 'Black text stroke',
        none: 'No text stroke'
      },
      borderRadius: 'Rounded corners',
      borderWidth: 'Border',
      border: 'Border',
      topPadding: 'Top padding',
      fadeIn: 'Fade in',
      fadeOut: 'Fade out',
      backgroundColor: 'Background color',
      opacity: 'Opacity'
    },
    label: {
      interactions: 'Interactions',
      menu: 'Menu',
      jumpTo: 'Jump To',
      text: 'Text',
      image: 'Image',
      pauseScreen: 'Pause Screen',
      transparentButton: 'Transparent Button',
      hightlight: 'Highlight',
      zoom: 'Zoom',
      linkVideo: 'Link Video',
      currentTime: 'Current time',
      form: 'Form'
    },
    mapKeyAction: {
      1: 'Jump to specific time in video',
      2: 'Jump to specific time in video - scheduled',
      3: 'Jump to start of video',
      4: 'Jump to end of video',
      5: 'Jump to interaction',
      6: 'Hyperlink',
      7: 'Continue',
      8: 'Jump to node'
    },
    actions: {
      noAction: 'No action',
      jump_to_specific: 'Jump to specific time in video',
      jump_to_specific_scheduler: 'Jump to specific time in video - scheduled',
      jump_to_start: 'Jump to start of video',
      jump_to_end: 'Jump to end of video',
      jump_to_interaction: 'Jump to interaction',
      link: 'Hyperlink',
      jump_to_node: 'Jump to node'
    },
    modal: {
      confirmDelete: 'Are you sure you want to delete interaction: {{interactionName}}?'
    },
    text: {
      label: {
        title: 'Title',
        action: 'Action',
        jumpTo: 'Jump to',
        jumpAt: 'Jump at',
        jumpToInteraction: 'Jump to interaction',
        hyperlink: 'Hyperlink',
        backgroundColor: 'Background color',
        textStroke: 'Text stroke',
        border: 'Border',
        roundedCorners: 'Rounded corners',
        topPadding: 'Top padding',
        fadeIn: 'Fade in',
        fadeOut: 'Fade out'
      },
      validate: {
        title: {
          required: 'Title is required',
          max: 'Title can only contain up to {{max}} characters'
        },
        content: {
          required: 'Text content is required',
          max: 'Text content can only contain up to {{max}} characters'
        }
      }
    },
    transparentButton: {
      label: {
        title: 'Title',
        action: 'Action',
        jumpTo: 'Jump to',
        jumpAt: 'Jump at',
        hyperlink: 'Hyperlink',
        border: 'Border',
        roundedCorners: 'Rounded corners'
      },
      validate: {
        title: {
          required: 'Title is required',
          max: 'Title can only contain up to {{max}} characters'
        }
      }
    },
    jump: {
      label: {
        title: 'Title',
        action: 'Action',
        jumpTo: 'Jump to',
        time_jump_to_node: 'Time jump to node'
      },
      validate: {
        title: {
          required: 'Title is required',
          max: 'Title can only contain up to {{max}} characters'
        }
      }
    },
    zoom: {
      label: {
        title: 'Title',
        zoomPreview: 'Zoom preview',
        zoomIn: 'Zoom in',
        zoomOut: 'Zoom out',
        border: 'Border',
        roundedCorners: 'Rounded corners'
      },
      validate: {
        title: {
          required: 'Title is required',
          max: 'Title can only contain up to {{max}} characters'
        }
      }
    },
    link: {
      label: {
        title: 'Title',
        backgroundColor: 'Background color',
        linkedVideo: 'Linked video',
        videoCode: 'Video code',
        textStroke: 'Text stroke',
        border: 'Border',
        roundedCorners: 'Rounded corners',
        topPadding: 'Top padding',
        fadeIn: 'Fade in',
        fadeOut: 'Fade out'
      },
      validate: {
        title: {
          required: 'Title is required',
          max: 'Title can only contain up to {{max}} characters'
        },
        content: {
          required: 'Text content is required',
          max: 'Text content can only contain up to {{max}} characters'
        },
        publicId: {
          required: 'Public id is required',
          max: 'Public id can only contain up to {{max}} characters',
          invalid: 'Public id is invalid'
        }
      }
    },
    menu: {
      fields: {
        title: 'Menu title',
        canCloseMenu: 'Posibility to close menu',
        showMenuButton: 'Show menu button',
        pauseVideo: 'Pause video when menu show up',
        numbering: 'Numbering',
        menuItems: 'Menu Items',
        position: {
          title: 'Position',
          left: 'Place menu on the left side',
          right: 'Place menu on the right side',
          middle: 'Place menu in the middle'
        },
        menuButton: {
          buttonName: 'Button Name',
          location: {
            title: 'Menu Button Location',
            top_left: 'Top Left',
            top_right: 'Top Right',
            bottom_left: 'Bottom Left',
            bottom_right: 'Bottom Right'
          }
        },
        menuItem: {
          title: 'Title',
          action: {
            title: 'Action',
            jump_to_specific: 'Jump to specific time in video',
            jump_to_start: 'Jump to start of video',
            jump_to_end: 'Jump to end of video',
            jump_to_interaction: 'Jump to interaction',
            link: 'Hyperlink',
            continue: 'Continue playing',
            jump_to_node: 'Jump to node'
          }
        }
      }
    },
    image: {
      fields: {
        title: 'Title',
        action: 'Action',
        jumpTo: 'Jump To',
        jumpTime: 'Jump At',
        link: 'Link',
        image: 'Image',
        fadeIn: 'Fade In',
        fadeOut: 'Fade Out',
        fullScreen: 'Full Screen'
      },
      yourImageHere: 'Your image here',
      selectingImage: 'Selecting Image:',
      interaction: {
        title: 'Interaction'
      },
      style: {
        title: 'Style'
      }
    },
    highlight: {
      fields: {
        title: 'Title',
        action: 'Action',
        jumpTo: 'Jump To',
        backgroundColor: 'Background Color',
        jumpTime: 'Jump At',
        link: 'Link',
        fadeIn: 'Fade In',
        fadeOut: 'Fade Out'
      }
    },
    pause: {
      fields: {
        title: 'Title',
        content: 'Content',
        sound: 'Sound',
        playSound: 'Play the sound',
        fadeSound: 'Fade the audio out and in',
        activeSound: 'Active sound'
      },
      selectASound: 'Select A Sound'
    },
    form: {
      fields: {
        title: 'Form title',
        firstField: 'Title of the first field',
        secondField: 'Title of the second field',
        thirdField: 'Title of the third field',
        emailField: 'Title of the email field',
        receiveEmail: 'Who should receive this input?',
        genericFeedback: 'Generic feedback',
        fileUpload: 'Upload file message',
        feedbackScreen: 'Feedback screen',
        enableBackground: 'Enable color indication for the feedback screen',
        enableFeedback: 'Enable automatic feedback title (like "Thank you!")',
        fadeIn: 'Fade in',
        fontSize: 'Font size'
      }
    }
  },

  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      }
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected'
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer'
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}'
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape'
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items'
    }
  },
  /* eslint-disable */

  // Auth
  auth: {
    label: {
      otp: 'OTP',
      email: 'Email',
      password: 'Password',
      passwordConfirm: 'Password confirm',
      forgotPasswordCaption:
        'Do not worry. Resetting your password is easy, just tell us the email address you registered with {{origin}}',
      resetPasswordCaption:
        'We have sent a password reset OTP to {{email}}, please check your mail and enter OTP to set a new password',

      login: 'Login',
      loginTitle: 'Login - WAOCON',
      forgotPassword: 'Forgot Password',
      forgotPasswordTitle: 'Forgot Password - WAOCON',
      resetPassword: 'Reset Password',
      resetPasswordTitle: 'Reset Password - WAOCON',
      forgotPasswordQs: 'Forgot password?',
      backToLogin: 'Back to login',
      resendOTP: 'Resend OTP',
      resendOTPMsg: 'We have resend OTP to your email'
    },

    validate: {
      otp: {
        required: 'OTP is required',
        format: 'OTP consists of 6 numeric characters'
      },

      email: {
        required: 'Email is required',
        format: 'Please enter a valid email'
      },

      password: {
        required: 'Password is required',
        min: 'Password must contain at least {{min}} characters',
        max: 'Password can only contain up to {{max}} characters',
        format: 'Password contains invalid characters',
        confirm: 'Password confirm does not match'
      }
    },

    request: {
      login: {
        validate: 'An error occurred, please check the information again',
        success: 'Login success',
        failure: 'Email or password is incorrect'
      },

      forgotPassword: {
        validate: 'An error occurred, please check the information again',
        success: 'Forgot password success',
        failure: 'Email does not exist'
      },

      resetPassword: {
        validate: 'An error occurred, please check the information again',
        success: 'Reset password success',
        failure: 'OTP is invalid or expired'
      }
    }
  },

  account: {
    label: {
      name: 'Name',
      email: 'Email',
      password: 'Password',
      passwordConfirm: 'Password confirm',
      role: 'Role',

      createAccount: 'Create Account',
      updateAccount: 'Update Account'
    },

    validate: {
      name: {
        required: 'Name is required',
        max: 'Name can only contain up to {{max}} characters',
        format: 'Please enter a valid name'
      },

      email: {
        required: 'Email is required',
        format: 'Please enter a valid email'
      },

      password: {
        required: 'Password is required',
        passwordConfirm: 'passwordConfirm is required',
        min: 'Password must contain at least {{min}} characters',
        max: 'Password can only contain up to {{max}} characters',
        format: 'Password contains invalid characters',
        confirm: 'Password confirm does not match'
      },

      role: {
        required: 'Role is required',
        format: 'Please enter a valid role'
      }
    },

    request: {
      create: {
        validate: 'An error occurred, please check the information again',
        success: 'Create account success',
        failure: 'Create account failure'
      },

      update: {
        validate: 'An error occurred, please check the information again',
        success: 'Update account success',
        failure: 'Update account failure'
      },

      detail: {
        validate: 'An error occurred, please check the information again',
        success: 'Detail account success',
        failure: 'Detail account failure'
      },

      delete: {
        validate: 'An error occurred, please check the information again',
        success: 'Delete account success',
        failure: 'Delete account failure'
      }
    },

    confirm: {
      delete: 'Do you want to delete account {{name}}'
    }
  },

  projectDetail: {
    label: {
      cancelUploadMedia: 'Canceled media upload',
      mediaExisted: 'Hey, the following file is already in your library',

      folderName: 'Folder Name',
      create: 'Create',
      newFolder: 'New Folder',
      createNewFolder: 'Create New Folder'
    },

    validate: {
      folderName: {
        required: 'Folder Name is required',
        max: 'Folder Name can only contain up to {{max}} characters'
      }
    },

    request: {
      fetch: {
        validate: 'An error occurred, please check the information again',
        success: 'Fetch project success',
        failure: 'Fetch project failure'
      },

      uploadMedia: {
        validate: 'An error occurred, please check the information again',
        success: 'Upload media success',
        failure: 'Upload media failure',
        cancel: 'Upload media canceled'
      },

      createFolder: {
        validate: 'An error occurred, please check the information again',
        success: 'Create folder success',
        failure: 'Create folder failure'
      },

      deleteMedia: {
        validate: 'An error occurred, please check the information again',
        success: 'Delete media success',
        failure: 'Delete media failure'
      },

      deleteFolder: {
        validate: 'An error occurred, please check the information again',
        success: 'Delete Folder success',
        failure: 'Delete Folder failure'
      }
    },

    confirm: {
      deleteMedia: 'Do you want to delete media: {{mediaName}}?',
      deleteFolder: 'Do you want to delete folder: {{folderName}}?'
    }
  },

  model: {
    account: {
      name: 'Name',
      email: 'Email',
      role: 'Role'
    }
  },

  action: {
    title: 'Action',
    add: 'Add',
    update: 'Update',
    delete: 'Delete'
  },

  breadcrumbs: {
    dashboard: 'Dashboard',
    project: 'Project',
    video: 'Video',
    accounts: {
      title: 'Accounts',
      list: {
        title: 'Accounts'
      },
      create: {
        title: 'Create Account'
      },
      update: {
        title: 'Update Account'
      }
    }
  },

  sidebar: {
    management: 'Management',
    account: {
      title: 'Account',
      edit: 'My Account',
      list: 'Account List',
      create: 'Create Account'
    },
    allYourProject: 'All Your Projects',
    projectProcessManagement: 'Projects Process Management'
  },

  projectSidebar: {
    library: 'Library',
    search: 'Search',
    designPresets: 'Design Presets',
    menuLayer: 'Menu Layer',
    backgroundSoundtrack: 'Background Soundtrack',

    libraryMsg: 'No asset preview'
  },

  publicSettings: {
    title: 'Public Settings',
    basicInfo: {
      basicInfo: 'Basic Info',
      title: 'Title',
      author: 'Author',
      description: 'Description',
      tags: 'Tags',
      saveAndApply: 'Save & Apply',
      cancel: 'Cancel',

      validate: {
        title: {
          max: 'Title can only contain up to {{max}} characters'
        },
        author: {
          max: 'Author can only contain up to {{max}} characters'
        },
        description: {
          max: 'Description can only contain up to {{max}} characters'
        }
      },

      request: {
        fetch: {
          validate: 'An error occurred, please check the information again',
          success: 'Fetch basic info success',
          failure: 'Fetch basic info failure'
        },
        update: {
          validate: 'An error occurred, please check the information again',
          success: 'Update basic info success',
          failure: 'Update basic info failure'
        }
      }
    },
    privacy: {
      privacy: 'Privacy',
      public: 'Public',
      private: 'Private',
      password: 'Password',
      publicDesc: 'Anyone can watch your project',
      privateDesc:
        'Set a password to prevent others from watching your project and disable sharing properties. Private projects are always unlisted',
      validate: {
        isPublic: {},
        password: {
          required: 'Password cannot be blank'
        }
      }
    },
    sharing: {
      sharing: 'Sharing'
    },
    advancedSettings: {
      advancedSettings: 'Advanced Settings',
      autoplay: 'Autoplay',
      defaultVolume: 'Default Volume'
    }
  },

  profileMenu: {
    profileMenu: 'Profile Menu',
    hi: 'Hi, ',
    logout: 'Logout',
    myProjects: 'My Projects',
    myAccount: 'My Account'
  },

  publishManager: {
    label: {
      publishManager: 'Publish Manager',
      publishNewRevision: 'Publish New Revision',
      copyLink: 'Copy Link',
      copiedToClipboard: 'Copied to clipboard!',
      videoBeingProcessed: 'Video is being processing'
    },

    validate: {},

    request: {
      newRevision: {
        validate: 'An error occurred, please check the information again',
        success: 'Publish new revision success',
        failure: 'Publish new revision failure'
      }
    }
  },

  projectPreview: {
    label: {
      projectPreview: 'Preview Project'
    },

    validate: {},

    request: {
      getPreview: {
        validate: 'An error occurred, please check the information again',
        success: 'Get preview success',
        failure: 'Get preview failure'
      }
    }
  },

  interactions: {
    label: {
      interactions: 'Interactions',
      menu: 'Menu',
      jumpTo: 'Jump To',
      text: 'Text',
      image: 'Image',
      pauseScreen: 'Pause Screen',
      transparentButton: 'Transparent Button',
      hightlight: 'Hightlight',
      zoom: 'Zoom',
      linkVideo: 'Link Video',
      currentTime: 'Current time'
    }
  },

  googleAnalyticsActions: {
    categories: {
      video: 'Video',
    },

    actions: {
      playVideo: 'Play Video',
      link: 'Link',
      menu: 'Menu',
      form: 'Form',
      zoom: 'Zoom',
      pauseScreen: 'Pause Screen',
      noAction: 'No Action'
    }
  }
};
