import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputField from 'src/components/FormControls/InputField';
import PasswordField from 'src/components/FormControls/PasswordField';
import SelectField from 'src/components/FormControls/SelectField';
import RegexPattern from 'src/constants/RegexPattern';
import UserRoles from 'src/constants/UserRoles';
import { useAccount } from 'src/hooks';
import * as Yup from 'yup';

const CreateForm = (props) => {
  const { t } = useTranslation();

  const { onCreate } = useAccount();

  const schema = Yup.object().shape({
    name: Yup.string()
      .required(t('account.validate.name.required'))
      .max(50, t('account.validate.name.max', { max: 50 })), // Example 50 is length of name

    email: Yup.mixed()
      .test('required', t('account.validate.email.required'), (value) => value.trim())
      .test('format', t('account.validate.email.format'), (value) => RegexPattern.Email.test(value)),

    password: Yup.string()
      .required(t('account.validate.password.required'))
      .min(6, t('account.validate.password.min', { min: 6 }))
      .max(32, t('account.validate.password.max', { max: 32 }))
      .test('format', t('account.validate.password.format'), (value) => RegexPattern.Password.test(value)),

    passwordConfirm: Yup.mixed().oneOf([Yup.ref('password'), null], t('account.validate.password.confirm')),

    type: Yup.mixed()
      .test('required', t('account.validate.role.required'), (value) => value.trim())
      .test('format', t('account.validate.role.format'), (value) => Object.values(UserRoles).includes(value))
  });

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirm: '',
      type: UserRoles.User
    },
    resolver: yupResolver(schema)
  });

  const { isSubmitting } = formState;

  return (
    <>
      <Box>
        <Typography textAlign="center" color="textPrimary" gutterBottom variant="h4">
          {t('account.label.createAccount')}
        </Typography>
      </Box>
      <Card
        sx={{
          p: 4,
          mt: 4
        }}
        {...props}
      >
        <form noValidate onSubmit={handleSubmit(onCreate)}>
          <InputField
            required
            autoFocus
            name="name"
            label={t('account.label.name')}
            control={control}
            formState={formState}
            disabled={isSubmitting}
          />
          <InputField
            required
            name="email"
            label={t('account.label.email')}
            control={control}
            formState={formState}
            disabled={isSubmitting}
          />
          <PasswordField
            required
            fullWidth
            name="password"
            label={t('account.label.password')}
            control={control}
            formState={formState}
            disabled={isSubmitting}
          />
          <PasswordField
            required
            fullWidth
            name="passwordConfirm"
            label={t('account.label.passwordConfirm')}
            control={control}
            formState={formState}
            disabled={isSubmitting}
          />
          <SelectField
            required
            name="type"
            label={t('account.label.role')}
            control={control}
            formState={formState}
            options={[
              { label: 'Admin', value: UserRoles.Admin },
              { label: 'User', value: UserRoles.User }
            ]}
          />
          <Box sx={{ mt: 2 }}>
            <Button fullWidth color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
              {t('account.label.createAccount')}
            </Button>
          </Box>
        </form>
      </Card>
    </>
  );
};

export default CreateForm;
