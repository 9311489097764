/* eslint-disable */
import { Box, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Rnd } from 'react-rnd';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { VIDEO_CONTROLBAR_HEIGHT } from 'src/configs/layout';
import { INTERACTION } from 'src/constants/interactions';
import RegexPattern from 'src/constants/RegexPattern';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import videoController from 'src/utils/videoController';
import ReactGA from 'react-ga';
import { borderStyles } from '../../interactionsForm/text/TextInteractionStyleForm';
import {
  ResizeHandleBottom,
  ResizeHandleBottomLeft,
  ResizeHandleBottomRight,
  ResizeHandleLeft,
  ResizeHandleRight,
  ResizeHandleTop,
  ResizeHandleTopLeft,
  ResizeHandleTopRight
} from '../textInteractionDisplay/TextInteractionDisplay';
import './TransparentButtonInteractionDisplay.css';

const TransparentButtonInteractionDisplay = ({ config, videoBounding, isEditting, isPublishMode }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [jumpToScheduler, setJumpToScheduler] = useState(false);

  const [triggerDisplay, setTriggerDisplay] = useState(false);

  const { widthCellGridState, showVideoGridState, videoCurrentTime, videoDuration, videoData } = useVideoPlayer();

  const backgroundCSS = isEditting
    ? {
        backgroundImage: `linear-gradient(45deg,#cbcbcb 25%,transparent 0,transparent 75%,#cbcbcb 0,#cbcbcb),
  linear-gradient(45deg,#cbcbcb 25%,transparent 0,transparent 75%,#cbcbcb 0,#cbcbcb)`,
        backgroundSize: '30px 30px',
        backgroundPosition: '0 0,15px 15px'
      }
    : {};

  const { start_time, end_time, jump, link, x, y, width, height } = config;

  const { borderRadius, borderWidth, borderStyle, borderColor } = config.style;

  const useStyles = makeStyles(() => ({
    wrapperContent: {
      color: '#000',
      wordBreak: 'break-all',

      width: width * videoBounding.width,
      height: height * videoBounding.height,
      maxWidth: '100%',
      maxHeight: '100%',

      overflow: 'hidden',
      cursor: isEditting ? 'move' : jump?.type !== 0 ? 'pointer' : 'default',
      position: 'absolute',
      zIndex: 100,

      ...backgroundCSS,

      borderRadius: parseInt(borderRadius, 10),
      borderWidth: parseInt(borderWidth, 10),
      borderStyle: borderStyles[borderStyle].cssValue,
      borderColor: `rgba(${borderColor?.r}, ${borderColor?.g}, ${borderColor?.b}, ${borderColor?.a})`
    }
  }));

  const classes = useStyles();

  const handleChangeConfig = useCallback((newConfig) => {
    dispatch(EdittingInteractionActions.setConfig(newConfig));
  }, []);

  useEffect(() => {
    if (showVideoGridState) {
      const xTemp = Math.trunc(x / widthCellGridState);
      const yTemp = Math.trunc(y / widthCellGridState);

      dispatch(EdittingInteractionActions.setConfig({ x: xTemp * widthCellGridState, y: yTemp * widthCellGridState }));
    }
  }, [showVideoGridState]);

  const actionTypeBindings = useCallback(() => {
    if (isPublishMode) {
      ReactGA.event({
        category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
        action:
          link && t(`googleAnalyticsActions.actions.link`) ||
          (jump?.type && t(`interaction.mapKeyAction.${jump?.type}`) ||
          t(`googleAnalyticsActions.actions.noAction`) ),
        label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.title}`,
        value: videoData.id
      });
    }
    
    if (isEditting || jump?.type === undefined) {
      if (!link) return null;

      if (link) {
        if (RegexPattern.URL.test(link)) {
          const newWindow = window.open(link, '_blank', 'noopener,noreferrer');

          videoController.getInstance()?.pause();
          if (newWindow) newWindow.opener = null;
        }
      }
    }

    switch (jump?.type) {
      case INTERACTION.ACTION.JUMP_TO_SPECIFIC:
        videoController.getInstance()?.seek(jump.jump_to);
        break;

      case INTERACTION.ACTION.JUMP_TO_SPECIFIC_SCHEDULER:
        setJumpToScheduler(true);
        break;

      case INTERACTION.ACTION.JUMP_TO_START:
        videoController.getInstance()?.seek(jump.jump_to);
        break;

      case INTERACTION.ACTION.JUMP_TO_END:
        videoController.getInstance()?.seek(jump.jump_to);
        break;

      case INTERACTION.ACTION.JUMP_TO_INTERACTION: {
        if (!isEditting) {
          videoController.getInstance()?.seek(jump.jump_to);
        }
        break;
      }
      case INTERACTION.ACTION.JUMP_TO_NODE: {
        if (isPublishMode) {
          const time_jump = parseInt(jump.time_jump_to_node || 0, 10) + (jump.product_jump_to || 0);
          videoController.getInstance()?.seek(time_jump);
        }
        break;
      }
    }
  }, [jump, videoDuration, link, isEditting, isPublishMode]);

  useEffect(() => {
    if (jumpToScheduler && videoCurrentTime >= jump.jump_time) {
      videoController.getInstance()?.seek(jump.jump_to);
      setJumpToScheduler(false);
    }
  }, [videoCurrentTime, jumpToScheduler, jump]);

  useEffect(() => {
    if (start_time > videoCurrentTime || end_time < videoCurrentTime) {
      setTriggerDisplay(false);
    } else {
      setTriggerDisplay(true);
    }
  }, [videoCurrentTime, start_time, end_time]);

  return (
    // <Box
    //   className="waocon-interaction-bounding-box"
    //   sx={{
    //     visibility: triggerDisplay ? 'visible' : 'hidden'
    //   }}
    // >
    <Rnd
      className="rnd"
      enableResizing={isEditting}
      disableDragging={!isEditting}
      {...(showVideoGridState ? { dragGrid: [widthCellGridState, widthCellGridState] } : {})}
      style={{
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        border: isEditting ? '2px dashed white' : 'none',
        cursor: 'auto !important',

        visibility: triggerDisplay ? 'visible' : 'hidden'
      }}
      bounds="parent"
      size={{
        width: config.width * videoBounding.width,
        height: config.height * videoBounding.height
      }}
      position={{
        x: config.x * videoBounding.width,
        y: config.y * videoBounding.height
      }}
      minWidth="10%"
      minHeight="10%"
      onDragStop={(e, d) => {
        handleChangeConfig({ x: d.lastX / videoBounding.width, y: d.lastY / videoBounding.height });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        handleChangeConfig({
          width: ref.offsetWidth / videoBounding.width,
          height: ref.offsetHeight / videoBounding.height,
          x: position.x / videoBounding.width,
          y: position.y / videoBounding.height
        });
      }}
      resizeHandleComponent={{
        bottomRight: <ResizeHandleBottomRight />,
        bottomLeft: <ResizeHandleBottomLeft />,
        bottom: <ResizeHandleBottom />,
        top: <ResizeHandleTop />,
        left: <ResizeHandleLeft />,
        right: <ResizeHandleRight />,
        topLeft: <ResizeHandleTopLeft />,
        topRight: <ResizeHandleTopRight />
      }}
    >
      <Box
        id="transparent-button-interaction-content"
        className={`${classes.wrapperContent}`}
        onClick={actionTypeBindings}
      />
    </Rnd>
    // </Box>
  );
};

TransparentButtonInteractionDisplay.propTypes = {
  config: PropTypes.object.isRequired,
  videoBounding: PropTypes.object.isRequired
};

export default TransparentButtonInteractionDisplay;
