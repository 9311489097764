import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';
import { requireRole } from 'src/hooks';
import UserRoles from 'src/constants/UserRoles';

const Accounts = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('page.accountList')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'white',
          minHeight: '100%'
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

export default requireRole(Accounts, UserRoles.Admin);
