export default {
  locale: 'jp-JP',

  label: {
    email: 'メール',
    password: 'パスワード',
    passwordConfirm: 'パスワードの確認',
    otp: 'OTP'
  },
  validate: {
    email: {
      required: 'メールを空白にすることはできません',
      format: '有効なメールを入力してください'
    },
    password: {
      required: 'パスワードを空白にすることはできません',
      min: 'パスワードには数字を少なくとも{{ number }}文字使用してください',
      max: 'パスワードが長すぎ',
      format: 'パスワードには 無効な文字が含まれる',
      confirm: '確認が一致しません'
    },
    otp: {
      required: ' OTPを入力してください',
      format: 'OTP 無効な文字'
    }
  },

  common: {
    messages: {
      error: 'エラー',
      notFound: '結果がありません.',
      copiedToClipboard: 'クリップボードにコピーする!',
      thankyou: 'ありがとうございます',
      noteBackToQuestionOnlyShowInEditMode: '編集モードでのみ表示される質問に戻す'
    },
    actions: {
      no: 'いいえ',
      yes: 'はい',
      save: '保存',
      next: '次に',
      back: '戻る',
      delete: '削除',
      submit: '提出',
      ingore: '無視',
      create: '作成',
      import: '輸入',
      export: '輸出',
      confirm: '確認',
      search: '検索',
      cancel: 'キャンセル',
      reset: 'リセット',
      edit: '編集',
      update: 'アップデート',
      dismiss: '却下',
      restore: '復元',
      template: 'ダウンロードテンプレート',
      restoreSelected: 'すべて修復',
      deleteSelected: 'すべて削除',
      duplicate: '複製',
      rename: 'リネーム',
      startProject: 'プロジェクトを起動する',
      replaceCopy: 'コピーを置き換え',
      addCopy: 'コピーを追加する',
      copy: 'コピー',
      paste: 'ペースト',
      new: '新しい',
      selectImage: 'イメージを選択する',
      selectAnImage: '一つのイメージを選択する',
      uploadAnImage: '画像をアップロードする',
      browse: '参照...',
      uploadSound: 'サウンドをアップロードする',
      selectSound: 'サウンドをを選択する',
      removeSound: 'サウンドを削除する',
      skipQuestion: '質問をスキップする',
      tryQuestion: '質問を試してください',
      continueToVideo: 'ビデオを引き続き',
      backToQuestion: '質問に戻る'
    },
    state: {
      on: 'オン',
      off: 'オフ',
      none: 'なし',
      undefined: ''
    },
    modal: {
      preview: 'プレビュー',
      confirmation: '確認'
    },
    days: {
      morning: '朝',
      evening: '夜',
      afternoon: 'ゆう方'
    },
    labels: {
      actions: 'アクション'
    },
    status: {
      active: 'アクティブ化',
      deactive: '非アクティブ化',
      activated: 'アクティブ',
      deactivated: '非アクティブ'
    },
    list: {
      deactivate: {
        success: '{{item }}を 削除するのは 成功!',
        failed: ' {{ item }}を 削除するのは失敗!',
        failure: '{{ item }}を 削除するのは 失敗!',
        confirm: '{{ item }} エントリーを 削除しますか?'
      },
      result: '{{records}} レコードの{{total}} 結果'
    }
  },
  projects: {
    title: 'すべてのプロジェクト',
    search: 'プロジェクト名を検索する',
    projectProcessManagement: 'プロジェクト名を検索する',

    create: {
      title: '新しいプロジェク トを作成する',
      success: 'プロジェクトを作成の成功!',
      failed: 'プロジェクトの作成は失敗しました! もう一度やり直してください!'
    },
    editTitle: {
      title: 'プロジェクトのタイトルを編集する',
      success: '変更は保存される!',
      failed: '変更は失敗しました! もう一度やり直してください'
    },
    delete: {
      confirmMessage: 'プロジェクトを削除しますか: {{ projectName }}?',
      success: '削除するのは成功!',
      failed: '削除するのは失敗しまいました! もう一度やり直してください.'
    },
    duplicate: {
      success: '複製プロジェクトは成功!',
      failed: '複製プロジェクトは失敗しまいました! もう一度やり直してください!'
    },
    fields: {
      id: 'ID',
      name: 'プロジェクト名',
      buttonSound: 'デフォルトのサウンドボタン',
      durationIn: 'イン',
      durationOut: 'アウト',
      aspect_ratio: 'アスペクト比'
    },
    defaultButtonSound: {
      none: 'なし',
      default: 'デフォルト'
    },
    update: {
      success: 'プロジェクトが更新される!',
      error: '何らかの問題がおきました. やり直してください!'
    }
  },
  projectProcessManagement: {
    table: {
      name: '名前',
      status: 'ステータス',
      createAt: '作成日',
      action: 'アクション'
    },
    viewStatus: {
      published: '公開済み',
      processing: '処理中...',
      publishedFailed: '公開失敗',
      draft: '下書き'
    },
    action: {
      viewLog: 'Logの表示',
      viewURL: 'URLの表示',
      rePublish: '再公開',
      viewProject: 'プロジェクトの表示'
    }
  },
  header: {
    user: {
      logout: 'ログアウト',
      profile: 'プロファイル',
      hello: 'Hi,'
    }
  },
  project: {
    modal: {
      confirmReplaceAssetTitle: ' アセットを置き換える',
      confirmReplaceAssetMsg: 'このノードはすでにアッセットが含まれています. 完全に置き換えますか?'
    },
    settings: {
      title: 'プロジェクト設定',
      videoNodes: 'ビデオ ノード',
      screens: '画面',
      paths: 'Paths',
      applyAll: 'すべて既存ノードに適用',
      defaultUITransitionDuration: 'デフォルトの UI 移行期間',
      defaultButtonSoundTooltip:
        'ボタンのサウンドは 3 秒未満である必要があります。 新しく作成されたボタンにのみ影響します.',
      defaultUITransitionDurationTooltip:
        'UL エレメントの表示 / 非表示アニメーションのデフォルトの長さ（ 0 ～ 2 秒）を設定します.　新しく作成されたノードのみに影響します'
    }
  },
  projectNode: {
    create: {
      failure: '何らかの問題がおきました. もう一度やり直してください!',
      maxNode: 'ビデオノードの数が制限に達する!'
    },
    fields: {
      name: 'ノード名',
      video: 'ビデオ ノード',
      title: 'タイトルノード',
      description: '説明のノード',
      durationIn: 'イン',
      durationOut: 'アウト',
      videoDuration: '期間',
      screenName: 'スクリーン名',
      screenAsset: 'アセット画面'
    },
    settings: {
      title: 'ノード設定',
      editNode: 'ノードの編集',
      UITransitionDuration: 'UI 切り替え効果の再生時間',
      UITransitionDurationTooltip:
        'UL エレメントの表示 / 非表示アニメーションのデフォルトの長さ（ 0 ～ 2 秒）を設定します.新しく作成されたノードのみに影響します',
      screenTitle: '設定画面'
    },
    edit: {
      noVideoWarning: 'このノードを編集するように、ビデオを追加してください',
      nodeName: 'ノード',
      videoName: 'ビデオ',
      backToListNode: 'ノードリストに戻る'
    }
  },
  interaction: {
    interactionTitle: 'インタラクション',
    styleTitle: 'スタイル',
    alertSoundFormat: 'オーディオファイルは MP3 形式でのみアップロードできます.',
    style: {
      borderStyle: {
        solid: '実線',
        dotted: '点線',
        dashed: '破線'
      },
      textStroke: {
        title: 'テキストのストローク',
        white: '白いテキストのストローク',
        black: '青いテキストのストローク',
        none: 'テキストのストロークなし'
      },
      borderRadius: '四隅が丸い',
      borderWidth: '境界線',
      border: '境界線',
      topPadding: 'Top padding',
      fadeIn: 'フェードイン',
      fadeOut: 'フェードアウト',
      backgroundColor: '背景色',
      opacity: '不透明度'
    },
    label: {
      interactions: 'インタラクション',
      menu: 'メニュー',
      jumpTo: 'ジャンプ',
      text: 'テキスト',
      image: '画像',
      pauseScreen: 'ポーズ画面',
      transparentButton: '透明なボタン',
      hightlight: 'ハイライト',
      zoom: 'ズーム',
      linkVideo: 'ビデオリンク',
      currentTime: '現在時刻',
      form: 'フォーム'
    },
    mapKeyAction: {
      1: 'ビデオの特定の時間にジャンプする',
      2: 'ビデオの特定の時間にジャンプする - スケジュール',
      3: 'ビデオの開始位置にジャンプする',
      4: 'ビデオの末尾にジャンプする',
      5: 'インタラクションにジャンプする',
      6: 'ハイパーリンク',
      7: 'プレーを続ける',
      8: 'ノードへジャンプする'
    },
    actions: {
      noAction: 'アクションなし',
      jump_to_specific: 'ビデオの特定の時間にジャンプする',
      jump_to_specific_scheduler: 'ビデオの特定の時間にジャンプする - スケジュール',
      jump_to_start: 'ビデオの開始位置にジャンプする',
      jump_to_end: 'ビデオの末尾にジャンプする',
      jump_to_interaction: 'インタラクションにジャンプする',
      link: 'ハイパーリンク',
      jump_to_node: 'ノードへジャンプする'
    },
    modal: {
      confirmDelete: 'インタラクションを削除してもよろしいですか:　{{ interactionName }}?'
    },
    text: {
      label: {
        title: 'タイトル',
        action: 'アクション',
        jumpTo: 'ジャンプ',
        jumpAt: 'ジャンプ',
        hyperlink: 'ハイパーリンク',
        backgroundColor: '背景色 ',
        textStroke: 'テキストのストローク',
        border: '境界線',
        roundedCorners: '角の丸い',
        topPadding: 'Top padding',
        fadeIn: 'フェードイン',
        fadeOut: 'フェードアウト'
      },
      validate: {
        title: {
          required: 'タイトルが必要',
          max: 'タイトルには {{max}}文字まで含まれる'
        },
        content: {
          required: 'テキストコンテンツが必要',
          max: 'テキストコンテンツには {{max}}文字まで含まれる'
        }
      }
    },
    transparentButton: {
      label: {
        title: 'タイトル',
        action: 'アクション',
        jumpTo: 'ジャンプ',
        jumpAt: 'ジャンプ',
        hyperlink: 'ハイパーリンク',
        border: '境界線',
        roundedCorners: '角の丸い'
      },
      validate: {
        title: {
          required: 'タイトルが必要',
          max: 'タイトルには {{max}} 文字まで含まれる'
        }
      }
    },
    jump: {
      label: {
        title: 'タイトル',
        action: 'アクション',
        jumpTo: 'ジャンプ',
        time_jump_to_node: 'ノードへジャンプする'
      },
      validate: {
        title: {
          required: 'タイトルが必要',
          max: 'タイトルには {{max}} 文字まで含まれる'
        }
      }
    },
    zoom: {
      label: {
        title: 'タイトル',
        zoomPreview: 'プレビュー　ズーム',
        zoomIn: '縮小',
        zoomOut: ' 拡大',
        border: '境界線',
        roundedCorners: '角が丸い'
      },
      validate: {
        title: {
          required: 'タイトルが必要',
          max: 'タイトルには {{max}}文字まで含まれる'
        }
      }
    },
    link: {
      label: {
        title: 'タイトル',
        backgroundColor: '背景色',
        linkedVideo: 'リンクするビデオ',
        videoCode: 'ビデオコード',
        textStroke: 'テキストのストローク',
        border: '境界線',
        roundedCorners: '角の丸い',
        topPadding: 'Top padding',
        fadeIn: 'フェードイン',
        fadeOut: 'フェードアウト'
      },
      validate: {
        title: {
          required: 'タイトルが必要',
          max: 'タイトルには {{max}}文字まで含まれる'
        },
        content: {
          required: 'テキスト コンテンツが必要',
          max: 'テキスト コンテンツ には {{max}}文字まで含まれる'
        }
      }
    },
    menu: {
      fields: {
        title: 'メニューのタイトル',
        menuItems: 'メニュー項目',
        canCloseMenu: ' メニューを閉じる可能性',
        showMenuButton: 'メニューボタンを表示する',
        pauseVideo: 'メニューが表示されたらビデオを一時停止',
        numbering: 'ナンバリング',
        position: {
          title: '位置',
          left: 'メニューを左側にに置き',
          right: 'メニューを右側にに置き',
          middle: 'メニューを真ん中に置き'
        },
        menuButton: {
          buttonName: 'ボタン名',
          location: {
            title: 'メニューボタンの場所',
            top_left: '左上',
            top_right: '右上',
            bottom_left: '左下',
            bottom_right: '右下'
          }
        },
        menuItem: {
          title: 'タイトル',
          action: {
            title: 'アクション',
            jump_to_specific: 'ビデオの特定の時間にジャンプする',
            jump_to_start: 'ビデオの最初にジャンプします',
            jump_to_end: 'ビデオの最後にジャンプします',
            jump_to_interaction: 'インタラクションにジャンプします',
            link: 'ハイパーリンク',
            continue: 'プレーを続ける',
            jump_to_node: 'ノードへジャンプする'
          }
        }
      }
    },
    image: {
      fields: {
        title: 'タイトル',
        action: 'アクション',
        jumpTo: 'ジャンプ',
        jumpTime: 'ジャンプ',
        link: 'リンク',
        image: '画像',
        fadeIn: 'フェードイン',
        fadeOut: 'フェードアウト',
        fullScreen: 'フルスクリーン'
      },
      yourImageHere: '画像はこちら',
      selectingImage: '画像を選択する:',
      interaction: {
        title: 'インタラクション'
      },
      style: {
        title: 'スタイル'
      }
    },
    highlight: {
      fields: {
        title: 'タイトル',
        action: 'アクション',
        jumpTo: 'ジャンプ',
        backgroundColor: '背景色',
        jumpTime: 'ジャンプ',
        link: 'リンク',
        fadeIn: 'フェードイン',
        fadeOut: 'フェードアウト'
      }
    },
    pause: {
      fields: {
        title: 'タイトル',
        content: 'コンテンツ',
        sound: 'サウンド',

        playSound: 'サウンドを再生する',
        fadeSound: 'オーディオのフェイドイン・アウト',
        activeSound: 'アクティブサウンド'
      },
      selectASound: 'サウンドを選択する'
    },
    form: {
      fields: {
        title: 'フォームのタイトル',
        firstField: '最初のフィールドのタイトル',
        secondField: '二つ目のフィールドのタイトル',
        thirdField: '三つ目のフィールドのタイトル',
        emailField: 'メールフィールドのタイトル',
        receiveEmail: '誰がこの入力を受けるべきか',
        genericFeedback: '一盤的なフィードバック',
        fileUpload: 'メッセジーファイルをアップロードする',
        feedbackScreen: 'フィードバック画面',
        enableBackground: 'フィードバック画面のカラー表示を有効にする',
        enableFeedback: '自動フィードバックを有効にする(ありがとうございました！などです)',
        fadeIn: 'フェイドイン',
        fontSize: 'フォントサイズ'
      }
    }
  },

  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path}は無効です',
      required: '${path}は必要です',
      oneOf: '${path}は 次の一つの値${values}である必要があります',
      notOneOf: '${path}は次の一つの値${values}である必要がありません'
    },
    string: {
      length: '${path} は正確に${length}の文字である',
      min: '${path} は${min} 以下の文字でなければなりません',
      max: '${path} は${max}以上の文字でなければなりません',
      matches: '${path} は次の"${regex}"と一致することが必要です',
      email: '${path} は有効なメールである必要があります',
      url: '${path}は有効なURLでなければなりません',
      trim: '${path} はトリムされた文字例でなければなりません',
      lowercase: '${path}は小文字である必要があります',
      uppercase: '${path}は大文字である必要があります',
      selected: '${path}を選択する必要があります'
    },
    number: {
      min: '${path} は${min}以上でなければなりません',
      max: '${path} は${max}以下でなければなりません',
      lessThan: '${path} は${less}より小さい必要があります',
      moreThan: '${path} は ${more}より大きい必要があります',
      notEqual: '${path} は ${notEqual}等しくない必要があります',
      positive: '${path} は正の数字であることが必要です',
      negative: '${path} は負数であることが必要です',
      integer: '${path} は整数であることが必要です'
    },
    date: {
      min: '${path} フィールド は${min}よりも後のものである必要があります',
      max: '${path} フィールドは ${max}よりも前のものである必要があります'
    },
    boolean: {},
    object: {
      noUnknown: '${path} フィールドは指定されないShape オブジェクトが含まれるキーを持つことができません'
    }
  },
  /* eslint-disable */
  // Auth
  auth: {
    label: {
      otp: 'OTP',
      email: 'メール',
      password: 'パスワード',
      passwordConfirm: 'パスワードの確認',
      forgotPasswordCaption:
        '心配はいりません. パスワードをリセットすることは簡単です。{{origin}}に登録したメールアドレスを教えるだけで済みます',
      resetPasswordCaption:
        'パスワードがリセットしたOTPを {{email}}へお送りしております。新しいパスワードを設定するには、メールをチェックし、OTPを受け取ってくださいますようお願い申し上げます',

      login: 'ログイン',
      loginTitle: 'ログイン - WAOCON',
      forgotPassword: 'パスワードを忘れた場合',
      forgotPasswordTitle: 'パスワードを忘れた場合 - WAOCON',
      resetPassword: 'パスワードのリセット',
      resetPasswordTitle: 'パスワードのリセット - WAOCON',
      forgotPasswordQs: 'パスワードを忘れましたか',
      backToLogin: 'ログインページへ戻る',
      resendOTP: 'OTPの再送',
      resendOTPMsg: 'メールへOTPをお送りしております'
    },

    validate: {
      otp: {
        required: 'OTPは必要です',
        format: 'OTPは六つの文字で構成されています'
      },

      email: {
        required: 'メールは必要',
        format: '有効なメールを入力してくださいますようお願い申し上げます'
      },

      password: {
        required: 'パスワードは必要です',
        min: 'パスワードには{{min}} 以上の文字を指定する必要があります',
        max: 'パスワードは{{max}}の文字まで指定することができます',
        format: 'パスワードに無効な文字があります',
        confirm: 'パスワードの確認は入力したパスワードと一致しません'
      }
    },

    request: {
      login: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'ロイング成功',
        failure: 'メールやパスワードが違います'
      },

      forgotPassword: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'パスワードお忘れが完了しました',
        failure: 'メールは存在しません'
      },

      resetPassword: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'パスワードを変更しました',
        failure: 'OTPは無効であるかまたは期限切れである'
      }
    }
  },

  account: {
    label: {
      name: 'ネーム',
      email: 'メール',
      password: 'パスワード',
      passwordConfirm: 'パスワードの確認',
      role: 'ロール',

      createAccount: 'アクアウントの作成',
      updateAccount: 'アクアウントの更新'
    },

    validate: {
      name: {
        required: 'ネームは必要です',
        max: 'ネームは {{max}}の文字まで指定することができます',
        format: '有効なネームを入力してくださいますようお願い申し上げます'
      },

      email: {
        required: 'メールは必要です',
        format: '有効なメールを入力してくださいますようお願い申し上げます'
      },

      password: {
        required: 'パスワードは必要です',
        passwordConfirm: 'パスワードの確認を空白にすることはできません',
        min: 'パスワードには{{min}} 以上の文字を指定する必要があります',
        max: 'パスワードは{{max}}の文字まで指定することができます',
        format: 'パスワードに無効な文字があります',
        confirm: 'パスワードの確認は入力したパスワードと一致しません'
      },

      role: {
        required: 'ロールは必要です',
        format: '有効なロールを入力してくださいますようお願い申し上げます'
      }
    },

    request: {
      create: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'アクアウントを作成しました',
        failure: 'アクアウントを作成できません'
      },

      update: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'アクアウントを更新しました',
        failure: 'アクアウントを更新できません'
      },

      detail: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'アクアウント情報を更新しました',
        failure: 'アクアウント情報を更新できません'
      },

      delete: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'アクアウントを削除しました',
        failure: 'アクアウントを削除できません'
      }
    },

    confirm: {
      delete: '{{name}}のアクアウントを削除しますか'
    }
  },

  projectDetail: {
    label: {
      cancelUploadMedia: 'メディアアップロードはキャンセルしました',
      mediaExisted: '次のファイルは既にライブラリに入っております',

      folderName: 'フォルダ名',
      create: '作成',
      newFolder: '新しいフォルダ',
      createNewFolder: '新しいフォルダの作成'
    },

    validate: {
      folderName: {
        required: 'フォルダ名は必要です',
        max: 'フォルダ名は {{max}} の文字まで指定することができます'
      }
    },

    request: {
      fetch: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'プロジェクトをフェッチしました',
        failure: 'プロジェクトをフェッチできません'
      },

      uploadMedia: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'メディアをアップロードしました',
        failure: 'メディアをアップロードできません',
        cancel: 'メディアアップロードはキャンセルしました'
      },

      createFolder: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'フォルダを作成しました',
        failure: 'フォルダを作成できません'
      },

      deleteMedia: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'メディアを削除しました',
        failure: 'メディアを削除できません'
      },

      deleteFolder: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'フォルダを削除しました',
        failure: 'フォルダを削除できません'
      }
    },

    confirm: {
      deleteMedia: ' {{mediaName}}というメディアを削除しますか?',
      deleteFolder: '{{folderName}}というフォルダを削除しますか?'
    }
  },

  model: {
    account: {
      name: 'ネーム',
      email: 'メール',
      role: 'ロール'
    }
  },

  action: {
    title: 'アクション',
    add: '追加',
    update: '更新',
    delete: '削除'
  },

  breadcrumbs: {
    dashboard: 'ダッシュボード',
    project: 'プロジェクト',
    video: 'ビデオ',
    accounts: {
      title: 'アクアウント',
      list: {
        title: 'アクアウント'
      },
      create: {
        title: 'アクアウント作成'
      },
      update: {
        title: 'アクアウント更新'
      }
    }
  },

  sidebar: {
    management: '管理',
    account: {
      title: 'アクアウント',
      edit: 'カウント情報の編集',
      list: 'アクアウントリスト',
      create: 'アクアウント作成'
    },
    allYourProject: '全てのプロジェクト',
    projectProcessManagement: 'プロジェクト進捗管理'
  },

  projectSidebar: {
    library: 'ライブラリ',
    search: 'サーチ',
    designPresets: 'デザインプリセット',
    menuLayer: 'レイヤーメニュー',
    backgroundSoundtrack: '背景音楽',

    libraryMsg: 'アッセットのプレビューはありません'
  },

  publicSettings: {
    title: '公開設定',
    basicInfo: {
      basicInfo: '基本情報',
      title: 'タイトル',
      author: '著者',
      description: '説明',
      tags: 'タグ',
      saveAndApply: '保存して適用',
      cancel: 'Cancel',

      validate: {
        title: {
          max: 'タイトルは{{max}}の文字まで指定することができます'
        },
        author: {
          max: '著者は{{max}} の文字まで指定することができます'
        },
        description: {
          max: '{{max}} の文字まで指定することができます'
        }
      },

      request: {
        fetch: {
          validate: 'エラーが起きました。 情報をご確認お願い致します',
          success: '基本情報をフェッチしました',
          failure: '基本情報をフェッチできません'
        },
        update: {
          validate: 'エラーが起きました。 情報をご確認お願い致します',
          success: '基本情報を更新しました',
          failure: '基本情報を更新できません'
        }
      }
    },
    privacy: {
      privacy: 'プライバシー',
      public: '公開',
      private: 'プライベート',
      password: 'パスワード',
      publicDesc: 'どなたでもプロジェクトをご覧可能でございます',
      privateDesc:
        'ほかのユーザにプロジェクトを閲覧したり、プロパティを共有できないようにパスワードを設定してくださいますようお願い申し上げま。プライベートなプロジェクトは常に非公開になります',
      validate: {
        isPublic: {},
        password: {
          required: 'パスワードを空白にすることはできません'
        }
      }
    },
    sharing: {
      sharing: '共有中'
    },
    advancedSettings: {
      advancedSettings: '詳細設定',
      autoplay: '自動再生',
      defaultVolume: 'デフォルトのボリューム'
    }
  },

  profileMenu: {
    profileMenu: 'プロファイルメニュー',
    hi: 'こんにちは、',
    logout: 'ログアウト',
    myProjects: 'マイプロジェクト',
    myAccount: 'カウント情報の編集'
  },

  publishManager: {
    label: {
      publishManager: 'マネジャーのパリッシュ',
      publishNewRevision: '新しいレビジョンのパリッシュ',
      copyLink: 'リンクをコピー',
      copiedToClipboard: 'クリップボードにコピーしました',
      videoBeingProcessed: 'ビデオを処理しています'
    },

    validate: {},

    request: {
      newRevision: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: '新しいレビジョンをパリッシュしました',
        failure: '新しいレビジョンをパリッシュできません'
      }
    }
  },

  projectPreview: {
    label: {
      projectPreview: 'プロジェクトプレビュー'
    },

    validate: {},

    request: {
      getPreview: {
        validate: 'エラーが起きました。 情報をご確認お願い致します',
        success: 'プレビューを利用しました',
        failure: 'プレビューを利用できません'
      }
    }
  },

  interactions: {
    label: {
      interactions: 'インタラクション',
      menu: 'メニュー',
      jumpTo: 'ジャンプする',
      text: 'テキスト',
      image: 'イメージ',
      pauseScreen: 'ポーズ画面',
      transparentButton: '透明ボタン',
      hightlight: 'ハイライト',
      zoom: 'ズーム',
      linkVideo: '動画リンク',
      currentTime: '現在時刻'
    }
  },

  googleAnalyticsActions: {
    categories: {
      video: 'ビデオ',
    },

    actions: {
      playVideo: 'ビデオを再生',
      link: 'リンク',
      menu: 'メニュー',
      form: 'フォーム',
      zoom: 'ズーム',
      pauseScreen: 'ポーズ画面',
      noAction: 'アクションなし'
    }
  }
};
