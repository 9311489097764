import { FormControl, FormLabel, Select, MenuItem, Grid, FormHelperText, Tooltip, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import _get from 'lodash/get';
import HelpIcon from '@material-ui/icons/HelpOutline';

const SelectField = ({
  name,
  control,
  formState,
  error,
  label,
  tooltipMsg,
  inputProps,
  helperText,
  options,
  labelProps,
  layout,
  disabled,
  handleChange,
  ...props
}) => {
  const { errors = {} } = formState;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl
          fullWidth
          disabled={disabled}
          error={error || !!_get(errors, `${name}.message`)}
          helperText={helperText || _get(errors, `${name}.message`)}
        >
          <Grid container {...layout.grid}>
            <Grid item {...layout.label}>
              {!!label && (
                <FormLabel {...labelProps}>
                  {label}
                  {tooltipMsg && (
                    <Tooltip title={tooltipMsg}>
                      <IconButton sx={{ p: 0, ml: 1 }}>
                        <HelpIcon sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </FormLabel>
              )}
            </Grid>
            <Grid item {...layout.input}>
              <Select
                displayEmpty
                defaultValue=""
                fullWidth
                size="small"
                {...props}
                value={field.value}
                onChange={(evt) => {
                  field.onChange(evt.target.value);
                  handleChange({ [name]: evt.target.value });
                }}
                disabled={disabled}
                error={error || !!_get(errors, `${name}.message`)}
                helperText={helperText || _get(errors, `${name}.message`)}
              >
                {options &&
                  options.map((item) => (
                    <MenuItem
                      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      value={item.value}
                      key={item.value}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item {...layout.label} />
                <Grid item {...layout.input}>
                  <FormHelperText sx={{ mx: 0 }}>{helperText || _get(errors, `${name}.message`)}</FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      )}
    />
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,

  error: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,

  inputProps: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  tooltipMsg: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string,
  labelProps: PropTypes.object,
  layout: PropTypes.object,
  handleChange: PropTypes.func
};

SelectField.defaultProps = {
  error: false,
  required: false,
  autoFocus: false,
  disabled: false,

  inputProps: null,
  type: '',
  label: '',
  tooltipMsg: '',
  helperText: '',
  autoComplete: '',
  layout: {
    grid: {
      my: 1
    },
    label: {
      xs: 12
    },
    input: {
      xs: 12
    }
  },
  handleChange: () => null
};

export default SelectField;
