import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { DraggingTypes } from 'src/actions/dragging';

const INITIAL_STATE = {
  dragItem: null,
  dragType: null,
  dropItem: null,
  dropType: null,
  freeze: false
};

const resetRequest = () => INITIAL_STATE;

const setDragItem = (state = INITIAL_STATE, action) => ({
  ...state,
  dragItem: action.item,
  dragType: action.dragType
});

const setDropItem = (state = INITIAL_STATE, action) => ({
  ...state,
  dropItem: action.item,
  dropType: action.dropType
});

const setFreeze = (state = INITIAL_STATE, action) => ({
  ...state,
  freeze: action.freeze
});
export const HANDLERS = {
  [DraggingTypes.DRAGGING_SET_DRAG_ITEM]: setDragItem,
  [DraggingTypes.DRAGGING_SET_DROP_ITEM]: setDropItem,
  [DraggingTypes.DRAGGING_RESET_REQUEST]: resetRequest,
  [DraggingTypes.DRAGGING_SET_FREEZE]: setFreeze
};

export const dragging = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'dragging',
  storage
};

export default persistReducer(persistConfig, dragging);
