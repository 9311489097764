/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Box, Typography, makeStyles, Button, IconButton, List, Paper } from '@material-ui/core';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as videoPlayerSelectors from 'src/selectors/videoPlayer';
import videoController from 'src/utils/videoController';
import { INTERACTION } from 'src/constants/interactions';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { compareByOrderProperty } from 'src/utils/compareArray';
import { GA_CATEGORIES, GA_ACTION } from 'src/utils/getInitials';

import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const useStyles = makeStyles(() => ({
  title: {
    padding: '0px 20px',
    overflow: 'hidden',
    lineClamp: '1',
    textOverflow: 'ellipsis',
    maxWidth: '90%'
  }
}));

const GOLD_RATIO = 30720;

const getMenuButtonCoordinate = (location) => {
  const padding = 5;
  switch (location) {
    case INTERACTION.MENU.MENU_BUTTON.LOCATION.BOTTOM_RIGHT: {
      return { bottom: padding, right: padding };
    }
    case INTERACTION.MENU.MENU_BUTTON.LOCATION.BOTTOM_LEFT: {
      return { bottom: padding, left: padding };
    }
    case INTERACTION.MENU.MENU_BUTTON.LOCATION.TOP_RIGHT: {
      return { top: padding, right: padding };
    }
    case INTERACTION.MENU.MENU_BUTTON.LOCATION.TOP_LEFT: {
      return { top: padding, left: padding };
    }
    default:
      return { top: padding, right: padding };
  }
};

export const MenuInteractionDisplay = ({ config, videoBounding, isEditting, isPublishMode }) => {
  // flag open menu
  const [open, setOpen] = useState(false);
  const isMobile = useCheckMobileScreen();
  // flag whether show menu again or not
  // const [disable, setDisable] = useState(false);

  const { t } = useTranslation();
  const [showMenuButton, setShowMenuButton] = useState(
    config.show_menu !== INTERACTION.MENU.MENU_BUTTON.LOCATION.HIDDEN
  );

  // flag menu trigger by click menu button
  const [manuallyTrigger, setManuallyTrigger] = useState(false);
  const videoControl = videoController.getInstance();
  const FONTSIZE = useMemo(() => (videoBounding.width * videoBounding.height) / GOLD_RATIO, [videoBounding]);
  const FONTSIZE_MOBILE = 16;
  const width = useMemo(() => videoBounding.width / 2, [videoBounding]);
  const currentTime = useSelector(videoPlayerSelectors.getCurrentTime);
  const classes = useStyles();
  const { videoData, videoDuration } = useVideoPlayer();

  useEffect(() => {
    const isTriggered = manuallyTrigger;
    // (manuallyTrigger || (currentTime >= config.start_time && currentTime < config.end_time)) && !disable;
    if (isTriggered) {
      if (config.pause) {
        videoControl.pause();
      }
      setOpen(true);
    } else {
      setOpen(false);
      // fix when start_time == 0
      // if (currentTime <= config.start_time) {
      //   setDisable(false);
      // }
    }
  }, [manuallyTrigger]);

  useEffect(() => {
    setShowMenuButton(
      config.show_menu !== INTERACTION.MENU.MENU_BUTTON.LOCATION.HIDDEN &&
        currentTime >= config.start_time &&
        currentTime < config.end_time
    );

    if (!showMenuButton && currentTime < config.start_time && manuallyTrigger) {
      setOpen(false);
      setManuallyTrigger(false);
    }
  }, [currentTime, config.show_menu, config.end_time, config.start_time]);
  const onMenuButtonClick = useCallback(() => {
    if (isPublishMode) {
      ReactGA.event({
        category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
        action: t('googleAnalyticsActions.actions.menu'),
        label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.title}`,
        value: videoData.id
      });
    }
  }, [isPublishMode]);
  const onItemClick = useCallback(
    (item) => {
      let type;
      if (item.jump) {
        type = item.jump.type;
      } else if (item.link !== undefined && item.link !== null) {
        type = INTERACTION.MENU.MENU_ITEM.ACTION.LINK;
      } else {
        type = INTERACTION.MENU.MENU_ITEM.ACTION.CONTINUE;
      }
      if (isPublishMode) {
        ReactGA.event({
          category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
          action:
            (config.link && t(`googleAnalyticsActions.actions.link`)) ||
            (type && t(`interaction.mapKeyAction.${type}`)) ||
            t(`googleAnalyticsActions.actions.noAction`),
          label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.title} - ${item.title}`,
          value: videoData.id
        });
      }

      switch (type) {
        case INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_END:
        case INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_START: {
          if (isPublishMode) {
            videoControl.seek(item.jump.product_jump_to);
          } else {
            videoControl.seek(item.jump.jump_to);
          }
          videoControl.play();
          // setDisable(true);
          setManuallyTrigger(false);
          break;
        }
        case INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_SPECIFIC: {
          videoControl.seek(item.jump.jump_to);
          videoControl.play();
          // setDisable(true);
          setManuallyTrigger(false);
          break;
        }
        case INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_NODE: {
          if (isPublishMode) {
            videoControl.seek(parseInt(item.jump.time_jump_to_node || 0, 10) + (item.jump.product_jump_to || 0));
            videoControl.play();
            // setDisable(true);
            setManuallyTrigger(false);
          }
          break;
        }
        case INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_INTERACTION: {
          if (!isEditting) {
            videoControl.seek(item.jump.jump_to);
            videoControl.play();
            // setDisable(true);
            setManuallyTrigger(false);
          }
          break;
        }
        case INTERACTION.MENU.MENU_ITEM.ACTION.CONTINUE: {
          videoControl.play();
          // setDisable(true);
          setManuallyTrigger(false);
          break;
        }
        case INTERACTION.MENU.MENU_ITEM.ACTION.LINK: {
          window.open(item.link, '_blank');
          break;
        }
        default:
      }
    },
    [isPublishMode, isEditting]
  );

  const menuPositionProps = useMemo(() => {
    switch (config.position) {
      case INTERACTION.MENU.POSITION.MIDDLE: {
        return {
          position: 'absolute',
          top: open ? 0 : -width,
          left: (videoBounding.width - width) / 2,
          width,
          height: videoBounding.height,
          transition: 'top 0.2s linear'
        };
      }
      case INTERACTION.MENU.POSITION.LEFT: {
        return {
          position: 'absolute',
          top: 0,
          left: open ? 0 : -width,
          height: videoBounding.height,
          width,
          transition: 'left 0.2s linear'
        };
      }
      case INTERACTION.MENU.POSITION.RIGHT: {
        return {
          position: 'absolute',
          top: 0,
          right: open ? 0 : -width,
          height: videoBounding.height,
          width,
          transition: 'right 0.2s linear'
        };
      }
      default:
        return {};
    }
  }, [open, config, videoBounding]);

  return (
    <>
      {showMenuButton && (
        <Box sx={{ position: 'absolute', ...getMenuButtonCoordinate(config.show_menu), maxWidth: '50%' }}>
          <Button
            sx={{
              maxWidth: '100%',
              minWidth: '32px',
              borderRadius: '16px',
              bgcolor: '#1D475A',
              px: 1,
              py: '2px',
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                svg: {
                  color: '#1D475A'
                },
                '.MuiTypography-root': {
                  color: '#1D475A'
                },
                bgcolor: 'white'
              }
            }}
            onClick={() => {
              setManuallyTrigger(true);
              onMenuButtonClick();
              // setDisable(false);
            }}
          >
            <MenuIcon sx={{ color: 'white', fontSize: isMobile ? FONTSIZE_MOBILE : FONTSIZE }} />
            {!!config.button_name && (
              <Typography
                color="white"
                sx={{ fontSize: isMobile ? FONTSIZE_MOBILE - 4 : FONTSIZE, maxWidth: '100%' }}
                noWrap
              >
                {config.button_name}
              </Typography>
            )}
          </Button>
        </Box>
      )}
      <Box
        // className="waocon-interaction-bounding-box"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          opacity: open ? 1 : 0,
          visibility: open ? 'visible' : 'hidden',
          transition: 'opacity 0.2s linear, visibility 0.2s linear',
          // menu on top other interaction
          zIndex: 2
        }}
      >
        <Box
          sx={{
            ...menuPositionProps,
            boxShadow: `0 0 0 ${videoBounding.width * 2}px rgba(0,0,0,0.6)`
          }}
        >
          <Box
            sx={{
              height: '10%',
              py: '8px',
              width: '100%',
              display: 'flex',
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#1D475A'
            }}
          >
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
              <Typography
                color="white"
                noWrap
                className={classes.title}
                sx={{ fontSize: isMobile ? FONTSIZE_MOBILE * 1.1 : FONTSIZE * 1.1, fontWeight: 600 }}
              >
                {config.title}
              </Typography>
            </Box>

            {!!config.close_menu && (
              <IconButton
                // sx={{ pr: '2px' }}
                onClick={() => {
                  // setDisable(true);
                  setManuallyTrigger(false);
                  videoControl.play();
                }}
              >
                <CloseIcon sx={{ color: 'white', fontSize: isMobile ? FONTSIZE_MOBILE : FONTSIZE }} />
              </IconButton>
            )}
          </Box>

          <Box
            sx={{
              height: `90%`,
              flexFlow: 'column',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'white',
              pb: '16px',
              pt: '4px',
              px: '10px'
            }}
          >
            <List style={{ maxHeight: '100%', overflow: 'auto', width: '100%' }}>
              {config?.items?.sort(compareByOrderProperty).map((item, idx) => (
                <Button
                  // key={idx.toString()}
                  sx={{
                    display: 'inline-flex',
                    color: 'black',
                    width: '90%',
                    borderRadius: '2px',
                    m: '4px',
                    py: '4px',
                    minHeight: '30px',
                    '&:hover': {
                      bgcolor: '#1D475A',
                      color: 'white',
                      boxShadow: '0px 5px 18px -6px #000000'
                    },
                    justifyContent: config.number ? 'flex-start' : 'flex-start'
                  }}
                  onClick={() => onItemClick(item)}
                >
                  <Typography
                    fontSize={isMobile ? FONTSIZE_MOBILE : FONTSIZE}
                    sx={{
                      overflow: 'auto',
                      msOverflowStyle: 'none',
                      scrollbarWidth: 'none',
                      whiteSpace: 'nowrap',
                      '&::-webkit-scrollbar': {
                        height: isMobile ? '4px' : '0.8rem'
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#f1f1f190',
                        borderRadius: '2px'
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: '#888',
                        borderRadius: '2px'
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        background: '#555'
                      }
                    }}
                  >
                    {config.number ? `${idx + 1}. ${item.title}` : item.title}
                  </Typography>
                </Button>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </>
  );
};

MenuInteractionDisplay.propTypes = {
  videoBounding: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isPublishMode: PropTypes.bool
};

MenuInteractionDisplay.defaultProps = {
  isPublishMode: false
};
