/* eslint-disable no-unused-vars */
import {
  Alert,
  Box,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as ProjectDetailActions from 'src/actions/projectDetail';
import { useModal } from 'src/hooks/useModal';
import UploadMediaProgress from 'src/pages/project-sidebar/components/UploadMediaProgress';
import * as ProjectDetailSelectors from 'src/selectors/projectDetail';
import { validate } from 'uuid';
import './SelectSoundModal.css';

const useStyles = makeStyles(() => ({
  itemRoot: {
    '&.Mui-selected': {
      backgroundColor: '#B7E7E8',
      '&:hover': {
        backgroundColor: '#87CDCF'
      }
    }
  }
}));

const SelectSoundModal = ({ modalName, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isShowing, closeModal } = useModal(modalName);
  const dispatch = useDispatch();
  const [selectedSound, setSelectedSound] = useState(null);
  const listSound = useSelector(ProjectDetailSelectors.getListSound);
  const projectID = useSelector(ProjectDetailSelectors.getProjectID);
  const uploadMediaQueueState = useSelector(ProjectDetailSelectors.getUploadMediaQueueState);
  const inputFileRef = useRef(null);
  const [inputFile, setInputFile] = useState('');

  const onConfirm = useCallback(() => {
    onChange(selectedSound);
    closeModal(modalName);
  }, [closeModal, modalName, selectedSound]);
  const handleClose = useCallback(() => {
    closeModal(modalName);
  }, [modalName]);

  const handleUploadMedia = useCallback(
    (evt) => {
      const { files: _files } = evt.target;

      onUploadMedia({
        files: [_files[0]],
        projectID
      });
      setInputFile('');
    },
    [projectID]
  );

  const onUploadMedia = useCallback(
    async ({ files, folderID = null, projectID: _projectID, replace = false }) => {
      try {
        const index = await dispatch(
          ProjectDetailActions.uploadMediaRequest({ files, folderID, projectID: _projectID, replace })
        );
        await dispatch(ProjectDetailActions.uploadMediaSuccess());
        dispatch(ProjectDetailActions.removeUploadMediaQueue(index));
        dispatch(ProjectDetailActions.autoFetchRequest(projectID));
      } catch (err) {
        if (validate(err.message)) {
          dispatch(ProjectDetailActions.removeUploadMediaQueue(err.message));
        }
        dispatch(ProjectDetailActions.uploadMediaFailure());
      }
    },
    [projectID]
  );

  return (
    <>
      <Dialog open={isShowing} onClose={() => handleClose()} scroll="paper" maxWidth="sm" fullWidth>
        <DialogTitle
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            backgroundColor: '#87CDCF'
          }}
          id={`title-${modalName}`}
        >
          {t('common.actions.selectSound')}
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 4 }}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <Box sx={{ m: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-start' }}>
                {t('common.actions.uploadSound')}
                <Button
                  disabled={uploadMediaQueueState.length}
                  color="primary"
                  onClick={() => inputFileRef.current.click()}
                  variant="contained"
                >
                  <input
                    type="file"
                    accept=".mp3"
                    ref={inputFileRef}
                    value={inputFile}
                    name="media"
                    style={{ display: 'none' }}
                    onChange={handleUploadMedia}
                  />
                  {t('common.actions.browse')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={8}>
              {uploadMediaQueueState.length ? (
                <Box sx={{ mt: 4 }}>
                  <Box>
                    <UploadMediaProgress value={uploadMediaQueueState[0]?.progress} sx={{ height: '20px' }} />
                  </Box>
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">{t('interaction.alertSoundFormat')}</Alert>
            </Grid>
            <Grid item xs={4} sx={{ mt: 1 }}>
              <Typography variant="h6">{t('interaction.pause.selectASound')}</Typography>
            </Grid>
          </Grid>
        </Box>

        <DialogContent dividers sx={{ p: 0 }}>
          <List component="nav">
            {listSound
              .slice(0)
              .reverse()
              .map((sound) => (
                <ListItem
                  key={sound.id}
                  className={classes.itemRoot}
                  button
                  selected={selectedSound?.id === sound.id}
                  onClick={() => setSelectedSound(sound)}
                >
                  <ListItemIcon>
                    <MusicNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary={sound.name} primaryTypographyProps={{ noWrap: true }} />
                  <Box className="select-sound-modal">
                    <audio
                      controls
                      controlsList="nodownload noremoteplayback"
                      style={{
                        maxWidth: '200px',
                        height: '32px',
                        borderRadius: '4px'
                      }}
                    >
                      <source key={sound.id} type="audio/mp3" src={`${process.env.REACT_APP_PUBLIC_URL}${sound.url}`} />

                      <track kind="captions" />
                    </audio>
                  </Box>
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center'
          }}
        >
          <Button sx={{ marginRight: '24px' }} color="secondary" variant="outlined" onClick={() => handleClose()}>
            {t('common.actions.dismiss')}
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm}>
            {t('common.actions.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SelectSoundModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SelectSoundModal;
