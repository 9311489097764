export const CompareScalarValuesArray = (array1, array2) =>
  array1.length === array2.length && array1.every((value, index) => value === array2[index]);

export const compareByOrderProperty = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};
