import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from 'src/actions/projectNode';
import { fields as _fields } from 'src/models/node';
import { FormSchema } from 'src/models/schema';
import * as Selectors from 'src/selectors/projectNode';
import * as ProjectSelectors from 'src/selectors/projectDetail';
import { objectSpecialEqual } from 'src/utils/objectCompare';
import * as DraggingSelectors from 'src/selectors/dragging';
import * as DraggingActions from 'src/actions/dragging';

export const useScreenNodeSettings = (nodeID) => {
  const { t } = useTranslation();
  const fields = _fields(t);
  const dispatch = useDispatch();

  const node = useSelector((state) => Selectors.getSelectedNode(state, nodeID));
  const listImage = useSelector(ProjectSelectors.getListImage);
  // fetch
  useEffect(() => {
    dispatch(Actions.fetchNodeRequest(nodeID));
  }, [nodeID]);

  // update
  const getAutoCompleteImageValue = useCallback((imageID, _listImage) => {
    const image = _listImage.find((img) => `${img.id}` === `${imageID}`);
    return image
      ? {
          id: image.id,
          name: image.name
        }
      : { id: null, name: 'None' };
  }, []);
  const imageOptions = useMemo(() => [{ id: null, name: 'None' }, ...listImage], [listImage]);
  const { schema } = useMemo(() => new FormSchema(fields.id, [fields.name, fields.screenAsset]), []);
  const getDefaultValues = useCallback(
    (_node, _listImage) => ({
      [fields.name.name]: _node?.name || '',
      [fields.screenAsset.name]: getAutoCompleteImageValue(_node.image_id, _listImage)
    }),
    []
  );
  const { formState, control, reset, handleSubmit } = useForm({
    defaultValues: getDefaultValues(node, listImage),
    resolver: schema,
    reValidateMode: 'onBlur'
  });

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );
  const resetDefault = useCallback((_node, _listImage) => {
    reset(getDefaultValues(_node, _listImage), {
      keepErrors: false,
      keepDirty: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false
    });
  }, []);

  useEffect(() => {
    resetDefault(node, listImage);
  }, [node, listImage]);

  const onUpdate = useCallback(
    async (formData) => {
      // Them dong nay vi yup validate number bi sida, van chap nhan vd nhu 0.124a
      // under the hood la do no parse 0.124a thanh 0.124, vi vay can reset lai de hien thi dung gia tri
      resetDefault(formData, listImage);
      if (!objectSpecialEqual(formData, node)) {
        await dispatch(Actions.updateRequest({ id: node.id, ...formData }));
      }
    },
    [node, formState, listImage]
  );

  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      resetDefault();
    }
  }, [formState, resetDefault]);

  // Drag and drop
  const DnDMode = useSelector(DraggingSelectors.getDnDMode);
  const DnDModeProps = useMemo(
    () =>
      DnDMode
        ? {
            onMouseEnter: () => dispatch(DraggingActions.setDropItem({ item: node, dropType: 'image' })),
            onMouseLeave: () => dispatch(DraggingActions.setDropItem({ item: null, dropType: null }))
          }
        : {},
    [DnDMode]
  );
  return {
    t,
    node,
    formState,
    formItems,
    onUpdate,
    handleSubmit,
    resetDefault,
    imageOptions,
    control,
    DnDModeProps
  };
};
