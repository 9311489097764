import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';

export const AuthTypes = createTypes(`
  LOGIN_REQUESTING
  LOGIN_SUCCESS
  LOGIN_FAILURE

  FORGOT_PASSWORD_REQUESTING
  FORGOT_PASSWORD_SUCCESS
  FORGOT_PASSWORD_FAILURE

  RESET_PASSWORD_REQUESTING
  RESET_PASSWORD_SUCCESS
  RESET_PASSWORD_FAILURE

  LOGOUT
`);

// Login action
export const loginSuccess = (user) => ({ type: AuthTypes.LOGIN_SUCCESS, user });

export const loginFailure = () => ({
  type: AuthTypes.LOGIN_FAILURE
});

export const loginRequest = ({ email, password }) => async (dispatch) => {
  dispatch({ type: AuthTypes.LOGIN_REQUESTING });
  return axiosClient.post('/auth/login', { email, password });
};

// Forgot password action
export const forgotPasswordSuccess = () => ({ type: AuthTypes.FORGOT_PASSWORD_SUCCESS });

export const forgotPasswordFailure = () => ({
  type: AuthTypes.FORGOT_PASSWORD_FAILURE
});

export const forgotPasswordRequest = ({ email }) => async (dispatch) => {
  dispatch({ type: AuthTypes.FORGOT_PASSWORD_REQUESTING });
  return axiosClient.post('/auth/reset-password', { email });
};

// Reset password action
export const resetPasswordSuccess = () => ({ type: AuthTypes.RESET_PASSWORD_SUCCESS });

export const resetPasswordFailure = () => ({
  type: AuthTypes.RESET_PASSWORD_FAILURE
});

export const resetPasswordRequest = ({ otp, password }) => async (dispatch) => {
  dispatch({ type: AuthTypes.RESET_PASSWORD_REQUESTING });
  return axiosClient.post('/auth/reset-password?_method=PUT', { token: otp, password });
};

// Logout action
export const logoutRequest = () => ({ type: AuthTypes.LOGOUT });
