/* eslint-disable */
import { Box, Button, FormLabel, Slider, Switch } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { usePublishSettings } from 'src/hooks/publishSettings';

const AdvanceSettings = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const { publishState, onUpdate } = usePublishSettings();

  const initialForm = useMemo(() => {
    const { default_volume, autoplay } = publishState || {};

    return {
      default_volume: default_volume || 0,
      autoplay: autoplay || false
    };
  }, [publishState]);

  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: initialForm
  });

  const { isDirty } = formState;

  const onSubmit = useCallback(async (formValues) => {
    const { default_volume, autoplay } = formValues;
    const publish = await onUpdate({ id, default_volume, autoplay });
    reset({ default_volume: publish.default_volume, autoplay: publish.autoplay });
  });

  const handleCancelClick = useCallback(() => {
    reset(initialForm);
  }, [initialForm, publishState]);

  return (
    <form noValidate>
      <>
        <Controller
          name="autoplay"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ mb: 2 }}>
              <FormLabel sx={{ display: 'block' }}>{t('publicSettings.advancedSettings.autoplay')}</FormLabel>
              <Switch edge="start" size="small" checked={value} onChange={onChange} />
            </Box>
          )}
        />
      </>

      <>
        <Controller
          name="default_volume"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box>
              <FormLabel>{t('publicSettings.advancedSettings.defaultVolume')}</FormLabel>
              <Slider
                sx={{ width: '100%' }}
                valueLabelDisplay="auto"
                size="small"
                value={value}
                onChange={(_, value) => onChange(value)}
              />
            </Box>
          )}
        />
      </>

      <Box sx={{ display: isDirty ? '' : 'none' }}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)} sx={{ mr: 1 }}>
          {t('publicSettings.basicInfo.saveAndApply')}
        </Button>
        <Button variant="contained" onClick={handleCancelClick} sx={{ ml: 1 }}>
          {t('publicSettings.basicInfo.cancel')}
        </Button>
      </Box>
    </form>
  );
};

export default AdvanceSettings;
