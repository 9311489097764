import { INTERACTION } from 'src/constants/interactions';
import StringField from 'src/models/fields/stringField';
import { hmsmsToSeconds } from 'src/utils/timelineFormat';
import BooleanField from './fields/booleanField';
import EnumeratorField from './fields/enumeratorField';
import IdField from './fields/idField';

const Label = (t, field) => t(`interaction.menu.fields.${field}`);

const positionOptions = (t) =>
  Object.keys(INTERACTION.MENU.POSITION).map((key) => ({
    value: INTERACTION.MENU.POSITION[key],
    label: Label(t, `position.${key.toLowerCase()}`)
  }));
const menuButtonLocationsOptions = (t) =>
  Object.keys(INTERACTION.MENU.MENU_BUTTON.LOCATION)
    .filter((key) => key !== 'HIDDEN')
    .map((key) => ({
      value: INTERACTION.MENU.MENU_BUTTON.LOCATION[key],
      label: Label(t, `menuButton.location.${key.toLowerCase()}`)
    }));
export const fields = (t) => ({
  id: new IdField('id', Label(t, 'id')),
  type: new StringField('type'),
  title: new StringField('title', Label(t, 'title'), {
    max: 100
  }),
  position: new EnumeratorField('position', Label(t, 'position.title'), positionOptions(t)),
  canCloseMenu: new BooleanField('close_menu', Label(t, 'canCloseMenu')),
  showMenuButton: new StringField('show_menu', Label(t, 'showMenuButton')),
  pauseVideo: new BooleanField('pause', Label(t, 'pauseVideo')),
  numbering: new BooleanField('number', Label(t, 'numbering')),
  buttonLocation: new EnumeratorField(
    'menu_button_location',
    Label(t, 'menuButton.location.title'),
    menuButtonLocationsOptions(t)
  ),
  startTime: new StringField('start_time', Label(t, 'startTime'), {
    testFunc: (val) => !Number.isNaN(hmsmsToSeconds(val))
  }),
  endTime: new StringField('end_time', Label(t, 'endTime'), {
    testFunc: (val) => !Number.isNaN(hmsmsToSeconds(val))
  }),
  buttonName: new StringField('button_name', Label(t, 'menuButton.buttonName'), {
    max: 100
  }),
  menuItems: new StringField('items')
});

export default {
  fields
};
