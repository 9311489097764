import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as ProjectActions from 'src/actions/projectList';
import { useNotify } from 'src/hooks/useNotify';
import { fields as _fields } from 'src/models/project';
import { FormSchema } from 'src/models/schema';
import * as ProjectListSelectors from 'src/selectors/projectList';
import { useModal } from '../useModal';

export const useProjectEditTitle = ({ modalName, project }) => {
  const filter = useSelector(ProjectListSelectors.getFilter);
  const page = useSelector(ProjectListSelectors.getPage);
  const { successNotify, errorNotify } = useNotify();
  const { t } = useTranslation();
  const fields = _fields(t);
  const dispatch = useDispatch();
  const { closeModal, isShowing } = useModal(modalName);
  const handleClose = useCallback(() => closeModal(modalName), [modalName]);

  const onFetch = useCallback(async (_filter, _page) => {
    await dispatch(ProjectActions.listRequest(_filter, _page));
  }, []);

  const { schema } = useMemo(() => new FormSchema(fields.id, [fields.name]), []);
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      [fields.name.name]: project.name
    },
    resolver: schema
  });
  const isSubmitting = useMemo(() => formState.isSubmitting, [formState]);
  const formItems = useMemo(
    () => ({
      control,
      formState
    }),
    [control, formState]
  );
  const onSubmit = useCallback(
    async (formData) => {
      try {
        await dispatch(ProjectActions.editTitleRequest(formData, project.id));
        successNotify(t('projects.editTitle.success'));
        handleClose();
        onFetch(filter, page);
      } catch (e) {
        dispatch(ProjectActions.editTitleFailure(e));
        errorNotify(t('projects.editTitle.failed'));
      }
    },
    [filter, page]
  );

  useEffect(() => {
    reset({ [fields.name.name]: project.name });
  }, [isShowing, project]);
  return {
    t,
    isShowing,
    isSubmitting,
    formItems,
    handleClose,
    control,
    handleSubmit,
    fields,
    onSubmit
  };
};
