/* eslint-disable no-unused-vars */
import { Box, IconButton, makeStyles } from '@material-ui/core';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as VideoActions from 'src/actions/videoPlayer';
import { VIDEO_CONTROLBAR_HEIGHT } from 'src/configs/layout';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import * as VideoPlayerSelector from 'src/selectors/videoPlayer';
import * as VideoViewerSelectors from 'src/selectors/videoViewer';
import { getIndexNextTime, sortListInteraction } from 'src/utils/objectCompare';
import videoController from 'src/utils/videoController';
import { ControlBar, Player } from 'video-react';
import 'video-react/dist/video-react.css';
import ReactGA from 'react-ga';
import BigPlayButton from 'video-react/lib/components/BigPlayButton';
import PlayToggle from 'video-react/lib/components/control-bar/PlayToggle';
import ProgressControl from 'video-react/lib/components/control-bar/ProgressControl';
import VolumeMenuButton from 'video-react/lib/components/control-bar/VolumeMenuButton';
import Shortcut from 'video-react/lib/components/Shortcut';
import CurrentTimeDisplay from 'video-react/lib/components/time-controls/CurrentTimeDisplay';
import DurationDisplay from 'video-react/lib/components/time-controls/DurationDisplay';
import TimeDivider from 'video-react/lib/components/time-controls/TimeDivider';
import './css/VideoDisplay.css';
import { useTranslation } from 'react-i18next';
import { EditorVideoBigPlayButton } from './EditorVideoBigPlayButton';
import { getInteractionDisplay } from './InteractionsDisplay';
import { NextPrevInteractionMultiInteraction } from './NextPrevInteractionMultiInteraction';
import { ToggleNextPrevButton } from './ToggleNextPrevButton';
import { EndScreen } from './EndScreen';

const useStyles = makeStyles(() => ({
  controllBar: {
    bottom: `-${VIDEO_CONTROLBAR_HEIGHT}px !important`
  }
}));

const DOMRectToObj = (domRect, isVideoBounding = false) => ({
  x: domRect.x,
  y: domRect.y,
  width: domRect.width,
  height: domRect.height - (isVideoBounding ? VIDEO_CONTROLBAR_HEIGHT : 0),
  top: domRect.top,
  left: domRect.left
});

const getProgressControlBounding = () =>
  DOMRectToObj(
    document
      .getElementsByClassName('video-react-progress-holder video-react-slider-horizontal video-react-slider')[0]
      .getBoundingClientRect()
  );

const PublishVideoDisplay = (props) => {
  const { videoMedia, stopTime, width, autoPlay, defaultVolume } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const listInteraction = useSelector(VideoViewerSelectors.getInteractionList);
  const videoCurrentTime = useSelector(VideoPlayerSelector.getCurrentTime);

  const [progressControlBounding, setProgressControlBounding] = useState({});
  const videoBoundingRef = useRef(null);
  const [videoBounding, setVideoBounding] = useState(null);
  const [interactions, setInteractions] = useState([]);
  const [indexStop, setIndexStop] = useState(0);
  const [nextPrevInteractionMode, setNextPrevInteractionMode] = useState(false);
  const { videoData } = useVideoPlayer();

  const handleStateChange = useCallback((state) => {
    dispatch(VideoActions.setVideoPlayer(state));
  }, []);

  useEffect(() => {
    videoController.getInstance(videoRef?.current);
  }, [videoMedia]);

  useEffect(() => {
    videoRef.current?.subscribeToStateChange?.(handleStateChange);
  }, [videoMedia]);

  const zoom = useSelector(VideoPlayerSelector.getZoom);

  useEffect(() => {
    if (zoom.zoom) zoom.zoom = false;
  }, [zoom]);

  useEffect(() => {
    // handle seek video.
    const { player } = videoController.getInstance()?.getState();
    if (stopTime.length > 0 && player.seeking) {
      const i = getIndexNextTime(stopTime, videoCurrentTime);
      setIndexStop(i);
    }
    // handle stop video.
    if (
      stopTime.length > 0 &&
      !!stopTime[indexStop] &&
      videoCurrentTime >= stopTime[indexStop] - 0.2 &&
      videoCurrentTime < stopTime[indexStop] + 0.1
    ) {
      const i = getIndexNextTime(stopTime, stopTime[indexStop] + 0.1);
      setIndexStop(i);
      videoController.getInstance()?.pause();
    }
  }, [videoCurrentTime]);

  const videoHasStarted = useSelector(VideoPlayerSelector.getHasStarted);
  const videoHasEnded = useSelector(VideoPlayerSelector.getHasEnded);

  useEffect(() => {
    setVideoBounding(DOMRectToObj(videoBoundingRef.current.getBoundingClientRect(), true));
    setInteractions(sortListInteraction(listInteraction));
    setProgressControlBounding(getProgressControlBounding());
  }, [videoBoundingRef.current]);

  useEffect(() => {
    if (videoHasStarted) {
      ReactGA.event({
        category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
        action: t('googleAnalyticsActions.actions.playVideo'),
        label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${t(
          'googleAnalyticsActions.actions.playVideo'
        )}`,
        value: videoData.id
      });
    }
    setProgressControlBounding(getProgressControlBounding());
    setVideoBounding(DOMRectToObj(videoBoundingRef.current.getBoundingClientRect(), true));
  }, [videoHasStarted]);
  useEffect(() => {
    const handleWindowResize = () => {
      setVideoBounding(DOMRectToObj(videoBoundingRef.current.getBoundingClientRect(), true));
      setProgressControlBounding(getProgressControlBounding());
    };

    window.addEventListener('resize', handleWindowResize);

    videoRef.current.volume = defaultVolume;

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [videoRef]);
  const muted = useSelector(VideoPlayerSelector.getMuted);
  const [showUnmuted, setShowUnmuted] = useState(autoPlay);
  const onClickUnmuted = useCallback(() => {
    setShowUnmuted(false);
    videoRef.current.muted = false;
  }, []);
  useEffect(() => {
    if (!muted) {
      setShowUnmuted(false);
    } else {
      setShowUnmuted(true);
    }
  }, [muted]);

  return (
    <>
      {showUnmuted && (
        <IconButton
          onClick={onClickUnmuted}
          sx={{
            position: 'fixed',
            top: 8,
            left: '50%',
            transform: 'translate(-50%, 0)',
            zIndex: 4,
            borderRadius: '16px',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.9)'
            }
          }}
        >
          <VolumeOffIcon />
          Click to unmute
        </IconButton>
      )}
      <Box
        component="div"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          paddingBottom: `${VIDEO_CONTROLBAR_HEIGHT}px`
        }}
        id="videoBounding"
        ref={videoBoundingRef}
      >
        <Box
          id="zoom-interaction"
          sx={{
            position: 'relative',
            transform: zoom?.zoom ? `scale(${1 / zoom?.width})` : 'scale(1)',
            transformOrigin: zoom?.zoom
              ? `${(zoom?.x + zoom?.width / 2) * 100}% ${(zoom?.y + zoom?.height / 2) * 100}%`
              : 'unset',
            transition: 'transform 600ms, width 600ms, height 600ms'
          }}
        >
          <Player
            className="video-player"
            playsInline
            fluid
            width={width}
            ref={videoRef}
            src={videoMedia.src}
            aspectRatio="16:9"
            autoPlay={autoPlay}
            muted={autoPlay}
          >
            <Shortcut disabled />
            <BigPlayButton position="center" />
            <Shortcut disabled />
            <ControlBar disableDefaultControls autoHide={false} className={classes.controllBar}>
              <PlayToggle />
              <VolumeMenuButton />
              <CurrentTimeDisplay />
              <TimeDivider />
              <DurationDisplay />
              <ProgressControl />
              <ToggleNextPrevButton
                order={7}
                className="test"
                on={nextPrevInteractionMode}
                setMode={(on) => setNextPrevInteractionMode(on)}
              />
            </ControlBar>
          </Player>
        </Box>
        {!!videoBounding && interactions.length > 0 && (
          <>
            {/* <MultiInteractionDisplay videoBounding={videoBounding} listInteractions={interactions} /> */}
            <Box id="interaction-bounding-box" className="waocon-interaction-bounding-box">
              {videoHasStarted &&
                interactions.map((interaction) =>
                  getInteractionDisplay({ interaction, videoBounding, multiInter: true })
                )}
            </Box>
            <NextPrevInteractionMultiInteraction
              videoBounding={videoBounding}
              listInteraction={interactions}
              progressControlBounding={progressControlBounding}
              visible={nextPrevInteractionMode}
              onClickOff={() => setNextPrevInteractionMode(false)}
            />
          </>
        )}
        {!autoPlay && <EditorVideoBigPlayButton loadingScreen={videoMedia.loadingScreen} />}
        {videoHasEnded && videoMedia.endScreen && <EndScreen src={videoMedia.endScreen} />}
      </Box>
    </>
  );
};

PublishVideoDisplay.propTypes = {
  videoMedia: PropTypes.object.isRequired,
  stopTime: PropTypes.array.isRequired,
  width: PropTypes.number,
  autoPlay: PropTypes.bool,
  defaultVolume: PropTypes.number.isRequired
};

export default PublishVideoDisplay;
