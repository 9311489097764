/*eslint-disable*/
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'reduxsauce';
import { PublishSettingsTypes } from 'src/actions/publishSettings';

const requestState = {
  requesting: false,
  success: false,
  failure: false
};

const INITIAL_STATE = {
  publish: {},

  fetch: requestState,
  update: requestState
};

const reset = () => INITIAL_STATE;

// Fetch
const fetchSuccess = (state = INITIAL_STATE, { publish }) => ({
  ...state,
  fetch: {
    ...requestState,
    success: true
  },
  publish
});

const fetchFailure = (state = INITIAL_STATE) => ({
  ...state,
  fetch: {
    ...requestState,
    failure: true
  }
});

const fetchRequest = (state = INITIAL_STATE) => ({
  ...state,
  fetch: {
    ...requestState,
    requesting: true
  }
});

// Update
const updateSuccess = (state = INITIAL_STATE, { publish }) => ({
  ...state,
  update: {
    ...requestState,
    success: true
  },
  publish
});

const updateFailure = (state = INITIAL_STATE) => ({
  ...state,
  update: {
    ...requestState,
    failure: true
  }
});

const updateRequest = (state = INITIAL_STATE) => ({
  ...state,
  update: {
    ...requestState,
    requesting: true
  }
});

export const HANDLERS = {
  [PublishSettingsTypes.PUBLISH_SETTINGS_RESET]: reset,

  [PublishSettingsTypes.PUBLISH_SETTINGS_FETCH_SUCCESS]: fetchSuccess,
  [PublishSettingsTypes.PUBLISH_SETTINGS_FETCH_FAILURE]: fetchFailure,
  [PublishSettingsTypes.PUBLISH_SETTINGS_FETCH_REQUEST]: fetchRequest,

  [PublishSettingsTypes.PUBLISH_SETTINGS_UPDATE_SUCCESS]: updateSuccess,
  [PublishSettingsTypes.PUBLISH_SETTINGS_UPDATE_FAILURE]: updateFailure,
  [PublishSettingsTypes.PUBLISH_SETTINGS_UPDATE_REQUEST]: updateRequest
};

export const publishSettings = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'publishSettings',
  storage
};

export default persistReducer(persistConfig, publishSettings);
