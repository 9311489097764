import { InputAdornment, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SearchIcon from 'src/icons/Search';

export const ProjectFilter = ({ filter, onSearch }) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: filter
  });
  useEffect(() => {
    reset({ ...filter });
  }, [filter]);
  const { t } = useTranslation();
  return (
    <form noValidate onSubmit={handleSubmit(onSearch)}>
      <Controller
        name="name"
        control={control}
        render={({ field: renderProps }) => (
          <TextField
            fullWidth
            size="small"
            {...renderProps}
            value={renderProps.value}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
              sx: { backgroundColor: '#E6E9FF', border: 'none' }
            }}
            placeholder={t('projects.search')}
            variant="outlined"
          />
        )}
      />
    </form>
  );
};

ProjectFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired
};
