/*eslint-disable*/
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isImageMedia, isSoundMedia, isVideoMedia, soundsExtension, videosExtension } from 'src/utils/mediaTypes';

const Library = ({ item }) => {
  const { t } = useTranslation();
  const mediaRef = useRef(null);

  useEffect(
    useCallback(() => {
      mediaRef.current?.load();
    }),
    [item]
  );

  return (
    <>
      <Typography mb={1}>{t('projectSidebar.library')}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '4px',
          width: '100%',
          height: '140px'
        }}
      >
        {!item?.url && <Typography align="center">{t('projectSidebar.libraryMsg')}</Typography>}

        {item && isSoundMedia(item) && (
          <audio
            ref={mediaRef}
            controls
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '4px'
            }}
          >
            {soundsExtension.map((soundExtension, idx) => (
              <source
                key={idx}
                type={`audio/${soundExtension}`}
                src={`${process.env.REACT_APP_PUBLIC_URL}${item.url}`}
              />
            ))}
            <track kind="captions" />
          </audio>
        )}

        {item && isVideoMedia(item) && (
          <video
            ref={mediaRef}
            controls
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '4px'
            }}
          >
            {videosExtension.map((videoExtension, idx) => (
              <source
                key={idx}
                type={`video/${videoExtension}`}
                src={`${process.env.REACT_APP_PUBLIC_URL}${item.url}`}
              />
            ))}
            <track kind="captions" />
          </video>
        )}

        {isImageMedia(item) && (
          <img
            style={{
              width: 'auto',
              height: '100%',
              maxWidth: '100%',
              borderRadius: '4px'
            }}
            src={`${process.env.REACT_APP_PUBLIC_URL}${item.url}`}
            alt={item?.name}
          />
        )}
      </Box>
    </>
  );
};

Library.propTypes = {
  item: PropTypes.object
};

export default Library;
