import { useTheme } from '@emotion/react';
import { Box, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import EditorField from 'src/components/FormControls/EditorField';
import 'src/containers/interactionsForm/link-video/LinkVideoInteraction.css';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import ColorField from '../text/ColorField';

const LinkVideoInteractionInteractionForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const textConfig = useSelector(EdittingInteractionSelectors.getConfig);
  const dispatch = useDispatch();

  const [titleError, setTitleError] = useState('');
  const [contentError, setContentError] = useState('');
  const [idPublicError, setIdPublicError] = useState(() => {
    if (textConfig.id_public) {
      return '';
    }
    return t('interaction.link.validate.publicId.required');
  });

  const { control, handleSubmit, reset } = useForm({
    model: 'onChange',
    defaultValues: textConfig
  });

  const onSubmit = handleSubmit((formValues) => {
    dispatch(EdittingInteractionActions.textInteractionSetTextConfig(formValues));
  });

  useEffect(() => {
    reset(textConfig);
  }, [textConfig]);

  useEffect(() => {
    if (!textConfig.id_public) {
      dispatch(EdittingInteractionActions.setValidateError(true));
    }
  }, []);

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="title"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.link.label.title')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    onChange(event.target.value);
                    dispatch(EdittingInteractionActions.setConfig({ title: event.target.value }));

                    if (!event.target.value.length || event.target.value.length > 100) {
                      if (!event.target.value.length) {
                        setTitleError(t('interaction.link.validate.title.required'));
                      } else {
                        setTitleError(t('interaction.link.validate.title.max', { max: 100 }));
                      }
                      dispatch(EdittingInteractionActions.setValidateError(true));
                    } else {
                      setTitleError('');
                      if (contentError || idPublicError) {
                        dispatch(EdittingInteractionActions.setValidateError(true));
                      } else {
                        dispatch(EdittingInteractionActions.setValidateError(false));
                      }
                    }
                  }}
                  value={value}
                />
              </Box>
              {!!titleError && (
                <Box
                  sx={{
                    display: 'flex',
                    height: 40,
                    maxHeight: 40,
                    alignItems: 'center',
                    color: theme.palette.error.main
                  }}
                >
                  <Typography width={160} />
                  <Typography sx={{ width: 'calc(100% - 160px)' }}>{titleError}</Typography>
                </Box>
              )}
            </Box>
          )}
        />

        <EditorField
          name="content"
          control={control}
          defaultTextAlign="Center"
          formState={{ errors: contentError ? { content: { message: contentError } } : {} }}
          onChange={(obj) => {
            const newValue = Object.values(obj)[0];
            dispatch(EdittingInteractionActions.setConfig({ content: newValue }));

            // if (!newValue.length || newValue.length > 100) {
            if (!newValue.length) {
              if (!newValue.length) {
                setContentError(t('interaction.link.validate.content.required'));
              } else {
                setContentError(t('interaction.link.validate.content.max', { max: 100 }));
              }
              dispatch(EdittingInteractionActions.setValidateError(true));
            } else {
              setContentError('');
              if (titleError || idPublicError) {
                dispatch(EdittingInteractionActions.setValidateError(true));
              } else {
                dispatch(EdittingInteractionActions.setValidateError(false));
              }
            }
          }}
        />
        {/* <Controller
          name="content"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ mb: 2 }}>
              <Editor
                onEditorChange={(newValue) => {
                  onChange(newValue);
                  dispatch(EdittingInteractionActions.setConfig({ content: newValue }));

                  // if (!newValue.length || newValue.length > 100) {
                  if (!newValue.length) {
                    if (!newValue.length) {
                      setContentError(t('interaction.link.validate.content.required'));
                    } else {
                      setContentError(t('interaction.link.validate.content.max', { max: 100 }));
                    }
                    dispatch(EdittingInteractionActions.setValidateError(true));
                  } else {
                    setContentError('');
                    if (titleError || idPublicError) {
                      dispatch(EdittingInteractionActions.setValidateError(true));
                    } else {
                      dispatch(EdittingInteractionActions.setValidateError(false));
                    }
                  }
                }}
                value={value}
                init={{
                  width: '100%',
                  height: 300,
                  menubar: false,
                  plugins: ['lists'],
                  toolbar:
                    'fontselect fontsizeselect forecolor | undo redo | bold italic underline | alignleft aligncenter alignright | indent outdent removeformat strikethrough subscript superscript | numlist bullist',
                  toolbar_mode: 'sliding'
                }}
              />
              <Typography sx={{ color: theme.palette.error.main }}>{contentError}</Typography>
            </Box>
          )}
        /> */}

        <Controller
          name="style.background_color"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 600 }} width={160}>
                {t('interaction.link.label.backgroundColor')}
              </Typography>
              <Box sx={{ width: 120 }}>
                <ColorField
                  id="link-react-color-custom"
                  color={value}
                  onColorChange={(newColor) => {
                    onChange(newColor);
                    dispatch(EdittingInteractionActions.setStyleConfig({ background_color: newColor }));
                  }}
                />
              </Box>
            </Box>
          )}
        />

        <Controller
          name="id_public"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.link.label.linkedVideo')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    onChange(event.target.value);
                    dispatch(EdittingInteractionActions.setConfig({ id_public: event.target.value }));

                    if (!event.target.value.length || event.target.value.length > 300) {
                      if (!event.target.value.length) {
                        setIdPublicError(t('interaction.link.validate.publicId.required'));
                      } else {
                        setIdPublicError(t('interaction.link.validate.publicId.max', { max: 300 }));
                      }
                      dispatch(EdittingInteractionActions.setValidateError(true));
                    } else {
                      setIdPublicError('');
                      if (titleError || contentError) {
                        dispatch(EdittingInteractionActions.setValidateError(true));
                      } else {
                        dispatch(EdittingInteractionActions.setValidateError(false));
                      }
                    }
                  }}
                  value={value}
                  placeholder="Video code"
                />
              </Box>
              {!!idPublicError && (
                <Box
                  sx={{
                    display: 'flex',
                    height: 40,
                    maxHeight: 40,
                    alignItems: 'center',
                    color: theme.palette.error.main
                  }}
                >
                  <Typography width={160} />
                  <Typography sx={{ width: 'calc(100% - 160px)' }}>{idPublicError}</Typography>
                </Box>
              )}
            </Box>
          )}
        />
      </form>
    </>
  );
};

export default LinkVideoInteractionInteractionForm;
