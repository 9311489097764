import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as ProjectActions from 'src/actions/projectList';
import { useNotify } from 'src/hooks/useNotify';
import { useModal } from '../useModal';

export const useProjectOptions = ({ modalName, projectID }) => {
  const { successNotify, errorNotify } = useNotify();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { closeModal, isShowing } = useModal(modalName);
  const handleClose = useCallback(() => closeModal(modalName), [modalName]);

  const onDelete = useCallback(async () => {
    try {
      await dispatch(ProjectActions.deleteRequest(projectID));
      successNotify(t('projects.delete.success'));
      dispatch(ProjectActions.deleteSuccess());
      handleClose();
      dispatch(ProjectActions.listRefetchRequest());
    } catch (e) {
      dispatch(ProjectActions.deleteFailure(e));
      errorNotify(t('projects.delete.failed'));
    }
  }, [projectID]);

  const onDuplicate = useCallback(async () => {
    try {
      await dispatch(ProjectActions.duplicateRequest(projectID));
      successNotify(t('projects.duplicate.success'));
      dispatch(ProjectActions.duplicateSuccess());
      dispatch(ProjectActions.listRefetchRequest());
    } catch (e) {
      dispatch(ProjectActions.duplicateFailure(e));
      errorNotify(t('projects.duplicate.failed'));
    }
  }, [projectID]);

  return {
    t,
    isShowing,
    handleClose,
    onDelete,
    onDuplicate
  };
};
