import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PublishManagerTypes } from 'src/actions/publishManager';

const requestState = {
  requesting: false,
  success: false,
  failure: false
};

const INITIAL_STATE = {
  pushlish: null,

  newRevision: requestState
};

const publishManagerReset = () => INITIAL_STATE;

const newRevisionRequest = (state = INITIAL_STATE) => ({
  ...state,
  newRevision: {
    ...requestState,
    requesting: true
  }
});

const newRevisionSuccess = (state = INITIAL_STATE, { publish }) => ({
  ...state,
  newRevision: {
    ...requestState,
    success: true
  },
  publish
});

const newRevisionFailure = (state = INITIAL_STATE) => ({
  ...state,
  newRevision: {
    ...requestState,
    failure: true
  }
});

export const HANDLERS = {
  [PublishManagerTypes.PUBLISH_MANAGER_RESET]: publishManagerReset,

  [PublishManagerTypes.PUBLISH_MANAGER_NEW_REVISION_REQUESTING]: newRevisionRequest,
  [PublishManagerTypes.PUBLISH_MANAGER_NEW_REVISION_SUCCESS]: newRevisionSuccess,
  [PublishManagerTypes.PUBLISH_MANAGER_NEW_REVISION_FAILURE]: newRevisionFailure
};

const authReducer = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'publishManager',
  storage
};

export default persistReducer(persistConfig, authReducer);
