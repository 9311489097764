import { createSelector } from 'reselect';

const projectListState = (state) => state.projectList;

export const getLoading = createSelector(projectListState, (projectList) => projectList.loading);

export const getError = createSelector(projectListState, (projectList) => projectList.error);

export const getList = createSelector(projectListState, (projectList) => projectList.list);
export const getPage = createSelector(projectListState, (projectList) => projectList.page);
export const getFilter = createSelector(projectListState, (projectList) => projectList.filter);
export const getTotalPage = createSelector(projectListState, (projectList) => projectList.totalPage);
