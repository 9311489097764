import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Controller } from 'react-hook-form';
import _get from 'lodash/get';
import { FormControl, FormLabel, Grid, FormHelperText } from '@material-ui/core';

const PasswordField = (props) => {
  const {
    label,
    name,
    control,
    formState,
    layout = {},

    error,
    helperText,
    labelProps,
    autoComplete,
    ...others
  } = props;

  const { errors } = formState;

  const [showPassword, setShowPassword] = useState(() => false);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((pre) => !pre);
  }, [setShowPassword]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onBlur, onChange } }) => (
        <FormControl {...others} error={error || !!_get(errors, `${name}.message`)}>
          <Grid container {...layout.grid}>
            <Grid item {...layout.label}>
              {!!label && <FormLabel {...labelProps}>{label}</FormLabel>}
            </Grid>
            <Grid item {...layout.input}>
              <OutlinedInput
                size="small"
                fullWidth
                inputRef={ref}
                inputProps={{ onBlur, onChange }}
                id={name}
                autoComplete={autoComplete}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={toggleShowPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item {...layout.label} />
                <Grid item {...layout.input}>
                  <FormHelperText sx={{ mx: 0 }}>{helperText || _get(errors, `${name}.message`)}</FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      )}
    />
  );
};

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,

  error: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  layout: PropTypes.object,
  labelProps: PropTypes.object,

  label: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string
};

PasswordField.defaultProps = {
  error: false,
  required: false,
  fullWidth: false,

  label: '',
  helperText: '',
  autoComplete: '',
  layout: {
    grid: {
      my: 1
    },
    label: {
      xs: 12
    },
    input: {
      xs: 12
    }
  }
};

export default PasswordField;
