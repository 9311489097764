/* eslint-disable no-unused-vars */
import { useTheme } from '@emotion/react';
import { Box, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { INTERACTION } from 'src/constants/interactions';
import 'src/containers/interactionsForm/jump-to/JumpToInteraction.css';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import * as ProjectNodeSelectors from 'src/selectors/projectNode';
import { getDuration } from 'src/selectors/videoPlayer';
import { getInteractionLabel } from 'src/utils/getInteractionStartTime';
import timelineFormat, { hmsmsToSeconds } from 'src/utils/timelineFormat';

const convertReduxToForm = (redux) => {
  const converted = { ...redux };
  if (redux.jump) {
    converted.jump = {
      ...redux.jump,
      jump_to: redux.jump?.jump_to ? timelineFormat(redux.jump.jump_to, 'hh:mm:ss.ms') : '00:00:00.00',
      jump_time: redux.jump?.jump_time ? timelineFormat(redux.jump.jump_time, 'hh:mm:ss.ms') : '00:00:00.00'
    };
    if (redux.type === INTERACTION.TYPE.JUMP_TO) {
      converted.jump.time_jump_to_node = redux.jump?.time_jump_to_node
        ? timelineFormat(redux.jump?.time_jump_to_node, 'hh:mm:ss.ms')
        : '00:00:00.00';
    }
  }

  return converted;
};

const actionOptions_ = (t) =>
  Object.keys(INTERACTION.JUMP_TO.ACTION).map((key) => ({
    value: INTERACTION.ACTION[key],
    label: t(`interaction.actions.${key.toLowerCase()}`)
  }));

const JumpToInteractionForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const actionOptions = actionOptions_(t);
  const textConfig = useSelector(EdittingInteractionSelectors.textInteractionGetTextConfig);
  const dispatch = useDispatch();
  const videoDuration = useSelector(getDuration);
  const nodeList = useSelector(ProjectNodeSelectors.getListJumpNode);
  const currentNode = useSelector(NodeInteractionSelectors.getNode);

  const [titleError, setTitleError] = useState('');

  const { control, watch, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: convertReduxToForm(textConfig, nodeList || [])
  });

  const convertJumpFields2Redux = useCallback(
    (values) => {
      let converted = {};
      if (values.type === INTERACTION.ACTION.JUMP_TO_INTERACTION) {
        converted = {
          type: values.type,
          interaction_id: values.interaction_id
        };
      } else if (values.type === INTERACTION.ACTION.JUMP_TO_NODE) {
        converted = {
          type: values.type,
          node_id: values.node_id,
          time_jump_to_node: hmsmsToSeconds(values.time_jump_to_node) || 0
        };
      } else {
        const jumpTimeSecs = hmsmsToSeconds(values.jump_time) || 0;
        let jumpToSecs;
        if (values.type === INTERACTION.ACTION.JUMP_TO_START) {
          jumpToSecs = 0;
        } else if (values.type === INTERACTION.ACTION.JUMP_TO_END) {
          jumpToSecs = videoDuration;
        } else {
          jumpToSecs = hmsmsToSeconds(values.jump_to) || 0;
        }
        converted = {
          type: values.type,
          jump_to: jumpToSecs,
          jump_time: jumpTimeSecs
        };
        setValue('jump.jump_to', timelineFormat(jumpToSecs, 'hh:mm:ss.ms'));
        setValue('jump.jump_time', timelineFormat(jumpTimeSecs, 'hh:mm:ss.ms'));
      }
      return converted;
    },
    [videoDuration]
  );
  const onFieldsSubmit = useCallback(() => {
    const jumpValues = getValues('jump');
    dispatch(EdittingInteractionActions.setJumpConfig(convertJumpFields2Redux(jumpValues)));
  });

  const interactionList = useSelector(NodeInteractionSelectors.getInteractionList);
  const jumpToInteractionOptions = useMemo(
    () =>
      interactionList.map((inter) => ({
        label: getInteractionLabel(inter),
        value: inter.id_interaction
      })),
    [interactionList]
  );

  // Handle jump to node
  const jumpToNodeOptions = useMemo(() =>
    nodeList.map((node) => ({
      label: node.name,
      value: node.id
    }))
  );

  const watchJumpTypeField = watch('jump.type');

  return (
    <>
      <form noValidate style={{ marginTop: 8 }}>
        <Controller
          name="jump.title"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.jump.label.title')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    onChange(event.target.value);
                    dispatch(EdittingInteractionActions.setJumpConfig({ title: event.target.value }));

                    if (!event.target.value.length || event.target.value.length > 100) {
                      if (!event.target.value.length) {
                        setTitleError(t('interaction.jump.validate.title.required'));
                      } else {
                        setTitleError(t('interaction.jump.validate.title.max', { max: 100 }));
                      }
                      dispatch(EdittingInteractionActions.setValidateError(true));
                    } else {
                      setTitleError('');
                      dispatch(EdittingInteractionActions.setValidateError(false));
                    }
                  }}
                  value={value}
                />
              </Box>
              {!!titleError && (
                <Box
                  sx={{
                    display: 'flex',
                    height: 40,
                    maxHeight: 40,
                    alignItems: 'center',
                    color: theme.palette.error.main
                  }}
                >
                  <Typography width={160} />
                  <Typography sx={{ width: 'calc(100% - 160px)' }}>{titleError}</Typography>
                </Box>
              )}
            </Box>
          )}
        />

        <Controller
          name="jump.type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 600 }} width={160}>
                {t('interaction.jump.label.action')}
              </Typography>
              <Select
                sx={{ width: 'calc(100% - 160px)' }}
                size="small"
                value={value}
                fullWidth
                onChange={(e) => {
                  onChange(e.target.value);
                  onFieldsSubmit({ 'jump.type': e.target.value });
                }}
              >
                {actionOptions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        />

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_SPECIFIC && (
          <Controller
            name="jump.jump_to"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.jump.label.jumpTo')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.target.blur();
                    }
                  }}
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                    onFieldsSubmit({ 'jump.jump_to': value });
                  }}
                  value={value}
                />
              </Box>
            )}
          />
        )}

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_INTERACTION && (
          <Controller
            name="jump.interaction_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.text.label.jumpToInteraction')}
                </Typography>
                <Select
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  value={value}
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value);
                    onFieldsSubmit({ 'jump.interaction_id': e.target.value });
                  }}
                >
                  {jumpToInteractionOptions.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
          />
        )}
        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_NODE && (
          <>
            <Controller
              name="jump.node_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: 600 }} width={160}>
                    {t('interaction.actions.jump_to_node')}
                  </Typography>
                  <Select
                    sx={{ width: 'calc(100% - 160px)' }}
                    size="small"
                    value={value}
                    fullWidth
                    onChange={(e) => {
                      onChange(e.target.value);
                      onFieldsSubmit({ 'jump.node_id': e.target.value });
                    }}
                  >
                    {jumpToNodeOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            />
            {getValues('jump.node_id') !== undefined ? (
              <Controller
                name="jump.time_jump_to_node"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 600 }} width={160}>
                      {t('interaction.jump.label.jumpTo')}
                    </Typography>
                    <TextField
                      sx={{ width: 'calc(100% - 160px)' }}
                      size="small"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.target.blur();
                        }
                      }}
                      onChange={onChange}
                      onBlur={() => {
                        console.log({ value });
                        onBlur();
                        onFieldsSubmit({ 'jump.time_jump_to_node': value });
                      }}
                      value={value}
                    />
                  </Box>
                )}
              />
            ) : (
              <div />
            )}
          </>
        )}
      </form>
    </>
  );
};

export default JumpToInteractionForm;
