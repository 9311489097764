import { FormControl, FormLabel, Grid, TextField, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import _get from 'lodash/get';
import { Controller } from 'react-hook-form';

const InputField = (props) => {
  const {
    label,
    name,
    control,
    formState,
    layout = {},

    error,
    helperText,
    labelProps,
    helperTextProps,
    OnBlur,
    BlurOnEnter,
    OnMouseUp,
    ...others
  } = props;

  const { errors = {} } = formState;
  const onKeyDown = useCallback((e) => e.key === 'Enter' && e.target.blur(), []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: renderProps }) => (
        <FormControl fullWidth error={error || !!_get(errors, `${name}.message`)}>
          <Grid container {...layout.grid}>
            <Grid item {...layout.label}>
              {!!label && <FormLabel {...labelProps}>{label}</FormLabel>}
            </Grid>
            <Grid item {...layout.input}>
              <TextField
                fullWidth
                size="small"
                {...others}
                {...renderProps}
                sx={{ ...others, ml: others.ml }}
                onBlur={() => {
                  renderProps.onBlur();
                  OnBlur({ [name]: renderProps.value });
                }}
                {...(BlurOnEnter ? { onKeyDown } : {})}
                {...(OnMouseUp
                  ? {
                      onMouseUp: () => {
                        OnMouseUp({ [name]: renderProps.value });
                      }
                    }
                  : {})}
                value={renderProps.value}
                error={error || !!_get(errors, `${name}.message`)}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item {...layout.label} style={{}} />
                <Grid item {...layout.input} style={{}}>
                  <FormHelperText {...helperTextProps}>{helperText || _get(errors, `${name}.message`)}</FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      )}
    />
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  error: PropTypes.bool,
  inputProps: PropTypes.object,
  helperTextProps: PropTypes.object,
  helperText: PropTypes.string,
  labelProps: PropTypes.object,
  layout: PropTypes.object,
  OnBlur: PropTypes.func,
  BlurOnEnter: PropTypes.bool,
  OnMouseUp: PropTypes.func
};

InputField.defaultProps = {
  error: false,
  layout: {
    grid: {
      my: 1
    },
    label: {
      xs: 12
    },
    input: {
      xs: 12
    }
  },
  OnBlur: () => null,
  BlurOnEnter: false,
  OnMouseUp: null
};

export default InputField;
