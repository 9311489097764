import { FormControl, FormLabel, Grid } from '@material-ui/core';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import RoundedSlider from 'src/components/interaction/RoundedSlider';

const RoundedSliderField = (props) => {
  const {
    label,
    name,
    control,
    formState,
    layout = {},
    onChange: handleChange,

    labelProps,
    ...others
  } = props;

  const { errors = {} } = formState;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: renderProps }) => (
        <FormControl fullWidth error={!!_get(errors, `${name}.message`)}>
          <Grid container {...layout.grid}>
            {!!label && (
              <Grid item {...layout.label}>
                <FormLabel {...labelProps}>{label}</FormLabel>
              </Grid>
            )}
            <Grid item {...layout.input}>
              <RoundedSlider
                {...others}
                {...renderProps}
                onChange={(_, number) => {
                  renderProps.onChange(number);
                  handleChange({ [name]: number });
                }}
                value={renderProps.value}
              />
            </Grid>
          </Grid>
        </FormControl>
      )}
    />
  );
};

RoundedSliderField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  labelProps: PropTypes.object,
  layout: PropTypes.object,
  onChange: PropTypes.func
};

RoundedSliderField.defaultProps = {
  layout: {
    grid: {
      my: 1
    },
    label: {
      xs: 3,
      style: {
        display: 'flex',
        alignItems: 'center'
      }
    },
    input: {
      xs: 9
    },
    onChange: null
  }
};

export default RoundedSliderField;
