import { createTypes } from 'reduxsauce';

export const EdittingInteractionTypes = createTypes(`
  EDITTING_INTERACTION_RESET_REQUEST

  EDITTING_INTERACTION_SET_CONFIG
  EDITTING_INTERACTION_TEXT_INTERACTION_SET_TEXT_CONFIG
  EDITTING_INTERACTION_TEXT_INTERACTION_SET_TEXT_STYLE_CONFIG
  EDITTING_INTERACTION_SET_STYLE_CONFIG
  EDITTING_INTERACTION_SET_VALIDATE_ERROR
  EDITTING_INTERACTION_PERSIT_CURRENT_STATE
  EDITTING_INTERACTION_SET_JUMP_CONFIG
  EDITTING_INTERACTION_SET_MENU_ITEM_LIST
  EDITTING_INTERACTION_SET_SINGLE_MENU_ITEM_CONFIG
  EDITTING_INTERACTION_SET_SINGLE_MENU_ITEM_JUMP_CONFIG
  EDITTING_INTERACTION_ADD_MENU_ITEM
  EDITTING_INTERACTION_SET_INITIAL_VIDEO_WIDTH

`);

export const setConfig = (config) => ({ type: EdittingInteractionTypes.EDITTING_INTERACTION_SET_CONFIG, config });

export const textInteractionSetTextConfig = (textConfig) => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_TEXT_INTERACTION_SET_TEXT_CONFIG,
  textConfig
});

export const textInteractionSetTextStyleConfig = (textStyleConfig) => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_TEXT_INTERACTION_SET_TEXT_STYLE_CONFIG,
  textStyleConfig
});

export const setStyleConfig = (styleConfig) => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_SET_STYLE_CONFIG,
  styleConfig
});

export const setValidateError = (error) => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_SET_VALIDATE_ERROR,
  error
});

export const persitCurrentState = () => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_PERSIT_CURRENT_STATE
});

export const setJumpConfig = (config) => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_SET_JUMP_CONFIG,
  config
});

export const setMenuItemList = (menuItems) => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_SET_MENU_ITEM_LIST,
  menuItems
});

export const setSingleMenuItemConfig = (item, index) => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_SET_SINGLE_MENU_ITEM_CONFIG,
  item,
  index: parseInt(index, 10)
});

export const setSingleMenuItemJumpConfig = (item) => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_SET_SINGLE_MENU_ITEM_JUMP_CONFIG,
  item
});

export const addMenuItem = (item) => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_ADD_MENU_ITEM,
  item
});

export const setInitialVideoWidth = (videoWidth) => ({
  type: EdittingInteractionTypes.EDITTING_INTERACTION_SET_INITIAL_VIDEO_WIDTH,
  videoWidth
});

export const resetRequest = () => ({ type: EdittingInteractionTypes.EDITTING_INTERACTION_RESET_REQUEST });
