import { createSelector } from 'reselect';

const authState = (state) => state.auth;

export const getUserState = createSelector(authState, (auth) => auth.user);

export const getLoginState = createSelector(authState, (auth) => auth.login);

export const getForgotPasswordState = createSelector(authState, (auth) => auth.forgotPassword);

export const getResetPasswordState = createSelector(authState, (auth) => auth.resetPassword);
