import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ProjectListActions from 'src/actions/projectList';
import * as ProjectListSelectors from 'src/selectors/projectList';

export const useProjectList = () => {
  const dispatch = useDispatch();
  const list = useSelector(ProjectListSelectors.getList);
  const filter = useSelector(ProjectListSelectors.getFilter);
  const page = useSelector(ProjectListSelectors.getPage);
  const totalPage = useSelector(ProjectListSelectors.getTotalPage);
  const loading = useSelector(ProjectListSelectors.getLoading);
  const error = useSelector(ProjectListSelectors.getError);

  const onFetch = useCallback(async () => {
    await dispatch(ProjectListActions.listRequest(filter, page));
  }, [filter, page]);

  const onPageChange = useCallback((evt, value) => {
    dispatch(ProjectListActions.setPage(value));
  }, []);

  const onSearch = useCallback((formData) => {
    dispatch(ProjectListActions.setFilter(formData));
  }, []);

  let isReseting = useMemo(() => false, []);

  useEffect(async () => {
    isReseting = true;
    await dispatch(ProjectListActions.resetRequest());
    isReseting = false;
  }, []);

  useEffect(() => {
    if (!isReseting) onFetch();
  }, [onFetch, isReseting]);

  return {
    list,
    pagination: {
      page,
      totalPage,
      onPageChange
    },
    loading,
    error,

    filter,

    onSearch
  };
};
