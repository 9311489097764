import { Box, Grid, Pagination } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useModal } from 'src/hooks/useModal';
import { useState, useEffect } from 'react';
import axiosClient from 'src/utils/axiosClient';
import moment from 'moment';
import { ViewLogErrorModal } from './viewLogErrorModal';
import { useProcessManagement } from '../../hooks/useProcessManagement';
import { ProjectFilter } from './filter';

const useStyles = makeStyles({
  publish: {
    color: 'black'
  },
  processing: {
    color: 'lime'
  },
  failure: {
    color: 'red'
  },
  failureBtn: {
    color: 'white',
    backgroundColor: 'blue',
    borderRadius: '5px',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: 'blue'
    }
  },
  btn: {
    minWidth: '100px',
    maxWidth: '100px',
    minHeight: '35px',
    maxHeight: '35px',
    padding: '8px',
    color: 'white',
    backgroundColor: '#34a5dd',
    borderRadius: '10px',
    textDecorationColor: 'none',
    fontSize: '12px',
    '&:hover': {
      opacity: '0.8'
    }
  },

  rePublish: {
    color: 'red',
    minWidth: '100px',
    maxWidth: '100px',
    minHeight: '35px',
    maxHeight: '35px',
    backgroundColor: 'orange',
    borderRadius: '10px',
    fontSize: '12px'
  }
});

const ProjectProcessManagementContainer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openModal } = useModal();
  const { listState, pageState, filterState, totalPageState, onPageChange, onSearch } = useProcessManagement();
  const [log, setLog] = useState();
  const [projectList, setProjectList] = useState(listState);

  useEffect(() => {
    setProjectList(listState);
  }, [listState]);

  const handleUpdateStatus = async (id) => {
    const rs = await axiosClient.post(`/admin/publish/update/${id}`, { status: '2' });
    setProjectList((prev) =>
      prev.map((el) =>
        el.id === id
          ? {
              ...rs.project
            }
          : el
      )
    );
    setLog(rs.logs);
  };

  const viewStatus = {
    0: t('projectProcessManagement.viewStatus.publishedFailed'),
    1: t('projectProcessManagement.viewStatus.processing'),
    2: t('projectProcessManagement.viewStatus.published')
  };

  return (
    <>
      <Grid container sx={{ fontSize: '12px' }}>
        <Grid item md={8} xs={12} sx={{ marginBottom: 5 }}>
          <ProjectFilter filter={filterState} onSearch={onSearch} />
        </Grid>
        <Grid item md={12} xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ maxWidth: '100%' }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '25%' }} align="center">
                    {t('projectProcessManagement.table.name')}
                  </TableCell>
                  <TableCell sx={{ width: '25%' }} align="center">
                    {t('projectProcessManagement.table.status')}
                  </TableCell>
                  <TableCell sx={{ width: '25%' }} align="center">
                    {t('projectProcessManagement.table.createAt')}
                  </TableCell>
                  <TableCell sx={{ width: '25%' }} align="center">
                    {t('projectProcessManagement.table.action')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...projectList].map((row) => (
                  <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ width: '25%' }} align="center" component="th" scope="row">
                      <Link sx={{ color: 'blue', textDecorationColor: 'blue' }} href={`/projects/${row.id}`}>
                        {row.name}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, width: '25%' }} align="center">
                      <span
                        className={clsx(
                          viewStatus[row.publish.status] === viewStatus[0] && classes.failure,
                          viewStatus[row.publish.status] === viewStatus[1] && classes.processing,
                          viewStatus[row.publish.status] === viewStatus[2] && classes.publish
                        )}
                      >
                        {row.publish.status === '0' && row.publish.status_process === null
                          ? t('projectProcessManagement.viewStatus.draft')
                          : viewStatus[row.publish.status]}
                      </span>
                      {row.publish.status === '0' && row.publish.status_process ? (
                        <Button
                          onClick={() => {
                            openModal(`viewLogErrorModal${row.id}`);
                          }}
                          className={clsx(viewStatus[0] && classes.failureBtn)}
                        >
                          {t('projectProcessManagement.action.viewLog')}
                        </Button>
                      ) : null}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600, width: '25%' }} align="center">
                      {moment(new Date(row.created_at)).format('YYYY-MM-DD HH:mm')}
                    </TableCell>
                    <TableCell sx={{ width: '25%' }} align="center">
                      {row.publish.status === '2' && !(row.publish.status === '0' && row.publish.status_process) ? (
                        <Button
                          onClick={() => {
                            window.open(`${process.env.REACT_APP_DOMAIN_URL}/v/${row.id_public}`);
                          }}
                          variant="contained"
                          color="secondary"
                          className={clsx(classes.btn)}
                        >
                          {t('projectProcessManagement.action.viewURL')}
                        </Button>
                      ) : null}
                      {row.publish.status === '1' || (row.publish.status === '0' && !row.publish.status_process) ? (
                        <Button
                          href={`/projects/${row.id}`}
                          variant="contained"
                          color="secondary"
                          className={clsx(classes.btn)}
                        >
                          {t('projectProcessManagement.action.viewProject')}
                        </Button>
                      ) : null}

                      {row.publish.status === '0' && row.publish.status_process ? (
                        <Button
                          onClick={() => handleUpdateStatus(row.id)}
                          variant="contained"
                          className={clsx(viewStatus[2] && classes.rePublish)}
                        >
                          {t('projectProcessManagement.action.rePublish')}
                        </Button>
                      ) : null}
                    </TableCell>
                    <ViewLogErrorModal log={log} row={row} modalName={`viewLogErrorModal${row.id}`} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          sx={{
            paddingTop: '24px',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            alighItems: 'center'
          }}
        >
          <Box>
            <Pagination count={totalPageState} page={pageState} onChange={onPageChange} />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative', mt: 4 }} />
    </>
  );
};

export default ProjectProcessManagementContainer;
