/* eslint-disable no-unused-vars */
import { Box, IconButton } from '@material-ui/core';
import videoController from 'src/utils/videoController';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { VIDEO_CONTROLBAR_HEIGHT } from 'src/configs/layout';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';

export const EditorVideoBigPlayButton = ({ loadingScreen }) => {
  const videoControl = videoController.getInstance();
  const videoHasStarted = useSelector(VideoPlayerSelectors.getHasStarted);

  return (
    <Box
      id="big-play-button-bounding-box"
      className="waocon-interaction-bounding-box"
      sx={{
        zIndex: 4,
        visibility: videoHasStarted ? 'hidden' : 'visible'
      }}
    >
      {loadingScreen && (
        <img
          alt="loadingScreen"
          src={loadingScreen}
          height="100%"
          width="100%"
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      )}
      <IconButton
        sx={{
          top: '50%',
          left: '50%',
          marginTop: '-0.75em',
          marginLeft: '-1.5em',
          width: '3em',
          height: '1.75em',
          borderRadius: '0.3em',
          border: '0.06666em solid #fff',
          bgcolor: 'rgba(43, 51, 63, 0.7)',
          '&:hover': {
            bgcolor: 'rgba(115, 133, 159, 0.5)'
          }
        }}
        onClick={() => videoControl.play()}
      >
        <PlayArrowIcon sx={{ color: 'white' }} />
      </IconButton>
    </Box>
  );
};

EditorVideoBigPlayButton.propTypes = {
  loadingScreen: PropTypes.string
};
