import { createSelector } from 'reselect';

const accountState = (state) => state.account;

export const getLoading = createSelector(accountState, (account) => account.loading);

export const getError = createSelector(accountState, (account) => account.error);
export const getAccountDetail = createSelector(accountState, (account) => account.accountDetail);

export const getList = createSelector(accountState, (account) => account.list);
export const getPage = createSelector(accountState, (account) => account.page);
export const getFilter = createSelector(accountState, (account) => account.filter);
export const getTotalPage = createSelector(accountState, (account) => account.totalPage);
