import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ViewVideoTypes } from 'src/actions';

const INITIAL_STATE = {
  node: null,
  loading: false,
  error: null
};
const fetchViewSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  node: action.node,
  loading: false,
  error: null
});

const fetchViewFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false
});

const fetchViewRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null
});

const fetchPreviewSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  node: action.node,
  loading: false,
  error: null
});

const fetchPreviewFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false
});

const fetchPreviewRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null
});

export const HANDLERS = {
  [ViewVideoTypes.VIEW_VIDEO_FETCH_REQUEST]: fetchViewRequest,
  [ViewVideoTypes.VIEW_VIDEO_FETCH_FAILURE]: fetchViewFailure,
  [ViewVideoTypes.VIEW_VIDEO_FETCH_SUCCESS]: fetchViewSuccess,
  [ViewVideoTypes.VIEW_VIDEO_FETCH_PREVIEW_REQUEST]: fetchPreviewRequest,
  [ViewVideoTypes.VIEW_VIDEO_FETCH_PREVIEW_FAILURE]: fetchPreviewFailure,
  [ViewVideoTypes.VIEW_VIDEO_FETCH_PREVIEW_SUCCESS]: fetchPreviewSuccess
};

export const videoViewerReducer = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'videoViewer',
  storage
};

export default persistReducer(persistConfig, videoViewerReducer);
