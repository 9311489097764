import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'reduxsauce';
import { ProjectProcessManagementTypes } from 'src/actions/projectProcessManagement';

const INITIAL_STATE = {
  list: [],
  page: 1,
  totalPage: 0,
  filter: { name: '' },
  loading: false,
  error: null
};

const resetQuery = (state = INITIAL_STATE) => ({
  ...state,
  page: 1,
  filter: { name: '' }
});
const listRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const listSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.list,
  totalPage: action.totalPage,
  loading: false,
  error: null
});

const listFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const setPage = (state = INITIAL_STATE, action) => ({
  ...state,
  page: action.page
});

const setFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...action.filter },
  page: 1
});
export const HANDLERS = {
  [ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_REST_REQUEST]: resetQuery,
  [ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_LIST_REQUEST]: listRequest,
  [ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_LIST_SUCCESS]: listSuccess,
  [ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_LIST_FAILURE]: listFailure,
  [ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_SET_FILTER]: setFilter,
  [ProjectProcessManagementTypes.PROJECT_PROCESS_MANAGEMENT_SET_PAGE]: setPage
};

export const projectProcess = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'process',
  storage
};

export default persistReducer(persistConfig, projectProcess);
