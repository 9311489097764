/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { INTERACTION } from 'src/constants/interactions';
import { fields as _menuFields } from 'src/models/menu';
import { fields as _menuItemFields } from 'src/models/menuItem';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import * as ProjectNodeSelectors from 'src/selectors/projectNode';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';
import { compareByOrderProperty } from 'src/utils/compareArray';
import { getInteractionLabel } from 'src/utils/getInteractionStartTime';
import timelineFormat, { hmsmsToSeconds } from 'src/utils/timelineFormat';
import * as yup from 'yup';

export const useMenuInteractionForm = () => {
  const { t } = useTranslation();
  const menuFields = _menuFields(t);
  const menuItemFields = _menuItemFields(t);
  const emptyMenuItem = {
    title: 'item',
    action: INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_SPECIFIC,
    [menuItemFields.jumpTo.name]: '00:00:00.00',
    [menuItemFields.timeJumpToNode.name]: '00:00:00.00',
    [menuItemFields.link.name]: ''
  };
  const schema = yup
    .object()
    .shape({
      [menuFields.title.name]: yup.string().trim().label(menuFields.title.label).required(),
      items: yup.array().of(
        yup.object().shape({
          title: yup.string().trim().label(menuItemFields.title.label).required().max(100)
        })
      )
    })
    .required();
  const edittingInteraction = useSelector(EdittingInteractionSelectors.getConfig);
  const videoDuration = useSelector(VideoPlayerSelectors.getDuration);
  const nodeMenuButtonEnable = useSelector(NodeInteractionSelectors.getMenuButtonEnable);
  const dispatch = useDispatch();

  const nodeInfo = useSelector(NodeInteractionSelectors.getNode);
  const interactionList = useSelector(NodeInteractionSelectors.getInteractionList);
  const jumpToInteractionOptions = useMemo(
    () =>
      interactionList.map((inter) => ({
        label: getInteractionLabel(inter),
        value: inter.id_interaction
      })),
    [interactionList]
  );

  const nodeList = useSelector(ProjectNodeSelectors.getListJumpNode);
  const jumpToNodeOptions = useMemo(() =>
    nodeList.map((node) => ({
      label: node.name,
      value: node.id
    }))
  );

  // helper function
  const convertRedux2Form = useCallback((reduxValues) => {
    const isShowMenuButton = reduxValues.show_menu !== INTERACTION.MENU.MENU_BUTTON.LOCATION.HIDDEN;
    const formValues = { ...reduxValues };

    formValues.show_menu_button = isShowMenuButton;
    formValues.show_menu = isShowMenuButton ? reduxValues.show_menu : INTERACTION.MENU.MENU_BUTTON.LOCATION.TOP_RIGHT;
    formValues[menuFields.menuItems.name] = reduxValues[menuFields.menuItems.name]
      .sort(compareByOrderProperty)
      .map((item) => {
        let actionInfo = {};
        if (item.jump) {
          if (item.jump.type === INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_INTERACTION) {
            actionInfo = { interaction_id: item.jump.interaction_id };
          } else if (item.jump.type === INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_NODE) {
            actionInfo = {
              node_id: item.jump.node_id,
              time_jump_to_node: timelineFormat(item.jump.time_jump_to_node, 'hh:mm:ss.ms')
            };
          } else if (item.jump.type === INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_START) {
            actionInfo = {
              product_jump_to: nodeInfo.start_time
            };
          } else if (item.jump.type === INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_END) {
            actionInfo = {
              product_jump_to: nodeInfo.end_time
            };
          } else {
            actionInfo = {
              [menuItemFields.jumpTime.name]: timelineFormat(item.jump[menuItemFields.jumpTime.name], 'hh:mm:ss.ms'),
              [menuItemFields.jumpTo.name]: timelineFormat(item.jump[menuItemFields.jumpTo.name], 'hh:mm:ss.ms'),
              [menuItemFields.timeJumpToNode.name]: timelineFormat(
                item.jump[menuItemFields.timeJumpToNode.name],
                'hh:mm:ss.ms'
              )
            };
          }
        } else if (item.link !== undefined) {
          actionInfo = {
            [menuItemFields.link.name]: item.link || '',
            [menuItemFields.jumpTime.name]: '00:00:00.00',
            [menuItemFields.jumpTo.name]: '00:00:00.00',
            [menuItemFields.timeJumpToNode.name]: '00:00:00.00'
          };
        }
        return {
          // avoid confict with fieldAraay id
          itemID: item.id,
          title: item.title,
          action: item.jump
            ? item.jump.type
            : item.link !== undefined && item.link !== null
            ? INTERACTION.MENU.MENU_ITEM.ACTION.LINK
            : INTERACTION.MENU.MENU_ITEM.ACTION.CONTINUE,
          ...actionInfo
        };
      });
    return formValues;
  }, []);

  const convertMenuItemList2Redux = useCallback(() => {
    const formMenuItem = getValues('items');
    return formMenuItem.map((item, index) => convertMenuItem2Redux(item, index));
  }, [videoDuration]);

  const convertMenuItem2Redux = useCallback(
    (menuItem, index) => {
      const converted = {
        id: menuItem.itemID,
        title: menuItem.title,
        order: index
      };
      if (menuItem.action === INTERACTION.ACTION.LINK) {
        converted.link = menuItem.link || '';
        converted.jump = null;
        converted.continue = false;
      } else if (menuItem.action === INTERACTION.ACTION.JUMP_TO_INTERACTION) {
        converted.jump = {
          type: menuItem.action,
          interaction_id: menuItem.interaction_id
        };
        converted.link = null;
        converted.continue = false;
      } else if (menuItem.action === INTERACTION.ACTION.JUMP_TO_NODE) {
        converted.jump = {
          type: menuItem.action,
          node_id: menuItem.node_id,
          product_jump_to: videoDuration,
          time_jump_to_node: hmsmsToSeconds(menuItem.time_jump_to_node) || 0
        };
        converted.link = null;
        converted.continue = false;
      } else if (menuItem.action === INTERACTION.ACTION.CONTINUE) {
        converted.jump = null;
        converted.link = null;
        converted.continue = true;
      } else {
        let jumpToSecs;
        let timeJumpToNode;
        let productJumpTo;
        const jumpTimeSecs = videoDuration || 0;
        if (menuItem.action === INTERACTION.ACTION.JUMP_TO_START) {
          jumpToSecs = 0;
          productJumpTo = nodeInfo.start_time;
        } else if (menuItem.action === INTERACTION.ACTION.JUMP_TO_END) {
          jumpToSecs = videoDuration || 0;
          productJumpTo = nodeInfo.end_time;
        } else {
          timeJumpToNode = hmsmsToSeconds(menuItem.time_jump_to_node) || 0;
          jumpToSecs = hmsmsToSeconds(menuItem.jump_to) || 0;
        }
        converted.jump = {
          type: menuItem.action,
          jump_to: jumpToSecs,
          jump_time: jumpTimeSecs,
          product_jump_time: nodeInfo.end_time,
          product_jump_to: productJumpTo,
          time_jump_to_node: timeJumpToNode
        };
        converted.link = null;
        converted.continue = false;

        setValue(`items.${index}.jump_to`, timelineFormat(jumpToSecs, 'hh:mm:ss.ms'));
        setValue(`items.${index}.jump_time`, timelineFormat(jumpTimeSecs, 'hh:mm:ss.ms'));
        setValue(`items.${index}.time_jump_to_node`, timelineFormat(timeJumpToNode, 'hh:mm:ss.ms'));
      }
      return converted;
    },
    [videoDuration]
  );

  // form
  const { formState, control, watch, getValues, setValue, trigger } = useForm({
    defaultValues: convertRedux2Form(edittingInteraction),
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur'
  });
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: menuFields.menuItems.name
  });
  const watchFieldArray = watch([menuFields.menuItems.name]);
  const watchShowMenuButton = watch('show_menu_button', false);
  const itemsFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index]
  }));

  // handle
  const onSingleFieldSubmit = useCallback(async (field) => {
    let submitField = { ...field };
    const key = Object.keys(field)[0];
    if (key === 'show_menu_button') {
      const menuButtonLocation = getValues('show_menu');
      submitField = { show_menu: field[key] ? menuButtonLocation : INTERACTION.MENU.MENU_BUTTON.LOCATION.HIDDEN };
    }
    if (key === menuFields.title.name || key === menuFields.buttonName.name) {
      const rs = await trigger(key);
      if (!rs) {
        dispatch(EdittingInteractionActions.setValidateError('key'));
        return;
      }
      dispatch(EdittingInteractionActions.setValidateError(false));
    }
    dispatch(EdittingInteractionActions.setConfig(submitField));
  }, []);

  const onMenuItemFieldSubmit = useCallback(async (field) => {
    const [_, index, key] = Object.keys(field)[0].split('.');
    let submitField = { [key]: Object.values(field)[0] };

    if (key !== 'title') {
      const menuItems = getValues(`items.${index}`);
      submitField = convertMenuItem2Redux(menuItems, index);
    } else {
      const rs = await trigger('items');
      if (!rs) {
        dispatch(EdittingInteractionActions.setValidateError('items'));
        return;
      }
      dispatch(EdittingInteractionActions.setValidateError(false));
    }
    dispatch(EdittingInteractionActions.setSingleMenuItemConfig(submitField, index));
  }, []);

  const handleRemove = useCallback((idx) => {
    remove(idx);
    dispatch(EdittingInteractionActions.setMenuItemList(convertMenuItemList2Redux()));
  }, []);

  const handleSwap = useCallback((fromIdx, toIdx) => {
    swap(fromIdx, toIdx);
    dispatch(EdittingInteractionActions.setMenuItemList(convertMenuItemList2Redux()));
  }, []);

  const handleClickNewMenuItem = useCallback(() => {
    append(emptyMenuItem);
    const newReduxMenuItem = {
      title: 'item',
      jump: {
        type: INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_SPECIFIC,
        jump_to: 0,
        jump_time: 0
      },
      link: ''
    };
    dispatch(EdittingInteractionActions.addMenuItem(newReduxMenuItem));
  }, []);

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );
  const MenuItemFormProps = useMemo(
    () => ({
      formItems,
      handleSubmit: onMenuItemFieldSubmit,
      watch,
      handleRemove,
      handleSwap
    }),
    [formState]
  );

  const disabledMenuButtonCheckBox = useMemo(() => nodeMenuButtonEnable && !edittingInteraction.isNodeMenuButton, [
    edittingInteraction,
    nodeMenuButtonEnable
  ]);
  // Update redux right after user click edit menu
  useEffect(() => () => dispatch(EdittingInteractionActions.resetRequest()), []);
  return {
    t,
    formItems,
    itemsFields,
    watchShowMenuButton,
    watchFieldArray,
    menuFields,
    MenuItemFormProps,
    handleClickNewMenuItem,
    onSingleFieldSubmit,
    disabledMenuButtonCheckBox,
    jumpToInteractionOptions,
    jumpToNodeOptions
  };
};
