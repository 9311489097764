/*eslint-disable*/
import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';

export const PublishSettingsTypes = createTypes(`
  PUBLISH_SETTINGS_RESET

  PUBLISH_SETTINGS_FETCH_REQUEST
  PUBLISH_SETTINGS_FETCH_SUCCESS
  PUBLISH_SETTINGS_FETCH_FAILURE

  PUBLISH_SETTINGS_UPDATE_REQUEST
  PUBLISH_SETTINGS_UPDATE_SUCCESS
  PUBLISH_SETTINGS_UPDATE_FAILURE
`);

export const reset = () => ({
  type: PublishSettingsTypes.PUBLISH_SETTINGS_RESET
});

// Fetch
export const fetchSuccess = (publish) => ({
  type: PublishSettingsTypes.PUBLISH_SETTINGS_FETCH_SUCCESS,
  publish
});

export const fetchFailure = () => ({
  type: PublishSettingsTypes.PUBLISH_SETTINGS_FETCH_FAILURE
});

export const fetchRequest = (projectID) => async (dispatch) => {
  dispatch({ type: PublishSettingsTypes.PUBLISH_SETTINGS_FETCH_REQUEST });
  return axiosClient.get('/admin/publish', {
    params: {
      project_id: projectID
    }
  });
};

// Update
export const updateSuccess = (publish) => ({
  type: PublishSettingsTypes.PUBLISH_SETTINGS_UPDATE_SUCCESS,
  publish
});

export const updateFailure = () => ({
  type: PublishSettingsTypes.PUBLISH_SETTINGS_UPDATE_FAILURE
});

export const updateRequest = ({
  id,
  title,
  author,
  description,
  sharing_title,
  sharing_description,
  is_public,
  password,
  default_volume,
  autoplay
}) => async (dispatch) => {
  dispatch({ type: PublishSettingsTypes.PUBLISH_SETTINGS_UPDATE_REQUEST });
  return axiosClient.put(`/admin/publish/${id}`, {
    title,
    author,
    description,
    sharing_title,
    sharing_description,
    is_public,
    password,
    default_volume,
    autoplay
  });
};
