import StringField from 'src/models/fields/stringField';
import BooleanField from './fields/booleanField';
import IdField from './fields/idField';

const Label = (t, field) => t(`interaction.form.fields.${field}`);

export const fields = (t) => ({
  id: new IdField('id', Label(t, 'id')),
  type: new StringField('type'),
  title: new StringField('title', Label(t, 'title'), {
    max: 100
  }),
  firstField: new StringField('first_field', Label(t, 'firstField'), {
    max: 100
  }),
  secondField: new StringField('second_field', Label(t, 'secondField'), {
    max: 100
  }),
  thirdField: new StringField('third_field', Label(t, 'thirdField'), {
    max: 100
  }),
  emailField: new StringField('email_field', Label(t, 'emailField'), {
    max: 100
  }),
  receiveEmail: new StringField('receive_email', Label(t, 'receiveEmail'), {
    max: 100
  }),
  genericFeedback: new StringField('generic_feedback', Label(t, 'genericFeedback'), {
    max: 100
  }),
  fileUpload: new StringField('file_upload', Label(t, 'fileUpload'), {
    max: 100
  }),
  feedbackScreen: new BooleanField('feedback_screen', Label(t, 'feedbackScreen')),
  enableBackground: new BooleanField('enable_background', Label(t, 'enableBackground')),
  enableFeedback: new BooleanField('enable_feedback', Label(t, 'enableFeedback')),
  fadeIn: new BooleanField('style.fadeIn', Label(t, 'fadeIn')),
  fontSize: new StringField('style.fontSize', Label(t, 'fontSize'))
});

export default {
  fields
};
