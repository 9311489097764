/* eslint-disable no-unused-vars */
import {
  Box,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useCallback, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useModal } from 'src/hooks/useModal';
import { validate } from 'uuid';
import * as ProjectDetailSelectors from 'src/selectors/projectDetail';
import * as ProjectDetailActions from 'src/actions/projectDetail';
import CloseIcon from '@material-ui/icons/Close';
import UploadMediaProgress from 'src/pages/project-sidebar/components/UploadMediaProgress';

const SelectImageModal = ({ modalName, onChange }) => {
  const { t } = useTranslation();
  const { isShowing, closeModal } = useModal(modalName);
  const dispatch = useDispatch();
  const [selectedImg, setSelectedImg] = useState(null);
  const imgRef = useRef(null);
  const listImage = useSelector(ProjectDetailSelectors.getListImage);
  const projectID = useSelector(ProjectDetailSelectors.getProjectID);
  const uploadMediaQueueState = useSelector(ProjectDetailSelectors.getUploadMediaQueueState);
  const inputFileRef = useRef(null);
  const [inputFile, setInputFile] = useState('');

  const onConfirm = useCallback(() => {
    if (selectedImg) {
      onChange({ img: selectedImg, width: imgRef.current.naturalWidth, height: imgRef.current.naturalHeight });
    }
    closeModal(modalName);
  }, [closeModal, modalName, selectedImg]);
  const handleClose = useCallback(() => {
    closeModal(modalName);
  }, [modalName]);

  const handleUploadMedia = useCallback(
    (evt) => {
      const { files: _files } = evt.target;

      onUploadMedia({
        files: [_files[0]],
        projectID
      });
      setInputFile('');
    },
    [projectID]
  );

  const onUploadMedia = useCallback(
    async ({ files, folderID = null, projectID: _projectID, replace = false }) => {
      try {
        const index = await dispatch(
          ProjectDetailActions.uploadMediaRequest({ files, folderID, projectID: _projectID, replace })
        );
        await dispatch(ProjectDetailActions.uploadMediaSuccess());
        dispatch(ProjectDetailActions.removeUploadMediaQueue(index));
        dispatch(ProjectDetailActions.autoFetchRequest(projectID));
      } catch (err) {
        if (validate(err.message)) {
          dispatch(ProjectDetailActions.removeUploadMediaQueue(err.message));
        }
        dispatch(ProjectDetailActions.uploadMediaFailure());
      }
    },
    [projectID]
  );

  return (
    <>
      <img
        ref={imgRef}
        style={{
          display: 'none',
          height: 'auto',
          width: '100%'
        }}
        src={selectedImg ? `${process.env.REACT_APP_PUBLIC_URL}${selectedImg?.url}` : ''}
        alt="img fake"
      />
      <Dialog open={isShowing} onClose={() => handleClose()} scroll="paper" maxWidth="md" fullWidth>
        <DialogTitle
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            backgroundColor: '#87CDCF'
          }}
          id={`title-${modalName}`}
        >
          {t('common.actions.selectImage')}
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 4 }}>
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        <Box sx={{ m: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-start' }}>
                {t('common.actions.uploadAnImage')}
                <Button
                  disabled={uploadMediaQueueState.length}
                  color="primary"
                  onClick={() => inputFileRef.current.click()}
                  variant="contained"
                >
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    ref={inputFileRef}
                    value={inputFile}
                    name="media"
                    style={{ display: 'none' }}
                    onChange={handleUploadMedia}
                  />
                  {t('common.actions.browse')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={10}>
              {uploadMediaQueueState.length ? (
                <Box sx={{ mt: 4 }}>
                  <Box>
                    <UploadMediaProgress value={uploadMediaQueueState[0]?.progress} sx={{ height: '20px' }} />
                  </Box>
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={2} sx={{ mt: 1 }}>
              <Typography variant="h6">{t('interaction.image.selectingImage')}</Typography>
            </Grid>
            <Grid item xs={8} sx={{ mt: 1 }}>
              <TextField
                disabled
                value={selectedImg?.name}
                fullWidth
                InputProps={{
                  sx: { height: '35px' }
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <DialogContent dividers>
          <Grid container spacing={2}>
            {listImage
              .slice(0)
              .reverse()
              .map((img) => (
                <Grid id={img.id} item xs={4}>
                  <Box
                    sx={{
                      maxWidth: '280px',
                      height: '100%',
                      border: img.id === selectedImg?.id ? '3px solid #87CDCF' : '3px solid #d9d9d9',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      px: 2,
                      py: 1
                    }}
                    onClick={() => setSelectedImg(img)}
                  >
                    <img
                      style={{
                        height: 'auto',
                        width: '100%'
                      }}
                      src={`${process.env.REACT_APP_PUBLIC_URL}${img.url}`}
                      alt={img?.name}
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            // marginBottom: 20,
            justifyContent: 'center'
          }}
        >
          <Button sx={{ marginRight: '24px' }} color="secondary" variant="outlined" onClick={() => handleClose()}>
            {t('common.actions.dismiss')}
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm}>
            {t('common.actions.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

SelectImageModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SelectImageModal;
