/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Box, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Rnd } from 'react-rnd';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import RegexPattern from 'src/constants/RegexPattern';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { borderStyles, textStrokes } from '../../interactionsForm/text/TextInteractionStyleForm';
import './LinkVideoInteractionDisplay.css';
import {
  ResizeHandleBottomRight,
  ResizeHandleBottomLeft,
  ResizeHandleBottom,
  ResizeHandleTop,
  ResizeHandleLeft,
  ResizeHandleRight,
  ResizeHandleTopLeft,
  ResizeHandleTopRight
} from '../textInteractionDisplay/TextInteractionDisplay';

const LinkVideoInteractionDisplay = ({ config, videoBounding, isEditting, isPublishMode = false }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [jumpToScheduler, setJumpToScheduler] = useState(false);

  const [triggerDisplay, setTriggerDisplay] = useState(false);

  const [transitionCSS, setTransitionCSS] = useState('');

  const { widthCellGridState, showVideoGridState, videoCurrentTime, videoData } = useVideoPlayer();

  const { start_time, end_time, x, y, width, height, id_public, title } = config;

  const {
    textStroke,
    borderRadius,
    borderWidth,
    borderStyle,
    borderColor,
    paddingTop,
    fadeIn,
    fadeOut,
    background_color
  } = config.style;

  const useStyles = makeStyles(() => ({
    wrapperContent: {
      color: '#000',
      wordBreak: 'break-all',

      width: width,
      height: height,
      maxWidth: '100%',
      maxHeight: '100%',

      overflow: 'hidden',
      cursor: isEditting ? 'move' : 'pointer',
      position: 'absolute',
      zIndex: 100,
      backgroundColor: `rgba(${background_color?.r}, ${background_color?.g}, ${background_color?.b}, ${background_color?.a})`,
      '-webkit-text-stroke': textStrokes[textStroke].cssValue,
      borderRadius: parseInt(borderRadius, 10),
      borderWidth: parseInt(borderWidth, 10),
      borderStyle: borderStyles[borderStyle].cssValue,
      borderColor: `rgba(${borderColor?.r}, ${borderColor?.g}, ${borderColor?.b}, ${borderColor?.a})`,
      paddingTop: parseFloat(paddingTop)
    }
  }));

  const classes = useStyles();

  const handleChangeConfig = useCallback((newConfig) => {
    dispatch(EdittingInteractionActions.setConfig(newConfig));
  }, []);

  useEffect(() => {
    // if (showVideoGridState) {
    //   const xTemp = Math.trunc(x / widthCellGridState);
    //   const yTemp = Math.trunc(y / widthCellGridState);

    //   dispatch(EdittingInteractionActions.setConfig({ x: xTemp * widthCellGridState, y: yTemp * widthCellGridState }));
    // }
    if (showVideoGridState) {
      const newX = (Math.trunc(config.x / widthCellGridState) * widthCellGridState) / videoBounding.width;
      const newY = (Math.trunc(config.y / widthCellGridState) * widthCellGridState) / videoBounding.height;
      const newWidth = Math.trunc(config.width / widthCellGridState) * widthCellGridState;
      const newHeight = Math.trunc(config.height / widthCellGridState) * widthCellGridState;
      dispatch(EdittingInteractionActions.setConfig({ x: newX, y: newY, width: newWidth, height: newHeight }));
    }
  }, [showVideoGridState]);

  const actionTypeBindings = useCallback(() => {
    if (isPublishMode) {
      ReactGA.event({
        category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
        action: t(`googleAnalyticsActions.actions.link`) || '',
        label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${title}`,
        value: videoData.id
      });
    }

    if (!!id_public && !isEditting) {
      const reviewLink = `${process.env.REACT_APP_DOMAIN_URL}/v/${id_public}`;
      if (RegexPattern.URL.test(reviewLink)) {
        const newWindow = window.open(reviewLink, '_blank', 'noopener,noreferrer');

        if (newWindow) newWindow.opener = null;
      }
    }
  }, [id_public, isEditting, isPublishMode]);

  useEffect(() => {
    if (start_time > videoCurrentTime || end_time < videoCurrentTime) {
      if (triggerDisplay) {
        if (fadeOut) {
          setTransitionCSS('link-video-interaction-fadeOut');
        } else {
          setTransitionCSS('');
        }
      }
      setTriggerDisplay(false);
    } else {
      if (!triggerDisplay) {
        if (fadeIn) {
          setTransitionCSS('link-video-interaction-fadeIn');
        } else {
          setTransitionCSS('');
        }
      }
      setTriggerDisplay(true);
    }
  }, [videoCurrentTime, start_time, end_time, fadeIn, fadeOut]);

  useEffect(() => {
    if (isEditting) {
      dispatch(EdittingInteractionActions.setInitialVideoWidth(videoBounding.width));
    }
  }, []);
  return (
    // <Box
    //   className="waocon-interaction-bounding-box"
    //   sx={{
    //     visibility: triggerDisplay ? 'visible' : 'hidden',
    //     opacity: triggerDisplay ? 1 : 0
    //   }}
    // >
    <Rnd
      className="rnd"
      enableResizing={isEditting}
      disableDragging={!isEditting}
      {...(showVideoGridState ? { dragGrid: [widthCellGridState, widthCellGridState] } : {})}
      style={{
        zIndex: 2,
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        border: isEditting ? '2px dashed white' : 'none',
        cursor: 'auto !important',

        visibility: triggerDisplay ? 'visible' : 'hidden',
        opacity: triggerDisplay ? 1 : 0
      }}
      bounds="parent"
      size={{
        width: config.width,
        height: config.height
      }}
      position={{
        x: config.x * videoBounding.width,
        y: config.y * videoBounding.height
      }}
      minWidth="10%"
      minHeight="10%"
      onDragStop={(e, d) => {
        handleChangeConfig({ x: d.lastX / videoBounding.width, y: d.lastY / videoBounding.height });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        handleChangeConfig({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          x: position.x / videoBounding.width,
          y: position.y / videoBounding.height
        });
      }}
      resizeHandleComponent={{
        bottomRight: <ResizeHandleBottomRight />,
        bottomLeft: <ResizeHandleBottomLeft />,
        bottom: <ResizeHandleBottom />,
        top: <ResizeHandleTop />,
        left: <ResizeHandleLeft />,
        right: <ResizeHandleRight />,
        topLeft: <ResizeHandleTopLeft />,
        topRight: <ResizeHandleTopRight />
      }}
    >
      <Box
        id="link-interaction-content"
        className={`${classes.wrapperContent} ${transitionCSS}`}
        onClick={actionTypeBindings}
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          transform: `scale(${videoBounding.width / config.style.initialVideoWidth})`,
          transformOrigin: 'top left',
          userSelect: 'none'
        }}
      >
        {parse(config.content)}
        <Box
          className="link-video-interaction-jump-to-schedule-checked"
          sx={{ visibility: !isEditting && jumpToScheduler ? 'visible' : 'hidden' }}
        >
          <CheckIcon className="link-video-interaction-jump-to-schedule-checked-icon" />
        </Box>
      </Box>
    </Rnd>
    // </Box>
  );
};

LinkVideoInteractionDisplay.propTypes = {
  config: PropTypes.object.isRequired,
  videoBounding: PropTypes.object.isRequired,
  isEditting: PropTypes.bool,
  isPublishMode: PropTypes.bool
};

export default LinkVideoInteractionDisplay;
