import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as AccountActions from 'src/actions/account';
import * as AccountSelectors from 'src/selectors/account';
import { useNotify } from './useNotify';

export const useAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorNotify, successNotify } = useNotify();

  const loadingState = useSelector(AccountSelectors.getLoading);
  const errorState = useSelector(AccountSelectors.getError);
  const listState = useSelector(AccountSelectors.getList);
  const accountDetailState = useSelector(AccountSelectors.getAccountDetail);

  const pageState = useSelector(AccountSelectors.getPage);
  const filterState = useSelector(AccountSelectors.getFilter);
  const totalPageState = useSelector(AccountSelectors.getTotalPage);

  const onFetch = useCallback((_filter, _page) => {
    dispatch(AccountActions.listRequest(_filter, _page));
  }, []);

  const onPageChange = useCallback((evt, value) => {
    dispatch(AccountActions.setPage(value));
  }, []);

  const onSearch = useCallback((formData) => {
    dispatch(AccountActions.setFilter(formData));
  }, []);

  const onCreate = useCallback(
    async (formData) => {
      try {
        await dispatch(AccountActions.createRequest(formData));
        dispatch(AccountActions.createSuccess());
        successNotify(t('account.request.create.success'));
        navigate('/app/accounts', { replace: true });
      } catch (err) {
        errorNotify(t('account.request.create.failure'));
        dispatch(AccountActions.createFailure());
      }
    },
    [dispatch, navigate]
  );

  const onUpdate = useCallback(
    async (formData, id) => {
      try {
        await dispatch(AccountActions.editRequest(formData, id));

        dispatch(AccountActions.editSuccess());
        navigate('/app/accounts', { replace: true });
        successNotify(t('account.request.update.success'));
      } catch (err) {
        errorNotify(t('account.request.update.failure'));
        dispatch(AccountActions.editFailure());
      }
    },
    [dispatch, navigate]
  );

  const onDelete = useCallback(
    async (id) => {
      try {
        await dispatch(AccountActions.deleteRequest(id));

        dispatch(AccountActions.deleteSuccess());
        navigate('/app/accounts', { replace: true });
        successNotify(t('account.request.delete.success'));
        onFetch();
      } catch (err) {
        errorNotify(t('account.request.delete.failure'));
        dispatch(AccountActions.deleteFailure());
      }
    },
    [dispatch, navigate]
  );

  const onDetail = useCallback(
    async (id) => {
      try {
        const { user } = await dispatch(AccountActions.detailRequest(id));

        dispatch(AccountActions.detailSuccess(user));
      } catch (err) {
        errorNotify(t('account.request.detail.failure'));
        dispatch(AccountActions.detailFailure());
      }
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    onFetch(filterState, pageState);
  }, [filterState, pageState]);

  return {
    loadingState,
    errorState,
    listState,
    accountDetailState,
    pageState,
    filterState,
    totalPageState,

    onFetch,
    onSearch,

    onCreate,
    onUpdate,
    onDelete,
    onDetail,

    pagination: {
      page: pageState,
      totalPage: totalPageState,
      onPageChange
    }
  };
};
