import { DialogContent, DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'src/hooks/useModal';

const AlertModal = ({ modalName, confirmAction, title, message, onClose }) => {
  const { t } = useTranslation();
  const { isShowing, closeModal } = useModal(modalName);

  const onConfirm = useCallback(() => {
    confirmAction();
    closeModal(modalName);
  }, [confirmAction, closeModal, modalName]);
  const handleClose = useCallback(() => {
    if (onClose) onClose();
    closeModal(modalName);
  }, [modalName]);
  return (
    <>
      <Dialog
        open={isShowing}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          style={{
            textAlign: 'center',
            justifyContent: 'center'
          }}
          id={`title-${modalName}`}
        >
          {title || t('common.modal.confirmation')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center" id={`content-${modalName}`}>
            {message || t('modal.confirmDelete')}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            marginBottom: 20,
            justifyContent: 'center'
          }}
        >
          <Button sx={{ marginRight: '24px' }} color="secondary" variant="outlined" onClick={() => handleClose()}>
            {t('common.actions.dismiss')}
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm}>
            {t('common.actions.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AlertModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  confirmAction: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

export default AlertModal;
