/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import * as NodeInteractionActions from 'src/actions/nodeInteraction';
import { INTERACTION } from 'src/constants/interactions';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import FormInteractionForm from 'src/containers/interactionsForm/form/FormInteractionForm';
import HighlightInteractionForm from 'src/containers/interactionsForm/highlight/HighlightInteractionForm';
import ImageInteractionForm from 'src/containers/interactionsForm/image/ImageInteractionForm';
import LinkVideoInteractionForm from 'src/containers/interactionsForm/link-video/LinkVideoInteractionForm';
import MenuInteractionForm from 'src/containers/interactionsForm/menu/MenuInteractionForm';
import PauseInteractionForm from 'src/containers/interactionsForm/pause/PauseInteractionForm';
import TextInteractionForm from 'src/containers/interactionsForm/text/TextInteractionForm';
import JumpToInteractionForm from 'src/containers/interactionsForm/jump-to/JumpToInteractionForm';
import TransparentButtonInteractionForm from 'src/containers/interactionsForm/transparent-button/TransparentButtonInteractionForm';
import ZoomInteractionForm from 'src/containers/interactionsForm/zoom/ZoomInteractionForm';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import * as VideoPlayerSelector from 'src/selectors/videoPlayer';
import { ReactComponent as FormIcon } from 'src/static/interactions/form.svg';
import { ReactComponent as HightlightIcon } from 'src/static/interactions/hightlight.svg';
import { ReactComponent as ImageIcon } from 'src/static/interactions/image.svg';
import { ReactComponent as JumpIcon } from 'src/static/interactions/jump.svg';
import { ReactComponent as LinkIcon } from 'src/static/interactions/link.svg';
import { ReactComponent as MenuIcon } from 'src/static/interactions/menu.svg';
import { ReactComponent as PauseIcon } from 'src/static/interactions/pause.svg';
import { ReactComponent as TextIcon } from 'src/static/interactions/text.svg';
import { ReactComponent as TransparentIcon } from 'src/static/interactions/transparent.svg';
import { ReactComponent as ZoomIcon } from 'src/static/interactions/zoom.svg';
import videoController from 'src/utils/videoController';
import { useNotify } from './useNotify';

const _interactions = (t) => ({
  menu: {
    name: t('interaction.label.menu'),
    icon: <MenuIcon />,
    component: <MenuInteractionForm />,
    defaultValues: (clickTime) => ({
      type: INTERACTION.TYPE.MENU,
      title: 'Menu',
      show_menu: INTERACTION.MENU.MENU_BUTTON.LOCATION.TOP_RIGHT,
      menu_button_location: INTERACTION.MENU.MENU_BUTTON.LOCATION.TOP_RIGHT,
      close_menu: true,
      number: false,
      pause: true,
      position: INTERACTION.MENU.POSITION.LEFT,
      button_name: '',
      start_time: clickTime,
      end_time: clickTime + INTERACTION.MENU.DURATION,
      items: [
        {
          title: 'item',
          jump: {
            type: INTERACTION.MENU.MENU_ITEM.ACTION.JUMP_TO_SPECIFIC,
            jump_time: clickTime,
            jump_to: 0,
            time_jump_to_node: 0
          },
          order: 0,
          [INTERACTION.MENU.MENU_ITEM.ACTION_KEY[INTERACTION.MENU.MENU_ITEM.ACTION.LINK]]: ''
        }
      ]
    })
  },
  jump_to: {
    name: t('interaction.label.jumpTo'),
    icon: <JumpIcon />,
    component: <JumpToInteractionForm />,
    defaultValues: (clickTime) => ({
      type: INTERACTION.TYPE.JUMP_TO,
      jump: {
        title: 'Jump',
        type: INTERACTION.JUMP_TO.ACTION.JUMP_TO_SPECIFIC,
        jump_time: clickTime,
        jump_to: 0
      }
    })
  },
  text: {
    name: t('interaction.label.text'),
    icon: <TextIcon />,
    component: <TextInteractionForm />,
    defaultValues: (clickTime, videoDuration) => ({
      type: INTERACTION.TYPE.TEXT,
      title: 'Text',
      content: '<p style="text-align: center;">Your text here</p>',
      start_time: clickTime,
      end_time:
        clickTime + INTERACTION.TEXT.DEFAULT_DURATION > videoDuration
          ? videoDuration
          : clickTime + INTERACTION.TEXT.DEFAULT_DURATION,
      jump: {
        type: INTERACTION.ACTION.JUMP_TO_SPECIFIC,
        jump_time: clickTime,
        jump_to: 0
      },
      link: '',
      background_color: {
        r: 255,
        g: 255,
        b: 255,
        a: 1
      },
      style: {
        textStroke: 0,
        borderRadius: 16,
        borderWidth: 0,
        borderStyle: 0,
        borderColor: {
          r: 65,
          g: 138,
          b: 201,
          a: 1
        },
        paddingTop: 0,
        fadeIn: false,
        fadeOut: false
      },
      x: 0.35,
      y: 0.7,
      width: 200,
      height: 40
    })
  },
  image: {
    name: t('interaction.label.image'),
    icon: <ImageIcon />,
    component: <ImageInteractionForm />,
    defaultValues: (clickTime) => ({
      type: INTERACTION.TYPE.IMAGE,
      start_time: clickTime,
      end_time: clickTime + INTERACTION.IMAGE.MIN_DURATION,
      title: 'Image',
      jump: null,
      link: null,
      image: null,
      style: {
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: INTERACTION.STYLE.DEFAULT_COLOR_RGBA,
        borderRadius: 0,
        opacity: 100,
        fadeIn: false,
        fadeOut: false,
        fullScreen: false
      },
      height: 0.4,
      width: 0.4,
      x: 0,
      y: 0
    })
  },
  pause: {
    name: t('interaction.label.pauseScreen'),
    icon: <PauseIcon />,
    component: <PauseInteractionForm />,
    defaultValues: (clickTime, videoDuration, videoBounding) => ({
      type: INTERACTION.TYPE.PAUSE,
      start_time: clickTime,
      end_time: clickTime + 0.5 > videoDuration ? videoDuration : clickTime + INTERACTION.FORM.DURATION,
      title: 'Pause screen',
      content: '<p style="text-align: center;">Your text here</p>',
      style: {
        textStroke: INTERACTION.STYLE.TEXT_STROKE.NONE,
        borderRadius: 0,
        borderWidth: 0,
        borderStyle: INTERACTION.STYLE.BORDER_STYLE.SOLID,
        borderColor: INTERACTION.STYLE.DEFAULT_COLOR_RGBA,
        backgroundColor: INTERACTION.STYLE.COLOR_WHITE_RGBA,
        topPadding: 0,
        fadeIn: false,
        fadeOut: false
      },
      height: videoBounding.height,
      width: videoBounding.width,
      x: 0,
      y: 0
    })
  },
  transparent_button: {
    name: t('interaction.label.transparentButton'),
    icon: <TransparentIcon />,
    component: <TransparentButtonInteractionForm />,
    defaultValues: (clickTime, videoDuration) => ({
      type: INTERACTION.TYPE.TRANSPARENT_BUTTON,
      title: 'Transparent button',
      start_time: clickTime,
      end_time:
        clickTime + INTERACTION.TRANSPARENT_BUTTON.DEFAULT_DURATION > videoDuration
          ? videoDuration
          : clickTime + INTERACTION.TRANSPARENT_BUTTON.DEFAULT_DURATION,
      // jump: {
      //   type: INTERACTION.ACTION.JUMP_TO_SPECIFIC,
      //   jump_time: 10,
      //   jump_to: 5
      // },
      jump: null,
      link: null,
      style: {
        borderRadius: 0,
        borderWidth: 0,
        borderStyle: 0,
        borderColor: {
          r: 65,
          g: 138,
          b: 201,
          a: 1
        }
      },
      x: 0,
      y: 0,
      width: 0.4,
      height: 0.4
    })
  },
  highlight: {
    name: t('interaction.label.hightlight'),
    icon: <HightlightIcon />,
    component: <HighlightInteractionForm />,
    defaultValues: (clickTime) => ({
      type: INTERACTION.TYPE.HIGHLIGHT,
      start_time: clickTime,
      end_time: clickTime + INTERACTION.HIGHLIGHT.MIN_DURATION,
      title: 'Highlight',
      jump: null,
      link: null,
      style: {
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: INTERACTION.STYLE.DEFAULT_COLOR_RGBA,
        borderRadius: 0,
        backgroundColor: INTERACTION.STYLE.DEFAULT_COLOR_RGBA,
        fadeIn: false,
        fadeOut: false
      },
      height: 0.4,
      width: 0.4,
      x: 0,
      y: 0
    })
  },
  zoom: {
    name: t('interaction.label.zoom'),
    icon: <ZoomIcon />,
    component: <ZoomInteractionForm />,
    defaultValues: (clickTime, videoDuration) => ({
      type: INTERACTION.TYPE.ZOOM,
      title: 'Zoom in',
      start_time: clickTime,
      end_time:
        clickTime + INTERACTION.ZOOM.DEFAULT_DURATION > videoDuration
          ? videoDuration
          : clickTime + INTERACTION.ZOOM.DEFAULT_DURATION,
      is_preview: false,
      style: {
        borderRadius: 0,
        borderWidth: 0,
        borderStyle: 0,
        borderColor: {
          r: 65,
          g: 138,
          b: 201,
          a: 1
        }
      },
      x: 0,
      y: 0,
      width: 0.25,
      height: 0.25
    })
  },
  link: {
    name: t('interaction.label.linkVideo'),
    icon: <LinkIcon />,
    component: <LinkVideoInteractionForm />,
    defaultValues: (clickTime, videoDuration) => ({
      type: INTERACTION.TYPE.LINK,
      title: 'Linked Video',
      content: '<p style="text-align: center;">Your text here</p>',
      start_time: clickTime,
      end_time:
        clickTime + INTERACTION.LINK_VIDEO.DEFAULT_DURATION > videoDuration
          ? videoDuration
          : clickTime + INTERACTION.LINK_VIDEO.DEFAULT_DURATION,
      id_public: '',
      style: {
        textStroke: 0,
        borderRadius: 16,
        borderWidth: 0,
        borderStyle: 0,
        borderColor: {
          r: 65,
          g: 138,
          b: 201,
          a: 1
        },
        background_color: {
          r: 255,
          g: 255,
          b: 255,
          a: 1
        },
        paddingTop: 0,
        fadeIn: false,
        fadeOut: false
      },
      x: 0.35,
      y: 0.7,
      width: 200,
      height: 40
    })
  },
  form: {
    name: t('interaction.label.form'),
    icon: <FormIcon />,
    component: <FormInteractionForm />,
    defaultValues: (clickTime, videoDuration) => ({
      type: INTERACTION.TYPE.FORM,
      start_time: clickTime,
      end_time: clickTime + 0.5 > videoDuration ? videoDuration : clickTime + 0.5,
      title: 'Form title',
      first_field: 'First field',
      second_field: 'Second field',
      third_field: 'Third field',
      email_field: 'Email address',
      file_upload: 'Upload a PDF or Word file',
      receive_email: 'yourEmail@gmail.com',
      feedback_screen: true,
      enable_background: true,
      enable_feedback: true,
      generic_feedback: '',
      style: {
        fontSize: 0.04,
        fadeIn: false
      }
    })
  }
});

export const useInteractionsForm = () => {
  const { errorNotify } = useNotify();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const videoBounding = useSelector(VideoPlayerSelector.getVideoBounding);
  const interactions = _interactions(t, videoBounding);
  const videoDuration = useSelector(VideoPlayerSelector.getDuration);
  const mode = useSelector(NodeInteractionSelectors.getMode);
  const videoControl = videoController.getInstance();
  const videoHasStarted = useSelector(VideoPlayerSelector.getHasStarted);
  const onInteractionClick = useCallback(
    (inter) => {
      // handle case user havent click big play button but clicked create new interaction
      if (!videoHasStarted) {
        videoController.getInstance().play();
        videoController.getInstance().pause();
      }
      videoController.getInstance().pause();
      dispatch(NodeInteractionActions.setMode(NODE_EDIT.MODE.CREATE));
      dispatch(
        EdittingInteractionActions.setConfig(
          interactions[inter].defaultValues(
            Math.max(videoControl.getState().player.currentTime - 0.2, 0),
            videoDuration,
            videoBounding
          )
        )
      );
    },
    [videoControl, videoDuration, videoHasStarted, videoBounding]
  );
  const backtoListMode = useCallback(() => {
    dispatch(NodeInteractionActions.setMode(NODE_EDIT.MODE.LIST));
    dispatch(EdittingInteractionActions.resetRequest());
  }, []);
  const currentInteractionType = useSelector(EdittingInteractionSelectors.getType);
  const currentInteractionName = useMemo(() => INTERACTION.KEY[currentInteractionType], [currentInteractionType]);
  const validateError = useSelector(EdittingInteractionSelectors.getValidateError);
  const onSubmitCreate = useCallback(async () => {
    try {
      await dispatch(NodeInteractionActions.createRequest());
      dispatch(NodeInteractionActions.setMode(NODE_EDIT.MODE.LIST));
      dispatch(EdittingInteractionActions.resetRequest());
    } catch (error) {
      if (error.message === 'interaction.link.validate.publicId.invalid') {
        errorNotify(t(error.message));
      }
    }
  }, []);

  const onSubmitEdit = useCallback(async () => {
    try {
      await dispatch(NodeInteractionActions.updateRefetchRequest());
      dispatch(NodeInteractionActions.setMode(NODE_EDIT.MODE.LIST));
      dispatch(EdittingInteractionActions.resetRequest());
    } catch (error) {
      if (error.message === 'interaction.link.validate.publicId.invalid') {
        errorNotify(t(error.message));
      }
    }
  }, []);

  return {
    t,
    mode,
    onInteractionClick,
    backtoListMode,
    currentInteractionName,
    interactions,
    onSubmitCreate,
    onSubmitEdit,
    validateError
  };
};
