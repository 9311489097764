import { Box, DialogContent, DialogContentText, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'src/hooks/useModal';

const MediaConfirmModal = ({ modalName, replaceCopy, addCopy, title, message, files }) => {
  const { t } = useTranslation();
  const { isShowing, closeModal } = useModal(modalName);

  const handleReplaceCopy = useCallback(() => {
    replaceCopy();
    closeModal(modalName);
  }, [replaceCopy, closeModal]);

  const handleAddCopy = useCallback(() => {
    addCopy();
    closeModal(modalName);
  }, [addCopy, closeModal]);

  return (
    <>
      <Dialog
        open={isShowing}
        onClose={() => closeModal(modalName)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          style={{
            textAlign: 'center',
            justifyContent: 'center'
          }}
          id={`title-${modalName}`}
        >
          {title || t('common.modal.confirmation')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center" id={`content-${modalName}`}>
            {message || t('modal.confirmDelete')}
          </DialogContentText>

          <Box
            sx={{
              height: 120,
              bgcolor: '#ccc',
              p: 1,
              overflow: 'auto'
            }}
          >
            {files?.map((file, idx) => (
              // eslint-disable-next-line
              <ListItemText key={idx}>{file.name}</ListItemText>
            ))}
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            marginBottom: 20,
            justifyContent: 'center'
          }}
        >
          <Button color="primary" variant="contained" onClick={handleReplaceCopy}>
            {t('common.actions.replaceCopy')}
          </Button>

          <Button color="primary" variant="contained" onClick={handleAddCopy}>
            {t('common.actions.addCopy')}
          </Button>

          <Button
            sx={{ marginRight: '24px' }}
            color="secondary"
            variant="outlined"
            onClick={() => closeModal(modalName)}
          >
            {t('common.actions.dismiss')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

MediaConfirmModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  modalName: PropTypes.string.isRequired,
  replaceCopy: PropTypes.func.isRequired,
  addCopy: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired
};

export default MediaConfirmModal;
