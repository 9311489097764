import { Box } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { LayoutContainer, LayoutHeader } from 'src/components/layout';
import { routes } from 'src/configs';
import { useAccount } from 'src/hooks';
import UpdateForm from './components/UpdateForm';

const AcccountUpdate = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { onDetail } = useAccount();

  useEffect(
    useCallback(async () => {
      await onDetail(id);
    }),
    [id]
  );

  return (
    <LayoutContainer
      title={t('breadcrumbs.accounts.update.title')}
      header={
        <LayoutHeader
          title={t('breadcrumbs.accounts.update.title')}
          breadcrumbs={[
            {
              path: routes.dashboard,
              name: t('breadcrumbs.dashboard')
            },
            {
              path: routes.accounts,
              name: t('breadcrumbs.accounts.title')
            },
            {
              path: routes.accountUpdate,
              name: t('breadcrumbs.accounts.update.title')
            }
          ]}
        />
      }
    >
      <Box px={3}>
        <UpdateForm id={id} />
      </Box>
    </LayoutContainer>
  );
};

export default AcccountUpdate;
