/* eslint-disable */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import AreaField from 'src/components/FormControls/AreaField';
import InputField from 'src/components/FormControls/InputField';
import { usePublishSettings } from 'src/hooks/publishSettings';
import * as yup from 'yup';

const BasicInfo = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const { publishState, onUpdate } = usePublishSettings();

  const initialForm = useMemo(() => {
    const { title, author, description } = publishState || {};

    return {
      title: title || '',
      author: author || '',
      description: description || ''
    };
  }, [publishState]);

  const schema = yup.object().shape({
    title: yup
      .string()
      .nullable()
      .trim()
      .max(100, t('publicSettings.basicInfo.validate.title.max', { max: 100 })),
    author: yup
      .string()
      .nullable()
      .trim()
      .max(100, t('publicSettings.basicInfo.validate.author.max', { max: 100 })),
    description: yup
      .string()
      .nullable()
      .trim()
      .max(200, t('publicSettings.basicInfo.validate.description.max', { max: 200 }))
  });

  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: initialForm,
    resolver: yupResolver(schema)
  });

  const { isDirty } = formState;

  const controlFields = useMemo(
    () => ({
      control,
      formState
    }),
    [control, formState]
  );

  const onSubmit = useCallback(async (formValues) => {
    const { title, author, description } = formValues;
    const publish = await onUpdate({ id, title, author, description });
    reset({ title: publish.title, author: publish.author, description: publish.description });
  });

  const handleCancelClick = useCallback(() => {
    reset(initialForm);
  }, [initialForm]);

  useEffect(() => {
    return () => {
      reset(initialForm);
    };
  }, []);

  return (
    <form noValidate>
      <InputField name="title" label={t('publicSettings.basicInfo.title')} {...controlFields} />
      <InputField name="author" label={t('publicSettings.basicInfo.author')} {...controlFields} />
      <AreaField
        style={{ resize: 'vertical', width: 350 }}
        minRows={5}
        name="description"
        label={t('publicSettings.basicInfo.description')}
        {...controlFields}
      />

      <Box sx={{ display: isDirty ? '' : 'none' }}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)} sx={{ mr: 1 }}>
          {t('publicSettings.basicInfo.saveAndApply')}
        </Button>
        <Button variant="contained" onClick={handleCancelClick} sx={{ ml: 1 }}>
          {t('publicSettings.basicInfo.cancel')}
        </Button>
      </Box>
    </form>
  );
};

export default BasicInfo;
