/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';
import _get from 'lodash/get';
import { INTERACTION } from 'src/constants/interactions';
import { getDataSubmit } from 'src/utils/getInteractionSubmit';

export const NodeInteractionTypes = createTypes(`
  NODE_INTERACTION_CREATE_REQUEST
  NODE_INTERACTION_CREATE_SUCCESS
  NODE_INTERACTION_CREATE_FAILURE

  NODE_INTERACTION_FETCH_REQUEST
  NODE_INTERACTION_FETCH_SUCCESS
  NODE_INTERACTION_FETCH_FAILURE

  NODE_INTERACTION_HOT_UPDATE_REQUEST
  NODE_INTERACTION_HOT_UPDATE_SUCCESS
  NODE_INTERACTION_HOT_UPDATE_FAILURE

  NODE_INTERACTION_UPDATE_REFETCH_REQUEST
  NODE_INTERACTION_UPDATE_REFETCH_SUCCESS
  NODE_INTERACTION_UPDATE_REFETCH_FAILURE

  NODE_INTERACTION_SET_MODE

  NODE_INTERACTION_HOT_DELETE_REQUEST
  NODE_INTERACTION_HOT_DELETE_SUCCESS
  NODE_INTERACTION_HOT_DELETE_FAILURE

  NODE_INTERACTION_DELETE_REFETCH_REQUEST
  NODE_INTERACTION_DELETE_REFETCH_SUCCESS
  NODE_INTERACTION_DELETE_REFETCH_FAILURE

  NODE_INTERACTION_SET_MENU_BUTTON_ENABLE

  NODE_INTERACTION_RESET_REQUEST

  NODE_INTERACTION_UPDATE_ORDER_REQUEST
  NODE_INTERACTION_UPDATE_ORDER_SUCCESS
  NODE_INTERACTION_UPDATE_ORDER_FAILURE

  NODE_INTERACTION_UPDATE_ACTIVE_REQUEST
  NODE_INTERACTION_UPDATE_ACTIVE_SUCCESS
  NODE_INTERACTION_UPDATE_ACTIVE_FAILURE
`);

export const createSuccess = () => ({ type: NodeInteractionTypes.NODE_INTERACTION_CREATE_SUCCESS });

export const createFailure = (error) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_CREATE_FAILURE,
  error
});

export const createRequest = () => async (dispatch, getState) => {
  const nodeID = _get(getState(), 'nodeInteraction.node.id', null);
  const newInteraction = _get(getState(), 'edittingInteraction.config', null);
  const nextOrder = _get(getState(), 'nodeInteraction.node.interactions.length', 0);
  const dataSubmit = { ...getDataSubmit(nodeID, newInteraction), order: nextOrder };
  try {
    dispatch({ type: NodeInteractionTypes.NODE_INTERACTION_CREATE_REQUEST });
    const rs = await axiosClient.post(`/admin/interaction`, dataSubmit);
    dispatch(createSuccess());
    const currentNodeID = _get(getState(), 'nodeInteraction.node.id', null);
    if (!currentNodeID) return;
    dispatch(fetchRequest(currentNodeID));
  } catch (e) {
    dispatch(createFailure(e));
    dispatch({ type: NodeInteractionTypes.NODE_INTERACTION_UPDATE_REFETCH_FAILURE });

    if (e.validate['link.id_public'][0] === 'id_public_exists') {
      throw new Error('interaction.link.validate.publicId.invalid');
    }
  }
};

export const updateRefetchSuccess = () => ({ type: NodeInteractionTypes.NODE_INTERACTION_UPDATE_REFETCH_SUCCESS });

export const updateRefetchFailure = (error) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_UPDATE_REFETCH_FAILURE,
  error
});

export const updateRefetchRequest = () => async (dispatch, getState) => {
  const editedInteraction = _get(getState(), 'edittingInteraction.config', null);
  const nodeID = _get(getState(), 'nodeInteraction.node.id', null);

  dispatch({ type: NodeInteractionTypes.NODE_INTERACTION_UPDATE_REFETCH_REQUEST });
  if (editedInteraction.type === INTERACTION.TYPE.MENU) {
    const newMenuItems = editedInteraction.items;
    const oldMenuItems = _get(getState(), 'nodeInteraction.node.interactions', []).find(
      (inter) => inter.id === editedInteraction.id
    ).items;

    const newMenuItemsID = newMenuItems.map((item) => item.id);
    const createItems = newMenuItems.filter((item) => !item.id);
    const deletedItems = oldMenuItems.filter((item) => !newMenuItemsID.includes(item.id));
    const updateItems = newMenuItems.filter((item) => !!item.id);

    await Promise.all(
      createItems.map((item) => axiosClient.post(`/admin/menu-item`, { ...item, menu_id: editedInteraction.id }))
    );
    await Promise.all(deletedItems.map((item) => axiosClient.delete(`/admin/menu-item/${item.id}`)));
    await Promise.all(updateItems.map((item) => axiosClient.put(`/admin/menu-item/${item.id}`, item)));

    delete editedInteraction.items;
  }

  const dataSubmit = getDataSubmit(nodeID, editedInteraction);

  try {
    const rs = await axiosClient.put(`/admin/interaction/${editedInteraction.id_interaction}`, dataSubmit);
    // run into error when edit menu, still dont know why
    // dispatch(updateRefetchSuccess());
    const currentNodeID = _get(getState(), 'nodeInteraction.node.id', null);
    if (!currentNodeID) return;
    dispatch(fetchRequest(currentNodeID));
  } catch (e) {
    dispatch(updateRefetchFailure(e));
    if (e.validate['link.id_public'][0] === 'id_public_exists') {
      throw new Error('interaction.link.validate.publicId.invalid');
    }
  }
};

export const fetchSuccess = (node) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_FETCH_SUCCESS,
  node
});

export const fetchFailure = (error) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_FETCH_FAILURE,
  error
});

export const fetchRequest = (nodeID) => async (dispatch, getState) => {
  try {
    dispatch({ type: NodeInteractionTypes.NODE_INTERACTION_FETCH_REQUEST });
    const rs = await axiosClient.get(`/admin/node/${nodeID}`);

    // get node video info
    const listVideo = _get(getState(), 'projectDetail.project.videos', []);
    const nodeVideo = listVideo.find((video) => video.id === parseInt(rs.node.video_id, 10));

    // get menu button enable
    const menuButtonEnable = rs.node.interactions.some((interaction) => {
      if (
        interaction.type === INTERACTION.TYPE.MENU &&
        interaction.show_menu !== INTERACTION.MENU.MENU_BUTTON.LOCATION.HIDDEN
      ) {
        interaction.isNodeMenuButton = true;
        return true;
      }
      return false;
    });

    dispatch(fetchSuccess({ ...rs.node, video_name: nodeVideo?.name, menuButtonEnable }));
  } catch (e) {
    dispatch(fetchFailure(e));
  }
};

export const setMode = (mode) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_SET_MODE,
  mode
});

export const hotUpdateSuccess = () => ({
  type: NodeInteractionTypes.NODE_INTERACTION_HOT_UPDATE_SUCCESS
});

export const hotUpdateFailure = (error, prevInteraction) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_HOT_UPDATE_FAILURE,
  error,
  prevInteraction
});

export const hotUpdateRequest = ({ interactionID, config }) => async (dispatch, getState) => {
  const prevInteraction = _get(getState(), 'nodeInteraction.node.interactions', []).find(
    (interact) => interact.id_interaction === interactionID
  );

  let putData = {};
  if (prevInteraction.type === INTERACTION.TYPE.JUMP_TO) {
    putData = {
      jump: {
        ...config
      }
    };
  } else {
    putData = {
      [INTERACTION.KEY[config.type]]: {
        ...config,
        type: undefined
      }
    };
  }

  try {
    dispatch({
      type: NodeInteractionTypes.NODE_INTERACTION_HOT_UPDATE_REQUEST,
      interaction: { interactionID, config }
    });
    const rs = await axiosClient.put(`/admin/interaction/${interactionID}`, putData);
    dispatch(hotUpdateSuccess(rs));
  } catch (e) {
    dispatch(hotUpdateFailure(e, prevInteraction));
  }
};

export const hotDeleteSuccess = () => ({
  type: NodeInteractionTypes.NODE_INTERACTION_HOT_DELETE_SUCCESS
});

export const hotDeleteFailure = (error, prevInteraction) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_HOT_DELETE_FAILURE,
  error,
  prevInteraction
});

export const hotDeleteRequest = (interactionID) => async (dispatch, getState) => {
  const prevInteraction = _get(getState(), 'nodeInteraction.node.interactions', []).find(
    (interact) => interact.id_interaction === interactionID
  );

  try {
    dispatch({
      type: NodeInteractionTypes.NODE_INTERACTION_HOT_DELETE_REQUEST,
      interactionID
    });
    const rs = await axiosClient.delete(`/admin/interaction/${interactionID}`);
    dispatch(hotDeleteSuccess(rs));
  } catch (e) {
    dispatch(hotDeleteFailure(e, prevInteraction));
  }
};

export const deleteRefetchSuccess = () => ({
  type: NodeInteractionTypes.NODE_INTERACTION_DELETE_REFETCH_SUCCESS
});

export const deleteRefetchFailure = (error) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_DELETE_REFETCH_FAILURE,
  error
});

export const deleteRefetchRequest = (interactionID) => async (dispatch, getState) => {
  const nodeID = _get(getState(), 'nodeInteraction.node.id', null);
  try {
    dispatch({
      type: NodeInteractionTypes.NODE_INTERACTION_DELETE_REFETCH_REQUEST
    });
    const rs = await axiosClient.delete(`/admin/interaction/${interactionID}`);
    dispatch(deleteRefetchSuccess(rs));
    dispatch(fetchRequest(nodeID));
  } catch (e) {
    dispatch(deleteRefetchFailure(e));
  }
};

export const setMenuButtonEnable = (value) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_SET_MENU_BUTTON_ENABLE,
  value
});
export const resetRequest = () => ({
  type: NodeInteractionTypes.NODE_INTERACTION_RESET_REQUEST
});

export const updateOrderSuccess = () => ({
  type: NodeInteractionTypes.NODE_INTERACTION_UPDATE_ORDER_SUCCESS
});

export const updateOrderFailure = (error) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_UPDATE_ORDER_FAILURE,
  error
});

export const updateOrderRequest = ({ startInd, endInd }) => async (dispatch, getState) => {
  const nodeID = _get(getState(), 'nodeInteraction.node.id', null);
  const newListInteraction = Array.from(_get(getState(), 'nodeInteraction.node.interactions', []));
  const [removed] = newListInteraction.splice(startInd, 1);
  newListInteraction.splice(endInd, 0, removed);
  try {
    dispatch({
      type: NodeInteractionTypes.NODE_INTERACTION_UPDATE_ORDER_REQUEST,
      listInteraction: newListInteraction
    });
    await Promise.all(
      newListInteraction.map((inter, idx) =>
        axiosClient.put(`/admin/interaction/${inter.id_interaction}`, { order: idx + 1 })
      )
    );
    dispatch(updateOrderSuccess());
    dispatch(fetchRequest(nodeID));
  } catch (e) {
    dispatch(updateOrderFailure(e));
  }
};

export const updateActiveSuccess = () => ({
  type: NodeInteractionTypes.NODE_INTERACTION_UPDATE_ACTIVE_SUCCESS
});

export const updateActiveFailure = (error) => ({
  type: NodeInteractionTypes.NODE_INTERACTION_UPDATE_ACTIVE_FAILURE,
  error
});

export const updateActiveRequest = ({ interactionID, is_active }) => async (dispatch, getState) => {
  const nodeID = _get(getState(), 'nodeInteraction.node.id', null);

  try {
    dispatch({
      type: NodeInteractionTypes.NODE_INTERACTION_UPDATE_ACTIVE_REQUEST
    });
    await axiosClient.put(`/admin/interaction/${interactionID}`, { is_active: is_active ? 1 : 0 });
    dispatch(updateActiveSuccess());
    dispatch(fetchRequest(nodeID));
  } catch (e) {
    dispatch(updateActiveFailure(e));
  }
};
