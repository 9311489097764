import {
  Box,
  Card,
  IconButton,
  Pagination,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Edit as EditIcon, Trash2 as TrashIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router';
import AlertModal from 'src/components/modal/AlertModal';
import { routes } from 'src/configs';
import UserRoles from 'src/constants/UserRoles';
import { useAccount } from 'src/hooks';
import { useModal } from 'src/hooks/useModal';

export const AccountList = ({ ...rest }) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { pagination, listState, onDelete } = useAccount();

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="25%" align="center">
                    {t('model.account.name')}
                  </TableCell>
                  <TableCell width="25%" align="center">
                    {t('model.account.email')}
                  </TableCell>
                  <TableCell width="25%" align="center">
                    {t('model.account.role')}
                  </TableCell>
                  <TableCell style={{ width: '100px' }} align="center">
                    {t('action.title')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listState.map((account) => (
                  <TableRow key={account?.id}>
                    <TableCell width="25%" align="center">
                      {account?.name}
                    </TableCell>
                    <TableCell width="25%" align="center">
                      {account?.email}
                    </TableCell>
                    <TableCell width="25%" align="center">
                      {account?.type === UserRoles.Admin ? 'Admin' : 'User'}
                    </TableCell>
                    <TableCell width="25%" align="center">
                      <IconButton onClick={() => navigate(routes.accountUpdate(account.id))}>
                        <SvgIcon viewBox="0 0 20 20" fontSize="small">
                          <EditIcon color="green" size={20} />
                        </SvgIcon>
                      </IconButton>
                      <AlertModal
                        message={t(`account.confirm.delete`, {
                          name: account.name
                        })}
                        modalName={`confirmDeleteAccount${account.id}`}
                        confirmAction={() => onDelete(account.id)}
                      />
                      <IconButton onClick={() => openModal(`confirmDeleteAccount${account.id}`)}>
                        <SvgIcon viewBox="0 0 20 20" fontSize="small">
                          <TrashIcon size={20} color="red" />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
      <Box
        sx={{
          py: 2,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Pagination count={pagination.totalPage} page={pagination.page} onChange={pagination.onPageChange} />
      </Box>
    </>
  );
};
