/* eslint-disable no-unused-expressions */
import { Box, IconButton, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as ProjectDetailActions from 'src/actions/projectDetail';
import AlertModal from 'src/components/modal/AlertModal';
import { useModal } from 'src/hooks/useModal';
import { useProjectDetail } from 'src/hooks/useProjectDetail';
import { isImageMedia, isSoundMedia, isVideoMedia } from 'src/utils/mediaTypes';
import DataTreeView from './components/DataTreeView';
import MediaConfirmModal from './components/MediaConfirmModal';
import ProjectCreateFolder from './components/ProjectCreateFolder';
import UploadMediaProgress from './components/UploadMediaProgress';

const Search = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const createFolderModalName = 'projectCreateFolder';
  const deleteMediaModalName = 'projectDeleteMedia';
  const mediaConfirmModalName = 'mediaConfirmModalName';

  const {
    projectState,
    searchTermsState,
    onUploadMedia,
    handNodeleSelect,
    uploadMediaQueueState,
    getMediaBySearchTerms,
    previewMediaState,
    selectedFolderState,
    onDeleteMedia,
    onDeleteFolder,
    onCancelUploadMedia
  } = useProjectDetail();

  const inputFileRef = useRef(null);

  const [mediaValue, setMediaValue] = useState('');

  const [mediaFiles, setMediaFiles] = useState([]);

  const [mediaExistedFiles, setMediaExistedFiles] = useState([]);

  const handleInputChange = useCallback((evt) => {
    dispatch(ProjectDetailActions.setSearchTerms(evt.target.value));
  }, []);

  const handleSubmit = useCallback((evt) => {
    evt.preventDefault();
    dispatch(ProjectDetailActions.setSearchTerms(''));
  }, []);

  const handleUploadMedia = useCallback(
    (evt) => {
      const medias = getMediaBySearchTerms(projectState);
      const { images, videos, sounds } = medias;
      const { files } = evt.target;
      const mediaFilesTemp = [];
      const mediaExistedFilesTemp = [];

      Object.values(files).forEach((file) => {
        mediaFilesTemp.push(file);

        images.forEach((image) => {
          if (file.name === image.name) {
            mediaExistedFilesTemp.push(file);
          }
        });

        videos.forEach((video) => {
          if (file.name === video.name) {
            mediaExistedFilesTemp.push(file);
          }
        });

        sounds.forEach((sound) => {
          if (file.name === sound.name) {
            mediaExistedFilesTemp.push(file);
          }
        });
      });

      setMediaFiles(mediaFilesTemp);
      setMediaExistedFiles(mediaExistedFilesTemp);

      mediaExistedFilesTemp.length
        ? openModal(mediaConfirmModalName)
        : onUploadMedia({
            files: mediaFilesTemp,
            folderID: selectedFolderState?.id,
            projectID: projectState?.id
          });
      setMediaValue('');
    },
    [selectedFolderState, projectState]
  );

  const handleReplaceCopy = useCallback(() => {
    onUploadMedia({
      files: mediaFiles,
      folderID: selectedFolderState?.id,
      projectID: projectState?.id,
      replace: true
    });
    setMediaValue('');
  }, [selectedFolderState, projectState, mediaFiles]);

  const handleAddCopy = useCallback(() => {
    onUploadMedia({
      files: mediaFiles,
      folderID: selectedFolderState?.id,
      projectID: projectState?.id,
      replace: false
    });
    setMediaValue('');
  }, [selectedFolderState, projectState, mediaFiles]);

  const handleCancelUploadMedia = useCallback((uploadMedia) => {
    uploadMedia.source.cancel();
    dispatch(ProjectDetailActions.removeUploadMediaQueue(uploadMedia.id));
  }, []);

  const handleCreateFolder = useCallback(() => {
    openModal(createFolderModalName);
  }, []);

  const handleOpenDeleteMediaAlert = useCallback(() => {
    openModal(deleteMediaModalName);
  }, []);

  const handleDeleteMediaOrFolder = useCallback(() => {
    if (previewMediaState) {
      const imageID = isImageMedia(previewMediaState) ? previewMediaState?.id : '';
      const videoID = isVideoMedia(previewMediaState) ? previewMediaState?.id : '';
      const soundID = isSoundMedia(previewMediaState) ? previewMediaState?.id : '';

      onDeleteMedia({
        images: imageID,
        videos: videoID,
        sounds: soundID,
        id: projectState?.id
      });
    }

    if (selectedFolderState) {
      onDeleteFolder({
        folderID: selectedFolderState.id,
        id: projectState?.id
      });
    }
  }, [previewMediaState, selectedFolderState, projectState]);

  useEffect(
    () => () => {
      dispatch(ProjectDetailActions.setPreviewMedia(null));
      dispatch(ProjectDetailActions.setSelectedFolder(null));
      dispatch(ProjectDetailActions.setSearchTerms(''));
      onCancelUploadMedia();
    },
    []
  );

  return (
    <>
      <Typography mb={1}>{t('projectSidebar.search')}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          height: '358px',
          borderRadius: '4px',
          overflow: 'auto'
        }}
      >
        <form
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            height: '32px',
            borderBottom: '1px solid black'
          }}
          onSubmit={handleSubmit}
        >
          <input
            style={{
              width: '100%',
              height: '100%',
              outline: 'none',
              border: 'none'
            }}
            value={searchTermsState}
            onChange={handleInputChange}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {searchTermsState ? (
              <ClearIcon
                onClick={() => dispatch(ProjectDetailActions.setSearchTerms(''))}
                style={{
                  cursor: 'pointer'
                }}
              />
            ) : (
              <SearchIcon />
            )}
          </Box>
        </form>
        {uploadMediaQueueState.length ? (
          <Box sx={{ maxHeight: 76, overflow: 'auto', borderBottom: '1px solid black' }}>
            <Box>
              {uploadMediaQueueState.map((uploadMedia) => (
                <Box
                  key={uploadMedia.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pl: 1
                  }}
                >
                  <UploadMediaProgress value={uploadMedia?.progress} />
                  <IconButton sx={{ mr: 2 }} size="small" onClick={() => handleCancelUploadMedia(uploadMedia)}>
                    <CancelIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        ) : null}

        <Box
          sx={{
            flex: 1,
            height: '360px',
            overflow: 'auto'
          }}
        >
          <DataTreeView
            treeItem={searchTermsState ? getMediaBySearchTerms(projectState, searchTermsState) : projectState}
            selected={previewMediaState?.uuid || selectedFolderState?.uuid || null}
            onNodeSelect={(_, uuid) => handNodeleSelect(projectState, uuid)}
          />
        </Box>

        <Box
          sx={{
            borderTop: '1px solid black'
          }}
        >
          <IconButton
            // disabled={uploadMediaState.requesting}
            color="primary"
            onClick={() => inputFileRef.current.click()}
          >
            <input
              multiple
              type="file"
              accept=".png, .jpg, .jpeg, .mp3, .mp4, .mov"
              ref={inputFileRef}
              value={mediaValue}
              name="media"
              style={{ display: 'none' }}
              onChange={handleUploadMedia}
            />
            <InsertDriveFileIcon />
          </IconButton>

          <IconButton onClick={handleCreateFolder} color="primary" component="span">
            <CreateNewFolderIcon />
          </IconButton>

          <IconButton
            disabled={!previewMediaState && !selectedFolderState}
            onClick={handleOpenDeleteMediaAlert}
            color="primary"
            component="span"
          >
            <DeleteIcon />
          </IconButton>

          {/* ProjectCreateFolder modal */}
          <ProjectCreateFolder modalName={createFolderModalName} />

          {/* Delete media AlertModal modal */}
          <AlertModal
            modalName={deleteMediaModalName}
            confirmAction={handleDeleteMediaOrFolder}
            message={
              previewMediaState
                ? t('projectDetail.confirm.deleteMedia', { mediaName: previewMediaState?.name })
                : t('projectDetail.confirm.deleteFolder', { folderName: selectedFolderState?.name })
            }
          />

          {/* Media Confirm modal */}
          <MediaConfirmModal
            modalName={mediaConfirmModalName}
            replaceCopy={handleReplaceCopy}
            addCopy={handleAddCopy}
            files={mediaExistedFiles}
            message={t('projectDetail.label.mediaExisted')}
          />
        </Box>
      </Box>
    </>
  );
};

Search.propTypes = {};

export default Search;
