import { Box, Button, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import InputField from 'src/components/FormControls/InputField';
import SelectField from 'src/components/FormControls/SelectField';
import SingleCheckboxField from 'src/components/FormControls/SingleCheckboxField';
import { useMenuInteractionForm } from 'src/hooks/useMenuInteractionForm';
import { MenuItemForm } from './MenuItemForm';

const InputFieldProps = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      xs: 2,
      style: {
        display: 'flex',
        alignItems: 'center'
      }
    },
    input: {
      xs: 8,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const MenuInteractionForm = () => {
  const {
    t,
    formItems,
    itemsFields,
    watchShowMenuButton,
    watchFieldArray,
    menuFields,
    MenuItemFormProps,
    disabledMenuButtonCheckBox,
    jumpToInteractionOptions,
    jumpToNodeOptions,
    handleClickNewMenuItem,
    onSingleFieldSubmit
  } = useMenuInteractionForm();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputField
            label="Title"
            name="title"
            {...formItems}
            {...InputFieldProps}
            BlurOnEnter
            OnBlur={onSingleFieldSubmit}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            label={menuFields.position.label}
            name={menuFields.position.name}
            options={menuFields.position.options}
            {...formItems}
            {...InputFieldProps}
            handleChange={onSingleFieldSubmit}
            sx={{ height: '30px' }}
          />
        </Grid>
        <Grid item xs={6}>
          <SingleCheckboxField
            label={menuFields.numbering.label}
            name={menuFields.numbering.name}
            {...formItems}
            fullWidth={false}
            handleChange={onSingleFieldSubmit}
          />
        </Grid>
        <Grid item xs={6}>
          <SingleCheckboxField
            label={menuFields.canCloseMenu.label}
            name={menuFields.canCloseMenu.name}
            {...formItems}
            fullWidth={false}
            handleChange={onSingleFieldSubmit}
          />
        </Grid>
        <Grid item xs={6}>
          <SingleCheckboxField
            label={menuFields.showMenuButton.label}
            name="show_menu_button"
            {...formItems}
            fullWidth={false}
            disabled={disabledMenuButtonCheckBox}
            handleChange={onSingleFieldSubmit}
          />
        </Grid>
        <Grid item xs={6}>
          <SingleCheckboxField
            label={menuFields.pauseVideo.label}
            name={menuFields.pauseVideo.name}
            {...formItems}
            fullWidth={false}
            handleChange={onSingleFieldSubmit}
          />
        </Grid>
        {!!watchShowMenuButton && (
          <>
            <Grid item xs={12}>
              <SelectField
                label={menuFields.buttonLocation.label}
                name="show_menu"
                options={menuFields.buttonLocation.options}
                {...formItems}
                {...InputFieldProps}
                handleChange={onSingleFieldSubmit}
                sx={{ height: '30px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={menuFields.buttonName.label}
                name={menuFields.buttonName.name}
                {...formItems}
                {...InputFieldProps}
                BlurOnEnter
                OnBlur={onSingleFieldSubmit}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6">{t('interaction.menu.fields.menuItems')}</Typography>
            <Button
              size="small"
              variant="contained"
              disabled={watchFieldArray[0].length >= 10}
              sx={{
                mr: 1,
                py: '0px !important',
                px: '4px !important',
                backgroundColor: '#e872a4',
                '&:hover': {
                  backgroundColor: '#ED257A'
                }
              }}
              onClick={handleClickNewMenuItem}
            >
              <AddIcon fontSize="small" />
              {t('common.actions.new')}
            </Button>
          </Box>
        </Grid>
        <Grid xs={12} sx={{ mt: 1 }}>
          {itemsFields.map((field, index) => (
            <MenuItemForm
              item={field}
              key={field.id}
              index={index}
              isLastItem={index === itemsFields.length - 1}
              jumpToInteractionOptions={jumpToInteractionOptions}
              jumpToNodeOptions={jumpToNodeOptions}
              {...MenuItemFormProps}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default MenuInteractionForm;
