import { isNode } from 'react-flow-renderer';
import { createSelector } from 'reselect';
import { NODE_TYPES } from 'src/constants/node';

const projectNodeState = (state) => state.projectNode;

export const getLoading = createSelector(projectNodeState, (projectNode) => projectNode.loading);

export const getError = createSelector(projectNodeState, (projectNode) => projectNode.error);

export const getElements = createSelector(projectNodeState, (projectNode) => projectNode.elements);

export const getNumNodes = createSelector(projectNodeState, (projectNode) => projectNode.numNodes);

export const getNumPaths = createSelector(projectNodeState, (projectNode) => projectNode.numPaths);

export const getSearchedNode = createSelector(projectNodeState, (projectNode) => projectNode.searchedItem);

export const getSelectedNode = createSelector(
  (state) => state.projectNode.elements,
  (_, nodeID) => nodeID,
  (list, id) => list.find((el) => el.id === id)?.data || {}
);

export const getScreen = createSelector(projectNodeState, (projectNode) => projectNode.screen);

export const getEdittingNodeInteractions = createSelector(
  (state) => state.projectNode.elements,
  (_, nodeID) => nodeID,
  (list, id) => list.find((el) => el.id === id)?.data.interactions || []
);

export const getListJumpNode = createSelector(projectNodeState, (projectNode) =>
  projectNode.elements
    .filter((el) => isNode(el) && el.type === NODE_TYPES.VIDEO_NODE && el.data.video_id)
    .map((e) => e.data)
);
