const videoController = (() => {
  let instance;

  const init = (newInstance = undefined) => newInstance || null;

  return {
    getInstance(newInstance = undefined) {
      if (newInstance) {
        instance = init(newInstance);
      }

      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
})();

export default videoController;
