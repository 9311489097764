import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ProjectEditTitleModal } from 'src/containers/projects/editTitleModal';
import { useProjectOptions } from 'src/hooks/projectList/options';
import { useModal } from 'src/hooks/useModal';
import AlertModal from '../modal/AlertModal';

const _handleOptions = (t) => ({
  rename: {
    icon: <CreateIcon sx={{ fontSize: 18 }} />,
    label: t('common.actions.rename')
  },
  duplicate: {
    icon: <FileCopyIcon sx={{ fontSize: 18 }} />,
    label: t('common.actions.duplicate')
  },
  delete: {
    icon: <DeleteIcon sx={{ fontSize: 18 }} />,
    label: t('common.actions.delete')
  }
});

export const ProjectPopover = ({ handleClick: _handleClick, ...others }) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleOptions = _handleOptions(t);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (type) => {
    _handleClick(type);
    setOpen(false);
  };

  return (
    <Box {...others}>
      <IconButton onClick={handleOpen} ref={anchorRef} sx={{ p: 0 }}>
        <MoreHorizIcon />
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 130, borderRadius: '4px !important' }
        }}
      >
        {Object.keys(handleOptions).map((handle) => (
          <MenuItem
            onClick={() => handleClick(handle)}
            key={handle}
            sx={{ '&:hover': { bgcolor: 'primary.light' }, pr: 0 }}
          >
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {handleOptions[handle].label}
                </Typography>
              }
            />
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& img': {
                    width: '100%'
                  }
                }}
              >
                {handleOptions[handle].icon}
              </Box>
            </ListItemIcon>
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
};

ProjectPopover.propTypes = {
  handleClick: PropTypes.func.isRequired
};

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 290,
    minHeight: 180
  }
}));

export const ProjectCard = ({ project }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const editModalName = `projectOptions${project.id}`;
  const confirmDeleteModalName = `confirmDeleteProject${project.id}`;
  const { onDelete, onDuplicate, t } = useProjectOptions({ modalName: confirmDeleteModalName, projectID: project.id });
  const handleClickOption = useCallback((type) => {
    switch (type) {
      case 'rename': {
        openModal(editModalName);
        break;
      }
      case 'delete': {
        openModal(confirmDeleteModalName);
        break;
      }
      case 'duplicate': {
        onDuplicate();
        break;
      }
      default:
    }
  }, []);
  return (
    <>
      <Card className={classes.root}>
        <CardMedia
          component="img"
          height="140px"
          image={project.thumbnail ? `${process.env.REACT_APP_PUBLIC_URL}${project.thumbnail}` : '/static/retangle.png'}
          onClick={() => navigate(`/projects/${project.id}`)}
          sx={{
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        />

        <CardContent sx={{ px: 2, pt: 1, pb: '4px !important' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Box sx={{ maxWidth: '80%' }}>
              <Typography style={{ wordWrap: 'break-word' }} variant="subtitle2" gutterBottom>
                {project.name}
              </Typography>
            </Box>

            <ProjectPopover sx={{ ml: 'auto' }} handleClick={handleClickOption} />
          </Box>
        </CardContent>
      </Card>
      <ProjectEditTitleModal modalName={editModalName} project={project} />
      <AlertModal
        modalName={confirmDeleteModalName}
        confirmAction={onDelete}
        message={t('projects.delete.confirmMessage', { projectName: project.name })}
      />
    </>
  );
};

ProjectCard.propTypes = {
  project: PropTypes.object.isRequired
};
