import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, LinearProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputField from 'src/components/FormControls/InputField';
import { useModal } from 'src/hooks/useModal';
import { useProjectDetail } from 'src/hooks/useProjectDetail';
import * as Yup from 'yup';

const ProjectCreateFolder = ({ modalName }) => {
  const { t } = useTranslation();

  const { closeModal, isShowing } = useModal(modalName);

  const { onCreateFolder, projectState, selectedFolderState } = useProjectDetail();

  const schema = Yup.object().shape({
    name: Yup.string()
      .required(t('projectDetail.validate.folderName.required'))
      .max(50, t('projectDetail.validate.folderName.max', { max: 50 })) // Example 50 is length of name
  });

  const { handleSubmit, control, formState, reset } = useForm({
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema)
  });

  const { isSubmitting } = formState;

  const handleCloseModal = useCallback(() => {
    closeModal(modalName);
  }, []);

  const onSubmit = useCallback(
    ({ name }) => {
      onCreateFolder({
        project_id: projectState?.id,
        folder_id: selectedFolderState?.id,
        name
      });
      reset({ name: '' });
      handleCloseModal();
    },
    [projectState, selectedFolderState]
  );

  return (
    <Dialog fullWidth open={isShowing} aria-labelledby="form-dialog-title">
      <Box display="flex" justifyContent="center">
        <Typography my={2} variant="h4" align="center" fontWeight="bold">
          {t('projectDetail.label.createNewFolder')}
        </Typography>
        <IconButton
          style={{
            top: 5,
            right: 5,
            position: 'absolute'
          }}
          aria-label="close"
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <Typography component="h1" variant="h5">
          {isSubmitting && <LinearProgress />}
        </Typography>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <InputField
            required
            autoFocus
            name="name"
            label={t('projectDetail.label.folderName')}
            control={control}
            formState={formState}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2
            }}
          >
            <Button color="secondary" disabled={isSubmitting} type="submit" variant="contained">
              {t('projectDetail.label.create')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

ProjectCreateFolder.propTypes = {
  modalName: PropTypes.string.isRequired
};

export default ProjectCreateFolder;
