import * as yup from 'yup';
import { i18n } from 'src/i18n';
import isString from 'lodash/isString';
import GenericField from 'src/models/fields/genericField';

export default class EnumeratorField extends GenericField {
  constructor(name, label, options, { required = false } = {}) {
    super(name, label);
    this.options = options || [];
    this.required = required;
  }

  _id = (option) => {
    if (!option) {
      return option;
    }

    if (isString(option)) {
      return option;
    }

    return option.value;
  };

  _label = (option) => {
    if (!option) {
      return option;
    }

    if (isString(option)) {
      return option;
    }

    return option.label;
  };

  forTable = (overrides) => {
    const defaultRender = (value) => this.forView(value);

    const { width, title = this.label, sorter = true, dataIndex = this.name, render = defaultRender, ...others } =
      overrides || {};

    return {
      ...others,
      title,
      width,
      sorter,
      dataIndex,
      render
    };
  };

  forView = (value) => {
    const option = this.options.find((item) => item.id === this._id(value));

    if (option) {
      return this._label(option);
    }

    return value;
  };

  forFormInitialValue = (value) => this._id(value);

  forFilter = () => yup.array().compact().ensure().of(yup.string().trim()).label(this.label);

  forForm = () => {
    let yupChain = yup
      .string()
      .nullable(true)
      .label(this.label)
      .oneOf([null, ...this.options.map((option) => this._id(option))]);

    if (this.required) {
      yupChain = yupChain.required(i18n.t('validation.string.selected'));
    }

    return yupChain;
  };

  forExport() {
    return yup.mixed().label(this.label);
  }

  forImport() {
    let yupChain = yup
      .string()
      .label(this.label)
      .nullable(true)
      .oneOf([null, ...this.options.map((option) => this._id(option))]);

    if (this.required) {
      yupChain = yupChain.required(i18n.t('validation.string.selected'));
    }

    return yupChain;
  }
}
