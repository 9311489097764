/* eslint-disable */
import { Box, makeStyles } from '@material-ui/core';
import { transform } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Rnd } from 'react-rnd';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { VIDEO_CONTROLBAR_HEIGHT } from 'src/configs/layout';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import ReactGA from 'react-ga';
import { borderStyles } from '../../interactionsForm/text/TextInteractionStyleForm';
import './ZoomInteractionDisplay.css';
import * as VideoPlayerActions from 'src/actions/videoPlayer';
import {
  ResizeHandleBottom,
  ResizeHandleBottomLeft,
  ResizeHandleBottomRight,
  ResizeHandleLeft,
  ResizeHandleRight,
  ResizeHandleTop,
  ResizeHandleTopLeft,
  ResizeHandleTopRight
} from '../textInteractionDisplay/TextInteractionDisplay';
import { useTranslation } from 'react-i18next';

const ZoomInteractionDisplay = ({ config, videoBounding, isEditting, isPublishMode = false }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [triggerDisplay, setTriggerDisplay] = useState(false);

  const { widthCellGridState, showVideoGridState, videoCurrentTime, videoDuration, videoData } = useVideoPlayer();

  const { start_time, end_time, x, y, width, height, is_preview, title } = config;

  const { borderRadius, borderWidth, borderStyle, borderColor } = config.style;

  const backgroundCSS =
    !is_preview && !triggerDisplay
      ? {
          backgroundImage: `linear-gradient(45deg,#cbcbcb 25%,transparent 0,transparent 75%,#cbcbcb 0,#cbcbcb),
  linear-gradient(45deg,#cbcbcb 25%,transparent 0,transparent 75%,#cbcbcb 0,#cbcbcb)`,
          backgroundSize: '30px 30px',
          backgroundPosition: '0 0,15px 15px'
        }
      : {};

  const useStyles = makeStyles(() => ({
    wrapperContent: {
      color: '#000',
      wordBreak: 'break-all',

      width: is_preview || triggerDisplay ? '100%' : width * videoBounding.width,
      height: is_preview || triggerDisplay ? '100%' : height * videoBounding.height,

      maxWidth: '100%',
      maxHeight: '100%',

      overflow: 'hidden',
      cursor: isEditting && !is_preview ? 'move' : 'default',
      position: 'absolute',
      zIndex: 100,

      ...backgroundCSS,

      borderRadius: parseInt(borderRadius),
      borderWidth: parseInt(borderWidth),
      borderStyle: borderStyles[borderStyle].cssValue,
      borderColor: `rgba(${borderColor?.r}, ${borderColor?.g}, ${borderColor?.b}, ${borderColor?.a})`,
      transition: 'transform 600ms, width 600ms, height 600ms, position 600ms'
    }
  }));

  const classes = useStyles();

  const handleChangeConfig = useCallback((newConfig) => {
    dispatch(EdittingInteractionActions.setConfig(newConfig));
  }, []);

  useEffect(() => {
    if (showVideoGridState) {
      const xTemp = Math.trunc(x / widthCellGridState);
      const yTemp = Math.trunc(y / widthCellGridState);

      dispatch(EdittingInteractionActions.setConfig({ x: xTemp * widthCellGridState, y: yTemp * widthCellGridState }));
    }
  }, [showVideoGridState]);

  useEffect(() => {
    if (!isEditting) {
      if (start_time > videoCurrentTime || end_time < videoCurrentTime) {
        setTriggerDisplay(false);
        dispatch(VideoPlayerActions.setZoom({ x, y, width, height, zoom: false }));
      } else {
        setTriggerDisplay(true);
        dispatch(VideoPlayerActions.setZoom({ x, y, width, height, zoom: true }));
      }
    }
  }, [videoCurrentTime, start_time, end_time, isEditting]);

  useEffect(() =>{
    if (isPublishMode && triggerDisplay) {
      ReactGA.event({
        category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
          action: t(`googleAnalyticsActions.actions.zoom`) || '',
          label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${title}`,
          value: videoData.id
      });
    }
  }, [triggerDisplay, isPublishMode]);

  useEffect(() => {
    if (config.is_preview) {
      dispatch(VideoPlayerActions.setZoom({ x, y, width, height, zoom: true }));
    } else {
      dispatch(VideoPlayerActions.setZoom({ x, y, width, height, zoom: false }));
    }
  }, [config.is_preview]);

  return (
    // <Box
    //   className="waocon-interaction-bounding-box"
    //   sx={{
    //     visibility: isEditting || triggerDisplay ? 'visible' : 'hidden'
    //   }}
    // >
    <Rnd
      lockAspectRatio={videoBounding.width / videoBounding.height}
      className="rnd"
      enableResizing={isEditting && !is_preview}
      disableDragging={!isEditting || is_preview}
      {...(showVideoGridState ? { dragGrid: [widthCellGridState, widthCellGridState] } : {})}
      style={{
        zIndex: 2,
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        border: isEditting && !is_preview ? '2px dashed white' : 'none',
        cursor: 'auto !important',

        transition: is_preview ? 'transform 600ms' : 'unset',

        visibility: isEditting || triggerDisplay ? 'visible' : 'hidden'
      }}
      bounds="parent"
      size={{
        width: is_preview || triggerDisplay ? videoBounding.width : config.width * videoBounding.width,
        height:
          is_preview || triggerDisplay
            ? videoBounding.height + VIDEO_CONTROLBAR_HEIGHT
            : config.height * videoBounding.height
      }}
      position={{
        x: is_preview || triggerDisplay ? 0 : config.x * videoBounding.width,
        y: is_preview || triggerDisplay ? 0 : config.y * videoBounding.height
      }}
      minWidth="10%"
      minHeight="10%"
      onDragStop={(e, d) => {
        handleChangeConfig({
          x: d.lastX / videoBounding.width,
          y: d.lastY / videoBounding.height
        });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        handleChangeConfig({
          width: ref.offsetWidth / videoBounding.width,
          height: ref.offsetHeight / videoBounding.height,
          x: position.x / videoBounding.width,
          y: position.y / videoBounding.height
        });
      }}
      resizeHandleComponent={{
        bottomRight: <ResizeHandleBottomRight />,
        bottomLeft: <ResizeHandleBottomLeft />,
        bottom: <ResizeHandleBottom />,
        top: <ResizeHandleTop />,
        left: <ResizeHandleLeft />,
        right: <ResizeHandleRight />,
        topLeft: <ResizeHandleTopLeft />,
        topRight: <ResizeHandleTopRight />
      }}
    >
      <Box className={`${classes.wrapperContent}`} />
    </Rnd>
    // </Box>
  );
};

ZoomInteractionDisplay.propTypes = {
  config: PropTypes.object.isRequired,
  videoBounding: PropTypes.object.isRequired
};

export default ZoomInteractionDisplay;
