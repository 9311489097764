import { experimentalStyled } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
// import { NAVBAR_HEIGHT, SIDEBAR_WIDTH } from 'src/configs/layout';
import { useVideoViewer } from 'src/hooks/useVideoViewer';
import Video from 'src/pages/Video';

const VideoLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const VideoLayout = () => {
  const { id } = useParams();
  const { onFetch } = useVideoViewer();
  const [data, setData] = useState(null);
  const [password, setPassword] = useState(null);
  const [isSubmited, setIsSubmited] = useState(false);

  console.log('data', data);

  useEffect(() => {
    console.log('id', id);
    const fetchData = async () => {
      const d = await onFetch({ id, password });
      setIsSubmited(false);
      if (d) setData(d);
    };
    return fetchData();
  }, [isSubmited]);

  return (
    <>
      <VideoLayoutRoot>
        {data && (
          <Video
            data={data}
            submitPass={(p) => {
              setIsSubmited(true);
              setPassword(p);
            }}
            isSubmited={isSubmited}
          />
        )}
      </VideoLayoutRoot>
    </>
  );
};

export default VideoLayout;
