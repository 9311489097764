import * as yup from 'yup';
import GenericField from 'src/models/fields/genericField';

export default class RelationToOneField extends GenericField {
  constructor(name, label, { key = 'id', required = false } = {}) {
    super(name, label);
    this.key = key || 'id';
    this.required = required;
  }

  forTable = (overrides) => {
    const defaultRender = undefined;

    const { title = this.label, sorter = false, dataIndex = this.name, render = defaultRender, ...others } =
      overrides || {};

    return {
      ...others,
      title,
      sorter,
      dataIndex,
      render
    };
  };

  forView = (value) => value;

  forFormInitialValue = (value) => value;

  forForm = () => {
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        return originalValue[this.key];
      });

    if (this.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  };

  forFilter = () =>
    yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return originalValue;
        }

        if (Array.isArray(originalValue)) {
          return originalValue.map((item) => item[this.key]);
        }

        return [originalValue[this.key]];
      });

  forExport = () =>
    yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue[this.key]) {
          return null;
        }

        return originalValue[this.key];
      });

  forImport = () => {
    let yupChain = yup.mixed().nullable(true).label(this.label);

    if (this.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  };
}
