import StringField from 'src/models/fields/stringField';
import DecimalField from './fields/decimalField';
import IdField from './fields/idField';
import RelationToOneField from './fields/relationToOneField';

const Label = (t, field) => t(`projectNode.fields.${field}`);

export const fields = (t) => ({
  id: new IdField('id_public', Label(t, 'id')),
  name: new StringField('name', Label(t, 'name'), {
    required: true,
    max: 100
  }),
  title: new StringField('title', Label(t, 'title'), {
    max: 100
  }),
  description: new StringField('description', Label(t, 'description'), {
    max: 100
  }),
  video: new RelationToOneField('video_id', Label(t, 'video')),
  durationIn: new DecimalField('duration_in', Label(t, 'durationIn'), { required: true, max: 2, min: 0 }),
  durationOut: new DecimalField('duration_out', Label(t, 'durationOut'), { required: true, max: 2, min: 0 }),
  screenName: new StringField('name', Label(t, 'screenName'), {
    required: true,
    max: 100
  }),
  screenAsset: new RelationToOneField('image_id', Label(t, 'screenAsset'))
});

export default {
  fields
};
