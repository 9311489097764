import { AppBar, Box, Grid, IconButton, Toolbar } from '@material-ui/core';
import { experimentalStyled, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { NAVBAR_HEIGHT } from 'src/configs/layout';
import MenuIcon from '../icons/Menu';
import AccountPopover from './AccountPopover';
import ActionListPopover from './ActionListPopover';
import LanguagePopover from './LanguagePopover';
import Logo from './Logo';
import ReviewProject from './PreviewProject';
import PublicSettings from './PublicSettings';
import PublishManager from './PublishManager';

const ProjectNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '150px',
      objectFit: 'contain'
    }
  },
  actions: {
    [theme.breakpoints.down('sm')]: {
      display: 'none !important'
    }
  },
  actionsMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none !important'
    }
  }
}));

const ProjectNavbar = (props) => {
  const classes = useStyles();
  const { onSidebarMobileOpen, ...other } = props;

  return (
    <ProjectNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: '40px !important', height: NAVBAR_HEIGHT }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
          <RouterLink to="/" style={{ display: 'flex' }}>
            <Logo className={classes.logo} />
          </RouterLink>
        </Box>
        <Grid style={{ display: 'flex', alignItems: 'center' }} className={classes.actions}>
          <Box sx={{ mr: 1 }}>
            <ReviewProject />
          </Box>
          <Box sx={{ mr: 1 }}>
            <PublishManager />
          </Box>
          <Box sx={{ mr: 1 }}>
            <PublicSettings />
          </Box>
          <Box sx={{ mr: 1 }}>
            <LanguagePopover />
          </Box>
          <Box sx={{ mr: 1 }}>
            <AccountPopover />
          </Box>
        </Grid>
        <Grid className={classes.actionsMobile}>
          <Box sx={{ mr: 1 }}>
            <ActionListPopover />
          </Box>
        </Grid>
      </Toolbar>
    </ProjectNavbarRoot>
  );
};

ProjectNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default ProjectNavbar;
