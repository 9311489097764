import MouseTrap from 'mousetrap';
import { useEffect, useMemo } from 'react';

export const useCopyPaste = (copyCb, pasteCb) => {
  const copyKeys = useMemo(() => ['command+c', 'ctrl+c'], []);
  const pasteKeys = useMemo(() => ['command+v', 'ctrl+v'], []);

  useEffect(() => {
    MouseTrap.bind(copyKeys, () => {
      copyCb();
      return false;
    });
    MouseTrap.bind(pasteKeys, () => {
      pasteCb();
      return false;
    });
    return () => {
      MouseTrap.unbind(copyKeys);
      MouseTrap.unbind(pasteKeys);
    };
  }, []);
};
