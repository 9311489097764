import { Avatar, Box, ButtonBase, IconButton, Popover, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import AdvanceSettings from 'src/containers/project-public-settings/AdvanceSettings';
import BasicInfo from 'src/containers/project-public-settings/BasicInfo';
import Privacy from 'src/containers/project-public-settings/Privacy';
import Sharing from 'src/containers/project-public-settings/Sharing';
import { usePublishSettings } from 'src/hooks/publishSettings';

const TabPanel = (props) => {
  const { children, value, index, ...others } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} id={`tab-${index}`} {...others}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const a11yProps = (index) => ({
  id: `tab-${index}`
});

const PublicSettings = (props) => {
  const { text } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const { onFetch } = usePublishSettings();

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    onFetch(id);
  }, [id]);

  return (
    <>
      <Tooltip title={t('publicSettings.title')}>
        <Box
          component={ButtonBase}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Avatar
            sx={{
              height: 32,
              width: 32
            }}
          >
            <SettingsApplicationsIcon />
          </Avatar>
          {text && (
            <Typography
              sx={{
                marginLeft: 1
              }}
              variant="p"
            >
              {text}
            </Typography>
          )}
        </Box>
      </Tooltip>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          position: 'relative',
          sx: { width: 400 }
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: '4px'
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ p: 2, mt: 3 }}>
          <Typography color="textPrimary" variant="h5" textAlign="center">
            {t('publicSettings.title')}
          </Typography>
        </Box>
        <Box sx={{ mt: 2, width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs scrollButtons allowScrollButtonsMobile variant="scrollable" value={value} onChange={handleChange}>
              <Tab label={t('publicSettings.basicInfo.basicInfo')} {...a11yProps(0)} />
              <Tab label={t('publicSettings.privacy.privacy')} {...a11yProps(1)} />
              <Tab label={t('publicSettings.sharing.sharing')} {...a11yProps(2)} />
              <Tab label={t('publicSettings.advancedSettings.advancedSettings')} {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BasicInfo />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Privacy />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Sharing />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AdvanceSettings />
          </TabPanel>
        </Box>
      </Popover>
    </>
  );
};

PublicSettings.propTypes = {
  text: PropTypes.string
};

export default PublicSettings;
