import { INTERACTION } from 'src/constants/interactions';
import StringField from 'src/models/fields/stringField';
import { hmsmsToSeconds } from 'src/utils/timelineFormat';
import JsonField from './fields/jsonField';
import EnumeratorField from './fields/enumeratorField';
import IdField from './fields/idField';
import BooleanField from './fields/booleanField';

const Label = (t, field) => t(`interaction.highlight.fields.${field}`);

const actionTypes = (t) =>
  ['', ...Object.keys(INTERACTION.ACTION)]
    .filter((key) => key !== 'CONTINUE')
    .map((key) =>
      key
        ? {
            value: INTERACTION.ACTION[key],
            label: t(`interaction.actions.${key.toLowerCase()}`)
          }
        : { value: '0', label: t(`interaction.actions.noAction`) }
    );

const borderStyleOptions = (t) =>
  Object.keys(INTERACTION.STYLE.BORDER_STYLE).map((key) => ({
    value: INTERACTION.STYLE.BORDER_STYLE[key],
    label: t(`interaction.style.borderStyle.${key.toLowerCase()}`)
  }));
export const fields = (t) => ({
  id: new IdField('id', Label(t, 'id')),
  type: new StringField('type'),
  backgroundColor: new JsonField('style.backgroundColor', Label(t, 'backgroundColor')),
  title: new StringField('title', Label(t, 'title'), {
    max: 100
  }),
  action: new EnumeratorField('action', Label(t, 'action'), actionTypes(t)),
  style: new JsonField('style', Label(t, 'style')),
  jump: new JsonField('jump'),
  jumpType: new EnumeratorField('jump.type', Label(t, 'actionType'), actionTypes(t)),
  jumpTo: new StringField('jump.jump_to', Label(t, 'jumpTo'), {
    testFunc: (val) => !Number.isNaN(hmsmsToSeconds(val))
  }),
  jumpTime: new StringField('jump.jump_time', Label(t, 'jumpTime')),
  link: new StringField('link', Label(t, 'link')),
  borderRadius: new EnumeratorField('style.borderStyle', 'BorderStyle', borderStyleOptions(t)),
  fadeIn: new BooleanField('style.fadeIn', Label(t, 'fadeIn')),
  fadeOut: new BooleanField('style.fadeOut', Label(t, 'fadeOut')),
  jumpToInteraction: new StringField('', t('interaction.actions.jump_to_interaction'))
});

export default {
  fields
};
