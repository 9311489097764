import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isNode, isEdge, useZoomPanHelper, useStoreState } from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as ProjectNodeActions from 'src/actions/projectNode';
import { useCopyPaste } from 'src/hooks/useCopyPaste';
import { useNotify } from 'src/hooks/useNotify';
import * as ProjectSelectors from 'src/selectors/projectNode';

const _paneContextMenuItems = (t) => ({
  paste: {
    label: t('common.actions.paste'),
    shortcut: 'Ctrl/Cmd+V'
  }
});
export const useProjectListNode = (id) => {
  const { t } = useTranslation();
  const { warningNotify } = useNotify();
  const dispatch = useDispatch();

  // fetch
  const elements = useSelector(ProjectSelectors.getElements);
  const loading = useSelector(ProjectSelectors.getLoading);
  const error = useSelector(ProjectSelectors.getError);
  const onFetch = useCallback((_id) => {
    dispatch(ProjectNodeActions.fetchListRequest({ project_id: _id }));
  }, []);

  useEffect(() => {
    onFetch(id);
  }, [id]);

  useEffect(() => () => dispatch(ProjectNodeActions.resetRequest()), []);

  // drag, delete, connect, block interactive
  const onUpdate = useCallback((node) => {
    dispatch(ProjectNodeActions.updateRequest(node));
  }, []);

  const handleNodeDragStop = useCallback((evt, node) => {
    const newNode = { id: node.id, x: node.position.x, y: node.position.y };
    onUpdate(newNode);
  }, []);

  const handleConnect = useCallback((params) => {
    if (params.source === params.target) return;
    dispatch(ProjectNodeActions.connectRequest(params.source, params.target));
  }, []);

  const handleDelete = useCallback((delElements) => {
    if (isNode(delElements[0])) {
      dispatch(ProjectNodeActions.deleteNodeRequest(delElements));
    } else if (isEdge(delElements[0])) {
      dispatch(ProjectNodeActions.deleteEdgeRequest(delElements));
    }
  }, []);

  const handleInteractiveChange = useCallback((val) => {
    if (val) {
      dispatch(ProjectNodeActions.enableNodesConnectRequest());
    } else {
      dispatch(ProjectNodeActions.disableNodesConnectRequest());
    }
  }, []);

  // copy
  const handleSelectionChange = useCallback((els) => {
    if (!els) return;
    dispatch(ProjectNodeActions.setSelectedRequest(els[0].id));
  }, []);

  const onCopyNode = useCallback(() => {
    dispatch(ProjectNodeActions.setCopyItemRequest());
  }, []);

  const onPasteNode = useCallback(async (pos) => {
    try {
      await dispatch(ProjectNodeActions.copyRequest(pos));
    } catch (e) {
      warningNotify(e.message === 'maxNode' ? t('projectNode.create.maxNode') : t('projectNode.create.failure'));
      dispatch(ProjectNodeActions.copyFailure(e.message));
    }
  });

  useCopyPaste(onCopyNode, onPasteNode);

  // node context menu
  const { project, setCenter } = useZoomPanHelper();
  const onNodeContextMenu = useCallback((evt, node) => {
    evt.preventDefault();
    dispatch(ProjectNodeActions.openNodeContextMenuRequest(node.id));
  }, []);

  // pane context menu
  const [paneContextMenuPos, setPaneContextMenuPos] = useState({ x: 0, y: 0 });
  const [openPaneContextMenu, setOpenPaneContextMenu] = useState(false);
  const rootRef = useRef(null);
  const [rootBounding, setRootBounding] = useState();
  const paneContextMenuItems = _paneContextMenuItems(t);
  useEffect(() => {
    setRootBounding(rootRef.current.getBoundingClientRect());
  }, []);
  const onPaneContextMenu = useCallback((evt) => {
    evt.preventDefault();
    setPaneContextMenuPos({ x: evt.clientX, y: evt.clientY });
    setOpenPaneContextMenu(true);
  }, []);

  const hanlePaneContextMenuClose = useCallback(() => {
    setOpenPaneContextMenu(false);
  }, []);

  const handleMenuItemClick = useCallback(
    (item) => {
      if (item === 'paste') {
        onPasteNode(project({ x: paneContextMenuPos.x - rootBounding.x, y: paneContextMenuPos.y - rootBounding.y }));
      }
      hanlePaneContextMenuClose();
    },
    [paneContextMenuPos]
  );

  // Search node
  const tranform = useStoreState((store) => store.transform);
  const searchedNode = useSelector(ProjectSelectors.getSearchedNode);
  const searchOptions = useMemo(
    () => elements.filter((el) => isNode(el)).map((el) => ({ label: el.data.name, id: el.id })),
    [elements]
  );
  const onAutocompleteChange = useCallback((value) => {
    dispatch(ProjectNodeActions.setSearchedNode(value ? value.id : null));
  }, []);
  const onHighlightChange = useCallback((value) => {
    dispatch(ProjectNodeActions.setHighlightNode(value ? value.id : null));
  }, []);
  const onSearchBlur = useCallback(() => {
    dispatch(ProjectNodeActions.setSearchedNode(null));
    dispatch(ProjectNodeActions.setHighlightNode(null));
  }, []);
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    if (searchedNode) {
      setCenter(searchedNode.x, searchedNode.y, tranform[2]);
    }
  }, [searchedNode]);
  return {
    elements,
    loading,
    error,

    handleNodeDragStop,
    handleConnect,
    handleDelete,
    handleInteractiveChange,

    handleSelectionChange,
    onNodeContextMenu,
    onPaneContextMenu,
    paneContextMenuPos,
    openPaneContextMenu,
    hanlePaneContextMenuClose,
    handleMenuItemClick,
    rootRef,
    paneContextMenuItems,
    searchOptions,
    onAutocompleteChange,
    onHighlightChange,
    searchedNode,
    onSearchBlur,
    openSearch,
    setOpenSearch
  };
};
