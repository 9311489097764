import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AuthTypes } from 'src/actions';

const requestState = {
  requesting: false,
  success: false,
  failure: false
};

const INITIAL_STATE = {
  user: null,
  login: requestState,
  forgotPassword: requestState,
  resetPassword: requestState
};

const loginRequest = (state = INITIAL_STATE) => ({
  ...state,
  login: {
    ...requestState,
    requesting: true
  }
});

const loginSuccess = (state = INITIAL_STATE, { user }) => ({
  ...state,
  login: {
    ...requestState,
    success: true
  },
  user
});

const loginFailure = (state = INITIAL_STATE) => ({
  ...state,
  login: {
    ...requestState,
    failure: true
  }
});

const forgotPasswordRequest = (state = INITIAL_STATE) => ({
  ...state,
  forgotPassword: {
    ...requestState,
    requesting: true
  }
});

const forgotPasswordSuccess = (state = INITIAL_STATE) => ({
  ...state,
  forgotPassword: {
    ...requestState,
    success: true
  }
});

const forgotPasswordFailure = (state = INITIAL_STATE) => ({
  ...state,
  forgotPassword: {
    ...requestState,
    failure: true
  }
});

const resetPasswordRequest = (state = INITIAL_STATE) => ({
  ...state,
  resetPassword: {
    ...requestState,
    requesting: true
  }
});

const resetPasswordSuccess = (state = INITIAL_STATE) => ({
  ...state,
  resetPassword: {
    ...requestState,
    success: true
  }
});

const resetPasswordFailure = (state = INITIAL_STATE) => ({
  ...state,
  resetPassword: {
    ...requestState,
    failure: true
  }
});

const logoutRequest = () => INITIAL_STATE;

export const HANDLERS = {
  [AuthTypes.LOGIN_REQUESTING]: loginRequest,
  [AuthTypes.LOGIN_SUCCESS]: loginSuccess,
  [AuthTypes.LOGIN_FAILURE]: loginFailure,

  [AuthTypes.FORGOT_PASSWORD_REQUESTING]: forgotPasswordRequest,
  [AuthTypes.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [AuthTypes.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,

  [AuthTypes.RESET_PASSWORD_REQUESTING]: resetPasswordRequest,
  [AuthTypes.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [AuthTypes.RESET_PASSWORD_FAILURE]: resetPasswordFailure,

  [AuthTypes.LOGOUT]: logoutRequest
};

const authReducer = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'auth',
  storage
};

export default persistReducer(persistConfig, authReducer);
