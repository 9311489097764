import { Box } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { INTERACTION } from 'src/constants/interactions';
import * as videoPlayerSelectors from 'src/selectors/videoPlayer';
import videoController from 'src/utils/videoController';
import ReactGA from 'react-ga';
import './HighlightInteractionDisplay.css';
import { useTranslation } from 'react-i18next';

const ResizeHandleBottomRight = () => (
  <Box
    sx={{
      zIndex: 1,
      position: 'absolute',
      top: '2px',
      left: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'se-resize !important'
    }}
  />
);

const ResizeHandleBottomLeft = () => (
  <Box
    sx={{
      zIndex: 1,
      position: 'absolute',
      top: '2px',
      right: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'sw-resize !important'
    }}
  />
);

const ResizeHandleBottom = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '-2px',
      left: '48%',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 's-resize !important'
    }}
  />
);

const ResizeHandleTop = () => (
  <Box
    sx={{
      position: 'absolute',
      bottom: '-2px',
      left: '48%',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'n-resize !important'
    }}
  />
);
const ResizeHandleLeft = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '48%',
      right: '-2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'w-resize !important'
    }}
  />
);

const ResizeHandleRight = () => (
  <Box
    sx={{
      position: 'absolute',
      top: '48%',
      right: '4px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'e-resize !important'
    }}
  />
);

const ResizeHandleTopLeft = () => (
  <Box
    className="resize-box"
    sx={{
      position: 'absolute',
      bottom: '2px',
      right: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'nw-resize !important'
    }}
  />
);

const ResizeHandleTopRight = () => (
  <Box
    sx={{
      position: 'absolute',
      bottom: '2px',
      left: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'ne-resize !important'
    }}
  />
);
export const HighlightInteractionDisplay = ({ config, videoBounding, isEditting, isPublishMode }) => {
  const dispatch = useDispatch();

  const [dragging, setDragging] = useState(false);

  const { t } = useTranslation();

  const handleChangeConfig = useCallback((newConfig) => {
    dispatch(EdittingInteractionActions.setConfig(newConfig));
  }, []);

  const videoControl = videoController.getInstance();
  const [jumpToSpecificScheduler, setJumpToSpecificScheduler] = useState(false);
  const videoData = useSelector(videoPlayerSelectors.getVideoData);
  const onClick = useCallback(() => {
    if (isPublishMode) {
      ReactGA.event({
        category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
        action:
          (config.link && t(`googleAnalyticsActions.actions.link`)) ||
          (config.jump.type && t(`interaction.mapKeyAction.${config.jump.type}`)) ||
          t(`googleAnalyticsActions.actions.noAction`),
        label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.title}`,
        value: videoData.id
      });
    }
    if (config.jump !== null) {
      switch (config.jump.type) {
        case INTERACTION.ACTION.JUMP_TO_START:
        case INTERACTION.ACTION.JUMP_TO_END:
        case INTERACTION.ACTION.JUMP_TO_SPECIFIC: {
          videoControl.seek(config.jump.jump_to);
          break;
        }
        case INTERACTION.ACTION.JUMP_TO_SPECIFIC_SCHEDULER: {
          setJumpToSpecificScheduler(true);
          break;
        }
        case INTERACTION.ACTION.JUMP_TO_INTERACTION: {
          if (!isEditting) {
            videoControl.seek(config.jump.jump_to);
          }
          break;
        }
        case INTERACTION.ACTION.JUMP_TO_NODE: {
          if (isPublishMode) {
            videoControl.seek(parseInt(config.jump.time_jump_to_node || 0, 10) + (config.jump.product_jump_to || 0));
          }
          break;
        }
        default:
      }
    } else if (config.link !== null) {
      window.open(config.link, '_blank');
      videoControl.pause();
    }
  }, [config.jump, config.link, isPublishMode, isEditting]);

  const showGrid = useSelector(videoPlayerSelectors.getShowVideoGrid);
  const gridCellWidth = useSelector(videoPlayerSelectors.getWidthCellGrid);
  useEffect(() => {
    if (showGrid) {
      const newX = (Math.trunc((config.x * videoBounding.width) / gridCellWidth) * gridCellWidth) / videoBounding.width;
      const newY =
        (Math.trunc((config.y * videoBounding.height) / gridCellWidth) * gridCellWidth) / videoBounding.height;
      const newWidth =
        (Math.trunc((config.width * videoBounding.width) / gridCellWidth) * gridCellWidth) / videoBounding.width;
      const newHeight =
        (Math.trunc((config.height * videoBounding.height) / gridCellWidth) * gridCellWidth) / videoBounding.height;
      dispatch(EdittingInteractionActions.setConfig({ x: newX, y: newY, width: newWidth, height: newHeight }));
    }
  }, [showGrid]);
  const [triggerDisplay, setTriggerDisplay] = useState(false);
  const [classNameFade, setClassNameFade] = useState('');
  const currentTime = useSelector(videoPlayerSelectors.getCurrentTime);
  useEffect(() => {
    if (jumpToSpecificScheduler) {
      if (Math.abs(currentTime - config.jump.jump_time) < 1) {
        videoControl.seek(config.jump.jump_to);
        setJumpToSpecificScheduler(false);
      }
    }
    if (currentTime >= config.start_time && currentTime < config.end_time) {
      if (!triggerDisplay) {
        if (config.style.fadeIn) {
          setClassNameFade('image-interaction-fadeIn');
        } else {
          setClassNameFade('');
        }
      }
      setTriggerDisplay(true);
    } else {
      if (triggerDisplay) {
        if (config.style.fadeOut) {
          setClassNameFade('image-interaction-fadeOut');
        } else {
          setClassNameFade('');
        }
      }
      setTriggerDisplay(false);
    }
  }, [currentTime]);
  return (
    // <Box
    //   className={`${classNameFade} waocon-interaction-bounding-box`}
    //   sx={{
    //     visibility: triggerDisplay ? 'visible' : 'hidden',
    //     overflow: 'hidden',
    //     opacity: triggerDisplay ? 1 : 0
    //   }}
    // >
    <Rnd
      className={`rnd ${classNameFade}`}
      enableResizing={isEditting}
      disableDragging={!isEditting}
      {...(showGrid ? { dragGrid: [gridCellWidth, gridCellWidth] } : {})}
      style={{
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        border: isEditting ? '2px dashed white' : 'none',
        cursor: 'auto !important',

        visibility: triggerDisplay ? 'visible' : 'hidden',
        opacity: triggerDisplay ? 1 : 0
      }}
      bounds="parent"
      size={{
        width: config.width * videoBounding.width,
        height: config.height * videoBounding.height
      }}
      position={{
        x: config.x * videoBounding.width,
        y: config.y * videoBounding.height
      }}
      minWidth="10%"
      minHeight="10%"
      onDragStart={() => setDragging(true)}
      onDragStop={(e, d) => {
        setDragging(false);
        handleChangeConfig({ x: d.lastX / videoBounding.width, y: d.lastY / videoBounding.height });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        handleChangeConfig({
          width: ref.offsetWidth / videoBounding.width,
          height: ref.offsetHeight / videoBounding.height,
          x: position.x / videoBounding.width,
          y: position.y / videoBounding.height
        });
      }}
      resizeHandleComponent={{
        bottomRight: <ResizeHandleBottomRight />,
        bottomLeft: <ResizeHandleBottomLeft />,
        bottom: <ResizeHandleBottom />,
        top: <ResizeHandleTop />,
        left: <ResizeHandleLeft />,
        right: <ResizeHandleRight />,
        topLeft: <ResizeHandleTopLeft />,
        topRight: <ResizeHandleTopRight />
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
          boxShadow: `0 0 0 ${videoBounding.width * 2}px rgba(${config.style.backgroundColor?.r}, ${
            config.style.backgroundColor?.g
          }, ${config.style.backgroundColor?.b}, ${config.style.backgroundColor?.a})`,
          borderRadius: `${config.style.borderRadius}%`,
          border: `${config.style.borderWidth}px ${config.style.borderStyle} rgba(${config.style.borderColor.r}, ${config.style.borderColor.g}, ${config.style.borderColor.b}, ${config.style.borderColor.a})`,
          cursor: dragging ? 'move !important' : 'auto !important',
          ...(!isEditting ? { cursor: 'pointer !important' } : {})
        }}
        {...(!isEditting ? { onClick } : {})}
      />
      <Box
        className="image-interaction-jump-to-schedule-checked"
        sx={{ visibility: !isEditting && jumpToSpecificScheduler ? 'visible' : 'hidden' }}
      >
        <CheckIcon className="image-interaction-jump-to-schedule-checked-icon" />
      </Box>
    </Rnd>
    // </Box>
  );
};

HighlightInteractionDisplay.propTypes = {
  videoBounding: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isPublishMode: PropTypes.bool
};
HighlightInteractionDisplay.defaultProps = {
  isPublishMode: false
};
