/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as ProjectActions from 'src/actions/projectDetail';
import { fields as _fields } from 'src/models/project';
import { FormSchema } from 'src/models/schema';
import * as ProjectSelectors from 'src/selectors/projectDetail';
import * as NodesSelectors from 'src/selectors/projectNode';
import { objectSpecialEqual } from 'src/utils/objectCompare';
import { useNotify } from './useNotify';

export const useProjectSettings = () => {
  const { t } = useTranslation();
  const fields = _fields(t);
  const { successNotify, errorNotify } = useNotify();
  const dispatch = useDispatch();

  const project = useSelector(ProjectSelectors.getProjectState);
  const numNodes = useSelector(NodesSelectors.getNumNodes);
  const numPaths = useSelector(NodesSelectors.getNumPaths);
  const { requesting: loading, failure: error } = useSelector(ProjectSelectors.getFetchState);

  const copyToClipboard = useCallback((projectID) => {
    navigator.clipboard.writeText(projectID);
    successNotify(t('common.messages.copiedToClipboard'), { autoHideDuration: 1500 });
  }, []);

  const { cast, schema } = useMemo(
    () =>
      new FormSchema(fields.id, [
        fields.name,
        fields.aspect_ratio,
        fields.buttonSound,
        fields.durationIn,
        fields.durationOut
      ]),
    []
  );
  const DefaultValues = useMemo(
    () => ({
      [fields.name.name]: project?.name,
      [fields.id.name]: project?.id_public,
      [fields.buttonSound.name]: project?.button_sound ?? '0',
      [fields.buttonSound.name]: project?.button_sound ?? '0',
      [fields.aspect_ratio.name]: project?.aspect_ratio ?? '1',
      [fields.durationIn.name]: project?.duration_in ?? 0,
      [fields.durationOut.name]: project?.duration_out ?? 0
    }),
    [project]
  );
  const { formState, control, reset, handleSubmit } = useForm({
    defaultValues: DefaultValues,
    resolver: schema,
    reValidateMode: 'onBlur'
  });

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );
  const resetDefault = useCallback(() => {
    reset(DefaultValues, {
      keepErrors: false,
      keepDirty: false,
      keepIsSubmitted: false,
      keepTouched: false,
      keepIsValid: false,
      keepSubmitCount: false
    });
  }, [DefaultValues]);

  useEffect(() => {
    resetDefault();
  }, [resetDefault]);
  const onUpdate = useCallback(
    async (formData) => {
      // Them dong nay vi yup validate number bi sida, van chap nhan vd nhu 0.124a
      // under the hood la do no parse 0.124a thanh 0.124, vi vay reset lai de hien thi dung gia tri
      reset(formData);
      if (!objectSpecialEqual(formData, project)) {
        try {
          const { data } = await dispatch(ProjectActions.updateRequest(formData, project.id));
          dispatch(ProjectActions.updateSuccess(data));
          successNotify(t('projects.update.success'));
        } catch (e) {
          errorNotify(t('projects.update.error'));
          resetDefault();
        }
      }
    },
    [project, formState]
  );

  useEffect(() => {
    if (Object.keys(formState.errors).length > 0) {
      resetDefault();
    }
  }, [formState, resetDefault]);
  return {
    t,
    project,
    numNodes,
    numPaths,
    loading,
    error,
    formState,
    formItems,
    onUpdate,
    handleSubmit,
    resetDefault,
    copyToClipboard
  };
};
