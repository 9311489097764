import { Box, Collapse, Drawer, ListItem, ListItemText } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { NAVBAR_HEIGHT } from 'src/configs/layout';
import { useProjectDetail } from 'src/hooks/useProjectDetail';
import { BackgroundSoundtrack, Library, Search } from 'src/pages/project-sidebar';
import Logo from './Logo';
import Scrollbar from './Scrollbar';

const ProjectSidebar = (props) => {
  const { t } = useTranslation();
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [isOpenBS, setIsOpenBS] = React.useState(false);

  const { previewMediaState } = useProjectDetail();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#b1c1d5'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Library item={previewMediaState} />
        </Box>
        <Box sx={{ p: 2 }}>
          <Search />
        </Box>

        <ListItem button onClick={() => setIsOpenBS(!isOpenBS)}>
          <ListItemText primary={t('projectSidebar.backgroundSoundtrack')} />
          {isOpenBS ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse sx={{ p: 2 }} in={!isOpenBS} timeout="auto">
          <BackgroundSoundtrack />
        </Collapse>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            // backgroundImage: 'url(/static/images/bg.jpeg)',
            backgroundAttachment: 'local',
            backgroundRepeat: 'round',
            // boxShadow:
            //   '0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
            height: `calc(100% - ${NAVBAR_HEIGHT}px) !important`,
            top: `${NAVBAR_HEIGHT}px !Important`,
            width: 250,
            borderRight: 'none'
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

ProjectSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default ProjectSidebar;
