/* eslint-disable no-unused-vars */
import { Avatar, Box, ButtonBase, Dialog, IconButton, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PreviewIcon from '@material-ui/icons/Preview';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
// import { reset } from 'src/actions/projectPreview';
import { useProjectDetail } from 'src/hooks/useProjectDetail';
// import { useProjectPreview } from 'src/hooks/useProjectPreview';
import PropTypes from 'prop-types';

const PreviewProject = (props) => {
  const { text } = props;
  const { t } = useTranslation();
  const { id } = useParams();
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const { previewState, onGetPreview } = useProjectPreview();
  const { projectState } = useProjectDetail();

  // useEffect(() => {
  //   if (open) {
  //     onGetPreview(id);
  //   }
  // }, [id, open]);

  // useEffect(
  //   () => () => {
  //     dispatch(reset());
  //   },
  //   [open]
  // );

  return (
    <>
      <Tooltip title={t('projectPreview.label.projectPreview')}>
        <Box
          component={ButtonBase}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Avatar
            sx={{
              height: 32,
              width: 32
            }}
          >
            <PreviewIcon />
          </Avatar>
          {text && (
            <Typography
              sx={{
                marginLeft: 1
              }}
              variant="p"
            >
              {text}
            </Typography>
          )}
        </Box>
      </Tooltip>

      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: '60vw',
            height: '70vh',
            p: 4
          }
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: '4px'
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Typography mb={4} color="textPrimary" variant="h5" textAlign="center">
          {projectState?.name}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          {/* <video
            ref={videoRef}
            controls
            style={{
              width: '100%',
              borderRadius: '4px'
            }}
          >
            <source src={previewState?.preview ? `${process.env.REACT_APP_PUBLIC_URL}${previewState.preview}` : ''} />
            <track kind="captions" />
          </video> */}
          <iframe
            width="100%"
            height="100%"
            style={{ border: '1px solid black', borderRadius: '8px', backgroundColor: '#f4f5f7' }}
            src={`${process.env.REACT_APP_DOMAIN_URL}/preview/${id}`}
            // src={`http://localhost:3000/preview/${id}`}
            title="video"
          />
        </Box>
      </Dialog>
    </>
  );
};

PreviewProject.propTypes = {
  text: PropTypes.string
};

export default PreviewProject;
