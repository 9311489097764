import { Box } from '@material-ui/core';
import { routes } from 'src/configs/';
import { useTranslation } from 'react-i18next';
import { LayoutContainer, LayoutHeader } from 'src/components/layout';
import { AccountList as List } from './components/List';

const AccountList = () => {
  const { t } = useTranslation();

  return (
    <LayoutContainer
      title={t('breadcrumbs.accounts.list.title')}
      header={
        <LayoutHeader
          title={t('breadcrumbs.accounts.list.title')}
          breadcrumbs={[
            {
              path: routes.dashboard,
              name: t('breadcrumbs.dashboard')
            },
            {
              path: routes.accounts,
              name: t('breadcrumbs.accounts.title')
            }
          ]}
          create={{
            name: t('action.add'),
            path: routes.createAccount
          }}
        />
      }
    >
      <Box>
        <List />
      </Box>
    </LayoutContainer>
  );
};

export default AccountList;
