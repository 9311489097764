/*eslint-disable*/
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as BackgroundSoundTrackActions from 'src/actions/backgroundSoundTrack';
import * as BackgroundSoundTrackSelectors from 'src/selectors/backgroundSoundTrack';
import { useNotify } from './useNotify';

const appendZero = (number) => (number < 10 ? `0${number}` : number);

export const useBackgroundSoundtrack = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorNotify, successNotify } = useNotify();

  const soundState = useSelector(BackgroundSoundTrackSelectors.getSoundState);
  const soundsState = useSelector(BackgroundSoundTrackSelectors.getSoundsState);
  const progressState = useSelector(BackgroundSoundTrackSelectors.getProgressState);
  const currentState = useSelector(BackgroundSoundTrackSelectors.getCurrentState);
  const durationsState = useSelector(BackgroundSoundTrackSelectors.getDurationsState);
  const pausedIconState = useSelector(BackgroundSoundTrackSelectors.getPausedIconState);

  const fetchState = useSelector(BackgroundSoundTrackSelectors.getFetchState);
  const updateState = useSelector(BackgroundSoundTrackSelectors.getUpdateState);
  const fetchSoundsState = useSelector(BackgroundSoundTrackSelectors.getFetchSoundsState);

  const getFormattedTime = (time) => {
    const dateTime = new Date(0, 0, 0, 0, 0, time, 0);
    const dateTimeM = appendZero(dateTime.getMinutes());
    const dateTimeS = appendZero(dateTime.getSeconds());

    return `${dateTimeM}:${dateTimeS}`;
  };
  const getProgress = (currentTime, duration) => parseFloat(100 * (currentTime / duration));
  const getCurrentTime = (progress, duration) => parseFloat((progress * duration) / 100);

  const onFetch = useCallback(
    async (id) => {
      try {
        const { sound } = await dispatch(BackgroundSoundTrackActions.fetchRequest(id));
        dispatch(BackgroundSoundTrackActions.fetchSuccess(sound));
      } catch (err) {
        errorNotify(t('backgroundSoundTrack.request.fetch.failure'));
        dispatch(BackgroundSoundTrackActions.fetchFailure());
      }
    },
    [dispatch, navigate]
  );

  const onUpdate = useCallback(
    async ({ id, is_active, loop, volume, mute }) => {
      try {
        const { sound } = await dispatch(
          BackgroundSoundTrackActions.updateRequest({ id, is_active, loop, volume, mute })
        );
        dispatch(BackgroundSoundTrackActions.updateSuccess(sound));
      } catch (err) {
        errorNotify(t('backgroundSoundTrack.request.update.failure'));
        dispatch(BackgroundSoundTrackActions.updateFailure());
      }
    },
    [dispatch]
  );

  const onFetchSounds = useCallback(
    async (id) => {
      try {
        const data = await dispatch(BackgroundSoundTrackActions.fetchSoundsRequest(id));
        dispatch(BackgroundSoundTrackActions.fetchSoundsSuccess(data.project.background_sound));
      } catch (err) {
        errorNotify(t('backgroundSoundTrack.request.update.failure'));
        dispatch(BackgroundSoundTrackActions.fetchSoundsFailure());
      }
    },
    [dispatch]
  );

  return {
    soundState,
    soundsState,
    progressState,
    currentState,
    durationsState,

    fetchState,
    updateState,
    fetchSoundsState,

    getFormattedTime,
    getProgress,
    getCurrentTime,
    pausedIconState,

    onFetch,
    onUpdate,
    onFetchSounds
  };
};
