/*eslint-disable*/
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import PropTypes from 'prop-types';
import React from 'react';
import Media from './Media';

const getTreeItemsFromData = (treeItems) =>
  treeItems?.map((treeItem) => {
    let folders;
    if (treeItem?.folders && treeItem?.folders.length > 0) {
      folders = getTreeItemsFromData(treeItem?.folders);
    }

    return (
      <TreeItem
        key={treeItem.id}
        nodeId={treeItem.uuid}
        label={treeItem.name}
        sx={{
          '& .Mui-selected, & .Mui-selected: hover': {
            backgroundColor: '#87CDCF !important'
          }
        }}
      >
        {folders}
        <Media treeItem={treeItem} />
      </TreeItem>
    );
  });

const DataTreeView = ({ treeItem, ...props }) => (
  <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} {...props}>
    {getTreeItemsFromData(treeItem?.folders)}

    <Media treeItem={treeItem} />
  </TreeView>
);

DataTreeView.propTypes = {
  treeItem: PropTypes.object.isRequired
};

export default DataTreeView;
