export default (name = '') =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');

export const GA_CATEGORIES = {
  VIDEO: 'VIDEO'
};

export const GA_ACTION = {
  PLAY_VIDEO: 'PLAY_VIDEO',
  VIDEO_INTERACTION_ACTION_JUMP_TO_NODE: 'JUMP_TO_NODE',
  VIDEO_INTERACTION_ACTION_JUMP_TO_INTERACTION: 'JUMP_TO_INTERACTION',
  VIDEO_INTERACTION_ACTION_CONTINUE: 'CONTINUE',
  VIDEO_INTERACTION_ACTION_LINK: 'LINK',
  VIDEO_INTERACTION_ACTION_MENU: 'MENU',
  VIDEO_INTERACTION_ACTION_FORM: 'FORM',
  VIDEO_INTERACTION_ACTION_ZOOM: 'ZOOM',
  VIDEO_INTERACTION_ACTION_PAUSE_SCREEN: 'PAUSE_SCREEN',
  VIDEO_INTERACTION_ACTION_NO_ACTION: 'NO_ACTION'
};
