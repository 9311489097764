/* eslint-disable no-unused-vars */
import { Box, IconButton, ListItemText, MenuItem, Popover, TextField, Typography } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import VideoCameraBackOutlinedIcon from '@material-ui/icons/VideoCameraBackOutlined';
import PropTypes from 'prop-types';
import React, { memo, useRef, useEffect, useMemo } from 'react';
import { Handle } from 'react-flow-renderer';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as DraggingSelectors from 'src/selectors/dragging';
import * as DraggingActions from 'src/actions/dragging';
import { useVideoNode } from 'src/hooks/useVideoNode';
import NodeContextMenu from './NodeContextMenu';
import './videoNode.css';

const cssProps = {
  nodeRoot: (selected, highlight) => {
    let borderCss = '1px solid #777';
    if (highlight) {
      borderCss = '1px solid #FFAB1A';
    } else if (selected) {
      borderCss = '1px solid #87CDCF';
    }
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: borderCss,
      borderRadius: '24px',
      height: '48px',
      py: '2.6px',
      backgroundColor: 'background.paper',
      cursor: 'pointer'
    };
  },
  nodeContent: (selected, searched) => {
    let bgColor = 'inherit';
    if (searched) {
      bgColor = '#fad087';
    } else if (selected) {
      bgColor = 'primary.light';
    }
    return {
      height: '100%',
      m: 0.5,
      borderRadius: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pr: '48px',
      pl: '4px',
      backgroundColor: bgColor,
      '&:hover': {
        cursor: 'pointer !important',
        backgroundColor: 'primary.main'
      },
      cursor: 'grab !important'
    };
  },
  nodeButton: () => ({
    position: 'absolute',
    top: 0,
    right: 0.1,
    backgroundColor: 'background.paper',
    p: 0.5,
    m: 0.5,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'primary.main',
      '.MuiSvgIcon-root': {
        color: 'white'
      }
    }
  }),
  nodeHandleLeft: {
    width: 7,
    height: 14,
    borderRadius: 0,
    borderBottomRightRadius: '7px',
    borderTopRightRadius: '7px',
    border: '1px solid gray',
    left: -6
  },
  nodeHandleRight: {
    width: 7,
    height: 14,
    borderRadius: 0,
    borderBottomLeftRadius: '7px',
    borderTopLeftRadius: '7px',
    border: '1px solid gray',
    right: -6
  }
};

const VideoNode = ({ data, isConnectable, selected, isDragging }) => {
  const {
    control,
    editable,
    handleCreateChild,
    onSubmit,
    toggleEdit,
    handleCloseContextMenu,
    anchorRef,
    anchorEl,
    nodeContextItems,
    handleMenuItemClick,
    blurByContextClick,
    setBlurByContextClick,
    DnDModeProps
  } = useVideoNode({
    data,
    selected,
    isDragging
  });

  return (
    <Box sx={cssProps.nodeRoot(selected, data.highlight)} {...DnDModeProps}>
      <Handle type="target" position="left" id="t" style={cssProps.nodeHandleLeft} isConnectable={isConnectable} />
      <Box sx={cssProps.nodeContent(selected, data.searched)}>
        <VideoCameraBackOutlinedIcon />
        <Box sx={{ ml: '4px', width: '80px' }} ref={anchorRef}>
          {editable ? (
            <Box className="nodrag">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(evt) => {
                      field.onChange(evt.target.value);
                      setBlurByContextClick(false);
                    }}
                    autoFocus
                    onFocus={(evt) => {
                      evt.target.select();
                    }}
                    fullWidth
                    size="small"
                    onBlur={() => {
                      if (!blurByContextClick) onSubmit();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true
                    }}
                    sx={{ backgroundColor: 'white' }}
                  />
                )}
              />
            </Box>
          ) : (
            <>
              <Typography noWrap onClick={toggleEdit}>
                {data.name}
              </Typography>
            </>
          )}
          <NodeContextMenu
            anchorEl={anchorEl ?? {}}
            open={data.openContextMenu ?? false}
            handleClose={handleCloseContextMenu}
            contextItems={nodeContextItems}
            handleItemClick={handleMenuItemClick}
          />
        </Box>
      </Box>

      <IconButton sx={cssProps.nodeButton(data.highlight)} onClick={() => handleCreateChild()}>
        <ControlPointIcon sx={{ color: data.highlight ? '#FFAB1A' : 'primary.main', fontSize: 32 }} />
      </IconButton>
      <Handle type="source" position="right" id="s" style={cssProps.nodeHandleRight} isConnectable={isConnectable} />
    </Box>
  );
};

VideoNode.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired
};

VideoNode.defaultProps = {
  isConnectable: true
};

export default memo(VideoNode);
