import StringField from 'src/models/fields/stringField';
import DecimalField from './fields/decimalField';
import EnumeratorField from './fields/enumeratorField';
import IdField from './fields/idField';

const Label = (t, field) => t(`projects.fields.${field}`);

export const fields = (t) => ({
  id: new IdField('id_public', Label(t, 'id')),
  name: new StringField('name', Label(t, 'name'), {
    required: true,
    max: 100
  }),
  aspect_ratio: new EnumeratorField('aspect_ratio', Label(t, 'aspect_ratio'), [
    { value: '1', label: '16:9' },
    { value: '2', label: '9:16' }
  ]),
  buttonSound: new EnumeratorField('button_sound', Label(t, 'buttonSound'), [
    { value: '0', label: t('projects.defaultButtonSound.none') },
    { value: '1', label: t('projects.defaultButtonSound.default') }
  ]),
  durationIn: new DecimalField('duration_in', Label(t, 'durationIn'), { required: true, max: 2, min: 0 }),
  durationOut: new DecimalField('duration_out', Label(t, 'durationOut'), { required: true, max: 2, min: 0 })
});

export default {
  fields
};
