import { Autocomplete, Box, Button, FormLabel, Grid, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import InputField from 'src/components/FormControls/InputField';
import { useScreenNodeSettings } from 'src/hooks/useScreenNodeSettings';
import { fields as _nodeFields } from 'src/models/node';

const InputFieldProps = {
  InputProps: {
    sx: { height: '30px' }
  }
};

export const ScreenNodeSettings = ({ setSettingsHeight, nodeID }) => {
  const { t, control, imageOptions, formItems, onUpdate, handleSubmit, DnDModeProps, node } = useScreenNodeSettings(
    nodeID
  );
  const nodeFields = _nodeFields(t);
  const ref = useRef(null);
  useEffect(() => {
    setSettingsHeight(ref.current.clientHeight);
  }, []);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onUpdate)();
      }}
    >
      <Box
        ref={ref}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor: '#F1F4F7',
          p: 2,
          pb: 1
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <div
              {...DnDModeProps}
              style={{
                // 16:9
                maxWidth: '275px',
                height: '155px',
                border: '1px solid #BEC0C3',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <img
                style={{ maxWidth: '100%', maxHeight: '100%' }}
                {...(!node.image ? { width: '100%', height: '100%' } : {})}
                alt="video"
                src={node.image ? `${process.env.REACT_APP_PUBLIC_URL}${node.image}` : '/static/empty_video.png'}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ textTransform: 'uppercase', mt: '-8px' }}>
                {t('projectNode.settings.screenTitle')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={5}>
                <Grid item md={4} sm={5}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputField
                        label={nodeFields.screenName.label}
                        name={nodeFields.screenName.name}
                        {...formItems}
                        OnBlur={handleSubmit(onUpdate)}
                        {...InputFieldProps}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Controller
                        name={nodeFields.screenAsset.name}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            autoSelect
                            blurOnSelect
                            autoHighlight
                            isOptionEqualToValue={(opt, value) => {
                              if (!value) return false;
                              return `${opt.id}` === `${value.id}`;
                            }}
                            {...field}
                            options={imageOptions}
                            onChange={(_, val) => {
                              field.onChange(val);
                            }}
                            getOptionLabel={(opt) => `${opt.name}` || ''}
                            renderInput={(params) => (
                              <>
                                <FormLabel>{nodeFields.screenAsset.label}</FormLabel>
                                <TextField
                                  {...params}
                                  onBlur={handleSubmit(onUpdate)}
                                  size="small"
                                  InputProps={{
                                    ...params.InputProps,
                                    sx: { height: 30, pt: '1px !important' }
                                  }}
                                />
                              </>
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* Them button submit nay de form lang nghe enter */}
      <Button type="submit" sx={{ display: 'none' }} />
    </form>
  );
};

ScreenNodeSettings.propTypes = {
  setSettingsHeight: PropTypes.func.isRequired,
  nodeID: PropTypes.string.isRequired
};
