/* eslint-disable */
import React, { useState } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

const ColorField = ({ id, color, onColorChange, sx }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleColorToggle = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleColorChange = (color) => {
    onColorChange(color.rgb);
  };

  const styles = reactCSS({
    default: {
      color: {
        position: 'relative',
        width: '50px',
        height: '30px',
        borderRadius: '2px',
        background: `rgba(${color?.r || 0}, ${color?.g || 0}, ${color?.b || 0}, ${color?.a || 0})`,
        ...sx.color
      },
      swatch: {
        width: '60px',
        padding: '4px',
        background: '#eee',
        border: '1px solid black',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        ...sx.swatch
      },
      popover: {
        zIndex: '2',
        position: 'absolute'
      },
      cover: {
        position: 'absolute',
        top: '-400px',
        right: '-400px',
        bottom: '0px',
        left: '-400px'
      }
    }
  });

  return (
    <>
      <div style={styles.swatch} onClick={handleColorToggle}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div id={id} style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleColorChange} />
        </div>
      ) : null}
    </>
  );
};

ColorField.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.object,
  color: PropTypes.object.isRequired,
  onColorChange: PropTypes.func.isRequired
};

ColorField.defaultProps = {
  id: '',
  sx: {}
};

export default ColorField;
