import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { EdittingInteractionTypes } from 'src/actions/edittingInteraction';

const INITIAL_STATE = {
  config: null,
  validateError: false
};

const resetRequest = () => INITIAL_STATE;

const setConfig = (state = INITIAL_STATE, action) => ({
  ...state,
  config: {
    ...state.config,
    ...action.config
  }
});

const textInteractionSetTextConfig = (state = INITIAL_STATE, action) => ({
  ...state,
  config: {
    ...state.config,
    ...action.textConfig,
    style: {
      ...state.config.style
    }
  }
});

const textInteractionSetTextStyleConfig = (state = INITIAL_STATE, action) => ({
  ...state,
  config: {
    ...state.config,
    style: {
      ...state.config.style,
      ...action.textStyleConfig
    }
  }
});

const setStyleConfig = (state = INITIAL_STATE, action) => ({
  ...state,
  config: {
    ...state.config,
    style: {
      ...state.config.style,
      ...action.styleConfig
    }
  }
});

const setJumpConfig = (state = INITIAL_STATE, action) => ({
  ...state,
  config: {
    ...state.config,
    jump: {
      ...state.config.jump,
      ...action.config
    }
  }
});

const setValidateError = (state = INITIAL_STATE, action) => ({
  ...state,
  validateError: action.error
});

// eslint-disable-next-line no-unused-vars
const persitCurrentState = (state = INITIAL_STATE) => {
  console.log('123');
  return {
    ...state
  };
};

const setMenuItemList = (state = INITIAL_STATE, action) => ({
  ...state,
  config: {
    ...state.config,
    items: action.menuItems
  }
});

const setSingleMenuItemConfig = (state = INITIAL_STATE, action) => ({
  ...state,
  config: {
    ...state.config,
    items: state.config.items.map((item, index) =>
      index === action.index
        ? { ...item, ...action.item, jump: action.item.jump === null ? null : { ...item.jump, ...action.item.jump } }
        : item
    )
  }
});

const setSingleMenuItemJumpConfig = (state = INITIAL_STATE, action) => ({
  ...state,
  config: {
    ...state.config,
    items: state.config.items.map((item) =>
      item.id === action.item.jump.jumpId ? { ...item, jump: { ...item.jump, ...action.item.jump } } : { ...item }
    )
  }
});

const addMenuItem = (state = INITIAL_STATE, action) => ({
  ...state,
  config: {
    ...state.config,
    items: [...state.config.items, { ...action.item, order: state.config.items.length }]
  }
});

const setInitialVideoWidth = (state = INITIAL_STATE, action) => {
  if (!state.config) return state;
  return {
    ...state,
    config: {
      ...state.config,
      style: {
        ...state.config.style,
        initialVideoWidth: action.videoWidth
      }
    }
  };
};

export const HANDLERS = {
  [EdittingInteractionTypes.EDITTING_INTERACTION_SET_CONFIG]: setConfig,
  [EdittingInteractionTypes.EDITTING_INTERACTION_RESET_REQUEST]: resetRequest,
  [EdittingInteractionTypes.EDITTING_INTERACTION_TEXT_INTERACTION_SET_TEXT_CONFIG]: textInteractionSetTextConfig,
  [EdittingInteractionTypes.EDITTING_INTERACTION_TEXT_INTERACTION_SET_TEXT_STYLE_CONFIG]: textInteractionSetTextStyleConfig,
  [EdittingInteractionTypes.EDITTING_INTERACTION_SET_STYLE_CONFIG]: setStyleConfig,
  [EdittingInteractionTypes.EDITTING_INTERACTION_SET_JUMP_CONFIG]: setJumpConfig,
  [EdittingInteractionTypes.EDITTING_INTERACTION_SET_VALIDATE_ERROR]: setValidateError,
  [EdittingInteractionTypes.EDITTING_INTERACTION_PERSIT_CURRENT_STATE]: persitCurrentState,
  [EdittingInteractionTypes.EDITTING_INTERACTION_SET_MENU_ITEM_LIST]: setMenuItemList,
  [EdittingInteractionTypes.EDITTING_INTERACTION_SET_SINGLE_MENU_ITEM_CONFIG]: setSingleMenuItemConfig,
  [EdittingInteractionTypes.EDITTING_INTERACTION_SET_SINGLE_MENU_ITEM_JUMP_CONFIG]: setSingleMenuItemJumpConfig,
  [EdittingInteractionTypes.EDITTING_INTERACTION_ADD_MENU_ITEM]: addMenuItem,
  [EdittingInteractionTypes.EDITTING_INTERACTION_SET_INITIAL_VIDEO_WIDTH]: setInitialVideoWidth
};

export const edittingInteraction = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'edittingInteraction',
  storage
};

export default persistReducer(persistConfig, edittingInteraction);
