import { createSelector } from 'reselect';

const backgroundSoundTrackState = (state) => state.backgroundSoundTrack;

export const getSoundState = createSelector(
  backgroundSoundTrackState,
  (backgroundSoundTrack) => backgroundSoundTrack.sound
);

export const getSoundsState = createSelector(
  backgroundSoundTrackState,
  (backgroundSoundTrack) => backgroundSoundTrack.sounds
);

export const getProgressState = createSelector(
  backgroundSoundTrackState,
  (backgroundSoundTrack) => backgroundSoundTrack.progress
);

export const getCurrentState = createSelector(
  backgroundSoundTrackState,
  (backgroundSoundTrack) => backgroundSoundTrack.current
);

export const getDurationsState = createSelector(
  backgroundSoundTrackState,
  (backgroundSoundTrack) => backgroundSoundTrack.duration
);

export const getPausedIconState = createSelector(
  backgroundSoundTrackState,
  (backgroundSoundTrack) => backgroundSoundTrack.pausedIcon
);

export const getFetchState = createSelector(
  backgroundSoundTrackState,
  (backgroundSoundTrack) => backgroundSoundTrack.fetch
);

export const getUpdateState = createSelector(
  backgroundSoundTrackState,
  (backgroundSoundTrack) => backgroundSoundTrack.update
);

export const getFetchSoundsState = createSelector(
  backgroundSoundTrackState,
  (backgroundSoundTrack) => backgroundSoundTrack.fetchSounds
);
