import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ProjectListTypes } from 'src/actions/projectList';

const INITIAL_STATE = {
  list: [],
  page: 1,
  totalPage: 0,
  filter: { name: '' },
  loading: false,
  error: null
};

const resetRequest = (state = INITIAL_STATE) => ({ ...state, filter: { name: '' }, page: 1 });

const listRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const listSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.list,
  totalPage: action.totalPage,
  loading: false,
  error: null
});

const listFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const setPage = (state = INITIAL_STATE, action) => ({
  ...state,
  page: action.page
});

const setFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...action.filter },
  page: 1
});

const createRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const createSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false
});

const createFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const editTitleRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const editTitleSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false
});

const editTitleFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const deleteRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const deleteSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false
});

const deleteFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const duplicateRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const duplicateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false
});

const duplicateFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

export const HANDLERS = {
  [ProjectListTypes.PROJECT_LIST_RESET_REQUEST]: resetRequest,
  [ProjectListTypes.PROJECT_LIST_REQUEST]: listRequest,
  [ProjectListTypes.PROJECT_LIST_SUCCESS]: listSuccess,
  [ProjectListTypes.PROJECT_LIST_FAILURE]: listFailure,
  [ProjectListTypes.PROJECT_SET_PAGE]: setPage,
  [ProjectListTypes.PROJECT_SET_FILTER]: setFilter,
  [ProjectListTypes.PROJECT_CREATE_REQUEST]: createRequest,
  [ProjectListTypes.PROJECT_CREATE_SUCCESS]: createSuccess,
  [ProjectListTypes.PROJECT_CREATE_FAILURE]: createFailure,
  [ProjectListTypes.PROJECT_EDIT_TITLE_REQUEST]: editTitleRequest,
  [ProjectListTypes.PROJECT_EDIT_TITLE_SUCCESS]: editTitleSuccess,
  [ProjectListTypes.PROJECT_EDIT_TITLE_FAILURE]: editTitleFailure,
  [ProjectListTypes.PROJECT_DELETE_REQUEST]: deleteRequest,
  [ProjectListTypes.PROJECT_DELETE_SUCCESS]: deleteSuccess,
  [ProjectListTypes.PROJECT_DELETE_FAILURE]: deleteFailure,
  [ProjectListTypes.PROJECT_DUPLICATE_REQUEST]: duplicateRequest,
  [ProjectListTypes.PROJECT_DUPLICATE_SUCCESS]: duplicateSuccess,
  [ProjectListTypes.PROJECT_DUPLICATE_FAILURE]: duplicateFailure
};

export const modal = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'projectList',
  storage
};

export default persistReducer(persistConfig, modal);
