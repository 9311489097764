/*eslint-disable*/
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'reduxsauce';
import { BackgroundSoundTrackTypes } from 'src/actions/backgroundSoundTrack';

const requestState = {
  requesting: false,
  success: false,
  failure: false
};

const INITIAL_STATE = {
  sound: null,
  sounds: [],

  progress: 0,
  current: 0,
  duration: 0,
  pausedIcon: true,

  fetch: requestState,
  update: requestState,
  fetSounds: requestState
};

const setSound = (state = INITIAL_STATE, { sound }) => ({
  ...state,
  sound
});

const setProgress = (state = INITIAL_STATE, { progress }) => ({
  ...state,
  progress
});

const setCurrent = (state = INITIAL_STATE, { current }) => ({
  ...state,
  current
});

const setDuration = (state = INITIAL_STATE, { duration }) => ({
  ...state,
  duration
});

const setPausedIcon = (state = INITIAL_STATE, { pausedIcon }) => ({
  ...state,
  pausedIcon
});

const reset = () => INITIAL_STATE;

// Fetch
const fetchSuccess = (state = INITIAL_STATE, { sound }) => ({
  ...state,
  fetch: {
    ...requestState,
    success: true
  },
  sound
});

const fetchFailure = (state = INITIAL_STATE) => ({
  ...state,
  fetch: {
    ...requestState,
    failure: true
  }
});

const fetchRequest = (state = INITIAL_STATE) => ({
  ...state,
  fetch: {
    ...requestState,
    requesting: true
  }
});

// Update
const updateSuccess = (state = INITIAL_STATE, { sound }) => ({
  ...state,
  update: {
    ...requestState,
    success: true
  },
  sound
});

const updateFailure = (state = INITIAL_STATE) => ({
  ...state,
  update: {
    ...requestState,
    failure: true
  }
});

const updateRequest = (state = INITIAL_STATE) => ({
  ...state,
  update: {
    ...requestState,
    requesting: true
  }
});

// Fetch Sounds
const fetchSoundsSuccess = (state = INITIAL_STATE, { sounds }) => {
  const sound = sounds?.find((sound) => !!sound.is_active);

  return {
    ...state,
    fetSounds: {
      ...requestState,
      success: true
    },
    sound: sound || null,
    sounds
  };
};

const fetchSoundsFailure = (state = INITIAL_STATE) => ({
  ...state,
  fetSounds: {
    ...requestState,
    failure: true
  }
});

const fetchSoundsRequest = (state = INITIAL_STATE) => ({
  ...state,
  fetSounds: {
    ...requestState,
    requesting: true
  }
});

export const HANDLERS = {
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_SET_SOUND]: setSound,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_SET_PROGRESS]: setProgress,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_SET_CURRENT]: setCurrent,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_SET_DURATION]: setDuration,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_SET_PAUSED_ICON]: setPausedIcon,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_RESET]: reset,

  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_SUCCESS]: fetchSuccess,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_FAILURE]: fetchFailure,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_REQUEST]: fetchRequest,

  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_UPDATE_SUCCESS]: updateSuccess,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_UPDATE_FAILURE]: updateFailure,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_UPDATE_REQUEST]: updateRequest,

  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_SOUNDS_SUCCESS]: fetchSoundsSuccess,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_SOUNDS_FAILURE]: fetchSoundsFailure,
  [BackgroundSoundTrackTypes.BG_SOUND_TRACK_FETCH_SOUNDS_REQUEST]: fetchSoundsRequest
};

export const backgroundSoundTrack = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'backgroundSoundTrack',
  storage
};

export default persistReducer(persistConfig, backgroundSoundTrack);
// export default backgroundSoundTrack;
