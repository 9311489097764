import { INTERACTION } from 'src/constants/interactions';
import StringField from 'src/models/fields/stringField';
import BooleanField from './fields/booleanField';
import EnumeratorField from './fields/enumeratorField';
import IdField from './fields/idField';
import JsonField from './fields/jsonField';

const Label = (t, field) => t(`interaction.pause.fields.${field}`);

const borderStyleOptions = (t) =>
  Object.keys(INTERACTION.STYLE.BORDER_STYLE).map((key) => ({
    value: INTERACTION.STYLE.BORDER_STYLE[key],
    label: t(`interaction.style.borderStyle.${key.toLowerCase()}`)
  }));

const textStrokeOptions = (t) =>
  Object.keys(INTERACTION.STYLE.TEXT_STROKE).map((key) => ({
    value: INTERACTION.STYLE.TEXT_STROKE[key],
    label: t(`interaction.style.textStroke.${key.toLowerCase()}`)
  }));
export const fields = (t) => ({
  id: new IdField('id', Label(t, 'id')),
  type: new StringField('type'),
  title: new StringField('title', Label(t, 'title'), {
    max: 100
  }),
  content: new StringField('content', Label(t, 'content')),
  backgroundColor: new JsonField('style.backgroundColor', t('interaction.style.backgroundColor')),
  sound: new StringField('sound', Label(t, 'sound')),
  activeSound: new StringField('active_sound', Label(t, 'activeSound')),
  playSound: new BooleanField('play_sound', Label(t, 'playSound')),
  fadeSound: new BooleanField('fade_sound', Label(t, 'fadeSound')),

  textStroke: new EnumeratorField('style.textStroke', t('interaction.style.textStroke.title'), textStrokeOptions(t)),
  borderRadius: new EnumeratorField('style.borderStyle', t('interaction.style.borderRadius'), borderStyleOptions(t)),
  border: new StringField('border', t('interaction.style.border')),
  topPadding: new StringField('border', t('interaction.style.topPadding')),
  borderWidth: new StringField('border', t('interaction.style.borderWidth')),
  fadeIn: new BooleanField('style.fadeIn', t('interaction.style.fadeIn')),
  fadeOut: new BooleanField('style.fadeOut', t('interaction.style.fadeOut'))
});

export default {
  fields
};
