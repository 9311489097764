import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as ViewVideoActions from 'src/actions/viewVideo';
// import { useTranslation } from 'react-i18next';
// import { useNotify } from './useNotify';

export const useVideoViewer = () => {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { errorNotify, successNotify } = useNotify();

  const onFetch = useCallback(
    async (params) => {
      try {
        const data = await dispatch(ViewVideoActions.fetchViewRequest(params));
        // dispatch(ViewVideoActions.fetchSuccess(data));
        return data;
      } catch (err) {
        // errorNotify(t('publicSettings.basicInfo.request.fetch.failure'));
        dispatch(ViewVideoActions.fetchViewFailure());
        return null;
      }
    },
    [dispatch]
  );

  return {
    onFetch
  };
};
