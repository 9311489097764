/*eslint-disable*/
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as PublishSettingsActions from 'src/actions/publishSettings';
import * as PublishSettingsSelectors from 'src/selectors/publishSettings';
import { useNotify } from './useNotify';

export const usePublishSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errorNotify, successNotify } = useNotify();

  const publishState = useSelector(PublishSettingsSelectors.getPublishState);

  const fetchState = useSelector(PublishSettingsSelectors.getFetchState);
  const updateState = useSelector(PublishSettingsSelectors.getUpdateState);

  const onFetch = useCallback(
    async (projectID) => {
      try {
        const { publish } = await dispatch(PublishSettingsActions.fetchRequest(projectID));
        dispatch(PublishSettingsActions.fetchSuccess(publish));
      } catch (err) {
        errorNotify(t('publicSettings.basicInfo.request.fetch.failure'));
        dispatch(PublishSettingsActions.fetchFailure());
      }
    },
    [dispatch]
  );

  const onUpdate = useCallback(
    async ({
      id,
      title,
      author,
      description,
      sharing_title,
      sharing_description,
      is_public,
      password,
      default_volume,
      autoplay
    }) => {
      try {
        const { publish } = await dispatch(
          PublishSettingsActions.updateRequest({
            id,
            title,
            author,
            description,
            sharing_title,
            sharing_description,
            is_public,
            password,
            default_volume,
            autoplay
          })
        );
        dispatch(PublishSettingsActions.updateSuccess(publish));
        successNotify(t('publicSettings.basicInfo.request.update.success'));
        return publish;
      } catch (err) {
        errorNotify(t('publicSettings.basicInfo.request.update.failure'));
        dispatch(PublishSettingsActions.updateFailure());
      }
    },
    [dispatch]
  );

  return {
    publishState,

    fetchState,
    updateState,

    onFetch,
    onUpdate
  };
};
