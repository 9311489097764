/* eslint-disable */
import { Avatar, Box, Button, ButtonBase, IconButton, Popover, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import axiosClient from 'src/utils/axiosClient';
import { useNotify } from 'src/hooks/useNotify';
import { usePublishManager } from 'src/hooks/usePublishManager';
import { reset } from 'src/actions/publishManager';
import { useDispatch } from 'react-redux';
import { useProjectDetail } from 'src/hooks/useProjectDetail';
import PropTypes from 'prop-types';

const PublishManager = (props) => {
  const { text } = props;
  const { id } = useParams();
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [publishStateIndetail, setPublishStateIndetail] = useState(null);
  const { successNotify } = useNotify();

  const [IDPublic, setIDPublic] = useState('');

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onNewRevision = async () => {
    try {
      setIsPublishing(true);
      const rs = await axiosClient.get(`/admin/project/publish/${id}`);
      setPublishStateIndetail(rs.publish);
    } finally {
      setIsPublishing(false);
    }
  };
  const onFetch = async () => {
    const rs = await axiosClient.get(`/admin/project/${id}`);
    setIDPublic(rs.project.id_public);
    setPublishStateIndetail(rs.project.publish);
  };

  const handlePublishClick = useCallback(async () => {
    await onNewRevision();
  }, [onNewRevision]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN_URL}/v/${IDPublic}`);

    successNotify(t('publishManager.label.copiedToClipboard'));
  }, [publishStateIndetail, IDPublic]);

  useEffect(() => {
    if (open) {
      onFetch();
    }
  }, [open]);

  useEffect(() => () => dispatch(reset()), []);
  return (
    <>
      <Tooltip title={t('publishManager.label.publishManager')}>
        <Box
          component={ButtonBase}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Avatar
            sx={{
              height: 32,
              width: 32
            }}
          >
            <PublishIcon />
          </Avatar>
          {text && (
            <Typography
              sx={{
                marginLeft: 1
              }}
              variant="p"
            >
              {text}
            </Typography>
          )}
        </Box>
      </Tooltip>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          position: 'relative',
          sx: { width: 400 }
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: '4px'
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ p: 2, mt: 3 }}>
          <Typography color="textPrimary" variant="h5" textAlign="center">
            {t('publishManager.label.publishManager')}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 2,
            height: '60vh'
            // backgroundImage: 'url(/static/images/auth.jpeg)'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Button
              disabled={isPublishing || parseInt(publishStateIndetail?.status) === 1}
              onClick={handlePublishClick}
              sx={{ borderRadius: '2px' }}
              variant="contained"
            >
              {t('publishManager.label.publishNewRevision')}
            </Button>
          </Box>

          {parseInt(publishStateIndetail?.status) === 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography
                textAlign="center"
                sx={{
                  width: '70%'
                }}
                mb={1}
                size="small"
              >
                {t('publishManager.label.videoBeingProcessed')}
              </Typography>
            </Box>
          )}

          {parseInt(publishStateIndetail?.status) === 2 && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  textAlign="center"
                  sx={{
                    maxWidth: 200,
                    overflow: 'hidden'
                  }}
                  mb={1}
                  size="small"
                >
                  {`${process.env.REACT_APP_PUBLIC_URL}/v/${IDPublic}`}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <Tooltip title="Copy to clipboard">
                  <Button
                    disabled={isPublishing}
                    onClick={handleCopyLink}
                    sx={{ borderRadius: '2px' }}
                    size="small"
                    variant="contained"
                  >
                    {t('publishManager.label.copyLink')}
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};

PublishManager.propTypes = {
  text: PropTypes.string
};

export default PublishManager;
