import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as ProjectProcessManagement from 'src/actions/projectProcessManagement';
import * as ProjectProcessManagementSelectors from 'src/selectors/projectProcessManagement';

export const useProcessManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listState = useSelector(ProjectProcessManagementSelectors.getList);

  const pageState = useSelector(ProjectProcessManagementSelectors.getPage);
  const filterState = useSelector(ProjectProcessManagementSelectors.getFilter);
  const totalPageState = useSelector(ProjectProcessManagementSelectors.getTotalPage);

  let isReseting = useMemo(() => false, []);

  const onFetch = useCallback(async () => {
    await dispatch(ProjectProcessManagement.listRequest(filterState, pageState));
  }, [filterState, pageState]);

  useEffect(async () => {
    isReseting = true;
    await dispatch(ProjectProcessManagement.resetRequest());
    isReseting = false;
  }, []);

  useEffect(() => {
    if (!isReseting) onFetch();
  }, [onFetch, isReseting]);

  const onPageChange = useCallback((evt, value) => {
    dispatch(ProjectProcessManagement.setPage(value));
  }, []);

  const onSearch = useCallback((formData) => {
    dispatch(ProjectProcessManagement.setFilter(formData));
  }, []);

  return {
    onPageChange,
    onSearch,
    listState,
    t,
    pageState,
    filterState,
    totalPageState
  };
};
