import { useTheme } from '@emotion/react';
import { Box, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { INTERACTION } from 'src/constants/interactions';
import 'src/containers/interactionsForm/text/TextInteraction.css';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import * as ProjectNodeSelectors from 'src/selectors/projectNode';
import { getDuration } from 'src/selectors/videoPlayer';
import { getInteractionLabel } from 'src/utils/getInteractionStartTime';
import { actionOptions_, convertFormToRedux, convertReduxToForm } from '../text/TextInteractionInteractionForm';

const TextInteractionInteractionForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const actionOptions = actionOptions_(t);
  const textConfig = useSelector(EdittingInteractionSelectors.textInteractionGetTextConfig);
  const dispatch = useDispatch();
  const videoDuration = useSelector(getDuration);

  const [titleError, setTitleError] = useState('');

  // Handle jump to interaction
  const interactionList = useSelector(NodeInteractionSelectors.getInteractionList);
  const jumpToInteractionOptions = useMemo(
    () =>
      interactionList.map((inter) => ({
        label: getInteractionLabel(inter),
        value: inter.id_interaction
      })),
    [interactionList]
  );

  // Handle jump to node
  const nodeList = useSelector(ProjectNodeSelectors.getListJumpNode);
  const jumpToNodeOptions = useMemo(() =>
    nodeList.map((node) => ({
      label: node.name,
      value: node.id
    }))
  );
  const { control, handleSubmit, watch, reset, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: convertReduxToForm(textConfig, nodeList || [])
  });

  const watchJumpTypeField = watch('jump.type');

  const onSubmit = handleSubmit((formValues) => {
    dispatch(EdittingInteractionActions.textInteractionSetTextConfig(convertFormToRedux(formValues, videoDuration)));
  });

  useEffect(() => {
    reset(convertReduxToForm(textConfig));
  }, [textConfig]);

  const watchActionType = watch('jump.type');
  const firstRenderRef = useRef(true);
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else if (
      [
        INTERACTION.ACTION.JUMP_TO_END,
        INTERACTION.ACTION.JUMP_TO_START,
        INTERACTION.ACTION.JUMP_TO_NODE,
        INTERACTION.ACTION.JUMP_TO_SPECIFIC,
        INTERACTION.ACTION.JUMP_TO_SPECIFIC_SCHEDULER
      ].includes(watchActionType)
    ) {
      setValue('jump.jump_time', '00:00:00.00');
      setValue('jump.jump_to', '00:00:00.00');
    }
  }, [watchActionType]);

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="title"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.transparentButton.label.title')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={(event) => {
                    onChange(event.target.value);
                    dispatch(EdittingInteractionActions.setConfig({ title: event.target.value }));

                    if (!event.target.value.length || event.target.value.length > 100) {
                      if (!event.target.value.length) {
                        setTitleError(t('interaction.transparentButton.validate.title.required'));
                      } else {
                        setTitleError(t('interaction.transparentButton.validate.title.max', { max: 100 }));
                      }
                      dispatch(EdittingInteractionActions.setValidateError(true));
                    } else {
                      setTitleError('');
                      dispatch(EdittingInteractionActions.setValidateError(false));
                    }
                  }}
                  value={value}
                />
              </Box>
              {!!titleError && (
                <Box
                  sx={{
                    display: 'flex',
                    height: 40,
                    maxHeight: 40,
                    alignItems: 'center',
                    color: theme.palette.error.main
                  }}
                >
                  <Typography width={160} />
                  <Typography sx={{ width: 'calc(100% - 160px)' }}>{titleError}</Typography>
                </Box>
              )}
            </Box>
          )}
        />

        <Controller
          name="jump.type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 600 }} width={160}>
                {t('interaction.transparentButton.label.action')}
              </Typography>
              <Select
                sx={{ width: 'calc(100% - 160px)' }}
                size="small"
                value={value}
                fullWidth
                onChange={(e) => {
                  onChange(e.target.value);
                  onSubmit();
                }}
              >
                {actionOptions.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        />

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_SPECIFIC && (
          <Controller
            name="jump.jump_to"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.transparentButton.label.jumpTo')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                    onSubmit();
                  }}
                  value={value}
                />
              </Box>
            )}
          />
        )}

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_SPECIFIC_SCHEDULER && (
          <Controller
            name="jump.jump_time"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.transparentButton.label.jumpAt')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                    onSubmit();
                  }}
                  value={value}
                />
              </Box>
            )}
          />
        )}

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_SPECIFIC_SCHEDULER && (
          <Controller
            name="jump.jump_to"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.transparentButton.label.jumpTo')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={onChange}
                  onBlur={() => {
                    onBlur();
                    onSubmit();
                  }}
                  value={value}
                />
              </Box>
            )}
          />
        )}

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_INTERACTION && (
          <Controller
            name="jump.interaction_id"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.text.label.jumpToInteraction')}
                </Typography>
                <Select
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  value={value}
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value);
                    onSubmit();
                  }}
                >
                  {jumpToInteractionOptions.map((opt) => (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
          />
        )}

        {watchJumpTypeField === INTERACTION.ACTION.JUMP_TO_NODE && (
          <>
            <Controller
              name="jump.node_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: 600 }} width={160}>
                    {t('interaction.actions.jump_to_node')}
                  </Typography>
                  <Select
                    sx={{ width: 'calc(100% - 160px)' }}
                    size="small"
                    value={value}
                    fullWidth
                    onChange={(e) => {
                      onChange(e.target.value);
                      onSubmit();
                    }}
                  >
                    {jumpToNodeOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            />

            {getValues('jump.node_id') && (
              <Controller
                name="jump.time_jump_to_node"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 600 }} width={160}>
                      {t('interaction.jump.label.jumpTo')}
                    </Typography>
                    <TextField
                      sx={{ width: 'calc(100% - 160px)' }}
                      size="small"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.target.blur();
                        }
                      }}
                      onChange={onChange}
                      onBlur={() => {
                        onBlur();
                        onSubmit();
                      }}
                      value={value}
                    />
                  </Box>
                )}
              />
            )}
          </>
        )}

        {watchJumpTypeField === INTERACTION.ACTION.LINK && (
          <Controller
            name="link"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box sx={{ display: 'flex', mb: 2, height: 40, maxHeight: 40, alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 600 }} width={160}>
                  {t('interaction.transparentButton.label.hyperlink')}
                </Typography>
                <TextField
                  sx={{ width: 'calc(100% - 160px)' }}
                  size="small"
                  fullWidth
                  onChange={(_value) => {
                    onChange(_value);
                    onSubmit();
                  }}
                  value={value}
                  placeholder="Example: https://google.com.vn"
                />
              </Box>
            )}
          />
        )}
      </form>
    </>
  );
};

export default TextInteractionInteractionForm;
