import { Grid, FormLabel, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import ColorPicker from '../ColorPicker';

const ColorField = ({ name, label, control, layout, onChange, ...others }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <FormControl sx={{ width: '100%' }}>
        <Grid container {...layout.grid}>
          {label && (
            <Grid item {...layout.label}>
              <FormLabel>{label}</FormLabel>
            </Grid>
          )}
          <Grid item {...layout.input}>
            <ColorPicker
              {...others}
              {...field}
              color={field.value}
              onColorChange={(colorHex) => {
                field.onChange(colorHex);
                onChange({ [name]: colorHex });
              }}
            />
          </Grid>
        </Grid>
      </FormControl>
    )}
  />
);

ColorField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  layout: PropTypes.object,
  onChange: PropTypes.func
};

ColorField.defaultProps = {
  label: '',
  layout: {
    grid: {
      my: 1
    },
    label: {
      xs: 4
    },
    input: {
      xs: 8
    }
  },
  onChange: null
};

export default ColorField;
