import * as yup from 'yup';
import GenericField from 'src/models/fields/genericField';

export default class JsonField extends GenericField {
  forTable(overrides) {
    const defaultRender = undefined;

    const { title = this.label, sorter = false, dataIndex = this.name, render = defaultRender, ...others } =
      overrides || {};

    return {
      ...others,
      title,
      sorter,
      dataIndex,
      render
    };
  }

  forView = (value) => value;

  forFormInitialValue = (value) => value;

  forForm = () => yup.mixed().label(this.label);

  forFilter = () => yup.mixed().label(this.label);

  forExport = () =>
    yup
      .mixed()
      .label(this.label)
      .transform((_, originalValue) => JSON.stringify(originalValue, null, 2));

  forImport = () => yup.mixed().label(this.label);
}
