/*eslint-disable*/
import { Box, IconButton, Slider, Typography } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import PauseCircleIcon from '@material-ui/icons/PauseCircle';
import PlayCircleIcon from '@material-ui/icons/PlayCircle';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { reset, setCurrent, setDuration, setPausedIcon, setProgress } from 'src/actions/backgroundSoundTrack';
import SelectField from 'src/components/FormControls/SelectField';
import { useBackgroundSoundtrack } from 'src/hooks/useBackgroundSoundtrack';
import { useProjectDetail } from 'src/hooks/useProjectDetail';
import { soundsExtension } from 'src/utils/mediaTypes';

const BackgroundSoundtrack = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const dispatch = useDispatch();

  const soundRef = useRef(null);

  const timeoutChangeVolumeRef = useRef(null);

  const { backgroundSoundState } = useProjectDetail();

  const {
    soundState,
    soundsState,
    progressState,
    durationsState,
    currentState,
    pausedIconState,
    updateState,

    getFormattedTime,
    getProgress,
    getCurrentTime,

    onFetch,
    onUpdate,
    onFetchSounds
  } = useBackgroundSoundtrack();

  const options = useMemo(() => soundsState?.map((sound) => ({ label: sound.name, value: sound.id })), [soundsState]);
  const { control, formState, reset: resetForm } = useForm({
    defaultValues: {
      sound: soundState?.id || ''
    }
  });

  const formItems = useMemo(() => ({
    control,
    formState
  }));

  const handleOptionsChange = useCallback(
    (option) => {
      dispatch(setProgress(0));
      if (option.sound) {
        onFetch(option.sound);
      }
    },
    [onFetch]
  );

  const handleActiveChange = useCallback(() => {
    const { id, is_active, loop, volume, mute } = soundState;
    onUpdate({ id, is_active: !is_active, loop, volume, mute });
  }, [soundState]);

  const handlePauseChange = useCallback(() => {
    if (soundRef.current) {
      if (soundRef.current?.paused) {
        dispatch(setPausedIcon(false));
        soundRef.current?.play();
      } else {
        dispatch(setPausedIcon(true));
        soundRef.current?.pause();
      }
    }
  }, [pausedIconState]);

  const handleProgressChange = useCallback(
    (_, value) => {
      if (soundRef.current) {
        const currentTime = getCurrentTime(value, soundRef.current.duration);

        if (!isNaN(currentTime)) {
          soundRef.current.currentTime = currentTime;
        }
        dispatch(setProgress(value));
        dispatch(setCurrent(currentTime));
      }
    },
    [soundState]
  );

  const handleLoopChange = useCallback(() => {
    const { id, is_active, loop, volume, mute } = soundState;
    onUpdate({ id, is_active, loop: !loop, volume, mute });
  }, [soundState]);

  const handleMuteChange = useCallback(() => {
    const { id, is_active, loop, volume, mute } = soundState;
    onUpdate({ id, is_active, loop, volume, mute: !mute });
  }, [soundState]);

  const handleVolumeChange = useCallback(
    (_, volume) => {
      if (timeoutChangeVolumeRef.current) {
        clearTimeout(timeoutChangeVolumeRef.current);
      }

      timeoutChangeVolumeRef.current = setTimeout(() => {
        const { id, is_active, loop, mute } = soundState;
        setTimeout(() => {
          onUpdate({ id, is_active, loop, volume, mute });
        }, 0);
      }, 1000);
      soundRef.current.volume = Math.floor(volume / 10) / 10;
    },
    [soundState]
  );

  useEffect(() => {
    if (soundRef.current) {
      soundRef.current.load();
      //
      soundRef.current.addEventListener(
        'timeupdate',
        () => {
          dispatch(setDuration(soundRef.current?.duration));
          dispatch(setCurrent(soundRef.current?.currentTime));
          dispatch(setProgress(getProgress(soundRef.current?.currentTime, soundRef.current?.duration)));
        },
        false
      );
      soundRef.current.addEventListener('canplaythrough', () => {}, false);
    }

    if (soundState) {
      resetForm({ sound: soundState.id });
    } else {
      resetForm({ sound: '' });
    }
    //
    return () => {
      soundRef.current?.removeEventListener('timeupdate', () => {}, false);
      soundRef.current?.removeEventListener('canplaythrough', () => {}, false);
    };
  }, [soundState]);

  useEffect(() => {
    onFetchSounds(id);
  }, [id, backgroundSoundState]);

  useEffect(() => () => dispatch(reset()), []);

  return soundsState?.length ? (
    <Box
      sx={{
        bgcolor: 'white',
        borderRadius: '4px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          pr: 1
        }}
      >
        {soundState ? (
          <IconButton
            onClick={handleActiveChange}
            component="span"
            sx={{
              flexShrink: 0
            }}
          >
            <PowerSettingsNewIcon color={soundState.is_active ? 'primary' : 'disabled'} />
          </IconButton>
        ) : (
          <IconButton
            disabled
            component="span"
            sx={{
              flexShrink: 0
            }}
          >
            <PowerSettingsNewIcon />
          </IconButton>
        )}

        <Box
          noValidate
          component="form"
          sx={{
            flex: 1
          }}
        >
          {soundState ? (
            <SelectField handleChange={handleOptionsChange} name="sound" options={options} {...formItems} />
          ) : (
            <SelectField
              handleChange={handleOptionsChange}
              name="sound"
              options={[...options, { label: 'None', value: '' }]}
              {...formItems}
            />
          )}
        </Box>
      </Box>

      {soundState ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pr: 1
          }}
        >
          <IconButton onClick={handlePauseChange} color="primary" component="span">
            {pausedIconState || progressState === 100 || progressState === 0 ? <PlayCircleIcon /> : <PauseCircleIcon />}
          </IconButton>
          <Slider
            key={`slider-${progressState}`}
            onChange={handleProgressChange}
            sx={{ maxWidth: 60 }}
            size="small"
            value={progressState || 0}
          />
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Typography fontSize={10}>
              {soundRef.current?.currentTime
                ? `${getFormattedTime(currentState)}/${getFormattedTime(durationsState)}`
                : '00:00/00:00'}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pr: 1
          }}
        >
          <IconButton disabled component="span">
            <PauseCircleIcon />
          </IconButton>
          <Slider disabled sx={{ maxWidth: 60 }} size="small" />
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Typography fontSize={10}>00:00/00:00</Typography>
          </Box>
        </Box>
      )}

      {soundState ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pr: 1
          }}
        >
          <IconButton
            color="primary"
            component="span"
            sx={{
              flexShrink: 0
            }}
          >
            <VolumeUpIcon />
          </IconButton>

          <Slider
            key={`slider-${100}`}
            onChange={handleVolumeChange}
            sx={{ maxWidth: 60 }}
            size="small"
            defaultValue={100}
            // value={soundState.volume}
          />

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton onClick={handleLoopChange} size="small" component="span">
              <LoopIcon color={!!soundState.loop ? 'primary' : 'disabled'} />
            </IconButton>

            <IconButton onClick={handleMuteChange} size="small" component="span">
              <VideocamOffIcon color={soundState.mute ? 'primary' : 'disabled'} />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pr: 1
          }}
        >
          <IconButton
            disabled
            component="span"
            sx={{
              flexShrink: 0
            }}
          >
            <VolumeOffIcon />
          </IconButton>

          <Slider disabled sx={{ maxWidth: 60 }} size="small" />

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton disabled size="small" color="primary" component="span">
              <LoopIcon />
            </IconButton>

            <IconButton disabled size="small" component="span">
              <VideocamOffIcon />
            </IconButton>
          </Box>
        </Box>
      )}

      {/* Audio hidden */}
      <audio ref={soundRef} style={{ display: 'hidden' }}>
        {soundsExtension.map((sound, idx) => (
          <source key={idx} src={soundState ? `${process.env.REACT_APP_PUBLIC_URL}${soundState?.url}` : ''} type={`audio/${sound}`} />
        ))}
      </audio>
    </Box>
  ) : (
    <Box
      sx={{
        bgcolor: 'white',
        borderRadius: '4px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          pr: 1
        }}
      >
        <IconButton
          disabled
          component="span"
          sx={{
            flexShrink: 0
          }}
        >
          <PowerSettingsNewIcon />
        </IconButton>
        <Box
          noValidate
          component="form"
          sx={{
            flex: 1
          }}
        >
          <SelectField disabled name="sound" options={[{ label: 'None', value: '' }]} {...formItems} />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          pr: 1
        }}
      >
        <IconButton disabled component="span">
          <PauseCircleIcon />
        </IconButton>

        <Slider disabled sx={{ maxWidth: 60 }} size="small" />

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Typography fontSize={10}>00:00/00:00</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          pr: 1
        }}
      >
        <IconButton
          disabled
          component="span"
          sx={{
            flexShrink: 0
          }}
        >
          <VolumeOffIcon />
        </IconButton>

        <Slider disabled sx={{ maxWidth: 60 }} size="small" />

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton disabled size="small" color="primary" component="span">
            <LoopIcon />
          </IconButton>

          <IconButton disabled size="small" component="span">
            <VideocamOffIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BackgroundSoundtrack;
