import { Avatar, Box, Card, CardContent, Container, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import LoginForm from './components/LoginForm';

const Login = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('auth.label.loginTitle')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Container maxWidth="sm">
          <Card
            sx={{
              my: 4
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mb: 1
                }}
              >
                <Avatar>
                  <LockOutlinedIcon />
                </Avatar>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography color="textPrimary" gutterBottom variant="h4">
                  {t('auth.label.login')}
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <LoginForm />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
