import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LayoutContainer, LayoutHeader } from 'src/components/layout';
import ProjectProcessManagementContainer from 'src/containers/projects/projectProcessManagement';

const ProjectProcessManagement = () => {
  const { t } = useTranslation();
  return (
    <LayoutContainer
      title={t('projects.projectProcessManagement')}
      header={<LayoutHeader title={t('projects.projectProcessManagement')} />}
    >
      <Box>
        <ProjectProcessManagementContainer />
      </Box>
    </LayoutContainer>
  );
};

export default ProjectProcessManagement;
