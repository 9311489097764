import { Slide } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-app-polyfill/ie11';
import * as serviceWorker from './serviceWorker';
import './i18n';

// import store
import store, { persistor } from './reducers/store';

import App from './App';
import { SettingsProvider } from './contexts/SettingsContext';
// init Google analytics
ReactGA.initialize(process.env.REACT_APP_GA);
// import store

// Should define it
const LoadingOverlay = () => <div>Loading</div>;

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

const Root = () => (
  // store.dispatch(authenticate());

  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LoadingOverlay />}>
        <SnackbarProvider anchorOrigin={anchorOrigin} TransitionComponent={Slide} maxSnack={3}>
          <SettingsProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SettingsProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);
ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
