/* eslint-disable */

const timelineFormat = (_seconds, format = 'mm:ss:sss') => {
  const seconds = parseFloat(_seconds);
  if (!Number(seconds) && seconds !== 0) return;

  const temp = format.trim().toLocaleLowerCase();

  switch (temp) {
    case 'mm:ss:sss':
      const temp = new Date(seconds * 1000);
      const roundTowCharMs = parseInt(temp.getMilliseconds() / 10)
        .toString()
        .slice(-2);

      if (parseInt(roundTowCharMs) >= 10) {
        return `${temp.toISOString().substr(11, 8)}:${roundTowCharMs}`;
      }
      return `${temp.toISOString().substr(11, 8)}:0${roundTowCharMs}`;

    case 'hh:mm:ss':
      const timeString = new Date(seconds * 1000).toISOString().substr(11, 8);
      return timeString.startsWith('00:') ? timeString.slice(3) : timeString;

    case 'mm:ss':
      return new Date(seconds * 1000).toISOString().substr(14, 5);
    case 'hh:mm:ss.ms':
      return new Date(seconds * 1000).toISOString().substr(11, 11);
    default:
      return seconds;
  }
};

const hmsToSeconds = (str) => {
  if (typeof str !== 'string') return;

  const [h, m, s, ms] = str.split(':');
  let temp;
  if (ms) {
    temp = +h * 60 * 60 + +m * 60 + +s + +ms / 100;
  } else {
    temp = +h * 60 * 60 + +m * 60 + +s;
  }
  return temp;
};

const hmsmsToSeconds = (str) => {
  if (typeof str !== 'string') return;
  const [hms, ms] = str.split('.');
  const [h, m, s] = hms.split(':');
  const temp = +h * 60 * 60 + +m * 60 + +s + +ms / 100;
  return temp;
};

export { hmsToSeconds, hmsmsToSeconds };
export default timelineFormat;
