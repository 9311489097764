import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ProjectNodeTypes } from 'src/actions/projectNode';
import { removeElements, isNode } from 'react-flow-renderer';
import { NODE_TYPES } from 'src/constants/node';

const INITIAL_STATE = {
  elements: [],
  numPaths: 0,
  numNodes: 0,
  selected: null,
  copyItem: null,
  searchedItem: null,
  dragItem: null,
  screen: 'projectSettings',
  loading: false,
  error: null
};

const resetRequest = () => INITIAL_STATE;

const fetchListRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const fetchListSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  elements: data.elements,
  numNodes: data.numNodes,
  numPaths: data.numPaths,
  loading: false,
  error: null
});

const fetchListFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const createChildRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null
});

const createChildSuccess = (state = INITIAL_STATE, { newNode, newEdge, isNewPath, isBeginning }) => ({
  ...state,
  elements: [...state.elements, newNode, newEdge].map((e) => {
    if (e.type === NODE_TYPES.START_NODE) {
      e.connectable = isBeginning ? false : e.connectable;
    }
    return e;
  }),
  numNodes: state.numNodes + 1,
  numPaths: state.numPaths + (isNewPath ? 1 : 0),
  loading: false,
  error: null
});

const createChildFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const updateRequest = (state = INITIAL_STATE, { node }) => ({
  ...state,
  elements: state.elements.map((e) =>
    e.id === `${node.id}`
      ? {
          ...e,
          data: { ...e.data, ...node, id: e.id },
          position: { x: node.x || e.position.x, y: node.y || e.position.y }
        }
      : e
  ),
  loading: true,
  error: null
});

const updateSuccess = (state = INITIAL_STATE, { node }) => ({
  ...state,
  elements: state.elements.map((e) =>
    e.id === `${node.id}`
      ? {
          ...e,
          data: { ...e.data, ...node, id: e.id },
          position: { x: node.x || e.position.x, y: node.y || e.position.y }
        }
      : e
  ),
  loading: false,
  error: null
});

const updateFailure = (state = INITIAL_STATE, { prevNode, error }) => ({
  ...state,
  elements: state.elements.map((e) => (e.id === `${prevNode.id}` ? prevNode : e)),
  loading: false,
  error
});

const connectRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const connectSuccess = (state = INITIAL_STATE, { edge, isPath, connectBeginning, newNumbPaths }) => ({
  ...state,
  elements: connectBeginning
    ? [...state.elements, edge].map((e) => {
        if (e.type === NODE_TYPES.START_NODE) {
          e.connectable = false;
        }
        if (e.id === edge.target) {
          e.data.isBeginning = true;
        }
        return e;
      })
    : [...state.elements, edge],
  numPaths: connectBeginning ? newNumbPaths : state.numPaths + (isPath ? 1 : 0),
  loading: false,
  error: null
});

const connectFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const deleteNodeRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const deleteNodeSuccess = (state = INITIAL_STATE, { delElements, isBeginning, isPath }) => ({
  ...state,
  elements: removeElements(delElements, state.elements).map((e) => {
    if (e.type === NODE_TYPES.START_NODE) {
      e.connectable = isBeginning ? true : e.connectable;
    }
    return e;
  }),
  numPaths: isBeginning ? 0 : state.numPaths - (isPath ? 1 : 0),
  numNodes: state.numNodes - 1,
  loading: false,
  error: null
});

const deleteNodeFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const disableNodesConnect = (state = INITIAL_STATE) => ({
  ...state,
  elements: state.elements.map((el) =>
    isNode(el) ? { ...el, prevConnectable: el.connectable, connectable: false } : el
  )
});

const enableNodesConnect = (state = INITIAL_STATE) => ({
  ...state,
  elements: state.elements.map((el) =>
    isNode(el) ? { ...el, connectable: el.prevConnectable, prevConnectable: undefined } : el
  )
});

const disableDrag = (state = INITIAL_STATE, { nodeID }) => ({
  ...state,
  elements: state.elements.map((el) => {
    if (el.id === nodeID) {
      el.draggable = false;
    }
    return el;
  })
});

const enableDrag = (state = INITIAL_STATE, { nodeID }) => ({
  ...state,
  elements: state.elements.map((el) => {
    if (el.id === nodeID) {
      el.draggable = true;
    }
    return el;
  })
});

export const setSelected = (state = INITIAL_STATE, { nodeID }) => ({
  ...state,
  selected: nodeID
});

export const setCopyItem = (state = INITIAL_STATE) => ({
  ...state,
  copyItem: state.selected
});

export const copyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null
});

export const copySuccess = (state = INITIAL_STATE, { newNode }) => ({
  ...state,
  elements: [...state.elements, { ...newNode }],
  numNodes: state.numNodes + 1,
  loading: false,
  error: null
});

export const copyFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  copyItem: null,
  loading: false,
  error: action.error
});

export const openNodeContextMenu = (state = INITIAL_STATE, { nodeID }) => ({
  ...state,
  elements: state.elements.map((el) => {
    if (el.id === nodeID) {
      el.data = {
        ...el.data,
        openContextMenu: true
      };
      el.selected = true;
    } else if (isNode(el)) {
      el.selected = false;
    }
    return el;
  })
});

export const closeNodeContextMenu = (state = INITIAL_STATE, { nodeID }) => ({
  ...state,
  elements: state.elements.map((el) => {
    if (el.id === nodeID) {
      el.data = {
        ...el.data,
        openContextMenu: !el.data.openContextMenu
      };
    }
    return el;
  })
});

export const setHighlightNode = (state = INITIAL_STATE, { nodeID }) => ({
  ...state,
  elements: state.elements.map((el) => {
    if (el.id === nodeID) {
      el.data = {
        ...el.data,
        highlight: true
      };
    } else {
      el.data = {
        ...el.data,
        highlight: false
      };
    }
    return el;
  })
});

export const setSearchedNode = (state = INITIAL_STATE, { nodeID }) => {
  const searchedNode = state.elements.find((el) => el.id === nodeID);
  return {
    ...state,
    elements: state.elements.map((el) => {
      if (el.id === nodeID) {
        el.data = {
          ...el.data,
          highlight: true,
          searched: true
        };
      } else {
        el.data = {
          ...el.data,
          highlight: false,
          searched: false
        };
      }
      return el;
    }),
    searchedItem: searchedNode
      ? { id: searchedNode.id, label: searchedNode.data.name, x: searchedNode.position.x, y: searchedNode.position.y }
      : null
  };
};

const fetchNodeRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null
});

const fetchNodeSuccess = (state = INITIAL_STATE, { node }) => ({
  ...state,
  elements: state.elements.map((el) => {
    if (el.id === `${node.id}`) {
      el.data = {
        ...el.data,
        ...node,
        id: el.id
      };
    }
    return el;
  }),
  loading: false,
  error: null
});

const fetchNodeFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const deleteEdgeRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const deleteEdgeSuccess = (state = INITIAL_STATE, { delElements, isScreenPath, isPath }) => ({
  ...state,
  elements: removeElements(delElements, state.elements).map((e) => {
    if (e.type === NODE_TYPES.START_NODE) {
      e.connectable = isScreenPath ? true : e.connectable;
    }
    return e;
  }),
  numPaths: isScreenPath ? 0 : state.numPaths - (isPath ? 1 : 0),
  numNodes: state.numNodes - 1,
  loading: false,
  error: null
});

const deleteEdgeFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const setDragItem = (state = INITIAL_STATE, { item }) => ({
  ...state,
  dragItem: item
});

const setScreen = (state = INITIAL_STATE, action) => ({
  ...state,
  screen: action.screen
});

export const HANDLERS = {
  [ProjectNodeTypes.PROJECT_NODES_RESET_REQUEST]: resetRequest,
  [ProjectNodeTypes.PROJECT_NODES_FETCH_LIST_REQUEST]: fetchListRequest,
  [ProjectNodeTypes.PROJECT_NODES_FETCH_LIST_SUCCESS]: fetchListSuccess,
  [ProjectNodeTypes.PROJECT_NODES_FETCH_LIST_FAILURE]: fetchListFailure,
  [ProjectNodeTypes.PROJECT_NODE_CREATE_CHILD_REQUEST]: createChildRequest,
  [ProjectNodeTypes.PROJECT_NODE_CREATE_CHILD_SUCCESS]: createChildSuccess,
  [ProjectNodeTypes.PROJECT_NODE_CREATE_CHILD_FAILURE]: createChildFailure,
  [ProjectNodeTypes.PROJECT_NODE_UPDATE_NODE_REQUEST]: updateRequest,
  [ProjectNodeTypes.PROJECT_NODE_UPDATE_NODE_SUCCESS]: updateSuccess,
  [ProjectNodeTypes.PROJECT_NODE_UPDATE_NODE_FAILURE]: updateFailure,
  [ProjectNodeTypes.PROJECT_NODE_CONNECT_REQUEST]: connectRequest,
  [ProjectNodeTypes.PROJECT_NODE_CONNECT_SUCCESS]: connectSuccess,
  [ProjectNodeTypes.PROJECT_NODE_CONNECT_FAILURE]: connectFailure,
  [ProjectNodeTypes.PROJECT_NODE_DELETE_NODE_REQUEST]: deleteNodeRequest,
  [ProjectNodeTypes.PROJECT_NODE_DELETE_NODE_SUCCESS]: deleteNodeSuccess,
  [ProjectNodeTypes.PROJECT_NODE_DELETE_NODE_FAILURE]: deleteNodeFailure,
  [ProjectNodeTypes.PROJECT_NODE_DISABLE_NODES_CONNECT]: disableNodesConnect,
  [ProjectNodeTypes.PROJECT_NODE_ENABLE_NODES_CONNECT]: enableNodesConnect,
  [ProjectNodeTypes.PROJECT_NODE_DISABLE_DRAG]: disableDrag,
  [ProjectNodeTypes.PROJECT_NODE_ENABLE_DRAG]: enableDrag,
  [ProjectNodeTypes.PROJECT_NODE_SET_SELECTED]: setSelected,
  [ProjectNodeTypes.PROJECT_NODE_SET_COPY_ITEM]: setCopyItem,
  [ProjectNodeTypes.PROJECT_NODE_COPY_REQUEST]: copyRequest,
  [ProjectNodeTypes.PROJECT_NODE_COPY_SUCCESS]: copySuccess,
  [ProjectNodeTypes.PROJECT_NODE_COPY_FAILURE]: copyFailure,
  [ProjectNodeTypes.PROJECT_NODE_OPEN_NODE_CONTEXT_MENU]: openNodeContextMenu,
  [ProjectNodeTypes.PROJECT_NODE_CLOSE_NODE_CONTEXT_MENU]: closeNodeContextMenu,
  [ProjectNodeTypes.PROJECT_NODE_SET_HIGHLIGHT_NODE]: setHighlightNode,
  [ProjectNodeTypes.PROJECT_NODE_SET_SEARCHED_NODE]: setSearchedNode,
  [ProjectNodeTypes.PROJECT_NODE_FETCH_NODE_REQUEST]: fetchNodeRequest,
  [ProjectNodeTypes.PROJECT_NODE_FETCH_NODE_SUCCESS]: fetchNodeSuccess,
  [ProjectNodeTypes.PROJECT_NODE_FETCH_NODE_FAILURE]: fetchNodeFailure,
  [ProjectNodeTypes.PROJECT_NODE_DELETE_EDGE_REQUEST]: deleteEdgeRequest,
  [ProjectNodeTypes.PROJECT_NODE_DELETE_EDGE_SUCCESS]: deleteEdgeSuccess,
  [ProjectNodeTypes.PROJECT_NODE_DELETE_EDGE_FAILURE]: deleteEdgeFailure,
  [ProjectNodeTypes.PROJECT_NODE_SET_DRAG_ITEM]: setDragItem,
  [ProjectNodeTypes.PROJECT_NODE_SET_SCREEN]: setScreen
};

export const modal = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'projectNode',
  storage
};

export default persistReducer(persistConfig, modal);
