import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputField from 'src/components/FormControls/InputField';
import PasswordField from 'src/components/FormControls/PasswordField';
import SelectField from 'src/components/FormControls/SelectField';
import RegexPattern from 'src/constants/RegexPattern';
import UserRoles from 'src/constants/UserRoles';
import { useAuth } from 'src/hooks';
import * as Yup from 'yup';

const UpdateUserForm = (props) => {
  const { t } = useTranslation();

  const { onUpdate, userState, accountDetailState } = useAuth();

  const schema = Yup.object().shape({
    name: Yup.string()
      .required(t('account.validate.name.required'))
      .max(50, t('account.validate.name.max', { max: 50 })),

    email: Yup.mixed()
      .test('required', t('account.validate.email.required'), (value) => value.trim())
      .test('format', t('account.validate.email.format'), (value) => RegexPattern.Email.test(value)),

    password: Yup.string()
      .min(6, t('account.validate.password.min', { min: 6 }))
      .trim(),

    passwordConfirm: Yup.string().when('password', (password, field) =>
      password
        ? field
            .required(t('account.validate.password.passwordConfirm'))
            .oneOf([Yup.ref('password'), null], t('account.validate.password.confirm'))
        : field
    ),

    type: Yup.mixed()
      .test('required', t('account.validate.role.required'), (value) => value.trim())
      .test('format', t('account.validate.role.format'), (value) => Object.values(UserRoles).includes(value))
  });

  const { handleSubmit, control, formState, reset, watch } = useForm({
    defaultValues: {
      name: accountDetailState?.name,
      email: accountDetailState?.email,
      password: accountDetailState?.password,
      passwordConfirm: accountDetailState?.passwordConfirm,
      type: accountDetailState?.type
    },
    resolver: yupResolver(schema)
  });

  const { isSubmitting } = formState;

  const onSubmit = (formValues) => {
    onUpdate(formValues, props?.id);
  };

  useEffect(
    useCallback(() => {
      reset({
        name: accountDetailState?.name,
        email: accountDetailState?.email,
        password: accountDetailState?.password,
        passwordConfirm: accountDetailState?.passwordConfirm,
        type: accountDetailState?.type
      });
    }),
    [accountDetailState]
  );

  return (
    <>
      <Box>
        <Typography textAlign="center" color="textPrimary" gutterBottom variant="h4">
          {t('account.label.updateAccount')}
        </Typography>
      </Box>
      <Card
        sx={{
          p: 4,
          mt: 4
        }}
        {...props}
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <InputField
            required
            autoFocus
            name="name"
            label={t('account.label.name')}
            control={control}
            formState={formState}
            disabled={isSubmitting}
          />
          <InputField
            required
            name="email"
            label={t('account.label.email')}
            control={control}
            formState={formState}
            disabled
          />
          <PasswordField
            fullWidth
            name="password"
            label={t('account.label.password')}
            control={control}
            formState={formState}
            disabled={isSubmitting}
          />
          <PasswordField
            fullWidth
            name="passwordConfirm"
            disabled={!watch('password')}
            label={t('account.label.passwordConfirm')}
            control={control}
            formState={formState}
          />
          <SelectField
            required
            name="type"
            label={t('account.label.role')}
            control={control}
            disabled={userState?.type === UserRoles.User}
            formState={formState}
            options={[
              { label: 'Admin', value: UserRoles.Admin },
              { label: 'User', value: UserRoles.User }
            ]}
          />
          <Box sx={{ mt: 2 }}>
            <Button fullWidth color="primary" disabled={isSubmitting} size="large" type="submit" variant="contained">
              {t('account.label.updateAccount')}
            </Button>
          </Box>
        </form>
      </Card>
    </>
  );
};

UpdateUserForm.propTypes = {
  id: PropTypes.string.isRequired
};

export default UpdateUserForm;
