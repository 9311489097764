/* eslint-disable no-unused-vars */
import { Autocomplete, Box, IconButton, makeStyles, OutlinedInput, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from 'src/icons/Search';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  MiniMap,
  useStoreState,
  useZoomPanHelper
} from 'react-flow-renderer';
import { useParams } from 'react-router';
import PaneContextMenu from 'src/components/projectNode/PaneContextMenu';
import VideoNode from 'src/components/projectNode/VideoNode';
import { NAVBAR_HEIGHT } from 'src/configs/layout';
import { NODE_TYPES } from 'src/constants/node';
import { useProjectListNode } from 'src/hooks/useProjectListNode';
import CustomEdge from 'src/components/projectNode/CustomEdge';
import { isMobile } from 'react-device-detect';
import EndNode from '../../components/projectNode/EndNode';
import StartNode from '../../components/projectNode/StartNode';
import './ListNode.css';

const useStyles = makeStyles((theme) => ({
  minimap: {
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px'
    }
  }
}));

const ProjectListNode = ({ settingsHeight }) => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    elements,
    handleNodeDragStop,
    handleConnect,
    handleDelete,
    handleInteractiveChange,
    handleSelectionChange,
    onNodeContextMenu,
    onPaneContextMenu,
    openPaneContextMenu,
    paneContextMenuPos,
    hanlePaneContextMenuClose,
    handleMenuItemClick,
    rootRef,
    paneContextMenuItems,
    searchOptions,
    onHighlightChange,
    onAutocompleteChange,
    searchedNode,
    onSearchBlur,
    openSearch,
    setOpenSearch
  } = useProjectListNode(id);
  const nodeTypes = {
    [NODE_TYPES.VIDEO_NODE]: VideoNode,
    [NODE_TYPES.START_NODE]: StartNode,
    [NODE_TYPES.END_NODE]: EndNode
  };

  const { fitView } = useZoomPanHelper();
  const [didFitView, setDidFitView] = useState(false);
  useEffect(() => {
    if (elements.length > 0 && !didFitView) {
      fitView();
      setDidFitView(true);
    }
  }, [elements]);

  return (
    <Box
      ref={rootRef}
      sx={{ height: `${window.innerHeight - settingsHeight - NAVBAR_HEIGHT}px`, border: '1px solid yellow' }}
    >
      <ReactFlow
        minZoom={0.25}
        maxZoom={1}
        elements={elements}
        nodeTypes={nodeTypes}
        style={{
          background: 'white'
        }}
        snapToGrid
        snapGrid={[50, 25]}
        onNodeDragStop={handleNodeDragStop}
        onConnect={handleConnect}
        // onElementsRemove={handleDelete}
        onSelectionChange={handleSelectionChange}
        onNodeContextMenu={onNodeContextMenu}
        onPaneContextMenu={onPaneContextMenu}
        edgeTypes={{ custom: CustomEdge }}
      >
        <MiniMap
          className={classes.minimap}
          nodeStrokeColor={(n) => {
            if (n.style?.background) return n.style.background;
            if (n.type === NODE_TYPES.START_NODE) return '#0041d0';
            if (n.type === NODE_TYPES.END_NODE) return '#ff0072';
            if (n.type === NODE_TYPES.VIDEO_NODE) return '#1a192b';

            return '#eee';
          }}
          nodeColor={(n) => {
            if (n.style?.background) return n.style.background;
            return '#fff';
          }}
          nodeBorderRadius={2}
        />
        <Controls
          onInteractiveChange={(val) => {
            handleInteractiveChange(val);
          }}
        >
          {openSearch ? (
            <ControlButton onClick={() => setOpenSearch(false)}>
              <CloseIcon sx={{ fontSize: 24 }} />
            </ControlButton>
          ) : (
            <ControlButton onClick={() => setOpenSearch(true)}>
              <SearchIcon sx={{ fontSize: 24 }} />
            </ControlButton>
          )}

          <Box
            sx={{
              position: 'absolute',
              top: 0.5,
              right: 170,
              width: openSearch ? 250 : 0,
              visibility: openSearch ? 'visible' : 'hidden',
              transition: 'width 0.3s ease-in-out, visibility 0.2s ease-in-out'
            }}
          >
            <Autocomplete
              value={searchedNode}
              options={searchOptions}
              autoHighlight
              sx={{
                bgcolor: 'white'
              }}
              onChange={(evt, val) => {
                onAutocompleteChange(val);
              }}
              onHighlightChange={(evt, val) => {
                onHighlightChange(val);
              }}
              getOptionLabel={(opt) => `${opt.label} - id: ${opt.id}`}
              isOptionEqualToValue={(opt, value) => opt.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  onBlur={onSearchBlur}
                  InputProps={{
                    ...params.InputProps,
                    sx: { height: 34, borderRadius: '0 !important', pt: '3px !important' }
                  }}
                />
              )}
            />
          </Box>
        </Controls>
        <Background gap={25} variant="lines" color="#F1F1F1" size={1} />
      </ReactFlow>
      <PaneContextMenu
        position={paneContextMenuPos}
        open={openPaneContextMenu}
        handleClose={hanlePaneContextMenuClose}
        handleItemClick={handleMenuItemClick}
        menuItems={paneContextMenuItems}
      />
    </Box>
  );
};

ProjectListNode.propTypes = {
  settingsHeight: PropTypes.number.isRequired
};

export default ProjectListNode;
