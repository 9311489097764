import { makeStyles } from '@material-ui/core';
import Slider, { SliderThumb } from '@material-ui/core/Slider';
import ArticleIcon from '@material-ui/icons/Article';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { INTERACTION } from 'src/constants/interactions';

const THUMB_WIDTH = 10;
const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(4),
    pointerEvents: 'none !important',
    padding: '0 !important',
    borderRadius: 8,
    overflow: 'hidden'
  },

  markLabel: {
    display: 'none'
  },

  mark: {
    dislay: 'none'
  },

  rail: {
    borderRadius: 8,
    height: theme.spacing(4),
    color: '#E9E9E9'
  },

  track: {
    width: '100% !important',
    borderRadius: 8,
    height: theme.spacing(4),
    color: '#E9E9E9'
  },

  thumb: {
    pointerEvents: 'all !important',
    color: '#53C6D6',
    margin: 0,
    borderRadius: 0,
    width: THUMB_WIDTH,
    height: theme.spacing(4),
    '&:hover, &:active': {
      boxShadow: 'none'
    },
    boxShadow: 'none !important'
  }
}));
const getInteractionIcon = (type) => {
  const props = { sx: { fontSize: 10, color: 'white' } };
  switch (type) {
    case INTERACTION.TYPE.MENU:
      return <MenuIcon {...props} />;
    case INTERACTION.TYPE.FORM:
      return <ArticleIcon {...props} />;
    case INTERACTION.TYPE.PAUSE:
      return <FullscreenIcon {...props} />;
    default:
      return null;
  }
};

const FixedTimeInteractionTimelineSlider = ({
  handleValueChange,
  videoDuration,
  sliderValue,
  handleValueChangeSubmit,
  interactionType
}) => {
  const rootRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const classes = useStyles();

  const ThumbComponent = (props) => {
    const { children, className, ...other } = props;
    const extraClassName = other['data-index'] === 0 ? 'first-thumb' : 'second-thumb';
    return (
      <SliderThumb {...other} className={clsx(className, extraClassName)}>
        {children}
        {getInteractionIcon(interactionType)}
      </SliderThumb>
    );
  };

  ThumbComponent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  };
  useEffect(() => {
    const { width: rootWidth } = rootRef.current.getBoundingClientRect();
    if (videoDuration) {
      setDuration((THUMB_WIDTH / rootWidth) * videoDuration);
    }
  }, [videoDuration]);
  return (
    <Slider
      ref={rootRef}
      classes={classes}
      components={{ Thumb: ThumbComponent }}
      min={0}
      max={videoDuration}
      value={sliderValue}
      onChange={(_, number) => {
        if (number <= videoDuration - duration) handleValueChange(number);
        else handleValueChange(sliderValue);
      }}
      step={0.01}
      onChangeCommitted={(_, number) => {
        if (number <= videoDuration - duration) {
          handleValueChangeSubmit(number, number + duration);
        } else {
          handleValueChangeSubmit(sliderValue, sliderValue + duration);
        }
      }}
    />
  );
};

FixedTimeInteractionTimelineSlider.propTypes = {
  videoDuration: PropTypes.number.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  sliderValue: PropTypes.number.isRequired,
  interactionType: PropTypes.string.isRequired,
  handleValueChangeSubmit: PropTypes.func.isRequired
};

export default FixedTimeInteractionTimelineSlider;
