/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { INTERACTION } from 'src/constants/interactions';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import videoController from 'src/utils/videoController';
import { GA_CATEGORIES } from 'src/utils/getInitials';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

const JumpToInteractionDisplay = ({ config, isEditting, isPublishMode }) => {
  const { t } = useTranslation();
  const { videoCurrentTime, videoDuration, videoData } = useVideoPlayer();
  const { jump_time, jump_to, type, time_jump_to_node, product_jump_to } = config.jump;
  const actionTypeBindings = useCallback(() => {
    if (isPublishMode) {
      ReactGA.event({
        category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
        action: t(`interaction.mapKeyAction.${config.jump.type}`) || '',
        label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.jump.title}`,
        value: videoData.id
      });
    }

    switch (type) {
      case INTERACTION.ACTION.JUMP_TO_SPECIFIC:
        videoController.getInstance()?.seek(jump_to);
        break;
      case INTERACTION.ACTION.JUMP_TO_START:
        videoController.getInstance()?.seek(jump_to);
        break;

      case INTERACTION.ACTION.JUMP_TO_END:
        videoController.getInstance()?.seek(jump_to);
        break;

      case INTERACTION.ACTION.JUMP_TO_INTERACTION: {
        if (!isEditting) {
          videoController.getInstance()?.seek(jump_to);
        }
        break;
      }
      case INTERACTION.ACTION.JUMP_TO_NODE: {
        if (isPublishMode) {
          let time_jump = parseInt(time_jump_to_node || 0, 10) + (product_jump_to || 0)
          videoController.getInstance()?.seek(time_jump);
        }
        break;
      }

      default:
    }
  }, [videoDuration, type, config.jump, isPublishMode, isEditting]);

  useEffect(() => {
    if (videoCurrentTime <= jump_time && jump_time - videoCurrentTime <= 0.6) {
        actionTypeBindings();
      }
  }, [videoCurrentTime]);

  return <></>;
};

JumpToInteractionDisplay.propTypes = {
  config: PropTypes.object.isRequired
};

export default JumpToInteractionDisplay;
