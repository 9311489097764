import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'reduxsauce';
import { AccountTypes } from 'src/actions/account';

const INITIAL_STATE = {
  list: [],
  page: 1,
  totalPage: 0,
  filter: { name: '' },
  accountDetail: null,
  loading: false,
  error: null
};

const resetQuery = (state = INITIAL_STATE) => ({
  ...state,
  page: 1,
  filter: { name: '' }
});

const listRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const listSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  list: action.list,
  totalPage: action.totalPage,
  loading: false,
  error: null
});

const listFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const setPage = (state = INITIAL_STATE, action) => ({
  ...state,
  page: action.page
});

const setFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  filter: { ...action.filter },
  page: 1
});

const createRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const createSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false
});

const createFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const editRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const editAccountRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const editSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false
});

const editFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const deleteRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const deleteSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: false
});

const deleteFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

const detailRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const detailUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: null,
  loading: true
});

const detailSuccess = (state = INITIAL_STATE, { accountDetail }) => ({
  ...state,
  accountDetail,
  loading: false,
  error: null
});

const detailFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  error: action.error
});

export const HANDLERS = {
  [AccountTypes.ACCOUNT_LIST_RESET_REQUEST]: resetQuery,
  [AccountTypes.ACCOUNT_LIST_REQUEST]: listRequest,
  [AccountTypes.ACCOUNT_LIST_SUCCESS]: listSuccess,
  [AccountTypes.ACCOUNT_LIST_FAILURE]: listFailure,
  [AccountTypes.ACCOUNT_SET_PAGE]: setPage,
  [AccountTypes.ACCOUNT_SET_FILTER]: setFilter,
  [AccountTypes.ACCOUNT_CREATE_REQUEST]: createRequest,
  [AccountTypes.ACCOUNT_CREATE_SUCCESS]: createSuccess,
  [AccountTypes.ACCOUNT_CREATE_FAILURE]: createFailure,
  [AccountTypes.ACCOUNT_EDIT_REQUEST]: editRequest,
  [AccountTypes.ACCOUNT_EDITUSER_REQUEST]: editAccountRequest,
  [AccountTypes.ACCOUNT_EDIT_SUCCESS]: editSuccess,
  [AccountTypes.ACCOUNT_EDIT_FAILURE]: editFailure,
  [AccountTypes.ACCOUNT_DELETE_REQUEST]: deleteRequest,
  [AccountTypes.ACCOUNT_DELETE_SUCCESS]: deleteSuccess,
  [AccountTypes.ACCOUNT_DELETE_FAILURE]: deleteFailure,
  [AccountTypes.ACCOUNT_DETAIL_REQUEST]: detailRequest,
  [AccountTypes.ACCOUNT_DETAILUSER_REQUEST]: detailUserRequest,
  [AccountTypes.ACCOUNT_DETAIL_SUCCESS]: detailSuccess,
  [AccountTypes.ACCOUNT_DETAIL_FAILURE]: detailFailure
};

export const modal = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'account',
  storage
};

export default persistReducer(persistConfig, modal);
