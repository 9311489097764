import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ModalTypes } from 'src/actions';

const INITIAL_STATE = {
  current: 0,
  modal: '',
  showModal: false,
  data: null,
  newsletterModal: true
};

const modalShow = (state = INITIAL_STATE, action) => ({
  ...state,
  showModal: true,
  data: action.data,
  modal: action.modal
});

const modalClose = (state = INITIAL_STATE, action) => ({
  ...state,
  showModal: false,
  modal: action.modal
});

const modalRefresh = (state = INITIAL_STATE, action) => ({
  ...state,
  current: action.current
});

export const HANDLERS = {
  [ModalTypes.MODAL_SHOW]: modalShow,
  [ModalTypes.MODAL_CLOSE]: modalClose,
  [ModalTypes.MODAL_REFRESH]: modalRefresh
};

export const modal = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'modal',
  storage
};

export default persistReducer(persistConfig, modal);
