/* eslint-disable prettier/prettier */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { Box, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Rnd } from 'react-rnd';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { INTERACTION } from 'src/constants/interactions';
import RegexPattern from 'src/constants/RegexPattern';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import videoController from 'src/utils/videoController';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { borderStyles, textStrokes } from '../../interactionsForm/text/TextInteractionStyleForm';
import './TextInteractionDisplay.css';

export const ResizeHandleBottomRight = () => (
  <Box
    sx={{
      zIndex: 101,
      position: 'absolute',
      top: '2px',
      left: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'se-resize !important'
    }}
  />
);

export const ResizeHandleBottomLeft = () => (
  <Box
    sx={{
      zIndex: 101,
      position: 'absolute',
      top: '2px',
      right: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'sw-resize !important'
    }}
  />
);

export const ResizeHandleBottom = () => (
  <Box
    sx={{
      zIndex: 101,
      position: 'absolute',
      top: '-2px',
      left: '48%',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 's-resize !important'
    }}
  />
);

export const ResizeHandleTop = () => (
  <Box
    sx={{
      zIndex: 101,
      position: 'absolute',
      bottom: '-2px',
      left: '48%',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'n-resize !important'
    }}
  />
);

export const ResizeHandleLeft = () => (
  <Box
    sx={{
      zIndex: 101,
      position: 'absolute',
      top: '48%',
      right: '-2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'w-resize !important'
    }}
  />
);

export const ResizeHandleRight = () => (
  <Box
    sx={{
      zIndex: 101,
      position: 'absolute',
      top: '48%',
      right: '4px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'e-resize !important'
    }}
  />
);

export const ResizeHandleTopLeft = () => (
  <Box
    className="resize-box"
    sx={{
      zIndex: 101,
      position: 'absolute',
      bottom: '2px',
      right: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'nw-resize !important'
    }}
  />
);

export const ResizeHandleTopRight = () => (
  <Box
    sx={{
      zIndex: 101,
      position: 'absolute',
      bottom: '2px',
      left: '2px',
      height: '8px',
      width: '8px',
      border: '1px solid black',
      backgroundColor: 'white',
      cursor: 'ne-resize !important'
    }}
  />
);

const TextInteractionDisplay = ({ config, videoBounding, isEditting, isPublishMode = false }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [jumpToScheduler, setJumpToScheduler] = useState(false);

  const [triggerDisplay, setTriggerDisplay] = useState(false);

  const [transitionCSS, setTransitionCSS] = useState('');

  const { widthCellGridState, showVideoGridState, videoCurrentTime, videoDuration, videoData } = useVideoPlayer();

  const { start_time, end_time, jump, link, x, y, width, height, background_color } = config;

  const { textStroke, borderRadius, borderWidth, borderStyle, borderColor, paddingTop, fadeIn, fadeOut } = config.style;

  const useStyles = makeStyles(() => ({
    wrapperContent: {
      color: '#000',
      wordBreak: 'break-all',
      // width: (width ?? 1) * videoBounding.width,
      // height: (height ?? 1) * videoBounding.height,
      width: width,
      height: height,
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'hidden',
      cursor: isEditting ? 'move' : jump?.type !== 0 ? 'pointer' : 'default',
      position: 'absolute',
      zIndex: 100,
      backgroundColor: `rgba(${background_color?.r}, ${background_color?.g}, ${background_color?.b}, ${background_color?.a})`,
      '-webkit-text-stroke': textStrokes[textStroke].cssValue,
      borderRadius: parseInt(borderRadius, 10),
      borderWidth: parseInt(borderWidth, 10),
      borderStyle: borderStyles[borderStyle].cssValue,
      borderColor: `rgba(${borderColor?.r}, ${borderColor?.g}, ${borderColor?.b}, ${borderColor?.a})`,
      // paddingTop: parseFloat(paddingTop) * videoBounding.height
      paddingTop: parseFloat(paddingTop)
    }
  }));

  const classes = useStyles();

  const handleChangeConfig = useCallback((newConfig) => {
    dispatch(EdittingInteractionActions.setConfig(newConfig));
  }, []);

  useEffect(() => {
    if (showVideoGridState) {
      const newX =
        (Math.trunc((x * videoBounding.width) / widthCellGridState) * widthCellGridState) / videoBounding.width;
      const newY =
        (Math.trunc((y * videoBounding.height) / widthCellGridState) * widthCellGridState) /
        videoBounding.height;
      const newWidth = Math.trunc(width / widthCellGridState) * widthCellGridState;
      const newHeight = Math.trunc(height / widthCellGridState) * widthCellGridState;
      dispatch(EdittingInteractionActions.setConfig({ x: newX, y: newY, width: newWidth, height: newHeight }));
    }
  }, [showVideoGridState]);

  // eslint-disable-next-line consistent-return
  const actionTypeBindings = useCallback(() => {
    if (isPublishMode) {
      ReactGA.event({
        category: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}`,
        action:
          link && t(`googleAnalyticsActions.actions.link`) ||
          (jump?.type && t(`interaction.mapKeyAction.${jump?.type}`) ||
          t(`googleAnalyticsActions.actions.noAction`) ),
        label: `${t('googleAnalyticsActions.categories.video')} - ${videoData.name}: ${config.title}`,
        value: videoData.id
      });
    }
    if (isEditting || jump?.type === undefined) {
      if (!link) return null;

      if (link) {
        if (RegexPattern.URL.test(link)) {
          const newWindow = window.open(link, '_blank', 'noopener,noreferrer');

          videoController.getInstance()?.pause();
          if (newWindow) newWindow.opener = null;
        }
      }
    }

    switch (jump?.type) {
      case INTERACTION.ACTION.JUMP_TO_SPECIFIC:
      case INTERACTION.ACTION.JUMP_TO_START:
      case INTERACTION.ACTION.JUMP_TO_END:
        videoController.getInstance()?.seek(jump.jump_to);
        break;
      case INTERACTION.ACTION.JUMP_TO_SPECIFIC_SCHEDULER:
        setJumpToScheduler(true);
        break;
      case INTERACTION.ACTION.JUMP_TO_INTERACTION: {
        if (!isEditting) {
          videoController.getInstance()?.seek(config.jump.jump_to);
        }
        break;
      }
      case INTERACTION.ACTION.JUMP_TO_NODE: {
        if (isPublishMode) {
          const time_jump = parseInt(config.jump.time_jump_to_node || 0, 10) + (config.jump.product_jump_to || 0);
          videoController.getInstance()?.seek(time_jump);
        }
        break;
      }
      default:
    }
  }, [jump, videoDuration, link, isPublishMode, isEditting]);

  useEffect(() => {
    if (jumpToScheduler && videoCurrentTime > jump.jump_time) {
      videoController.getInstance()?.seek(jump.jump_to);
      setJumpToScheduler(false);
    }
  }, [videoCurrentTime, jumpToScheduler, jump]);

  useEffect(() => {
    if (start_time > videoCurrentTime || end_time < videoCurrentTime) {
      if (triggerDisplay) {
        if (fadeOut) {
          setTransitionCSS('text-interaction-fadeOut');
        } else {
          setTransitionCSS('');
        }
      }
      setTriggerDisplay(false);
    } else {
      if (!triggerDisplay) {
        if (fadeIn) {
          setTransitionCSS('text-interaction-fadeIn');
        } else {
          setTransitionCSS('');
        }
      }
      setTriggerDisplay(true);
    }
  }, [videoCurrentTime, start_time, end_time, fadeIn, fadeOut]);

  useEffect(() => {
    if (isEditting) {
      dispatch(EdittingInteractionActions.setInitialVideoWidth(videoBounding.width));
    }
  }, []);

  return (
    <Rnd
      className="rnd"
      enableResizing={isEditting}
      disableDragging={!isEditting}
      {...(showVideoGridState ? { dragGrid: [widthCellGridState, widthCellGridState] } : {})}
      style={{
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: 'transparent',
        border: isEditting ? '2px dashed white' : 'none',
        cursor: 'auto !important',

        visibility: triggerDisplay ? 'visible' : 'hidden',
        opacity: triggerDisplay ? 1 : 0
      }}
      bounds="parent"
      size={{
        // width: config.width * videoBounding.width,
        // height: config.height * videoBounding.height
        width: config.width,
        height: config.height
      }}
      position={{
        x: config.x * videoBounding.width,
        y: config.y * videoBounding.height
      }}
      minWidth="10%"
      minHeight="10%"
      onDragStop={(e, d) => {
        handleChangeConfig({ x: d.lastX / videoBounding.width, y: d.lastY / videoBounding.height });
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        handleChangeConfig({
          // width: ref.offsetWidth / videoBounding.width,
          // height: ref.offsetHeight / videoBounding.height,
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          x: position.x / videoBounding.width,
          y: position.y / videoBounding.height
        });
      }}
      resizeHandleComponent={{
        bottomRight: <ResizeHandleBottomRight />,
        bottomLeft: <ResizeHandleBottomLeft />,
        bottom: <ResizeHandleBottom />,
        top: <ResizeHandleTop />,
        left: <ResizeHandleLeft />,
        right: <ResizeHandleRight />,
        topLeft: <ResizeHandleTopLeft />,
        topRight: <ResizeHandleTopRight />
      }}
    >
      <Box
        id="text-interaction-content"
        className={`${classes.wrapperContent} ${transitionCSS}`}
        onClick={actionTypeBindings}
        sx={{
          transform: `scale(${videoBounding.width / config.style.initialVideoWidth})`,
          transformOrigin: 'top left',
          userSelect: 'none',
          display: 'flex',
          justifyContent: 'center',
          flexFlow: 'column'
        }}
      >
        {parse(config.content)}
        <Box
          className="text-interaction-jump-to-schedule-checked"
          sx={{ visibility: !isEditting && jumpToScheduler ? 'visible' : 'hidden' }}
        >
          <CheckIcon className="text-interaction-jump-to-schedule-checked-icon" />
        </Box>
      </Box>
    </Rnd>
  );
};

TextInteractionDisplay.propTypes = {
  config: PropTypes.object.isRequired,
  videoBounding: PropTypes.object.isRequired,
  isEditting: PropTypes.bool.isRequired,
  isPublishMode: PropTypes.bool
};

export default TextInteractionDisplay;
