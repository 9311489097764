import { createTypes } from 'reduxsauce';
import axiosClient from 'src/utils/axiosClient';

export const PublishManagerTypes = createTypes(`
  PUBLISH_MANAGER_RESET

  PUBLISH_MANAGER_NEW_REVISION_REQUESTING
  PUBLISH_MANAGER_NEW_REVISION_SUCCESS
  PUBLISH_MANAGER_NEW_REVISION_FAILURE
`);

export const reset = () => ({
  type: PublishManagerTypes.PUBLISH_MANAGER_RESET
});

// New revision action
export const newRevisionSuccess = (publish) => ({
  type: PublishManagerTypes.PUBLISH_MANAGER_NEW_REVISION_SUCCESS,
  publish
});

export const newRevisionFailure = () => ({
  type: PublishManagerTypes.PUBLISH_MANAGER_NEW_REVISION_FAILURE
});

export const newRevisionRequest = (id) => async (dispatch) => {
  dispatch({ type: PublishManagerTypes.PUBLISH_MANAGER_NEW_REVISION_REQUESTING });
  return axiosClient.get(`/admin/project/publish/${id}`);
};
