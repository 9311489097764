import { useEffect } from 'react';
import NProgress from 'nprogress';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';

export const Loading = ({ loading }) =>
  loading ? (
    <div className="loading-overlay">
      <CircularProgress />
    </div>
  ) : null;

Loading.propTypes = {
  loading: PropTypes.bool
};

const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        minHeight: '100%'
      }}
    />
  );
};

export default LoadingScreen;
