import _get from 'lodash/get';
import qs from 'qs';
import { isNode, getIncomers, getOutgoers } from 'react-flow-renderer';
import { createTypes } from 'reduxsauce';
import { AXIS_MOVEMENT, ENDNODE_MAXSIZE, MAX_NODES } from 'src/configs/node';
import { NODE_TYPES } from 'src/constants/node';
import axiosClient from 'src/utils/axiosClient';
import { createEdge, createNode, flattenData } from 'src/utils/processNodeData';

export const ProjectNodeTypes = createTypes(`
  PROJECT_NODES_FETCH_LIST_REQUEST
  PROJECT_NODES_FETCH_LIST_SUCCESS
  PROJECT_NODES_FETCH_LIST_FAILURE

  PROJECT_NODES_RESET_REQUEST

  PROJECT_NODE_ADD_EDGE
  PROJECT_NODE_CREATE_CHILD_REQUEST
  PROJECT_NODE_CREATE_CHILD_SUCCESS
  PROJECT_NODE_CREATE_CHILD_FAILURE

  PROJECT_NODE_UPDATE_NODE_REQUEST
  PROJECT_NODE_UPDATE_NODE_SUCCESS
  PROJECT_NODE_UPDATE_NODE_FAILURE

  PROJECT_NODE_CONNECT_REQUEST
  PROJECT_NODE_CONNECT_SUCCESS
  PROJECT_NODE_CONNECT_FAILURE

  PROJECT_NODE_DELETE_NODE_REQUEST
  PROJECT_NODE_DELETE_NODE_SUCCESS
  PROJECT_NODE_DELETE_NODE_FAILURE

  PROJECT_NODE_DISABLE_NODES_CONNECT
  PROJECT_NODE_ENABLE_NODES_CONNECT

  PROJECT_NODE_DISABLE_DRAG
  PROJECT_NODE_ENABLE_DRAG

  PROJECT_NODE_COPY_REQUEST
  PROJECT_NODE_COPY_SUCCESS
  PROJECT_NODE_COPY_FAILURE

  PROJECT_NODE_SET_SELECTED
  PROJECT_NODE_SET_COPY_ITEM

  PROJECT_NODE_OPEN_NODE_CONTEXT_MENU
  PROJECT_NODE_CLOSE_NODE_CONTEXT_MENU

  PROJECT_NODE_SET_HIGHLIGHT_NODE
  PROJECT_NODE_SET_SEARCHED_NODE

  PROJECT_NODE_FETCH_NODE_SUCCESS
  PROJECT_NODE_FETCH_NODE_REQUEST
  PROJECT_NODE_FETCH_NODE_FAILURE

  PROJECT_NODE_DELETE_EDGE_REQUEST
  PROJECT_NODE_DELETE_EDGE_SUCCESS
  PROJECT_NODE_DELETE_EDGE_FAILURE

  PROJECT_NODE_SET_DRAG_ITEM

  PROJECT_NODE_SET_SCREEN
`);

export const resetRequest = () => ({ type: ProjectNodeTypes.PROJECT_NODES_RESET_REQUEST });

export const fetchListSuccess = (data) => ({
  type: ProjectNodeTypes.PROJECT_NODES_FETCH_LIST_SUCCESS,
  data
});

export const fetchListFailure = (error) => ({
  type: ProjectNodeTypes.PROJECT_NODES_FETCH_LIST_FAILURE,
  error
});

export const fetchListRequest = (query) => async (dispatch) => {
  try {
    dispatch({ type: ProjectNodeTypes.PROJECT_NODES_FETCH_LIST_REQUEST });
    const rs = await axiosClient.get(`/admin/node?${qs.stringify(query)}`);
    const data = flattenData(rs.nodes);
    dispatch(fetchListSuccess(data));
  } catch (e) {
    console.log('error fetch list node', e);
    dispatch(fetchListFailure(e));
  }
};

export const addEdge = (edge) => ({ type: ProjectNodeTypes.PROJECT_NODE_ADD_EDGE, edge });

export const createChildRequest = (parent) => async (dispatch, getState) => {
  if (_get(getState(), 'projectNode.numNodes', 0) >= MAX_NODES) {
    const error = new Error('maxNode');
    throw error;
  }
  const elements = _get(getState(), 'projectNode.elements', []);
  const projectID = _get(getState(), 'projectDetail.project.id');

  // feedback 11/11
  const endNode = elements.find((el) => el.type === NODE_TYPES.END_NODE);
  let nextChildX = parent.x + AXIS_MOVEMENT.X;
  if (nextChildX >= endNode.position.x && nextChildX <= endNode.position.x + ENDNODE_MAXSIZE.WIDTH) {
    nextChildX += ENDNODE_MAXSIZE.WIDTH + 10; // 10 is margin
  }
  const maxYatNextChildX = Math.max(
    ...elements.map((e) => (isNode(e) && e.position.x === nextChildX ? e.position.y : -Infinity))
  );
  let nextChildY = maxYatNextChildX !== -Infinity ? maxYatNextChildX + AXIS_MOVEMENT.Y : parent.y;
  if (nextChildY >= endNode.position.y && nextChildY <= endNode.position.y + ENDNODE_MAXSIZE.HEIGHT) {
    nextChildY += ENDNODE_MAXSIZE.HEIGHT + 10; // 10 is margin
  }

  const nextChildName = `Node ${_get(getState(), 'projectNode.numNodes', 0) + 1}`;
  dispatch({ type: ProjectNodeTypes.PROJECT_NODE_CREATE_CHILD_REQUEST });
  const rs = await axiosClient.post(`/admin/node`, {
    name: nextChildName,
    project_id: projectID,
    parent_id: parent.id,
    x: nextChildX,
    y: nextChildY
  });
  const newNode = createNode(parent, rs.node);
  const newEdge = createEdge(parent, rs.node);
  const isNewPath = parent.isBeginning;
  const isBeginning = parent.type === NODE_TYPES.START_NODE;
  dispatch(createChildSuccess(newNode, newEdge, isNewPath, isBeginning));
};

export const createChildSuccess = (newNode, newEdge, isNewPath, isBeginning) => ({
  type: ProjectNodeTypes.PROJECT_NODE_CREATE_CHILD_SUCCESS,
  newNode,
  newEdge,
  isNewPath,
  isBeginning
});

export const createChildFailure = () => ({ type: ProjectNodeTypes.PROJECT_NODE_CREATE_CHILD_FAILURE });

export const updateSuccess = (node) => ({ type: ProjectNodeTypes.PROJECT_NODE_UPDATE_NODE_SUCCESS, node });

export const updateFailure = (error, prevNode) => ({
  type: ProjectNodeTypes.PROJECT_NODE_UPDATE_NODE_FAILURE,
  error,
  prevNode
});

export const updateRequest = (node) => async (dispatch, getState) => {
  const prevNode = _get(getState(), 'projectNode.elements', []).find((el) => el.id === node.id);
  try {
    dispatch({ type: ProjectNodeTypes.PROJECT_NODE_UPDATE_NODE_REQUEST, node });
    const rs = await axiosClient.put(`/admin/node/${node.id}`, node);
    dispatch(updateSuccess(rs.node));
  } catch (e) {
    dispatch(updateFailure(e, prevNode));
  }
};

export const connectSuccess = (edge, isPath, connectBeginning, newNumbPaths) => ({
  type: ProjectNodeTypes.PROJECT_NODE_CONNECT_SUCCESS,
  edge,
  isPath,
  connectBeginning,
  newNumbPaths
});

export const connectFailure = (error) => ({ type: ProjectNodeTypes.PROJECT_NODE_CONNECT_FAILURE, error });

export const connectRequest = (source, target) => async (dispatch, getState) => {
  const newEdge = createEdge({ id: source }, { id: target });
  const revertEdge = createEdge({ id: target }, { id: source });
  const elements = _get(getState(), 'projectNode.elements', []);
  if (elements.find((e) => e.id === newEdge.id || e.id === revertEdge.id)) {
    return;
  }
  try {
    dispatch({ type: ProjectNodeTypes.PROJECT_NODE_CONNECT_REQUEST });
    await axiosClient.put(`/admin/node/${source}`, { children_id: target });
    const sourceNode = elements.find((e) => e.id === source);
    const isPath = sourceNode.data.isBeginning;
    const connectBeginning = sourceNode.type === NODE_TYPES.START_NODE;
    const newNumbPaths = connectBeginning ? getOutgoers({ id: target }, elements).length : 0;
    dispatch(connectSuccess(newEdge, isPath, connectBeginning, newNumbPaths));
  } catch (e) {
    dispatch(connectFailure(e));
  }
};

export const deleteNodeSuccess = (delElements, isBeginning, isPath) => ({
  type: ProjectNodeTypes.PROJECT_NODE_DELETE_NODE_SUCCESS,
  delElements,
  isBeginning,
  isPath
});

export const deleteNodeFailure = (error) => ({ type: ProjectNodeTypes.PROJECT_NODE_DELETE_NODE_FAILURE, error });

export const deleteNodeRequest = (delElements) => async (dispatch, getState) => {
  const delNode = delElements.find((e) => isNode(e));
  if (delNode.type === NODE_TYPES.START_NODE || delNode.type === NODE_TYPES.END_NODE) return;
  try {
    dispatch({ type: ProjectNodeTypes.PROJECT_NODE_DELETE_NODE_REQUEST });
    await axiosClient.delete(`/admin/node/${delNode.id}`);
    const elements = _get(getState(), 'projectNode.elements', []);
    const parents = getIncomers(delNode, elements);
    const isBeginning = parents.find((e) => e.type === NODE_TYPES.START_NODE);
    const isPath = parents.find((e) => e.data.isBeginning);
    dispatch(deleteNodeSuccess(delElements, isBeginning, isPath));
  } catch (e) {
    dispatch(deleteNodeFailure(e));
  }
};

export const disableNodesConnectRequest = () => ({ type: ProjectNodeTypes.PROJECT_NODE_DISABLE_NODES_CONNECT });

export const enableNodesConnectRequest = () => ({ type: ProjectNodeTypes.PROJECT_NODE_ENABLE_NODES_CONNECT });

export const disableDragRequest = (nodeID) => ({ type: ProjectNodeTypes.PROJECT_NODE_DISABLE_DRAG, nodeID });

export const enableDragRequest = (nodeID) => ({ type: ProjectNodeTypes.PROJECT_NODE_ENABLE_DRAG, nodeID });

export const copyRequest = (pos) => async (dispatch, getState) => {
  const copyNodeID = _get(getState(), 'projectNode.copyItem');
  if (!copyNodeID) return;
  if (_get(getState(), 'projectNode.numNodes', 0) >= MAX_NODES) {
    const error = new Error('maxNode');
    throw error;
  }
  const copyNode = _get(getState(), 'projectNode.elements', []).find(
    (el) => el.id === copyNodeID && isNode(el) && el.type === NODE_TYPES.VIDEO_NODE
  );
  // Copy node might be deleted
  if (!copyNode) {
    dispatch(copyFailure());
    return;
  }
  const elements = _get(getState(), 'projectNode.elements', []);
  let nextX = 0;
  let nextY = 0;
  if (pos) {
    nextX = pos.x;
    nextY = pos.y;
  } else {
    nextX = copyNode.position.x + AXIS_MOVEMENT.X;
    const maxYatNextChildX = Math.max(
      ...elements.map((e) => (isNode(e) && e.position.x === nextX ? e.position.y : -Infinity))
    );
    nextY = maxYatNextChildX !== -Infinity ? maxYatNextChildX + AXIS_MOVEMENT.Y : copyNode.position.y;
  }
  dispatch({ type: ProjectNodeTypes.PROJECT_NODE_COPY_REQUEST });
  const rs = await axiosClient.post(`/admin/node/copy`, {
    node_id: copyNodeID,
    x: nextX,
    y: nextY
  });
  const newNode = createNode(null, { ...rs.node });
  dispatch(copySuccess(newNode));
};

export const copySuccess = (newNode) => ({
  type: ProjectNodeTypes.PROJECT_NODE_COPY_SUCCESS,
  newNode
});

export const copyFailure = () => ({ type: ProjectNodeTypes.PROJECT_NODE_COPY_FAILURE });

export const setSelectedRequest = (nodeID) => ({ type: ProjectNodeTypes.PROJECT_NODE_SET_SELECTED, nodeID });

export const setCopyItemRequest = () => ({ type: ProjectNodeTypes.PROJECT_NODE_SET_COPY_ITEM });

export const openNodeContextMenuRequest = (nodeID) => ({
  type: ProjectNodeTypes.PROJECT_NODE_OPEN_NODE_CONTEXT_MENU,
  nodeID
});

export const closeNodeContextMenuRequest = (nodeID) => ({
  type: ProjectNodeTypes.PROJECT_NODE_CLOSE_NODE_CONTEXT_MENU,
  nodeID
});

export const setHighlightNode = (nodeID) => ({ type: ProjectNodeTypes.PROJECT_NODE_SET_HIGHLIGHT_NODE, nodeID });

export const setSearchedNode = (nodeID) => ({ type: ProjectNodeTypes.PROJECT_NODE_SET_SEARCHED_NODE, nodeID });

export const fetchNodeSuccess = (node) => ({
  type: ProjectNodeTypes.PROJECT_NODE_FETCH_NODE_SUCCESS,
  node
});

export const fetchNodeFailure = (error) => ({
  type: ProjectNodeTypes.PROJECT_NODE_FETCH_NODE_FAILURE,
  error
});

export const fetchNodeRequest = (nodeID) => async (dispatch) => {
  try {
    dispatch({ type: ProjectNodeTypes.PROJECT_NODE_FETCH_NODE_REQUEST });
    const rs = await axiosClient.get(`/admin/node/${nodeID}`);
    dispatch(fetchNodeSuccess(rs.node));
  } catch (e) {
    dispatch(fetchNodeFailure(e));
  }
};

export const deleteEdgeSuccess = (delElements, isScreenPath, isPath) => ({
  type: ProjectNodeTypes.PROJECT_NODE_DELETE_EDGE_SUCCESS,
  delElements,
  isScreenPath,
  isPath
});

export const deleteEdgeFailure = (error) => ({ type: ProjectNodeTypes.PROJECT_NODE_DELETE_EDGE_FAILURE, error });

export const deleteEdgeRequest = (delElements) => async (dispatch, getState) => {
  const delEdge = delElements[0];
  try {
    dispatch({ type: ProjectNodeTypes.PROJECT_NODE_DELETE_EDGE_REQUEST });
    await axiosClient.put(`/admin/node/remove-relation/${delEdge.source}`, { children_id: delEdge.target });
    const elements = _get(getState(), 'projectNode.elements', []);
    const srcNode = elements.find((el) => el.id === delEdge.source);
    const isScreenPath = srcNode.type === NODE_TYPES.START_NODE;
    const isPath = srcNode.data.isBeginning;
    dispatch(deleteEdgeSuccess(delElements, isScreenPath, isPath));
  } catch (e) {
    dispatch(deleteEdgeFailure(e));
  }
};

export const setDragItem = (item) => ({ type: ProjectNodeTypes.PROJECT_NODE_SET_DRAG_ITEM, item });

export const setScreen = (screen) => ({ type: ProjectNodeTypes.PROJECT_NODE_SET_SCREEN, screen });
