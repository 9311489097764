export const routes = {
  dashboard: '/app/projects',
  login: '/login',

  accounts: '/app/accounts',
  createAccount: '/app/accounts/create',
  accountDetail: (id) => `/app/accounts/${id}`,
  accountUpdate: (id) => `/app/accounts/${id}/update`,
  accountUpdateUser: (id) => `/app/${id}/updateAccount`,
  accountDelete: (id) => `/app/accounts/${id}/delete`
};
