/* eslint-disable no-unused-vars */
import { Box, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import timelineFormat, { hmsmsToSeconds, hmsToSeconds } from 'src/utils/timelineFormat';
import { useVideoPlayer } from 'src/hooks/useVideoPlayer';
import videoController from 'src/utils/videoController';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import { INTERACTION } from 'src/constants/interactions';

const useStyles = makeStyles((theme) => ({
  center: {
    paddingLeft: '12px',
    paddingTop: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}));

const getDuration = (type) => {
  switch (type) {
    case INTERACTION.TYPE.MENU:
      return INTERACTION.MENU.DURATION;
    default:
  }
  return 0;
};

export const InteractionSingleTimeInput = ({ sliderValue, handleValueChangeSubmit, videoDuration }) => {
  const classes = useStyles();
  const [editingTimeStr, setEdittingTimeStr] = useState(0);
  const [editable, setEditable] = useState(false);
  const handleCurrentTimeLblChange = useCallback(
    (evt) => {
      if (editable) {
        setEdittingTimeStr(evt.target.value);
      }
    },
    [editable]
  );

  const handleCurrentTimeLblFocus = useCallback(() => {
    setEditable(true);
    setEdittingTimeStr(timelineFormat(sliderValue, 'hh:mm:ss.ms'));
  }, [videoController, sliderValue]);

  const handleSubmitValue = useCallback(() => {
    setEditable(false);
    const newVal = hmsmsToSeconds(editingTimeStr);
    let startTime = 0;
    let endTime = 0;

    if (!Number.isNaN(newVal) && newVal > videoDuration - 1) {
      startTime = videoDuration - 1;
      endTime = videoDuration;
    } else {
      startTime = newVal;
      endTime = startTime + 1;
    }
    handleValueChangeSubmit(startTime, endTime);
  }, [editingTimeStr, videoController, sliderValue, videoDuration]);
  return (
    <Box className={classes.center}>
      <TextField
        value={editable ? editingTimeStr : timelineFormat(sliderValue, 'hh:mm:ss.ms')}
        onChange={handleCurrentTimeLblChange}
        onFocus={handleCurrentTimeLblFocus}
        onBlur={handleSubmitValue}
        size="small"
        onKeyDown={(evt) => {
          if (evt.key === 'Enter') {
            evt.target.blur();
          }
        }}
        inputProps={{ style: { textAlign: 'center', width: 100 } }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{ sx: { height: 30 } }}
      />
    </Box>
  );
};

InteractionSingleTimeInput.propTypes = {
  sliderValue: PropTypes.number.isRequired,
  handleValueChangeSubmit: PropTypes.func.isRequired,
  videoDuration: PropTypes.number.isRequired
};
