/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { Box, Button, FormLabel, Grid, Tab, Tabs, TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as EdittingInteractionActions from 'src/actions/edittingInteraction';
import ColorField from 'src/components/FormControls/ColorField';
import EditorField from 'src/components/FormControls/EditorField';
import InputField from 'src/components/FormControls/InputField';
import RoundedSliderField from 'src/components/FormControls/RoundedSliderField';
import SelectField from 'src/components/FormControls/SelectField';
import SingleCheckboxField from 'src/components/FormControls/SingleCheckboxField';
import { useModal } from 'src/hooks/useModal';
import { fields as _pauseField } from 'src/models/pause';
import * as EdittingInteractionSelectors from 'src/selectors/edittingInteraction';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';
import SelectSoundModal from './SelectSoundModal';

const tabsStyle = {
  '& .MuiTabs-indicator': {
    backgroundColor: '#ED257A'
  }
};

const tabStyle = {
  maxWidth: '50%',
  width: '50%',
  '&.Mui-selected': {
    color: '#ED257A'
  }
};

const InputFieldxs12Props = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      style: {
        width: 'max-content',
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px',
        marginRight: '10px'
      }
    },
    input: {
      style: {
        marginTop: '8px'
      }
    }
  }
};

const InputFieldxs6Props = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      xs: 4,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px'
      }
    },
    input: {
      xs: 8,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const borderWidthProps = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      xs: 7,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px'
      }
    },
    input: {
      xs: 5,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const topPaddingProps = {
  InputProps: {
    sx: { height: '30px' }
  },
  layout: {
    label: {
      xs: 3,
      style: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px'
      }
    },
    input: {
      xs: 2,
      style: {
        marginTop: '8px'
      }
    }
  }
};

const PauseInteractionForm = () => {
  const { t } = useTranslation();
  const pauseField = _pauseField(t);

  const [formIndex, setFormIndex] = useState(0);
  const handleChange = (event, newValue) => {
    setFormIndex(newValue);
  };
  const [formHeight, setFormHeight] = useState(0);
  const form0Ref = useRef();
  const form1Ref = useRef();
  const [triggerHeightChange, setTriggerHeightChange] = useState(false);

  const edittingInteraction = useSelector(EdittingInteractionSelectors.getConfig);
  const { control, formState, watch, setValue } = useForm({
    defaultValues: {
      ...edittingInteraction,
      style: {
        ...edittingInteraction.style
        // topPadding: Math.ceil(edittingInteraction.style.topPadding * videoBounding.height)
      }
    },
    reValidateMode: 'onBlur'
  });
  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  const dispatch = useDispatch();

  const onFieldsSubmit = useCallback((field) => {
    dispatch(EdittingInteractionActions.setConfig(field));
  });
  const onStyleFieldsSubmit = useCallback((field) => {
    const key = Object.keys(field)[0];
    // remove prefix 'style.'
    dispatch(
      EdittingInteractionActions.setStyleConfig({
        [key.slice(6)]: field[key]
      })
    );
  }, []);

  const { openModal } = useModal();
  const watchSound = watch('sound');
  useEffect(() => {
    if (formIndex === 0) {
      setFormHeight(form0Ref.current.getBoundingClientRect().height);
    } else if (formIndex === 1) {
      setFormHeight(form1Ref.current.getBoundingClientRect().height);
    }
  }, [formIndex, triggerHeightChange, watchSound]);
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs sx={tabsStyle} value={formIndex} onChange={handleChange}>
          <Tab sx={tabStyle} label={t('interaction.interactionTitle')} id="highlight-interaction" />
          <Tab sx={tabStyle} label={t('interaction.styleTitle')} id="highlight-style" />
        </Tabs>
      </Box>
      <Box sx={{ position: 'relative', p: 1, height: formHeight }}>
        <Box
          sx={{ position: 'absolute', visibility: formIndex === 0 ? 'visible' : 'hidden', width: '100%' }}
          ref={form0Ref}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputField
                label={pauseField.title.label}
                name="title"
                {...formItems}
                {...InputFieldxs12Props}
                BlurOnEnter
                OnBlur={onFieldsSubmit}
              />
            </Grid>
            <Grid item xs={12}>
              <EditorField
                name="content"
                {...formItems}
                onChange={onFieldsSubmit}
                onInit={() => setTriggerHeightChange(!triggerHeightChange)}
              />
            </Grid>
            <Grid item xs={12}>
              <ColorField
                name="style.backgroundColor"
                label={pauseField.backgroundColor.label}
                {...formItems}
                {...InputFieldxs12Props}
                onChange={onStyleFieldsSubmit}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} sx={{ pt: '4px !important' }}>
                  <FormLabel>{t('interaction.pause.fields.sound')}</FormLabel>
                </Grid>
                <Grid item xs={4}>
                  <Button variant="contained" onClick={() => openModal('selectSound')}>
                    {t('common.actions.selectSound')}
                  </Button>
                </Grid>
              </Grid>
              <Controller
                name="sound"
                control={control}
                render={({ field }) => (
                  <SelectSoundModal
                    modalName="selectSound"
                    onChange={(item) => {
                      if (item) {
                        field.onChange({ id: item.id, url: item.url, name: item.name });
                        onFieldsSubmit({ sound: { id: item.id, url: item.url, name: item.name } });
                      }
                    }}
                  />
                )}
              />
            </Grid>
            {watchSound && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={2} sx={{ pt: '4px !important' }}>
                    <FormLabel>{t('interaction.pause.fields.activeSound')}</FormLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      disabled
                      value={watchSound.name}
                      fullWidth
                      sx={{ mt: 1 }}
                      InputProps={{
                        sx: { height: '35px' }
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        setValue('sound', null);
                        onFieldsSubmit({ sound: null });
                      }}
                    >
                      {t('common.actions.removeSound')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={2} />
            <Grid item xs={5}>
              <SingleCheckboxField
                label={pauseField.playSound.label}
                name="play_sound"
                {...formItems}
                handleChange={onFieldsSubmit}
              />
            </Grid>
            <Grid item xs={5}>
              <SingleCheckboxField
                label={pauseField.fadeSound.label}
                name="fade_sound"
                {...formItems}
                handleChange={onFieldsSubmit}
              />
            </Grid>
          </Grid>
        </Box>
        {/* style form */}
        <Box
          sx={{ position: 'absolute', visibility: formIndex === 1 ? 'visible' : 'hidden', width: '100%' }}
          ref={form1Ref}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectField
                name="style.textStroke"
                label={pauseField.textStroke.label}
                options={pauseField.textStroke.options}
                {...formItems}
                {...InputFieldxs12Props}
                handleChange={onStyleFieldsSubmit}
                sx={{ height: '30px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <RoundedSliderField
                name="style.borderRadius"
                label={t('interaction.style.borderRadius')}
                {...formItems}
                onChange={onStyleFieldsSubmit}
                valueLabelDisplay="on"
              />
            </Grid>
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={11}>
                  <InputField
                    label={pauseField.topPadding.label}
                    name="style.topPadding"
                    {...formItems}
                    {...borderWidthProps}
                    BlurOnEnter
                    OnBlur={onStyleFieldsSubmit}
                    OnMouseUp={onStyleFieldsSubmit}
                    type="number"
                    inputProps={{ min: 0 }}
                  />
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                  px
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={11}>
                  <InputField
                    label={pauseField.border.label}
                    name="style.borderWidth"
                    {...formItems}
                    {...borderWidthProps}
                    BlurOnEnter
                    OnBlur={onStyleFieldsSubmit}
                    OnMouseUp={onStyleFieldsSubmit}
                    type="number"
                    inputProps={{ min: 0, max: 10 }}
                  />
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                  px
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sx={{ ml: -3 }}>
              <SelectField
                name="style.borderStyle"
                options={pauseField.borderRadius.options}
                {...formItems}
                {...InputFieldxs6Props}
                handleChange={onStyleFieldsSubmit}
                sx={{ height: '30px' }}
              />
            </Grid>
            <Grid item xs={2} sx={{ ml: 2 }}>
              <ColorField name="style.borderColor" {...formItems} onChange={onStyleFieldsSubmit} />
            </Grid>
            <Grid item xs={6}>
              <SingleCheckboxField
                label={pauseField.fadeIn.label}
                name="style.fadeIn"
                {...formItems}
                handleChange={onStyleFieldsSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <SingleCheckboxField
                label={pauseField.fadeOut.label}
                name="style.fadeOut"
                {...formItems}
                handleChange={onStyleFieldsSubmit}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PauseInteractionForm;
