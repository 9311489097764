/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import DraggableTreeItem from './DraggableTreeItem';

const Media = ({ treeItem }) => (
  <>
    {treeItem?.videos?.map((video) => (
      <DraggableTreeItem item={video} type="video" />
    ))}
    {treeItem?.images?.map((image) => (
      <DraggableTreeItem item={image} type="image" />
    ))}
    {treeItem?.sounds?.map((sound) => (
      <DraggableTreeItem item={sound} type="sound" />
    ))}
  </>
);

Media.propTypes = {
  treeItem: PropTypes.object.isRequired
};

export default Media;
