/* eslint-disable */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import InputField from 'src/components/FormControls/InputField';
import RadioFieldHelperText from 'src/components/FormControls/RadioFieldHelperText';
import RegexPattern from 'src/constants/RegexPattern';
import { usePublishSettings } from 'src/hooks/publishSettings';
import * as yup from 'yup';

const Privacy = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const options = useMemo(
    () => [
      {
        label: t('publicSettings.privacy.public'),
        value: '1',
        helperText: t('publicSettings.privacy.publicDesc')
      },
      {
        label: t('publicSettings.privacy.private'),
        value: '0',
        helperText: t('publicSettings.privacy.privateDesc')
      }
    ],
    []
  );

  const { publishState, onUpdate } = usePublishSettings();

  const [showPasswordField, setShowPasswordField] = useState(parseInt(publishState.is_public) === 0);

  const initialForm = useMemo(() => {
    const { is_public, password } = publishState || {};

    return {
      is_public: is_public.toString() || '1',
      password: password || ''
    };
  }, [publishState]);

  const schema = useMemo(() => {
    if (showPasswordField) {
      return yup.object().shape({
        is_public: yup.string().nullable(),
        password: yup
          .string()
          .nullable()
          .required(t('auth.validate.password.required'))
          .min(4, t('auth.validate.password.min', { min: 4 }))
          .max(32, t('auth.validate.password.max', { max: 32 }))
          .test('format', t('auth.validate.password.format'), (value) =>
            /^[\w!@#$%^&*()_+\-={};':"|,.<>?]{4,32}$/.test(value)
          )
      });
    }

    return yup.object().shape({
      is_public: yup.string()
    });
  }, [showPasswordField]);

  const { control, formState, handleSubmit, setValue, reset } = useForm({
    defaultValues: initialForm,
    resolver: yupResolver(schema)
  });

  const { isDirty } = formState;

  const controlFields = useMemo(
    () => ({
      control,
      formState
    }),
    [control, formState]
  );

  const onSubmit = useCallback(async (formValues) => {
    const { is_public, password } = formValues;
    const publish = await onUpdate({ id, is_public, password });
    reset({ is_public: publish.is_public, password: publish.password });
  });

  const handleRadioChange = useCallback((evt) => {
    if (evt.target.value === '0') {
      setShowPasswordField(true);
    } else {
      setShowPasswordField(false);
    }
  }, []);

  const handleCancelClick = useCallback(() => {
    if (!!publishState.is_public) {
      setShowPasswordField(!publishState.is_public);
    } else {
      setShowPasswordField(true);
    }
    reset(initialForm);
  }, [initialForm, publishState]);
  return (
    <form noValidate>
      <RadioFieldHelperText onChange={handleRadioChange} options={options} name="is_public" {...controlFields} />
      {showPasswordField && (
        <InputField
          sx={{ display: showPasswordField ? '' : 'none', ml: 5 }}
          label={t('publicSettings.privacy.password')}
          fullWidth={false}
          name="password"
          {...controlFields}
        />
      )}

      <Box sx={{ display: isDirty ? '' : 'none', ml: 5 }}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)} sx={{ mr: 1 }}>
          {t('publicSettings.basicInfo.saveAndApply')}
        </Button>
        <Button variant="contained" onClick={handleCancelClick} sx={{ ml: 1 }}>
          {t('publicSettings.basicInfo.cancel')}
        </Button>
      </Box>
    </form>
  );
};

export default Privacy;
