/* eslint-disable no-unused-vars */
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import React from 'react';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import { useInteractionsForm } from 'src/hooks/useInteractionsForm';

const useStyles = makeStyles((theme) => ({
  interactions: {
    marginTop: '16px',
    display: 'flex',
    flexWrap: 'wrap'
  },

  item: {
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '25%'
    },
    marginBottom: theme.spacing(2),
    '& rect:hover': {
      cursor: 'pointer'
    }
  }
}));

const InteractionsForm = () => {
  const classes = useStyles();
  const {
    t,
    mode,
    onInteractionClick,
    backtoListMode,
    currentInteractionName,
    interactions,
    onSubmitCreate,
    onSubmitEdit,
    validateError
  } = useInteractionsForm();

  return (
    <Box sx={{ paddingTop: '12px' }}>
      <Typography
        variant="h5"
        onClick={backtoListMode}
        display="inline"
        sx={{
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline !important'
          }
        }}
      >
        {`${t('interaction.label.interactions')}`}
      </Typography>
      <Typography variant="h6" display="inline">
        {currentInteractionName && ` > ${interactions[currentInteractionName].name}`}
      </Typography>

      {mode === NODE_EDIT.MODE.LIST && (
        <Box className={classes.interactions}>
          {Object.keys(interactions).map((inter) => (
            <Box key={inter} onClick={() => onInteractionClick(inter)} className={classes.item}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {interactions[inter].icon}
              </Box>

              <Typography variant="subtitle1" textAlign="center">
                {interactions[inter].name}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
      {(mode === NODE_EDIT.MODE.CREATE || mode === NODE_EDIT.MODE.EDIT) && (
        <>
          {interactions[currentInteractionName].component}
          <Box sx={{ display: 'flex', width: '100%', mt: 3, justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              sx={{
                mr: 1,
                py: '2px !important',
                px: '24px !important',
                color: '#e872a4',
                '&:hover': {
                  color: '#ED257A'
                }
              }}
              onClick={backtoListMode}
            >
              {t('common.actions.cancel')}
            </Button>
            <Button
              variant="contained"
              sx={{
                mr: 1,
                py: '0px !important',
                px: '24px !important',
                backgroundColor: '#e872a4',
                '&:hover': {
                  backgroundColor: '#ED257A'
                }
              }}
              disabled={validateError}
              onClick={() => (mode === NODE_EDIT.MODE.CREATE ? onSubmitCreate() : onSubmitEdit())}
            >
              {t('common.actions.save')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default InteractionsForm;
