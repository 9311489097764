import { combineReducers } from 'redux';

import modalReducer from './modal';
import authReducer from './auth';
import projectList from './projectList';
import projectProcessManagement from './projectProcessManagement';
import projectDetailReducer from './projectDetail';
import accountReducer from './account';
import projectNode from './projectNode';
import backgroundSoundTrackReducer from './backgroundSoundTrack';
import publicSettingsReducer from './publicSettings';
import publishManagerReducer from './publishManager';
import projectPreviewReducer from './projectPreview';
import dragging from './dragging';
import videoPlayerReducer from './videoPlayer';
import videoViewerReducer from './videoViewer';
import edittingInteraction from './edittingInteraction';
import nodeInteraction from './nodeInteraction';

const rootReducer = combineReducers({
  auth: authReducer,
  modal: modalReducer,
  projectList,
  projectDetail: projectDetailReducer,
  account: accountReducer,
  projectNode,
  backgroundSoundTrack: backgroundSoundTrackReducer,
  publicSettings: publicSettingsReducer,
  publishManager: publishManagerReducer,
  projectPreview: projectPreviewReducer,
  dragging,
  videoPlayer: videoPlayerReducer,
  videoViewer: videoViewerReducer,
  edittingInteraction,
  nodeInteraction,
  projectProcessManagement
});

export default rootReducer;
