import { createSelector } from 'reselect';

const projectDetailState = (state) => state.projectDetail;

export const getProjectState = createSelector(projectDetailState, (projectDetail) => projectDetail.project);

export const getSearchTerms = createSelector(projectDetailState, (projectDetail) => projectDetail.searchTerms);

export const getPreviewMediaState = createSelector(projectDetailState, (projectDetail) => projectDetail.previewMedia);

export const getSelectedFolder = createSelector(projectDetailState, (projectDetail) => projectDetail.selectedFolder);

export const getUploadMediaProgress = createSelector(
  projectDetailState,
  (projectDetail) => projectDetail.uploadMediaProgress
);

export const getUploadMediaCancelTokenSource = createSelector(
  projectDetailState,
  (projectDetail) => projectDetail.uploadMediaCancelTokenSource
);

export const getUploadMediaQueueState = createSelector(
  projectDetailState,
  (projectDetail) => projectDetail.uploadMediaQueue
);

export const getFetchState = createSelector(projectDetailState, (projectDetail) => projectDetail.fetch);

export const getUploadMediaState = createSelector(projectDetailState, (projectDetail) => projectDetail.uploadMedia);

export const getCreateFolderState = createSelector(projectDetailState, (projectDetail) => projectDetail.createFolder);

export const getDeleteMediaState = createSelector(projectDetailState, (projectDetail) => projectDetail.deleteMedia);

export const getBackgroundSoundState = createSelector(
  projectDetailState,
  (projectDetail) => projectDetail.project?.background_sound
);

export const getPublishState = createSelector(projectDetailState, (projectDetail) => projectDetail.project?.publish);

export const getListVideo = createSelector(projectDetailState, (projectDetail) =>
  travelMedia(projectDetail.project, 'videos', [])
);

export const getListImage = createSelector(projectDetailState, (projectDetail) =>
  travelMedia(projectDetail.project, 'images', [])
);

export const getListSound = createSelector(projectDetailState, (projectDetail) =>
  travelMedia(projectDetail.project, 'sounds', [])
);
const travelMedia = (root, key, arr) => {
  if (!root) return arr;
  if (root[key]) {
    arr = [...arr, ...root[key]];
  }
  if (root.folders) {
    for (let i = 0; i < root.folders.length; ++i) {
      arr = travelMedia(root.folders[i], key, arr);
    }
  }
  return arr;
};

export const getProjectID = createSelector(projectDetailState, (projectDetail) => projectDetail.project?.id);
