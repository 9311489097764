import { createReducer } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { NodeInteractionTypes } from 'src/actions/nodeInteraction';
import { NODE_EDIT } from 'src/constants/nodeEdit';
import { INTERACTION } from 'src/constants/interactions';

const INITIAL_STATE = {
  node: null,
  mode: NODE_EDIT.MODE.LIST,
  loading: false,
  error: null
};
const resetRequest = () => INITIAL_STATE;

const createSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  error: null
});

const createFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false
});

const createRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null
});

const fetchSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  node: action.node,
  loading: false,
  error: null
});

const fetchFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false
});

const fetchRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null
});

const setMode = (state = INITIAL_STATE, action) => ({
  ...state,
  mode: action.mode
});

const hotUpdateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  error: null
});

const hotUpdateFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  node: {
    ...state.node,
    interactions: state.node.interactions.map((interact) => {
      if (interact.id_interaction === action.prevInteraction.id_interaction) {
        return {
          ...action.prevInteraction
        };
      }
      return interact;
    })
  },
  error: action.error,
  loading: false
});

const hotUpdateRequest = (state = INITIAL_STATE, action) => ({
  ...state,
  node: {
    ...state.node,
    interactions: state.node.interactions.map((interact) => {
      if (interact.id_interaction === action.interaction.interactionID) {
        if (interact.type === INTERACTION.TYPE.JUMP_TO) {
          return {
            ...interact,
            jump: {
              ...interact.jump,
              ...action.interaction.config
            }
          };
        }
        return {
          ...interact,
          ...action.interaction.config
        };
      }
      return interact;
    })
  },
  error: null
});

const updateRefetchSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  error: null
});

const updateRefetchFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false
});

const updateRefetchRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null
});

const hotDeleteSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  error: null
});

const hotDeleteFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  node: {
    ...state.node,
    interactions: [...state.interactions, action.prevInteraction]
  },
  error: action.error,
  loading: false
});

const hotDeleteRequest = (state = INITIAL_STATE, action) => ({
  ...state,
  node: {
    ...state.node,
    interactions: state.node.interactions.filter((interact) => interact.id_interaction !== action.interactionID)
  },
  error: null
});

const deleteRefetchSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  error: null
});

const deleteRefetchFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false
});

const deleteRefetchRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null
});

const updateOrderSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  error: null
});

const updateOrderFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false
});

const updateOrderRequest = (state = INITIAL_STATE, action) => ({
  ...state,
  node: {
    ...state.node,
    interactions: action.listInteraction
  },
  loading: true,
  error: null
});

const updateActiveSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  error: null
});

const updateActiveFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false
});

const updateActiveRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  error: null
});

const setMenuButtonEnable = (state = INITIAL_STATE, action) => ({
  ...state,
  node: {
    ...state.node,
    menuButtonEnable: action.value
  }
});
export const HANDLERS = {
  [NodeInteractionTypes.NODE_INTERACTION_CREATE_REQUEST]: createSuccess,
  [NodeInteractionTypes.NODE_INTERACTION_CREATE_SUCCESS]: createFailure,
  [NodeInteractionTypes.NODE_INTERACTION_CREATE_FAILURE]: createRequest,
  [NodeInteractionTypes.NODE_INTERACTION_UPDATE_REFETCH_REQUEST]: updateRefetchRequest,
  [NodeInteractionTypes.NODE_INTERACTION_UPDATE_REFETCH_SUCCESS]: updateRefetchSuccess,
  [NodeInteractionTypes.NODE_INTERACTION_UPDATE_REFETCH_FAILURE]: updateRefetchFailure,
  [NodeInteractionTypes.NODE_INTERACTION_FETCH_REQUEST]: fetchRequest,
  [NodeInteractionTypes.NODE_INTERACTION_FETCH_FAILURE]: fetchFailure,
  [NodeInteractionTypes.NODE_INTERACTION_FETCH_SUCCESS]: fetchSuccess,
  [NodeInteractionTypes.NODE_INTERACTION_SET_MODE]: setMode,
  [NodeInteractionTypes.NODE_INTERACTION_RESET_REQUEST]: resetRequest,
  [NodeInteractionTypes.NODE_INTERACTION_HOT_UPDATE_REQUEST]: hotUpdateRequest,
  [NodeInteractionTypes.NODE_INTERACTION_HOT_UPDATE_FAILURE]: hotUpdateFailure,
  [NodeInteractionTypes.NODE_INTERACTION_HOT_UPDATE_SUCCESS]: hotUpdateSuccess,
  [NodeInteractionTypes.NODE_INTERACTION_HOT_DELETE_REQUEST]: hotDeleteRequest,
  [NodeInteractionTypes.NODE_INTERACTION_HOT_DELETE_SUCCESS]: hotDeleteFailure,
  [NodeInteractionTypes.NODE_INTERACTION_HOT_DELETE_FAILURE]: hotDeleteSuccess,
  [NodeInteractionTypes.NODE_INTERACTION_DELETE_REFETCH_REQUEST]: deleteRefetchRequest,
  [NodeInteractionTypes.NODE_INTERACTION_DELETE_REFETCH_SUCCESS]: deleteRefetchFailure,
  [NodeInteractionTypes.NODE_INTERACTION_DELETE_REFETCH_FAILURE]: deleteRefetchSuccess,
  [NodeInteractionTypes.NODE_INTERACTION_UPDATE_ORDER_REQUEST]: updateOrderRequest,
  [NodeInteractionTypes.NODE_INTERACTION_UPDATE_ORDER_SUCCESS]: updateOrderSuccess,
  [NodeInteractionTypes.NODE_INTERACTION_UPDATE_ORDER_FAILURE]: updateOrderFailure,
  [NodeInteractionTypes.NODE_INTERACTION_UPDATE_ACTIVE_REQUEST]: updateActiveRequest,
  [NodeInteractionTypes.NODE_INTERACTION_UPDATE_ACTIVE_SUCCESS]: updateActiveSuccess,
  [NodeInteractionTypes.NODE_INTERACTION_UPDATE_ACTIVE_FAILURE]: updateActiveFailure,
  [NodeInteractionTypes.NODE_INTERACTION_SET_MENU_BUTTON_ENABLE]: setMenuButtonEnable
};

export const nodeInteraction = createReducer(INITIAL_STATE, HANDLERS);

const persistConfig = {
  keyPrefix: 'hls-',
  key: 'nodeInteraction',
  storage
};

export default persistReducer(persistConfig, nodeInteraction);
