import { withStyles, Slider } from '@material-ui/core';

export default withStyles(() => ({
  root: {
    '& .MuiSlider-valueLabelCircle': {
      borderRadius: '50% !important',
      width: 19,
      height: 19
    },
    '& .MuiSlider-valueLabelLabel': {
      fontSize: '11px'
    }
  },
  rail: {
    border: '1px solid #000',
    borderRadius: 16,
    height: 10,
    color: '#CCD3FF'
  },

  track: {
    borderRadius: 16,
    height: 10,
    color: '#ED257A'
  },

  thumb: {
    color: '#ED257A',
    width: 19,
    height: 19
  },
  valueLabel: {
    '&.MuiSlider-valueLabelOpen': {
      transform: 'scale(1) translateY(5px)'
    }
  }
}))(Slider);
