import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export const EndScreen = ({ src }) => (
  <Box
    id="waocon-endScreen"
    className="waocon-interaction-bounding-box"
    sx={{
      zIndex: 4
    }}
  >
    <img alt="endScreen" src={src} height="100%" width="100%" />
  </Box>
);

EndScreen.propTypes = {
  src: PropTypes.string.isRequired
};
