import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LayoutContainer, LayoutHeader } from 'src/components/layout';
import ProjectListContainer from 'src/containers/projects/project';

const Projects = () => {
  const { t } = useTranslation();
  return (
    <LayoutContainer title={t('projects.title')} header={<LayoutHeader title={t('projects.title')} />}>
      <Box>
        <ProjectListContainer />
      </Box>
    </LayoutContainer>
  );
};

export default Projects;
