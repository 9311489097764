import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DraggingActions from 'src/actions/dragging';
import * as ProjectDetailActions from 'src/actions/projectDetail';
import * as DraggingSelectors from 'src/selectors/dragging';

export const useDraggableTreeItem = ({ item, type }) => {
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const originPosRef = useRef(null);
  const rootRef = useRef(null);
  const boundingRootRef = useRef(null);

  const droptable = useSelector(DraggingSelectors.getDroptable);

  const dispatch = useDispatch();

  const onStart = useCallback(
    (evt, data) => {
      setDragging(true);
      originPosRef.current = { x: data.x, y: data.y };
      boundingRootRef.current = rootRef.current.getBoundingClientRect();
      setPosition({
        x: data.x - boundingRootRef.current.x + evt.clientX - boundingRootRef.current.x - 30,
        y:
          data.y -
          boundingRootRef.current.y +
          evt.clientY -
          boundingRootRef.current.y -
          48 /** de cursor chech xuong 1 ti */
      });

      dispatch(DraggingActions.setDragItem({ item, dragType: type }));
    },
    [originPosRef, rootRef, boundingRootRef, item, type]
  );

  const onDrag = useCallback((_, data) => {
    setPosition({ x: data.x, y: data.y });
  }, []);

  const onStop = useCallback(() => {
    setDragging(false);
    setPosition(originPosRef.current);

    dispatch(DraggingActions.stopDrag());
  }, [originPosRef.current]);

  const onMouseDown = useCallback(() => {
    dispatch(ProjectDetailActions.setPreviewMedia(item));
    dispatch(ProjectDetailActions.setSelectedFolder(null));
  }, [item]);

  return {
    dragging,
    position,
    droptable,
    rootRef,

    onStart,
    onDrag,
    onStop,
    onMouseDown
  };
};
