import { ListItemText, MenuItem, Popover, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const NodeContextMenu = ({ anchorEl, open, handleClose, contextItems, handleItemClick }) => (
  <Popover
    anchorEl={anchorEl}
    open={open || false}
    onClose={() => {
      handleClose();
    }}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    PaperProps={{
      sx: { width: 180, borderRadius: '4px !important', bgcolor: '#F1F4F7' }
    }}
  >
    {Object.keys(contextItems).map((item) => (
      <MenuItem
        onClick={() => {
          handleItemClick(item);
          handleClose();
        }}
        key={item}
        sx={{
          maxHeight: 40,
          '&:hover': { bgcolor: 'primary.light' },
          p: 0,
          px: 1,
          my: 0.1
        }}
      >
        <ListItemText
          primary={
            <Typography color="textPrimary" variant="subtitle2" sx={{ fontSize: 12 }}>
              {contextItems[item].label}
            </Typography>
          }
        />
        {contextItems[item].shortcut && (
          <Typography color="textPrimary" variant="subtitle2" sx={{ fontSize: 12 }}>
            {contextItems[item].shortcut}
          </Typography>
        )}
      </MenuItem>
    ))}
  </Popover>
);

NodeContextMenu.propTypes = {
  anchorEl: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  contextItems: PropTypes.object.isRequired,
  handleItemClick: PropTypes.func.isRequired
};

export default NodeContextMenu;
