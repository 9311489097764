import { IconButton, Typography, Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useProjectCreate } from 'src/hooks/projectList/create';
import moment from 'moment';

export const ViewLogErrorModal = ({ modalName, row, log }) => {
  const { t, isShowing, handleClose } = useProjectCreate({
    modalName
  });
  const viewStatus = {
    0: t('projectProcessManagement.viewStatus.publishedFailed'),
    1: t('projectProcessManagement.viewStatus.processing'),
    2: t('projectProcessManagement.viewStatus.published')
  };

  return (
    <>
      <Dialog onClose={handleClose} fullWidth open={isShowing} aria-labelledby="form-dialog-title">
        <DialogTitle>
          <Typography mt={2} variant="h4" align="center" fontWeight="bold">
            {row.name}
          </Typography>
          <IconButton
            style={{
              top: 5,
              right: 5,
              position: 'absolute'
            }}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            {t('projectProcessManagement.table.status')}:{viewStatus[row.publish.status]}
          </Typography>
          <Typography>
            {t('projectProcessManagement.table.createAt')}:{moment(row.created_at).format('YYYY-MM-DD HH:mm')}
          </Typography>
          <Box
            sx={{
              marginTop: '15px',
              maxWidth: '100%',
              padding: 4,
              border: '1px solid black',
              borderRadius: '10px',
              wordWrap: 'break-word',
              overflowY: 'auto',
              maxHeight: '300px'
            }}
          >
            <Typography>{log}</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

ViewLogErrorModal.propTypes = {
  modalName: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  log: PropTypes.string.isRequired
};
