import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress, Typography } from '@material-ui/core';

const LinearProgressWithLabel = ({ value, ...props }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress variant="determinate" value={value} {...props} />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
    </Box>
  </Box>
);

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired
};

const UploadMediaProgress = ({ value, ...props }) => (
  <Box sx={{ width: '100%' }}>
    <LinearProgressWithLabel value={value} {...props} />
  </Box>
);

UploadMediaProgress.propTypes = {
  value: PropTypes.number.isRequired
};

export default UploadMediaProgress;
