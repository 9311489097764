const MENU = {
  DURATION: 10,
  POSITION: {
    MIDDLE: '1',
    LEFT: '2',
    RIGHT: '3'
  },
  MENU_BUTTON: {
    LOCATION: {
      HIDDEN: '1',
      BOTTOM_RIGHT: '2',
      BOTTOM_LEFT: '3',
      TOP_RIGHT: '4',
      TOP_LEFT: '5'
    }
  },
  MENU_ITEM: {
    ACTION: {
      JUMP_TO_SPECIFIC: '1',
      JUMP_TO_START: '3',
      JUMP_TO_END: '4',
      JUMP_TO_INTERACTION: '5',
      LINK: '6',
      CONTINUE: '7',
      JUMP_TO_NODE: '8'
    },
    ACTION_KEY: {
      1: 'jump',
      3: 'jump',
      4: 'jump',
      6: 'link',
      7: 'continue'
    }
  }
};

const MAP_KEY_ACTION = {
  1: 'JUMP_TO_SPECIFIC',
  2: 'JUMP_TO_SPECIFIC_SCHEDULER',
  3: 'JUMP_TO_START',
  4: 'JUMP_TO_END',
  5: 'JUMP_TO_INTERACTION',
  6: 'LINK',
  7: 'CONTINUE',
  8: 'JUMP_TO_NODE'
};

const IMAGE = {
  MIN_DURATION: 10
};

const TEXT = {
  DEFAULT_DURATION: 10
};

const TRANSPARENT_BUTTON = {
  DEFAULT_DURATION: 10
};

const ZOOM = {
  DEFAULT_DURATION: 10
};

const LINK_VIDEO = {
  DEFAULT_DURATION: 10
};

const HIGHLIGHT = {
  MIN_DURATION: 10
};

const PAUSE = {
  DURATION: 10
};

const FORM = {
  DURATION: 10
};
const JUMP_TO = {
  DURATION: 10,
  ACTION: {
    JUMP_TO_SPECIFIC: '1',
    JUMP_TO_START: '3',
    JUMP_TO_END: '4',
    JUMP_TO_INTERACTION: '5',
    JUMP_TO_NODE: '8'
  }
};

const ACTION = {
  JUMP_TO_SPECIFIC: '1',
  JUMP_TO_SPECIFIC_SCHEDULER: '2',
  JUMP_TO_START: '3',
  JUMP_TO_END: '4',
  JUMP_TO_INTERACTION: '5',
  LINK: '6',
  CONTINUE: '7',
  JUMP_TO_NODE: '8'
};
const TYPE = {
  IMAGE: '1',
  QUESTION: '2',
  PAUSE: '3',
  RATING: '4',
  MENU: '5',
  TEXT: '6',
  SCROLL: '7',
  HOTPOT: '8',
  TRANSPARENT_BUTTON: '9',
  HIGHLIGHT: '10',
  ZOOM: '11',
  LINK: '12',
  SOUND: '13',
  FORM: '14',
  JUMP_TO: '15'
};

const KEY = {
  1: 'image',
  2: 'question',
  3: 'pause',
  4: 'rating',
  5: 'menu',
  6: 'text',
  7: 'scroll',
  8: 'hotpot',
  9: 'transparent_button',
  10: 'highlight',
  11: 'zoom',
  12: 'link',
  13: 'sound',
  14: 'form',
  15: 'jump_to'
};

const STYLE = {
  DEFAULT_COLOR_RGBA: {
    r: 0,
    g: 0,
    b: 0,
    a: 0.5
  },
  COLOR_WHITE_RGBA: {
    r: 255,
    g: 255,
    b: 255,
    a: 1
  },
  TEXT_STROKE: {
    WHITE: '1px white',
    BLACK: '1px black',
    NONE: 'none'
  },
  BORDER_STYLE: {
    SOLID: 'solid',
    DOTTED: 'dotted',
    DASHED: 'dashed'
  }
};
export const INTERACTION = {
  KEY,
  TYPE,
  STYLE,
  MENU,
  IMAGE,
  ACTION,
  TEXT,
  PAUSE,
  TRANSPARENT_BUTTON,
  ZOOM,
  LINK_VIDEO,
  FORM,
  HIGHLIGHT,
  JUMP_TO,
  MAP_KEY_ACTION
};
