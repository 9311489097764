/* eslint-disable no-unused-vars */
import { Box, IconButton } from '@material-ui/core';
import CodeOffIcon from '@material-ui/icons/CodeOff';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';
import { getListStartTime } from 'src/utils/objectCompare';
import videoController from 'src/utils/videoController';

export const NextPrevInteractionMultiInteraction = ({
  videoBounding,
  progressControlBounding,
  visible,
  listInteraction,
  onClickOff
}) => {
  const videoDuration = useSelector(VideoPlayerSelectors.getDuration);
  const currentTime = useSelector(VideoPlayerSelectors.getCurrentTime);
  const isPaused = useSelector(VideoPlayerSelectors.getIsPaused);
  const videoControl = videoController.getInstance();
  const [curIdx, setCurIdx] = useState(-1);
  const [listStartTime, setListStartTime] = useState([]);
  useEffect(() => {
    setListStartTime(getListStartTime(listInteraction));
  }, [listInteraction]);
  useEffect(() => {
    if (videoControl) {
      if (visible) {
        videoControl.pause();
      }
    }
  }, [isPaused, visible, videoControl]);
  useEffect(() => {
    if (!visible) {
      setCurIdx(-1);
    }
  }, [visible]);

  const onPrevClick = useCallback(() => {
    if (currentTime <= listStartTime[0] || (curIdx === 0 && currentTime === listStartTime[curIdx])) return;
    let newIdx;
    if (curIdx === -1 || currentTime !== listStartTime[curIdx]) {
      listStartTime.every((time, index, array) => {
        if (index === listStartTime.length - 1) {
          newIdx = index;
          return false;
        }
        if (currentTime === array[index + 1]) {
          newIdx = index;
          return false;
        }
        if (currentTime > time && currentTime < array[index + 1]) {
          newIdx = index;
          return false;
        }
        return true;
      });
    } else {
      newIdx = curIdx - 1;
    }
    videoControl.seek(listStartTime[newIdx]);
    setCurIdx(newIdx);
  }, [listStartTime, currentTime, videoControl, curIdx]);

  const onNextClick = useCallback(() => {
    if (
      currentTime >= listStartTime.at(-1) ||
      (curIdx === listStartTime.length - 1 && currentTime === listStartTime.at(-1))
    )
      return;
    let newIdx;
    if (currentTime < listStartTime[0]) {
      newIdx = 0;
    } else if (curIdx === -1 || currentTime !== listStartTime[curIdx]) {
      listStartTime.every((time, index, array) => {
        if (currentTime === time) {
          newIdx = index + 1;
          return false;
        }
        if (currentTime > time && currentTime < array[index + 1]) {
          newIdx = index + 1;
          return false;
        }
        return true;
      });
    } else {
      newIdx = curIdx + 1;
    }
    videoControl.seek(listStartTime[newIdx]);
    setCurIdx(newIdx);
  }, [listStartTime, currentTime, videoControl, curIdx]);

  return videoBounding && progressControlBounding ? (
    <Box
      className="waocon-interaction-bounding-box"
      sx={{
        overflow: 'visible',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `rgba(0, 0, 0, 0.5)`,
        visibility: visible ? 'visible' : 'hidden'
      }}
    >
      <IconButton onClick={onPrevClick}>
        <SkipPreviousIcon sx={{ color: 'white' }} />
      </IconButton>

      <IconButton
        onClick={() => {
          onClickOff();
          videoControl.play();
        }}
      >
        <CodeOffIcon sx={{ color: 'white' }} />
      </IconButton>

      <IconButton onClick={onNextClick}>
        <SkipNextIcon sx={{ color: 'white' }} />
      </IconButton>
      <Box
        sx={{
          position: 'absolute',
          top: progressControlBounding.top - videoBounding.top,
          left: progressControlBounding.left - videoBounding.left,
          width: progressControlBounding.width,
          height: progressControlBounding.height,
          pointerEvents: 'none'
        }}
      >
        {listStartTime.map((time, i) => (
          <Box
            key={`${time + i}`}
            index={time}
            sx={{
              width: '5px',
              height: '5px',
              bgcolor: '#e3a807',
              borderRadius: '50%',
              position: 'absolute',
              top: '-1px',
              left: (time / videoDuration) * progressControlBounding.width
            }}
          />
        ))}
      </Box>
    </Box>
  ) : null;
};

NextPrevInteractionMultiInteraction.propTypes = {
  listInteraction: PropTypes.array.isRequired,
  videoBounding: PropTypes.object.isRequired,
  progressControlBounding: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onClickOff: PropTypes.func.isRequired
};
