import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import * as NodeInteractionSelectors from 'src/selectors/nodeInteraction';
import * as VideoPlayerSelectors from 'src/selectors/videoPlayer';
import { getInteractionDisplay } from './InteractionsDisplay';

export const NodeInteractionsDisplay = ({ videoBounding }) => {
  const videoHasStarted = useSelector(VideoPlayerSelectors.getHasStarted);
  const nodeInteractions = useSelector(NodeInteractionSelectors.getInteractionList);
  return (
    <>
      {videoHasStarted &&
        nodeInteractions
          .filter((inter) => inter.is_active)
          .map((interaction) => getInteractionDisplay({ interaction, videoBounding }))}
    </>
  );
};

NodeInteractionsDisplay.propTypes = {
  videoBounding: PropTypes.object.isRequired
};
