/*eslint-disable*/
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as ProjectDetailActions from 'src/actions/projectDetail';
import * as ProjectDetailSelectors from 'src/selectors/projectDetail';
import { useNotify } from './useNotify';
import { validate } from 'uuid';

export const useProjectDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorNotify, successNotify, warningNotify } = useNotify();

  const projectState = useSelector(ProjectDetailSelectors.getProjectState);
  const searchTermsState = useSelector(ProjectDetailSelectors.getSearchTerms);
  const previewMediaState = useSelector(ProjectDetailSelectors.getPreviewMediaState);
  const selectedFolderState = useSelector(ProjectDetailSelectors.getSelectedFolder);
  const uploadMediaProgressState = useSelector(ProjectDetailSelectors.getUploadMediaProgress);
  const uploadMediaCancelTokenSourceState = useSelector(ProjectDetailSelectors.getUploadMediaCancelTokenSource);
  const uploadMediaQueueState = useSelector(ProjectDetailSelectors.getUploadMediaQueueState);
  const backgroundSoundState = useSelector(ProjectDetailSelectors.getBackgroundSoundState);
  const publishState = useSelector(ProjectDetailSelectors.getPublishState);

  const fetchState = useSelector(ProjectDetailSelectors.getFetchState);
  const uploadMediaState = useSelector(ProjectDetailSelectors.getUploadMediaState);

  const onFetch = useCallback(
    async (id) => {
      try {
        const data = await dispatch(ProjectDetailActions.fetchProjectRequest(id));
        dispatch(ProjectDetailActions.fetchProjectSuccess(data));
      } catch (err) {
        errorNotify(t('projectDetail.request.fetch.failure'));
        dispatch(ProjectDetailActions.fetchProjectFailure());
      }
    },
    [dispatch, navigate]
  );

  const onUploadMedia = useCallback(
    async ({ files, folderID, projectID, replace }) => {
      try {
        const index = await dispatch(ProjectDetailActions.uploadMediaRequest({ files, folderID, projectID, replace }));
        await dispatch(ProjectDetailActions.uploadMediaSuccess());
        dispatch(ProjectDetailActions.removeUploadMediaQueue(index));
        onFetch(projectID);
        successNotify(t('projectDetail.request.uploadMedia.success'));
      } catch (err) {
        if (validate(err.message)) {
          dispatch(ProjectDetailActions.removeUploadMediaQueue(err.message));
          errorNotify(t('projectDetail.request.uploadMedia.cancel'));
        } else {
          errorNotify(t('projectDetail.request.uploadMedia.failure'));
        }
        dispatch(ProjectDetailActions.uploadMediaFailure());
      }
    },
    [dispatch, navigate]
  );

  const onCancelUploadMedia = useCallback(() => {
    dispatch(ProjectDetailActions.cancelUploadMedia());
  }, [dispatch]);

  const onCreateFolder = useCallback(
    async ({ project_id, folder_id, name }) => {
      try {
        await dispatch(ProjectDetailActions.createFolderRequest({ project_id, folder_id, name }));
        dispatch(ProjectDetailActions.createFolderSuccess());
        onFetch(project_id);
        successNotify(t('projectDetail.request.createFolder.success'));
      } catch (err) {
        errorNotify(t('projectDetail.request.createFolder.failure'));
        dispatch(ProjectDetailActions.createFolderFailure());
      }
    },
    [dispatch, navigate]
  );

  const onDeleteMedia = useCallback(
    async ({ images, videos, sounds, id }) => {
      try {
        await dispatch(ProjectDetailActions.deleteMediaRequest({ images, videos, sounds }));
        dispatch(ProjectDetailActions.deleteMediaSuccess());
        dispatch(ProjectDetailActions.setPreviewMedia(null));
        onFetch(id);
        successNotify(t('projectDetail.request.deleteMedia.success'));
      } catch (err) {
        errorNotify(t('projectDetail.request.deleteMedia.failure'));
        dispatch(ProjectDetailActions.deleteMediaFailure());
      }
    },
    [dispatch, navigate]
  );

  const onDeleteFolder = useCallback(
    async ({ folderID, id }) => {
      try {
        await dispatch(ProjectDetailActions.deleteFolderRequest(folderID));
        dispatch(ProjectDetailActions.deleteFolderSuccess());
        dispatch(ProjectDetailActions.setSelectedFolder(null));
        onFetch(id);
        successNotify(t('projectDetail.request.deleteFolder.success'));
      } catch (err) {
        errorNotify(t('projectDetail.request.deleteFolder.failure'));
        dispatch(ProjectDetailActions.deleteFolderFailure());
      }
    },
    [dispatch, navigate]
  );

  const handNodeleSelect = (root, uuid) => {
    const imageMedia = root.images?.find((image) => image.uuid === uuid);
    if (imageMedia) {
      dispatch(ProjectDetailActions.setPreviewMedia(imageMedia));
      dispatch(ProjectDetailActions.setSelectedFolder(null));
      return;
    }

    const videoMedia = root.videos?.find((video) => video.uuid === uuid);
    if (videoMedia) {
      dispatch(ProjectDetailActions.setPreviewMedia(videoMedia));
      dispatch(ProjectDetailActions.setSelectedFolder(null));
      return;
    }

    const soundMedia = root.sounds?.find((sound) => sound.uuid === uuid);
    if (soundMedia) {
      dispatch(ProjectDetailActions.setPreviewMedia(soundMedia));
      dispatch(ProjectDetailActions.setSelectedFolder(null));
      return;
    }

    if (root.folders) {
      let foldersLength = root.folders.length;

      for (let i = 0; i < foldersLength; i++) {
        if (root.folders[i].uuid === uuid) {
          dispatch(ProjectDetailActions.setPreviewMedia(null));
          dispatch(ProjectDetailActions.setSelectedFolder(root.folders[i]));
          return;
        }

        const found = handNodeleSelect(root.folders[i], uuid);
        if (found) {
          dispatch(ProjectDetailActions.setPreviewMedia(found));
          dispatch(ProjectDetailActions.setSelectedFolder(null));
          return;
        }
      }
    }
  };

  const getMediaBySearchTerms = (root, searchTerms = '', acc = { images: [], videos: [], sounds: [] }) => {
    searchTerms = searchTerms.toLowerCase();

    const imagesMedia = root.images.filter((image) => image.name.toLowerCase()?.includes(searchTerms));
    if (imagesMedia.length) {
      acc.images = [...(acc?.images || []), ...imagesMedia];
    }

    const videosMedia = root.videos.filter((video) => video.name.toLowerCase()?.includes(searchTerms));
    if (videosMedia.length) {
      acc.videos = [...(acc?.videos || []), ...videosMedia];
    }

    const soundsMedia = root.sounds.filter((sound) => sound.name.toLowerCase()?.includes(searchTerms));
    if (soundsMedia.length) {
      acc.sounds = [...(acc?.sounds || []), ...soundsMedia];
    }

    if (root.folders) {
      let foldersLength = root.folders.length;
      for (let i = 0; i < foldersLength; i++) {
        getMediaBySearchTerms(root.folders[i], searchTerms, acc);
      }
    }
    return acc;
  };
  return {
    projectState,
    searchTermsState,
    previewMediaState,
    selectedFolderState,
    uploadMediaProgressState,
    uploadMediaCancelTokenSourceState,
    uploadMediaQueueState,
    backgroundSoundState,
    publishState,

    fetchState,
    uploadMediaState,

    onFetch,
    onUploadMedia,
    onCancelUploadMedia,
    onCreateFolder,
    onDeleteMedia,
    onDeleteFolder,

    handNodeleSelect,
    getMediaBySearchTerms
  };
};
